import React, { Component, useState } from "react";
import { Box, Text, Grid, Button } from "grommet";
import { BookingHeader } from "./BookingHeader";
import ContentContainer from "./ContentContainer";
import { CurrencyFormat } from "./CurrencyFormat";
import { Payment } from "./Payment";
import { PaymentHistory } from "./PaymentHistory";
import { useGuestPortal } from "../contexts/GuestPortalContext";
import { usePortalManagePayment } from "../contexts/PortalManagePaymentContext";

const ManagePayments = () => {
    const { booking, venue } = useGuestPortal();
    const {
        paymentOpen,
        OpenPaymentDialog,
        ClosePaymentDialog,
        payments,
        SaveBookingWithPendingPayment,
        net,
        discount,
        tax,
        gross,
        paidToDate,
        outstandingBalance,
        invoiceUrl
    } = usePortalManagePayment();

    return (
        <Box gap="medium" fill background="light-1">
            <Box gap="medium" alignSelf="center" width="xxlarge" background="light-3">
                <Box gap="medium" alignSelf="center" width="xlarge" pad="medium">
                    <BookingHeader booking={booking} venue={venue} />
                    <Box
                        alignSelf="center"
                        gap="large"
                        margin="small"
                        width="xxlarge"
                        direction="row-responsive"
                    >
                        <Box gap="large" width="xlarge" direction="column">
                            <ContentContainer
                                height={{ min: "400px" }}
                                title="Payment Summary"
                            >
                                <Grid
                                    areas={[
                                        { name: "Net_label", start: [0, 0], end: [0, 0] },
                                        { name: "Net", start: [1, 0], end: [1, 0] },
                                        { name: "Discount_label", start: [0, 1], end: [0, 1] },
                                        { name: "Discount", start: [1, 1], end: [1, 1] },
                                        { name: "tax_label", start: [0, 2], end: [0, 2] },
                                        { name: "tax", start: [1, 2], end: [1, 2] },
                                        { name: "total_label", start: [0, 3], end: [0, 3] },
                                        { name: "total", start: [1, 3], end: [1, 3] }
                                    ]}
                                    columns={["flex", "flex"]}
                                    rows={["xxsmall", "xxsmall", "xxsmall", "xxsmall"]}
                                    gap="small"
                                >
                                    <Box
                                        gridArea="Net_label"
                                        justify="center"
                                        alignContent="center"
                                        weight="bold"
                                    >
                                        <Text>Net:</Text>
                                    </Box>
                                    <Box
                                        gridArea="Net"
                                        justify="center"
                                        alignContent="center"
                                        round="xsmall"
                                        border={{ color: "brand", size: "xsmall" }}
                                    >
                                        <Text alignSelf="center">
                                            {CurrencyFormat(net)}
                                        </Text>
                                    </Box>
                                    <Box
                                        gridArea="Discount_label"
                                        justify="center"
                                        alignContent="center"
                                        weight="bold"
                                    >
                                        <Text>Discount:</Text>
                                    </Box>
                                    <Box
                                        gridArea="Discount"
                                        justify="center"
                                        alignContent="center"
                                        round="xsmall"
                                        border={{ color: "brand", size: "xsmall" }}
                                    >
                                        <Text alignSelf="center">
                                            {CurrencyFormat(discount)}
                                        </Text>
                                    </Box>
                                    <Box
                                        gridArea="tax_label"
                                        justify="center"
                                        alignContent="center"
                                        weight="bold"
                                    >
                                        <Text>Tax:</Text>
                                    </Box>
                                    <Box
                                        gridArea="tax"
                                        justify="center"
                                        alignContent="center"
                                        round="xsmall"
                                        border={{ color: "brand", size: "xsmall" }}
                                    >
                                        <Text alignSelf="center">
                                            {CurrencyFormat(tax)}
                                        </Text>
                                    </Box>
                                    <Box
                                        gridArea="total_label"
                                        justify="center"
                                        alignContent="center"
                                        weight="bold"
                                    >
                                        <Text>Total:</Text>
                                    </Box>
                                    <Box
                                        gridArea="total"
                                        justify="center"
                                        alignContent="center"
                                        round="xsmall"
                                        border={{ color: "brand", size: "xsmall" }}
                                    >
                                        <Text alignSelf="center">
                                            {CurrencyFormat(gross)}
                                        </Text>
                                    </Box>
                                </Grid>
                            </ContentContainer>
                        </Box>
                        <Box gap="medium" width="xlarge" direction="column">
                            <ContentContainer
                                height={{ min: "400px" }}
                                title="Payment Schedule"
                            >
                                <Grid
                                    areas={[
                                        { name: "PaidTodate_label", start: [0, 0], end: [0, 0] },
                                        { name: "PaidTodate", start: [1, 0], end: [1, 0] },
                                        {
                                            name: "OutstandingBalance_label",
                                            start: [0, 1],
                                            end: [0, 1]
                                        },
                                        { name: "OutstandingBalance", start: [1, 1], end: [1, 1] }
                                    ]}
                                    columns={["flex", "flex"]}
                                    rows={["xxsmall", "xxsmall", "xxsmall", "xxsmall"]}
                                    gap="small"
                                >
                                    <Box
                                        gridArea="PaidTodate_label"
                                        justify="center"
                                        alignContent="center"
                                        weight="bold"
                                    >
                                        <Text>Paid To Date:</Text>
                                    </Box>
                                    <Box
                                        gridArea="PaidTodate"
                                        justify="center"
                                        alignContent="center"
                                        round="xsmall"
                                        border={{ color: "brand", size: "xsmall" }}
                                    >
                                        <Text alignSelf="center">
                                            {CurrencyFormat(paidToDate)}
                                        </Text>
                                    </Box>
                                    <Box
                                        gridArea="OutstandingBalance_label"
                                        justify="center"
                                        alignContent="center"
                                        weight="bold"
                                    >
                                        <Text>Outstanding Balance:</Text>
                                    </Box>
                                    <Box
                                        gridArea="OutstandingBalance"
                                        justify="center"
                                        alignContent="center"
                                        round="xsmall"
                                        border={{ color: "brand", size: "xsmall" }}
                                    >
                                        <Text alignSelf="center">
                                            {CurrencyFormat(outstandingBalance)}
                                        </Text>
                                    </Box>
                                </Grid>
                                <Box justify="end" gap="large" direction="row">
                                    <Button
                                        id="btnPrintInvoice"
                                        primary
                                        label="Print Invoice"
                                        href={invoiceUrl}
                                        target="_blank"
                                        disabled={invoiceUrl == null}
                                    />
                                    <Button
                                        id="btnMakePayment"
                                        primary
                                        label="Make Payment"
                                        disabled={outstandingBalance <= 0}
                                        onClick={OpenPaymentDialog}
                                    />
                                </Box>
                            </ContentContainer>
                        </Box>
                    </Box>
                    <Box alignSelf="center" width="xlarge">
                        <ContentContainer title="Payment History">
                            {booking ? (
                                <PaymentHistory payments={payments} step={10} />
                            ) : (
                                <Text> No Booking Data</Text>
                            )}
                        </ContentContainer>
                    </Box>
                </Box>
            </Box>
            {
                //PaymentOpen && <Payment setupPayment={setupPayment} onCancel={() => { SetPaymentOpen(false) }} onPay={(amount) => { SetPaymentOpen(false)}} booking={booking} stripePromise={stripePromise} />
            }
            {paymentOpen && (
                <Payment />
            )}
        </Box>
    );
};
export { ManagePayments };
