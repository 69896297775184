import React from "react";
import { Box, Button } from "grommet";
import ContentContainer from "./ContentContainer";
import ResourceCard from './ResourceCard';
import Slider from "react-slick";


export function SelectResource(props) {
    const { onClick, resourceList} = props;

    if (!resourceList) return (
        <ContentContainer title="No Resources">
            <Box width="xlarge" pad="small" margin="small" />
        </ContentContainer>);

    const sliderSettings = {
        dots: true,
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: resourceList.length < 3 ? resourceList.length : 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        cssEase: "linear"
    };


    let resourceCards = resourceList.map((resourceItem, index) => (<Box key={resourceItem.id} pad="small" margin="small">
        <ResourceCard id={"card" + resourceItem.id} resource={resourceItem} height="medium" action="Add" onClickAddItem={() => { onClick(resourceItem); }} />
    </Box>));
    return (
        <Box width="xlarge" pad="small" margin="small">
            <Slider {...sliderSettings}>
                {resourceCards}
            </Slider>
        </Box>
);
}
