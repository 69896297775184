import React, { Component } from "react";
import SignIn from './SignIn';
import LeadGuestPortal from './LeadGuestPortal'
import GuestPortal from './GuestPortal'
import { GuestPortalContextProvider, useGuestPortal } from "../contexts/GuestPortalContext";
import { PortalContextProvider } from "../contexts/usePortal";


function PortalPage() {
    const { signedIn, leadBooker, portalSettings, booking, venue, contact, chatHistory, resources, logout, checkPermissions } = useGuestPortal();
    if (!signedIn) {
        return (
            <>
                <SignIn />
            </>
        );
    }
    else {
        if (portalSettings == null) {

            return (<></>)
        }
        else {
            if (leadBooker) {
                return (
                    <LeadGuestPortal />
                );
            }
            else {
                return (<GuestPortal portalSettings={portalSettings} booking={booking} venue={venue} contact={contact} resources={resources} checkPermissions={checkPermissions} logout={logout} />);
            }

        }
    }
}

export default class Widget extends Component {
    constructor(props) {
        super(props);
    }


    render() {


        return (
            <PortalContextProvider>
                <GuestPortalContextProvider>
                    <PortalPage />
                </GuestPortalContextProvider>
            </PortalContextProvider>)
    };

};

