import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Link } from "react-router-dom";
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import ResourceDescription from './ResourceDescription';
import { useTranslation } from 'react-i18next'

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const useStyles = makeStyles(theme => ({
    infoIcon: {
        cursor: 'pointer',
        color: theme.palette.custom.secondary,
    }
}));

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose &&
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            }
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function VenueDetails({ venue, addVenueToBooking }) {
    const [open, setOpen] = React.useState(false);
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    if (!venue) return null;

    const { id, name, description, image, price, price_type, layouts } = venue;
    const addVenueToBookingChild = addVenueToBooking;

    const OpenVenueData = () => {
        setOpen(true);
    };
    const CloseVenueData = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Tooltip title={<Typography color="inherit" style={{ textAlign: 'center' }}>{t("switch.widget.venue.details")}</Typography>} aria-label={<Typography color="inherit">{t("switch.widget.venue.details")}</Typography>}>
                <IconButton id="openVenueDetails" onClick={OpenVenueData} style={{ padding: '0px' }}>
                    <InfoIcon fontSize="small" className={classes.infoIcon} />
                </IconButton>
            </Tooltip>
            {open &&
                <Dialog id="venueDataPopup" onClose={CloseVenueData} aria-labelledby="customized-dialog-title" open={open}>
                    <DialogTitle id="customized-dialog-title" onClose={CloseVenueData}>
                        {name}
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography gutterBottom>
                            <ResourceDescription id={id} description={description} venue={true} />
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button id="btn-close" autoFocus onClick={CloseVenueData} color="primary">{t("switch.widget.buttons.close")}</Button>
                        <Button id="btn-add" onClick={addVenueToBookingChild} variant="outlined" style={{ color: `${theme.palette.success.main}`, border: '2px solid', borderColor: `${theme.palette.success.main}` }}>{t("switch.widget.buttons.add")}</Button>
                    </DialogActions>
                </Dialog>
            }
        </React.Fragment>
    );
}