import React from "react";
import { Anchor, Box, ResponsiveContext, Text } from "grommet";
import styled from "styled-components";
import { Fragment } from "react";

const StyledAnchor = styled(Anchor)`
  font-weight: 200;
`;

export const data = [
    ["legal stuff", "terms", "privacy", "security"],
    ["company", "about", "press", "events"]
];





const FooterContent = (props) => {
    const { portalSettings } = props;
    return (
        <ResponsiveContext.Consumer>
            {size => (
                <Box
                    direction="row"
                    gap={size !== "xsmall" && size !== "small" ? "xlarge" : "small"}
                >
                    {getContent({ portalSettings })}
                </Box>
            )}
        </ResponsiveContext.Consumer>
    );
}

export { FooterContent };


const getContent = (props) => {
    const { portalSettings } = props;

    return data.map((item, index) => (
        <Box gap="small" key={index + item[0]}>
            <Text weight="bolder" size="16px" style={{textTransform: 'capitalize'}}>
                {item[0]}
            </Text>
            <Box gap="xsmall">
                <StyledAnchor href={getlink(item[1], portalSettings)} size="small" color="black">
                    {item[1]}
                </StyledAnchor>
                <StyledAnchor href={getlink(item[2], portalSettings)} size="small" color="black">
                    {item[2]}
                </StyledAnchor>
                <StyledAnchor href={getlink(item[3], portalSettings)} size="small" color="black">
                    {item[3]}
                </StyledAnchor>
            </Box>
        </Box>
    ));
};

function getlink(linkName, settings) {
    if (settings != null && settings.footerLinks != null) {
        return settings.footerLinks[linkName] ? settings.footerLinks[linkName] : "/";
    }
    else {
        return "/";
    }
};