import React from "react";
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CurrencyFormat from './CurrencyFormat';
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 'auto',
        height: 'auto',
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
    takeAwayDescription: {
        fontSize: 12,
    }
}));

const Details = props => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    if (!props.occasionData) return null;

    const details = props.occasionData[0]

    return (
        <Grid container direction="row" justify="space-evenly" alignItems="center" spacing={4}>
            <Grid item xs={12} >
                <Paper style={{ padding: '40px' }}>
                    <Typography component="h6" style={{ textAlign: 'center' }}>
                        {t("switch.widget.takeaway.details")}
                    </Typography>
                    <Typography variant="body2" component="span" className={classes.takeAwayDescription}>
                        {t("switch.widget.takeaway.maxdistance")} <CurrencyFormat value={details.maxDeliveryDistanceMiles} suffix="mi" matUiVariant="body2" />
                    </Typography>
                    <Typography variant="body2" component="span" className={classes.takeAwayDescription}>
                        {t("switch.widget.takeaway.minvalue")}  <CurrencyFormat value={details.minimumOrderValue} prefix={t("switch.widget.currancysymbol")} matUiVariant="body2" />
                    </Typography>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default Details;
