import React from "react";
import ContentContainer from "./ContentContainer";
import { Box, Button, Grommet, TextInput, FormField, Select, Form, Layer } from "grommet";
import TimeInput from "./TimeInput";
import styled from 'styled-components'
import { Dialog } from "./Dialog";

const BorderLessTimeInput = styled(TimeInput)`
  border-style: none;
.bviFhB {
 font:inherit;
 background-color:rgba(255,255,255,1);
 border:1px solid rgba(164,167,181,1);
 border-radius:12rem;
 width:100%;
 -webkit-transition:all 0.1s linear,padding 0s,border 0s;
 transition:all 0.1s linear,padding 0s,border 0s;
 display:inline-block;
 padding:0 1rem;
 line-height:2.5rem;
 height:2.5rem;
 color:rgba(42,48,57,1);
 font-size:1rem;
 box-sizing:border-box;
 margin:0;
 padding-left:1rem;
 padding-right:2.35rem;
 border-style: none;
}

`;

export function AgendaItemDialog(props) {
    const { open, onCancel, onSave, onDelete, onChange, item } = props;

    const isNew = !item ? true : !item.id ? true : false;
    let x = isNew ? {
        entryType: "general",
        startTime: null,
        duration: null,
        description: null,
        bookingDay: 1,
        id: null
    } : item

    const [thisItem, setItem] = React.useState();
    if (!open && thisItem) setItem(null);
    if (open && !thisItem) {
        setItem(x);
    }

    const options = [
        { label: "General", value: "general" },
        { label: "Arrival", value: "arrival" },
        { label: "Setup", value: "setup" },
        { label: "Clean", value: "clean" },
        { label: "Service", value: "service" },
        { label: "Clear", value: "clear" },
        { label: "Departure", value: "departure" },
    ];

    function HandleChange(props) {
        const name = props.target.name;

        let value = "";

        switch (name) {
            case "entryType":
                value = props.option.value;
                break;
            case "duration":
                value = Number.parseInt(props.target.value);
                break;
            default:
                value = props.target.value;
                break;
        }

        const newState = {
            ...thisItem,
            [name]: value
        };
        if (onChange) {
            onChange(newState);
        }

        setItem(newState);
    };

    if (!open || !thisItem) return null;
    return (
        //<Layer id="agendaEditDialog" onClose={onCancel}>
        //    <ContentContainer emoji=":page_facing_up:" title={!isNew ? "Edit Agenda" : "Add Agenda Item"} height="large" margin="small">
        //        <Box width="medium" >


        //            <FormField label="Type">
        //                <Select
        //                    id="ffEntryType"
        //                    name="entryType"
        //                    options={options}
        //                    value={thisItem.entryType || "general"}
        //                    labelKey="label"
        //                    valueKey={{ key: "value", reduce: true }}
        //                    onChange={HandleChange} />
        //            </FormField>
        //            <FormField label="Start Time">
        //                {//<TextInput id="ffStartTime" name="startTime" value={thisItem.startTime || ""} onChange={HandleChange} />
        //                }
        //                <BorderLessTimeInput id="ffStartTime" name="startTime" value={thisItem.startTime || ""} onChange={(value) => { HandleChange({ target: { name: "startTime", value: value } }) }} />
        //            </FormField>
        //            <FormField label="Duration">
        //                <TextInput id="ffDuration" name="duration" value={thisItem.duration || "0"} onChange={HandleChange} />
        //            </FormField>
        //            <FormField label="Description">
        //                <TextInput id="ffDescription" name="description" value={thisItem.description || ""} onChange={HandleChange} />
        //            </FormField>
        //            <Box tag="footer" margin={{ top: "medium" }} direction="row" justify="between">
        //                <Button id="btnCancel" label="Cancel" onClick={onCancel} />
        //                {!isNew && < Button id="btnDelete" primary label="Delete" onClick={onDelete} />}
        //                <Button id="btnSave" primary label="Save" onClick={onSave} />
        //            </Box>


        //        </Box>
        //    </ContentContainer>
        //</Layer>
        <Dialog id="agendaEditDialog" emoji=":page_facing_up:" dialogStyle="EditBox" title={!isNew ? "Edit Agenda" : "Add Agenda Item"} onCancel={onCancel} onSave={onSave} onDelete={!isNew ? onDelete : null} >
            <Box direction="column" flex="grow">
                <FormField label="Type">
                    <Select
                        id="ffEntryType"
                        name="entryType"
                        options={options}
                        value={thisItem.entryType || "general"}
                        labelKey="label"
                        valueKey={{ key: "value", reduce: true }}
                        onChange={HandleChange} />
                </FormField>
                <FormField label="Start Time">
                    {//<TextInput id="ffStartTime" name="startTime" value={thisItem.startTime || ""} onChange={HandleChange} />
                    }
                    <BorderLessTimeInput id="ffStartTime" name="startTime" value={thisItem.startTime || ""} onChange={(value) => { HandleChange({ target: { name: "startTime", value: value } }) }} />
                </FormField>
                <FormField label="Duration">
                    <TextInput id="ffDuration" name="duration" value={thisItem.duration || "0"} onChange={HandleChange} />
                </FormField>
                <FormField label="Description">
                    <TextInput id="ffDescription" name="description" value={thisItem.description || ""} onChange={HandleChange} />
                </FormField>
            </Box>
        </Dialog>

    );
}
