import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import {
    MuiPickersUtilsProvider,
    TimePicker,
    DatePicker
} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";

import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { useTheme, makeStyles } from "@material-ui/core/styles";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import { ShowLayoutSelector } from "./WidgetRoomBooking";
import EditIcon from "@material-ui/icons/Edit";
import InputAdornment from "@material-ui/core/InputAdornment";
import ConvertHexToRGBA from "./ConvertHexToRGBA";
import { TimeSelect } from './TimeSelect';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    guestSelectorContainer: {
        borderLeft: "1px solid " + theme.palette.custom.secondary,
        borderTopLeftRadius: "10px",
        borderBottomLeftRadius: "10px",
        paddingLeft: "8px",
        paddingRight: "10px",
        borderTop: "1px solid " + theme.palette.custom.secondary,
        borderBottom: "1px solid " + theme.palette.custom.secondary,
        paddingTop: "16px",
        [theme.breakpoints.down("sm")]: {
            borderBottomLeftRadius: "0px"
        }
    },
    guestSelectorLabel: {
        fontSize: "12px",
        paddingBottom: "4px"
    },
    dateSelectorContainer: {
        paddingLeft: "10px",
        paddingRight: "10px",
        borderLeft: "1px solid " + theme.palette.custom.secondary,
        borderTop: "1px solid " + theme.palette.custom.secondary,
        borderBottom: "1px solid " + theme.palette.custom.secondary,
        [theme.breakpoints.down("sm")]: {
            borderRight: "1px solid " + theme.palette.custom.secondary,
            borderTopRightRadius: "10px"
        }
    },
    startTimeSelectorContainer: {
        paddingLeft: "10px",
        paddingRight: "10px",
        borderLeft: "1px solid " + theme.palette.custom.secondary,
        borderTop: "1px solid " + theme.palette.custom.secondary,
        borderBottom: "1px solid " + theme.palette.custom.secondary,
        [theme.breakpoints.down("sm")]: {
            borderLeft: "1px solid " + theme.palette.custom.secondary,
            borderBottomLeftRadius: "10px",
            paddingLeft: "8px",
            borderTop: "0px"
        }
    },
    endTimeSelectorContainer: {
        borderRight: "1px solid " + theme.palette.custom.secondary,
        borderTopRightRadius: "10px",
        borderBottomRightRadius: "10px",
        paddingRight: "8px",
        paddingLeft: "10px",
        borderLeft: "1px solid " + theme.palette.custom.secondary,
        borderTop: "1px solid " + theme.palette.custom.secondary,
        borderBottom: "1px solid " + theme.palette.custom.secondary,
        [theme.breakpoints.down("sm")]: {
            borderTop: "0px",
            borderTopRightRadius: "0px"
        }
    },
    periodSelectorContainer: {
        borderRight: "1px solid " + theme.palette.custom.secondary,
        borderTopRightRadius: "10px",
        borderBottomRightRadius: "10px",
        paddingRight: "8px",
        paddingLeft: "10px",
        borderTop: "1px solid " + theme.palette.custom.secondary,
        borderLeft: "1px solid " + theme.palette.custom.secondary,
        borderBottom: "1px solid " + theme.palette.custom.secondary,
        paddingTop: "16.8px"
    },
    adornment: {
        color: ConvertHexToRGBA(theme.palette.custom.secondary, 0.5)
    }
}));

const getUnique = (items, value) => {
    return [...new Set(items.map(item => item[value]))];
};

const BookingFormPickers = props => {
    const theme = useTheme();
    const classes = useStyles();
    const [thisPeriodId, setThisPeriodId] = React.useState(null);
    const [startTimes, setStartTimes] = React.useState(null);
    const { context, hideSettings } = props;
    const { t, i18n } = useTranslation();

    if (!context || !hideSettings) return null;

    const {
        occasionData,
        occasionBaseData,
        bookingRequest,
        setBookingDate,
        handleChange,
        calculatedRate,
        type,
        minDate,
        maxDate,
        selectedGuests,
        selectedStart,
        selectedEnd,
        hideBookingEndTime,
        setStartTime,
        setEndTime,
        setGuestNumbers,
        resetWidgetData,
        removeBookingItem,
        setOccasion,
        calculating,
        style,
        period,
        setThisPeriod
    } = context;

    const {
        hideOccasion,
        hideDatePicker,
        hideTimePicker,
        hideGuestPicker,
        roomPickers
    } = hideSettings;

    let OccasionType = getUnique(occasionBaseData, "name");
    // add all
    if (context.style !== "takeAway") {
        OccasionType = ["all", ...OccasionType];
    }
    // map to jsx
    OccasionType = OccasionType.map((item, index) => (
        <option key={index} value={item}>
            {item}
        </option>
    ));
    // map to jsx for takeAway
    if (context.style === "takeAway") {
        OccasionType = OccasionType.map((item, index) => (
            <Grid item xs={6}>
                <FormControlLabel
                    key={index}
                    value={item.value}
                    control={<Radio />}
                    label={item}
                    labelPlacement="bottom"
                />
            </Grid>
        ));
    }

    // ------------
    // Get occasion from data matching currently selected occasion
    let Occasion = occasionData.filter(x => x.name === type);

    //Calculate Occasion length
    let OccasionLength = "";
    if (Occasion.length > 0) {
        if (Occasion[0].minDurationMinutes === Occasion[0].maxDurationMinutes) {
            OccasionLength = Occasion[0].minDurationMinutes;
        } else {
            OccasionLength =
                Occasion[0].maxDurationMinutes - Occasion[0].minDurationMinutes;
        }
    }

    function localSetThisPeriod() {
        setThisPeriod(Occasion[0].periods[0]);
        setThisPeriodId(Occasion[0].periods[0].periodid);
        let newTime = new Date(bookingRequest.bookingDate);
        newTime.setHours(Number.parseInt(bookingRequest.startTime.split(":")[0]));
        newTime.setMinutes(Number.parseInt(bookingRequest.startTime.split(":")[1]));
        setEndTime(new Date(newTime.getTime() + OccasionLength * 60000));
    }

    // Set selected period
    const SelectedPeriod = event => {
        let period = Occasion[0].periods.filter(
            x => x.periodid === event.target.value
        );
        setThisPeriod(period[0]);
        setThisPeriodId(period[0].periodid);
    };

    const SelectedStartTimes = event => {
        setStartTimes(event.target.value);
        let newTime = new Date(bookingRequest.bookingDate);
        newTime.setHours(Number.parseInt(event.target.value.split(":")[0]));
        newTime.setMinutes(Number.parseInt(event.target.value.split(":")[1]));
        //const newTime = Date.parse("01-01-2020T12:00:00");
        setStartTime(newTime);
        const endTime = new Date(newTime.getTime() + OccasionLength * 60000);
        setEndTime(endTime);
    };

    const localSetGuestNumbers = event => {
        let value = Number.parseInt(event.target.value);
        const RoomDetailFromBookingRequest = context.bookingRequest.Details.filter(
            detail => detail.resourceType === "Room"
        );
        let RoomDetailsFromContext = "";
        if (RoomDetailFromBookingRequest.length === 0) {
            setGuestNumbers(event);
        } else {
            RoomDetailsFromContext = context.rooms.filter(
                room => room.id === RoomDetailFromBookingRequest[0].ResourceId
            );
            let capacity = RoomDetailsFromContext[0].maximumCapacity;
            const LayoutDetails = RoomDetailsFromContext[0].layouts.filter(
                layout => layout.id === RoomDetailFromBookingRequest[0].roomLayoutId
            );
            if (LayoutDetails && LayoutDetails.length > 0) {
                capacity =
                    LayoutDetails[0].capacitySeated > LayoutDetails[0].capacityStanding
                        ? LayoutDetails[0].capacitySeated
                        : LayoutDetails[0].capacityStanding;
            }
            if (value <= capacity) {
                setGuestNumbers(event);
            } else if (event.target.value === "") {
                setGuestNumbers(event);
            } else {
                alert(t("switch.widget.bookingformpicker.roomcapacityexceeded"));
                setGuestNumbers(event);
            }
        }
    };

    const timeInputStyle = {
        border: 'none',
    };

    return (
        <Grid container direction="row" justify="center" alignItems="center">
            {roomPickers &&
                hideOccasion &&
                hideDatePicker &&
                !hideTimePicker &&
                hideGuestPicker && (
                    <React.Fragment>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid
                                item
                                xs={6}
                                md={3}
                                id="guest-selector-container"
                                className={classes.guestSelectorContainer}
                            >
                                <InputLabel
                                    id="guest-selector-label"
                                    className={classes.guestSelectorLabel}
                                >
                                    {t("switch.widget.bookingformpicker.setguests")}
                                </InputLabel>
                                <TextField
                                    variant="standard"
                                    id="selectedGuests"
                                    labelId="guest-selector-label"
                                    name="selectedGuests"
                                    value={selectedGuests}
                                    onChange={localSetGuestNumbers}
                                    notched={true}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon
                                                    textSize="small"
                                                    className={classes.adornment}
                                                />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                md={3}
                                id="date-selector-container"
                                className={classes.dateSelectorContainer}
                            >
                                <DatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label={t("switch.widget.bookingformpicker.bookingdate")}
                                    value={bookingRequest.bookingDate}
                                    onChange={setBookingDate}
                                    autoOk={true}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date"
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon
                                                    textSize="small"
                                                    className={classes.adornment}
                                                />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </React.Fragment>
                )}
            {!hideOccasion && (
                <Grid item xs={12}>
                    {style === "takeAway" && (
                        <React.Fragment>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="delivery or take away"
                                    name="deliveryOrTakeAway"
                                    defualtValue={type}
                                    value={type}
                                    onChange={setOccasion}
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                    >
                                        {OccasionType}
                                    </Grid>
                                </RadioGroup>
                            </FormControl>
                        </React.Fragment>
                    )}
                    {style !== "takeAway" && (
                        <React.Fragment>
                            <Typography gutterBottom align="center">
                                {t("switch.widget.bookingformpicker.occasion")}
                            </Typography>
                            <select
                                name="type"
                                id="type"
                                onChange={setOccasion}
                                className="form-control"
                                value={type}
                            >
                                {OccasionType}
                            </select>
                        </React.Fragment>
                    )}
                </Grid>
            )}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                {!hideDatePicker && (
                    <Grid item xs={4}>
                        <DatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label={t("switch.widget.bookingformpicker.bookingdate")}
                            value={bookingRequest.bookingDate}
                            onChange={setBookingDate}
                            minDate={minDate}
                            maxDate={maxDate}
                            KeyboardButtonProps={{
                                "aria-label": "change date"
                            }}
                        />
                    </Grid>
                )}
                <Grid
                    item
                    xs={6}
                    md={roomPickers ? 3 : 4}
                    id={roomPickers ? "starttime-selector-container" : ""}
                    className={roomPickers ? classes.startTimeSelectorContainer : ""}
                >
                    {hideBookingEndTime &&
                        period.period &&
                        period.period.validStartTimes.length >= 1 ? (
                        <React.Fragment>
                            <InputLabel
                                id="period-time-label"
                                style={{ marginTop: roomPickers ? 16.8 : 19, fontSize: 13 }}
                            >
                                {t("switch.widget.bookingformpicker.settime")}
                            </InputLabel>
                            <Select
                                labelId="period-time-label"
                                id="period-time"
                                value={bookingRequest.startTime}
                                defaultValue={bookingRequest.startTime}
                                onChange={SelectedStartTimes}
                                style={{ width: "100%", maxWidth: 226 }}
                            >
                                {period.period.validStartTimes.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item}>
                                            {item.split(":")[0]}:{item.split(":")[1]}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </React.Fragment>
                    ) : (

                        <TimeSelect id="start-time-picker" labelStyle={{ marginTop: roomPickers ? 16.8 : 19, fontSize: 13 }} label={t("switch.widget.bookingformpicker.starttime")} value={selectedStart} defaultValue={selectedStart} onChange={setStartTime} style={{ width: "100%", maxWidth: 426 }} earliest="06:00" latest="24:00" interval={15} bookingDate={bookingRequest.bookingDate} />

                        //              <TimePicker
                        //  margin="normal"
                        //  id="start-time-picker"
                        //  label="Start Time"
                        //  value={selectedStart}
                        //  onChange={setStartTime}
                        //                  minutesStep={15}
                        //                  style={timeInputStyle}
                        //  InputProps={{
                        //    endAdornment: (
                        //      <InputAdornment position="end">
                        //        <EditIcon textSize="small" className={classes.adornment} />
                        //      </InputAdornment>
                        //    )
                        //  }}
                        ///>
                    )}
                </Grid>
                {!hideTimePicker &&
                    hideBookingEndTime && (
                        <Grid
                            item
                            xs={6}
                            md={roomPickers ? 3 : 4}
                            style={{ marginTop: roomPickers ? "" : 19 }}
                            id={roomPickers ? "starttime-selector-container" : ""}
                            className={roomPickers ? classes.periodSelectorContainer : ""}
                        >
                            <InputLabel id="period-selector-label" style={{ fontSize: 13 }}>
                                {t("switch.widget.bookingformpicker.setperiod")}
                            </InputLabel>
                            <Select
                                labelId="period-selector-label"
                                id="period-selector"
                                value={thisPeriodId}
                                defaultValue={
                                    thisPeriodId ? thisPeriodId : localSetThisPeriod()
                                }
                                onChange={SelectedPeriod}
                                style={{ width: "100%", maxWidth: 226 }}
                            >
                                {Occasion[0].periods.map((item, index) => {
                                    if (item.period.fixedStart !== null) {
                                        return (
                                            <MenuItem key={index} value={item.periodid}>
                                                {item.period.name}
                                            </MenuItem>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </Select>
                        </Grid>
                    )}
                {hideBookingEndTime ? (
                    ""
                ) : (
                    <Grid
                        item
                        xs={6}
                        md={roomPickers ? 3 : 4}
                        id={roomPickers ? "endtime-selector-container" : ""}
                        className={roomPickers ? classes.endTimeSelectorContainer : ""}
                    >
                        <TimeSelect id="end-time-picker" labelStyle={{ marginTop: roomPickers ? 16.8 : 19, fontSize: 13 }} label={t("switch.widget.bookingformpicker.endtime")} value={selectedEnd} defaultValue={selectedEnd} onChange={setEndTime} style={{ width: "100%", maxWidth: 426 }} earliest={"06:00"} latest="24:00" interval={15} bookingDate={bookingRequest.bookingDate} />


                    </Grid>
                )}
            </MuiPickersUtilsProvider>
            {!hideGuestPicker && (
                <Grid item xs={4}>
                    <TextField
                        variant="standard"
                        id="selectedGuests"
                        label={t("switch.widget.bookingformpicker.guests")}
                        name="selectedGuests"
                        value={selectedGuests}
                        type="number"
                        onChange={localSetGuestNumbers}
                        notched={true}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <EditIcon textSize="small" className={classes.adornment} />
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default BookingFormPickers;
