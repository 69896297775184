import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionActions'
import { FoodListTakeAway } from './FoodListTakeAway'
import NoSearchResults from "./NoSearchResults";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 'auto',
        height: 'auto',
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

function FoodTakeAway({ food, addBookingDetail, removeBookingDetail }) {
    const classes = useStyles();
    const [ menuDetails, setMenuDetails ] = React.useState({
        venueId: 0,
        resourceId: 0,
        menuId: 0,
        courseId: 0,
    });

    if (!food) return null;

    if (!food.menu)  {
        return (
            <NoSearchResults title={"switch.widget.notfound.nofood"} classes={classes} />
        );
    }

    if (food.menu.courses.length > 0 ) {
        if (menuDetails.venueId === 0) {
            let thisMenuDetails = {...menuDetails, venueId: food.venueId, resourceId: food.id, menuId: food.menu.id,}; 
            setMenuDetails(thisMenuDetails)
        }
        return(
            <div>
                {food.menu.courses.map(course => {
                    return(
                        <Accordion
                            key={course.id}
                            defaultExpanded
                            id={"course-"+`${course.id}`}>
                            <AccordionSummary
                                key={course.id}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={"panel-content-"+`${course.id}`}
                            >
                                <Typography key={course.id} className={classes.heading}>{course.name}</Typography>
                            </AccordionSummary>
                            <AccordionDetails key={course.id}>
                                <FoodListTakeAway key={course.id} courseId={course.id} menuDetails={menuDetails} setMenuDetails={setMenuDetails}
                                                    food={course.items}  addBookingDetail={addBookingDetail} removeBookingDetail={removeBookingDetail} />
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </div>
        );
    }

    return null

}

export default FoodTakeAway;


