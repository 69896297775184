import React, { useEffect } from 'react'
import AppBarComponent from "./AppBarComponent";
import RoomsList from "./RoomsList";
import {
    useNavigate,
    useParams
} from "react-router-dom";

export function RoomsWrapper(params) {
    const {context, classes} = params;

    return(
        <div id="occasionWrapper" className={classes.contentFullWidth}>
            <AppBarComponent context={context} classes={classes} title='switch.widget.room.prompt' />
            <Rooms data={context} classes={classes} />
        </div >
    )
}

function Rooms({ data, classes }) {
    const { sortedRooms, addBookingDetail, removeBookingDetail , skiprooms } = data;
    const navigate = useNavigate();
    const { widgetKey } = useParams();

    if (skiprooms) {
        navigate("/" + widgetKey + "/extras");
    }

    function pushHistory(id, venueId, fieldVal, menuDetails, courseId, menuItemId, roomLayoutId, resourceType) {
        addBookingDetail(id, venueId, fieldVal, menuDetails, courseId, menuItemId, roomLayoutId, resourceType)
        navigate("/" + widgetKey + "/extras");
    }

    return (
        <div className={classes.rootTwo}>
            <RoomsList rooms={sortedRooms} addBookingDetail={pushHistory} removeBookingDetail={removeBookingDetail} />
        </div>);
}

export default RoomsWrapper;