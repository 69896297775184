import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

export default function BookingNotes({ currentNotes, addBookingDetailNotes, bookingDescription, addPublicDescription }) {
    const [notes, setNotes] = React.useState();
    const [description, setDescription] = React.useState(bookingDescription);
    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        setNotes(currentNotes);
    }, []);


    const handleChange = event => {
        setNotes(event.target.value);
        addBookingDetailNotes(0, event.target.value);
    };

    const handleDescriptionChange = event => {
        setDescription(event.target.value);
        addPublicDescription(event.target.value);
    };

    return (
        <React.Fragment>
            <Typography>{t("switch.widget.bookingnotes.descriptionprompt")}</Typography>
            <TextField
                autoFocus
                margin="dense"
                id="publicDescription"
                name="publicDescription"
                label={t("switch.widget.bookingnotes.descriptionlabel")}
                fullWidth
                value={description}
                onChange={handleDescriptionChange}
                variant="outlined"
            />
            <Typography>{t("switch.widget.bookingnotes.notesprompt")}</Typography>
            <TextField
                autoFocus
                margin="dense"
                id="notes"
                name="notes"
                label={t("switch.widget.bookingnotes.noteslabel")}
                fullWidth
                multiline
                rows="8"
                value={notes}
                onChange={handleChange}
                placeholder={t("switch.widget.bookingnotes.notesplaceholder")}
                variant="outlined"
            />
        </React.Fragment>
    );
}