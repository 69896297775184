import React from "react";
import { Box, Button, Layer, CheckBox, Paragraph, Header, RadioButtonGroup } from "grommet";
import ContentContainer from "./ContentContainer";



export function MarketingPreferences(props) {
    const { open, content, prompt, onSave, guest } = props
    const [termsChecked, AgreeTerms] = React.useState(false);
    const [optIn, ChangeOpt] = React.useState(!guest || !guest.contact ? null : guest.contact.allowMarketing);

    if (!open || !guest) {
        return null;
    }
    else {

        let newRecord = {
            ...guest,
            contact: {
                ...guest.contact,
                allowMarketing: optIn,
                allowMarketingLastUpdate: new Date().toISOString(),
                allow3rdPartyMarketing: optIn,
                allow3rdPartyMarketingLastUpdate: new Date().toISOString(),
            }
        }



        return <Layer id="marketingPrefDialog">
            <Box direction="row-responsive" justify="center" align="center" pad="small" margin="small">
                <ContentContainer emoji=":shield:" title={prompt} content={content} width="large">
                    <Box
                        pad="medium"
                        background="light-1"
                        elevation="medium"
                        gap="medium"
                    >
                        <Header> Terms & Conditions </Header>
                        <Paragraph fill>We Want you to know exactly how our service works and why we need your registration details.  Please confirm you have read and agreed to our terms and conditions before you continue</Paragraph>
                        <CheckBox id="chkTerms" label="I agree to the terms and conditions" checked={termsChecked} onChange={(event) => { AgreeTerms(event.target.checked) }} />
                    </Box>
                    <Box
                        pad="medium"
                        background="light-1"
                        elevation="medium"
                        gap="medium"
                    >
                        <Header> Contact Permission</Header>
                        <Paragraph fill>We would love to send you info about events and offers and the latest news by email, post SMS, phone and other electronic means.  We will always treat your personal details with the utmost care and will never sell them to other companies for marketing purposes.</Paragraph>
                        {// <CheckBox name="allowMarketing" label="Keep my info for future marketing" checked={result.contact.allowMarketing} onChange={handleChange} />
                            //<CheckBox name="allow3rdPartyMarketing" label="Share with your trusted partners" checked={result.contact.allow3rdPartyMarketing} onChange={handleChange} />
                        }
                        <RadioButtonGroup
                            id="rbMarketingOptions"
                            name="radio"
                            options={[
                                { label: "Yes please, I'd like to hear about offers and services.", value: true },
                                { label: 'No thanks, I dont want to hear about offers and services', value: false },
                            ]}
                            value={optIn}
                            onChange={event => ChangeOpt(event.target.value === "true" ? true : false)}
                        />
                    </Box>

                    <Button id="btnSave" primary label="Save" onClick={() => { onSave(newRecord) }} disabled={optIn === null || !termsChecked} />

                </ContentContainer>
            </Box>
        </Layer>;
    }
}
