import React, { Fragment, useState } from "react";
import { memo } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { useWidgetContext } from "../widgetContext";
import ResourcesChangedDialog from "./ResourcesChangedDialog";
import SlideShowComponent from "./SlideShowComponent";
import { useTranslation } from "react-i18next"


const useStyles = makeStyles(theme => ({
    roomInfo: {
        width: '100%',
        fontWeight: '700'
    },
    occasionInfo: {
        background: theme.palette.colors.white,
        width: '100% !important',
        textAlign: 'center',
        fontWeight: '700',
        padding: '10px',
        display: 'table',
        width: '100%',
        height: '88px',
        border: '1px solid',
        borderColor: theme.palette.custom.secondary,
        borderTop: '0px solid',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        letterSpacing: '3px',
        cursor:'pointer'
    }
}));

const Occasion = memo(({ occasion, setOccasionById }) => {
    if (!occasion) return null;
    const classes = useStyles();
    const context = useWidgetContext();
    const { t, i18n } = useTranslation();
    const { id, name, slideShow } = occasion;
    const { bookingRequest, occasionBaseData, removeBookingItem } = context;
    const [removed, setRemoved] = useState(null);
    const [open, setOpen] = useState(false);
    
    function handleAccept() {
        if(removed.venues.length > 0) {
            removed.venues.map(item => {
                removeBookingItem(item.id);
            });
        }
        if(removed.rooms.length > 0) {
            removed.rooms.map(item => {
                removeBookingItem(item.id);
            });
        }
        if(removed.food.length > 0) {
            removed.food.map(item => {
                removeBookingItem(item.id);
            });
        }
        if(removed.extras.length > 0) {
            removed.extras.map(item => {
                removeBookingItem(item.id);
            });
        }
        
        setOccasionById(id);
        setRemoved(null);
        setOpen(false)
    }
    function handleCancel() {
        setRemoved(null)
        setOpen(false)
    }

    function handleSetOccasion(id){
        if (bookingRequest.Details.length > 0) {
            const tempDetails = occasionBaseData.filter(occasion => occasion.id === id)[0].details;
            const filteredDetails =  bookingRequest.Details.filter(d => !tempDetails.some(f => f.resources.some(g => g.resourceid === d.ResourceId)));
            let all = {
                venues: context.sortedVenues.filter(v => filteredDetails.some(fd => fd.ResourceId === v.id)), 
                rooms: context.sortedRooms.filter(r => filteredDetails.some(fd => fd.ResourceId === r.id)), 
                food: context.sortedFood.filter(f => filteredDetails.some(fd => fd.ResourceId === f.id)), 
                extras: context.sortedExtras.filter(e => filteredDetails.some(fd => fd.ResourceId === e.id))
            }
            if (filteredDetails.length > 0){
                setOpen(true);
                setRemoved(all);
            } else {
                setOccasionById(id);
            }
        } else {
            setOccasionById(id);
        }
    }

    return (
        <Fragment>
            <Grid container direction="column" justify="flex-start" alignItems="center" className={context.style === 'takeAway' ? 'takeAway' : 'empty'}>
                <Grid item xs={12} className="img-container">
                    <SlideShowComponent slideShow={slideShow} />
                </Grid>

                <Tooltip title={<Typography color="inherit" style={{ textAlign: 'center' }}>{t("switch.widget.occasion.addtoorder")}</Typography>} aria-label={<Typography color="inherit">{t("switch.widget.occasion.addtoorder")}</Typography>}>
                <Grid item xs={12} className={context.style === 'takeAway' ? 'takeAway-info' : classes.occasionInfo} id="btnAdd" onClick={() => handleSetOccasion(id)}>
                    <Typography component="h6"  className="occasion-info-title">
                        {name}
                    </Typography> 
                </Grid>
                </Tooltip>
            </Grid>
            {open &&
                <ResourcesChangedDialog handleAccept={handleAccept} handleCancel={handleCancel} open={open} removed={removed} title={t("switch.widget.occasion.change")} />
            }
        </Fragment>
            
    );
});

export default Occasion;
