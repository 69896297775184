import React, { Fragment, useEffect, useState } from 'react'
import { RadioButtonGroup, Box, Grid, TextArea, TextInput, Button } from 'grommet'
import Api from '../Api';


const API = new Api(
    {
        urlRoot: window.location.origin + "/Dispace/Switch/1.0.0/AnswerQuestion/calculateRefund/"
        //urlRoot: "https://switchdev.azurewebsites.net/Dispace/Switch/1.0.0/BookingRequest/"
    });




const SupplementaryQuestions = (props) => {
  const {questions, switchURN, updateAnswers, refresh} = props;
  const [loading, setLoading] = useState(false);
  
  const QuestionInputs = (props) => {
    const {q, handleAnswer} = props
    switch (q.question.responseType) {
      case 'boolean':
      case 'fixedOptions':
        let options = []
        q.question.availableResponses.map(res => {
            options.push( 
              {
                id: res.value+'-'+res.id+'-'+res.questionId,
                label:  res.value,
                value:  res.value
              }
            )
        })
        return (
          <RadioButtonGroup
            name={q.question.name}
            options={options}
            value={q.response}
            onChange={(e) => handleAnswer(e)}
          />
        )
        break;
      case 'freeText':
        return (
          <TextArea
            name={q.question.name}
            placeholder="type here"
            value={q.response}
            onChange={(e) => handleAnswer(e)}
          />
        )
      case 'numeric':
        return (
          <TextInput
            name={q.question.name}
            placeholder="type here"
            value={q.response}
            onChange={(e) => handleAnswer(e)}
          />
        )
      default:
        break;
    }
}



  const handleAnswer = (e) => {
    var questionsClone = questions;
    var foundIndex = questionsClone.findIndex(
      ques => ques.question.name === e.target.name
    );
    let updatedQuestion = questionsClone[foundIndex];
    updatedQuestion = {...updatedQuestion, answered: true, response: e.target.value, }
    questionsClone[foundIndex] = updatedQuestion;

    updateAnswers(questionsClone);
  }

  const handleSubmit = () => {
    setLoading(true);
    API.answerQuestions(null, switchURN, questions).then(result => {
      updateAnswers(result.data);        
    }).finally(() => {
        setLoading(false);
    }
    )
    
  }

  return (
    <React.Fragment>
      <Grid
        rows={['full', 'full', 'full', 'full', 'full']}
        columns={['33.3333%', '33.3333%', '33.3333%']}
        gap="small"
        areas={[
          { name: 'grid-0', start: [0, 0], end: [0, 0] },
          { name: 'grid-1', start: [1, 0], end: [1, 0] },
          { name: 'grid-2', start: [2, 0], end: [2, 0] },

          { name: 'grid-3', start: [0, 1], end: [0, 1] },
          { name: 'grid-4', start: [1, 1], end: [1, 1] },
          { name: 'grid-5', start: [2, 1], end: [2, 1] },

          { name: 'grid-6', start: [0, 2], end: [0, 2] },
          { name: 'grid-7', start: [1, 2], end: [1, 2] },
          { name: 'grid-8', start: [2, 2], end: [2, 2] },

          { name: 'grid-9', start: [0, 3], end: [0, 3] },
          { name: 'grid-10', start: [1, 3], end: [1, 3] },
          { name: 'grid-11', start: [2, 3], end: [2, 3] },
        ]}
      >
        {questions && questions.map((q,index) => {  
          return (
            <Box
                gridArea={'grid-'+index}
            >
              <div dangerouslySetInnerHTML={{ __html: q.question.questionText }} />
              <QuestionInputs q={q} handleAnswer={handleAnswer} />
            </Box>
          )
        })}
      </Grid>
      <Box align="end" pad={{ top: "small" }}>
              <Button id="btnSaveQuestions" primary label="Save" onClick={handleSubmit} disabled={loading} />
      </Box>
  </React.Fragment>
  )
}

export default SupplementaryQuestions