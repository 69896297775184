import React from "react";
import { Box, Button } from "grommet";
import { Tile } from "./Tile";
import { FeatureEmoji } from "./emojis/FeatureEmoji";
import { ResourceTypeEmojiName } from "./emojis/ResourceTypeEmojiName";

export function SelectResourceType(props) {
    const { resources, onClick } = props;


    return (
        <Box direction="row-responsive" justify="center" align="center" pad="large">
            {resources && resources.rooms && resources.rooms.length > 0 && <Tile id="btnRoom" direction="row" action="Room" summary="" clickAction={() => { onClick(1) }}>
                <FeatureEmoji name={ResourceTypeEmojiName("Room")} size="96px" />
            </Tile>}
            {resources && resources.foodandbeverage && resources.foodandbeverage.length > 0 && <Tile id="btnFood" direction="row" action="Food" summary="" clickAction={() => { onClick(2) }}>
                <FeatureEmoji name={ResourceTypeEmojiName("FoodAndBeverage")} size="96px" />
            </Tile>}
            {resources && resources.equipment && resources.equipment.length > 0 && <Tile id="btnEquipment" direction="row" action="Equipment" summary="" clickAction={() => { onClick(3) }}>
                <FeatureEmoji name={ResourceTypeEmojiName("Equipment")} size="96px" />
            </Tile>}
            {resources && resources.parking && resources.parking.length > 0 && <Tile id="btnParking" direction="row" action="Parking" summary="" clickAction={() => { onClick(4) }}>
                <FeatureEmoji name={ResourceTypeEmojiName("Parking")} size="96px" />
            </Tile>}
            {resources && resources.accommodation && resources.accommodation.length > 0 && <Tile id="btnAccomodation" direction="row" action="Accomodation" summary="" clickAction={() => { onClick(5) }}>
                <FeatureEmoji name={ResourceTypeEmojiName("Accommodation")} size="96px" />
            </Tile>}
        </Box>);
}
