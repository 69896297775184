import React, { Fragment } from "react";
import { Box, Text, Image } from "grommet";
import { CurrencyFormat } from "./CurrencyFormat";
import RichTextField from "./RichTextField";
import { Dialog } from './Dialog'
import Layouts from './RoomLayoutIcons'


export function ResourceInfo(props) {
    const { open, onClose, resourceName, resource } = props;

    if (!open) return null;
    return <Dialog id="resourceInfo" dialogStyle="EditBox" emoji=":european_post_office:" title={resourceName} onOK={onClose} >
        <Box direction="column" justify="between" align="start">
            {!resource ? <Text> No Data To Display</Text> :

                <Box>
                    <Box direction="row" justify="between" gap="small" align="center" pad="small" margin="small" flex="grow">
                        <Box height="small" width="50%">
                            {resource.image && <Image src={resource.image.imageUrl} fit="cover" />}
                            <Text id="txtPrice" >Prices from {CurrencyFormat(resource.price)} {resource.resourceType === "Room" ? "per " + resource.price_type : ""} {resource.price_inc_tax ? "(inc VAT)" : "(ex VAT)"}</Text>
                            {resource.resourceType === "Room" && resource.layouts.length > 0 && 
                                <Fragment>
                                    <Text id="txtLayouts" style={{marginTop: '20px'}}>Available Layouts:</Text>
                                    <Layouts layouts={resource.layouts} />
                                </Fragment>
                            }
                        </Box>
                        <Box width="50%" >
                            <RichTextField value={resource.description} />
                        </Box>
                    </Box>

             
                </Box>
            }
        </Box>
    </Dialog>
}
