import { usePayment } from "../contexts/PaymentContext";
import Button from "@material-ui/core/Button";
import { useTranslation } from 'react-i18next'
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1)
    }

}));


export default function HostedPaymentPayButton() {
    const { processing, PerformRedirect } = usePayment();
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();

    return (<Button

        variant={processing ? "contained" : "outlined"}
        style={{
            color: processing
                ? `${theme.palette.info.main}`
                : `${theme.palette.success.main}`,
            border: "2px solid",
            borderColor: processing
                ? `${theme.palette.info.main}`
                : `${theme.palette.success.main}`
        }}
        className={classes.button}
        onClick={PerformRedirect}
        disabled={processing}
    >
        {t("switch.widget.checkout.pay")}
    </Button>)
};