import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import { useMultiDayForm } from "../contexts/useMultiDayForm";

export default function Block() {

    const { dayFilter, ruleText, UpdateDayFilter } = useMultiDayForm();
    return (
        <Box ml={1}>
            <FormGroup row>
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={dayFilter.includes("MO")}
                                onChange={UpdateDayFilter}
                                name="MO"
                            />
                        }
                        label="Mon"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={dayFilter.includes("TU")}
                                onChange={UpdateDayFilter}
                                name="TU"
                            />
                        }
                        label="Tue"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={dayFilter.includes("WE")}
                                onChange={UpdateDayFilter}
                                name="WE"
                            />
                        }
                        label="Wed"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={dayFilter.includes("TH")}
                                onChange={UpdateDayFilter}
                                name="TH"
                            />
                        }
                        label="Thu"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={dayFilter.includes("FR")}
                                onChange={UpdateDayFilter}
                                name="FR"
                            />
                        }
                        label="Fri"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={dayFilter.includes("SA")}
                                onChange={UpdateDayFilter}
                                name="SA"
                            />
                        }
                        label="Sat"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={dayFilter.includes("SU")}
                                onChange={UpdateDayFilter}
                                name="SU"
                            />
                        }
                        label="Sun"
                    />
                </Grid>
            </FormGroup>
            <p>{ruleText}</p>
        </Box>
    );
}