import React from "react";
import { Box, Button } from "grommet";
import ContentContainer from "./ContentContainer";
import { QuantityEditor } from "./QuantityEditor";


export function SelectQuantity(props) {
    const { onChange, newItem, min, max } = props;


    return (
        <Box direction="row-responsive" justify="center" align="center" pad="large">
            <QuantityEditor onChange={(update) => { onChange(update); }} item={newItem} min={min} max={max}/>
        </Box>
    );
}
