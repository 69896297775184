import React from 'react'
import { useContext } from "react";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Grid, Button } from "@material-ui/core";
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import { WidgetContext } from "../widgetContext";

const BackButton = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { skiprooms, widgetKey, occasions, venue } = useContext(WidgetContext);
    if (!props) return null;

    const handleClick = () => {
        let isInExtras = "/" + widgetKey + "/extras";
        let currentPath = location.pathname;
        if (skiprooms && currentPath === isInExtras) {
            navigate(-2)
        } else {
            navigate(-1)
        }
    }
    if (occasions.length === 1 && location?.pathname?.includes("venue")) return;
    if (occasions.length === 1 && venue.length === 1 && location?.pathname?.includes("room")) return;

    return (
        <Grid container direction="row" justifyContent="space-around" alignItems="center" spacing={0}>
            <Grid item xs={10} xl={props.matUiXl} style={{ marginLeft: props.margin ? props.margin : '', paddingTop: '10px' }}>
                <Button
                    variant="outlined"
                    style={{ marginTop: '10px', border: '2px solid', borderColor: `${theme.palette.colors.black}`, color: `${theme.palette.colors.black}` }}
                    onClick={() => handleClick()}
                    startIcon={<ArrowBackIcon />}
                >
                    {t('switch.widget.buttons.back')}
                </Button>
            </Grid>
        </Grid>
    )
}
export default BackButton;