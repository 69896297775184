import * as React from 'react'
import { createContext, useContext, useState, useEffect } from 'react'
import Api from '../Api'
import Permissions from '../guestPortalComponents/Permissions';
import { PortalManagePaymentContextProvider } from './PortalManagePaymentContext';
import { usePortal } from './usePortal';

export const useGuestPortal = () => {
    const context = useGuestPortalContext()

    return context
}

export const GuestPortalContext = createContext(undefined)

export const GuestPortalContextProvider = ({ context, children }) => {
    const { bookingRef: slugRef, email: slugMail, leadBooker: slugLead, signedIn: savedState, sessionState } = usePortal();
    const [bookingRef, setBookingRef] = useState(slugRef);
    const [email, setEmail] = useState(slugMail);
    const [leadBooker, setLeadBooker] = useState(slugLead);
    const [signedIn, setSignedIn] = useState(savedState);
    const [portalSettingsData, setPortalSettingsData] = useState(sessionState);
    const [errorText, setErrorText] = useState();
    const [rights, setRights] = useState([]);
    const [booking, setBooking] = useState();
    const [chatHistory, setChatHistory] = useState();
    const [contact, setContact] = useState();
    const [questions, setQuestions] = useState();
    const [resources, setResources] = useState();
    const [venue, setVenue] = useState();
    const [savedCards, setSavedCards] = useState();
    const [stripeSettings, setStripeSettings] = useState();
    const [portalSettings, setPortalSettings] = useState(null);
    const [loading, setLoading] = useState(false);



    let API = new Api(
        {
            urlRoot: window.location.origin + "/Dispace/Switch/1.0.0/BookingRequest/",
            widgetKey: portalSettings != null ? portalSettings.widgetKey : null,
        });

    function refresh() {
        setLoading(true);
        API.portalSignIn(booking.switchURN, contact.email).then(
            result => {
                setPortalSettingsData(result);
                setLoading(false);
            }
        );
    }
    const logout = () => {
        window.sessionStorage.removeItem('savedState');
        setBookingRef(slugRef);
        setEmail(slugMail);
        setPortalSettingsData(null);
        setErrorText(null);
        setRights(null);
        setBooking(null);
        setChatHistory(null);
        setContact(null);
        setQuestions(null);
        setResources(null);
        setVenue(null);
        setSavedCards(null);
        setStripeSettings(null);
        setPortalSettings(null);
        setSignedIn(false);
    }

    const loadSavedSession = () => {
        if (sessionState != null) {
            setPortalSettingsData(sessionState);
        }
    }

    const checkPermissions = (feature) => {
        return Permissions(rights, feature);
    }

    const doSignIn = () => {
        setLoading(true);
        setErrorText(null);
        API.portalSignIn(bookingRef, email).then((result) => {
            if (result.validated) {
                setPortalSettingsData(result);
                setSignedIn(true);
                setLoading(false);
                window.sessionStorage.setItem("savedState", JSON.stringify({
                    ...result,
                    signedIn: true
                }))
            }
            else {
                setPortalSettingsData(result);
                setSignedIn(false);
                setLoading(false);
                setErrorText(result != null ? result.errorText : "An Unexpected Error Occured");
            }
        });
    }


    useEffect(
        () => {
            if (portalSettingsData != null) {
                setErrorText(portalSettingsData.errorText);
                setBooking(portalSettingsData.booking);
                setVenue(portalSettingsData.venue);
                setContact(portalSettingsData.contact);
                setSavedCards(portalSettingsData.savedCards);
                setRights(portalSettingsData.rights);
                setChatHistory(portalSettingsData.chatHistory);
                setQuestions(portalSettingsData.questions);
                setResources(portalSettingsData.resources);
                setPortalSettings(portalSettingsData.portalSettings);
                setStripeSettings(portalSettingsData.portalSettings ? {

                    publishableKey: portalSettingsData.portalSettings.stripeKey,
                    connectedAccountId: portalSettingsData.portalSettings.stripeAccountId

                } : null);
                setLeadBooker(portalSettingsData.leadBooker);
                setLoading(false);
            }
            else {
                const save = window.sessionStorage.getItem("savedState");
                if (save != null && save.signedIn) {
                    setPortalSettings(save);
                }
            }
        },
        [portalSettingsData]
    );

    let contextData = {
        bookingRef: booking ? booking.switchURN: bookingRef,
        setBookingRef: setBookingRef,
        email: email,
        setEmail: setEmail,
        leadBooker: leadBooker,
        signedIn: signedIn,
        loading: loading,
        API: API,
        checkPermissions, checkPermissions,
        logout: logout,
        errorText: errorText,
        booking: booking,
        venue: venue,
        contact: contact,
        savedCards: savedCards,
        stripeSettings: stripeSettings,
        doSignIn: doSignIn,
        chatHistory: chatHistory,
        questions: questions,
        resources, resources,
        portalSettings: portalSettings,
        loadSavedSession: loadSavedSession,
        setBooking: setBooking,
        refresh: refresh
    }

    return (
        <GuestPortalContext.Provider value={contextData}>
            <PortalManagePaymentContextProvider>
                {children}
            </PortalManagePaymentContextProvider>
        </GuestPortalContext.Provider>
    )
}

export const useGuestPortalContext = () => useContext(GuestPortalContext)
