import React from "react";
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Daily from "./Daily";
import Weekly from "./Weekly";
import Monthly from "./Monthly";
import { useMultiDayForm } from "../contexts/useMultiDayForm";
export default function Repeat() {
    const { repeatFrequency, setRepeatFrequency, IEnumFrequency } = useMultiDayForm();

    let repeatComponent;
    switch (repeatFrequency) {
        case IEnumFrequency.DAILY:
            repeatComponent = <div><Daily /></div>;
            break;
        case IEnumFrequency.WEEKLY:
            repeatComponent = <div><Weekly /></div>;
            break;
        case IEnumFrequency.MONTHLY:
            repeatComponent = <div><Monthly /></div>;
            break;
        default:
            repeatComponent = null;
    }

    return (
        <Box ml={1}>
            <FormControl component="fieldset">
                <RadioGroup
                    aria-label="repeatType"
                    name="repeatType"
                    value={repeatFrequency}
                    onChange={(e) => { setRepeatFrequency(e.target.value) }}
                    row
                >
                    <FormControlLabel value={IEnumFrequency.DAILY} control={<Radio />} label="Daily" />
                    <FormControlLabel value={IEnumFrequency.WEEKLY} control={<Radio />} label="Weekly" />
                    <FormControlLabel value={IEnumFrequency.MONTHLY} control={<Radio />} label="Monthly" />
                </RadioGroup>
            </FormControl>
            {repeatComponent}
        </Box>
    );
}