import React from 'react'
import { Layer, Box, Heading, Text, Button, Paragraph} from 'grommet'
import PropTypes from "prop-types";
import { FeatureEmoji } from "./emojis/FeatureEmoji";


const styleParaemters=(style)=>{
    const defaultstlye = {
        align: "center",
        alignSelf: "center",
        justify: "center",
        pad: "small",
        flex: "grow",
        height: "small",
        elevation: "xsmall",
        border: { color: "border" }
    }
    switch (style) {
        case "MessageBox":
            return {
                ...defaultstlye,
                border: false,                
                elevation: "none",                
            }
        case "EditBox":
            return {
                ...defaultstlye,
                height: "medium",
            }
        case "TabbedEditBox":
            return {
                ...defaultstlye,
                height: "medium",
                border: false,
                elevation: "none",   
                pad: "xxsmall",
            }

        default:
            return defaultstlye;
    }
}

const styleWidth = (style) => {
    const defaultstlye = "large"
    switch (style) {
        case "MessageBox":
            return "medium"
        case "TabbedEditBox":
            return "xlarge"
        default:
            return defaultstlye;
    }
}

export const Dialog = ({ children, title, content, dialogStyle, onConfirm, onCancel, onDelete, onSave, onSend, onOK, onBack, ...rest }) => (
    <Layer animate modal={true}>
        <Box align="center" justify="center" direction="row" pad="small" margin="small">
            <Box align="start" justify="center" round="small" border={{ "color": "border" }} pad="small" gap="small" direction="column">
                <Heading level={2} margin="none" size="small">
                    <Box direction="row" align="center" gap="small">
                        {!rest.emoji ? null : <FeatureEmoji name={rest.emoji} size="60px" />}
                        {title}
                    </Box>
                </Heading>
                {content && <Paragraph fill color="border" textAlign="start" >{content}</Paragraph>}
                <Box {...styleParaemters(dialogStyle)} >
                    <Box align="center" justify="center" width={styleWidth(dialogStyle)} overflow={dialogStyle === "TabbedEditBox" ? "hidden" : "auto"} gap="small">
                        {children}
                    </Box>
                </Box>
                <Box align="center" justify="end" direction="row" gap="medium" alignSelf="end" height="xxsmall">
                    {onBack && <Button id="btnBack" label="Back" onClick={onBack} />}
                    {onCancel && <Button id="btnCancel" label="Cancel" onClick={onCancel} />}
                    {onDelete && < Button id="btnDelete" primary label="Delete" onClick={onDelete} />}
                    {onSave && <Button id="btnSave" primary label="Save" onClick={onSave} />}
                    {onConfirm && <Button id="btnConfirm" primary label="Continue" onClick={onConfirm} />}
                    {onSend && <Button id="btnSend" primary label="Send" onClick={onSend} />}
                    {onOK && <Button id="btnOK" label="OK" onClick={onOK} />}
                </Box>
            </Box>
        </Box>
    </Layer>

)
Dialog.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    children: PropTypes.node.isRequired,
    dialogStyle: PropTypes.string.isRequired
};

export default Dialog;