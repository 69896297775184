import React, { useState } from 'react'
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, } from '@material-ui/core';
import { Alert, AlertTitle } from "@material-ui/lab";
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import { useTranslation } from "react-i18next"


const useStyles = makeStyles(theme => ({
    cartDesktopContainer2: {
        marginTop: '18px',
        minWidth: '450px',
        maxWidth: '500px',
        borderRadius: '10px',
        border: '1px solid',
        borderColor: theme.palette.custom.secondary,
        [theme.breakpoints.down('sm')]: {
            minWidth: '100%',
            margin: '0px',
        },
    },
    alert: {
      borderRadius: '10px !important',
    },
    unAvailableResources: {
      fontSize: 12,
    },
    bold: {
      fontWeight: '700',
    },
    pointer: {
      cursor: 'pointer',
    },
    EmptyContainer: {
      marginTop: '100px',
    }
}))

const ListCartErrors = ({ context }) => {
    const classes =  useStyles();
    const { t, i18n } = useTranslation();
    const [currentErrorItem, setCurrentErrorItem] = useState(0)
    
    if (!context) return null;

    const { availability } = context;

    function changeItem(params) {
      if (params === 'next') {
        setCurrentErrorItem(currentErrorItem + 1);
      }
      if (params === 'prev') {
        setCurrentErrorItem(currentErrorItem - 1);
      }
    }

    if (typeof availability === "undefined" || !availability.hasOwnProperty('state')) {
        return null;
    }
    if (availability.unAvailableResources.length > 0) {
        return (
          <Grid container direction="row" justify="flex-start" alignItems="flex-start" id="cart-alert" className={classes.cartDesktopContainer2}>
            <Grid item xs={12}>
              <Alert severity="error" className={classes.alert}>
                        <AlertTitle> {availability.unAvailableResources.length === 1 ? t('switch.widget.carterror.notice') : availability.unAvailableResources.length + ' ' + t('switch.widget.carterror.notices')} </AlertTitle>
                  <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                    {currentErrorItem === 0 ? 
                      '' 
                      :
                      <Grid id="btnPrev" item xs={1} onClick={() => { changeItem('prev') }} className={classes.pointer}>
                        <ArrowLeftIcon fontSize="small" />
                      </Grid>
                    }
                    <Grid item xs={availability.unAvailableResources.length === 1 ? 12 : currentErrorItem === 0 || currentErrorItem === availability.unAvailableResources.length-1 ? 11 : 10}>
                      <Typography variant="body2" className={classes.unAvailableResources}> 
                        <span id="valueName" className={classes.bold}>{availability.unAvailableResources[currentErrorItem].value.name}</span> {": "} {availability.unAvailableResources[currentErrorItem].key}
                      </Typography>
                    </Grid>
                    {currentErrorItem === availability.unAvailableResources.length-1 ? 
                      ''
                      :
                      <Grid id="btnNext" item xs={1} onClick={() => { changeItem('next') }} className={classes.pointer}>
                        <ArrowRightIcon fontSize="small" />
                      </Grid>
                    }
                  </Grid>
              </Alert>
            </Grid>
          </Grid>
        )
    }
    if (!availability.unAvailableResources || availability.unAvailableResources.length === 0) {
      return(
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" className={classes.EmptyContainer}>
        </Grid>
      )
    }
};

export default ListCartErrors