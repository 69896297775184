import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import "./ChecOutForm.css";
import { Box, Text, Accordion, AccordionPanel, CheckBox, RadioButtonGroup } from "grommet";
import CheckoutCardForm from "./CheckOutCardForm";
import { Typography } from "@material-ui/core";
import { usePayment } from "../contexts/PaymentContext";
import CVCCaptureForm from "../CVCCaptureForm";
import { Loading } from "react-admin";
export default function CheckoutForm(props) {
    const {
        onSuccess
    } = props;

    const {
        stripePromise,
        consent,
        ChangeConsent,
        saveDefault,
        ChangeDefault,
        selectedCard,
        hasSavedCards,
        stripeOptions,
        savedCards,
        error,
        cardType,
        handleChangeCard,
        SetCardType,
        paymentRecord,
        loading,
        paymentStatus
    } = usePayment();


    const cardOptions = !hasSavedCards ? [] : savedCards.map(card => {

        const ExpiryDate = new Date(card.expiry);
        const options = { month: 'long' };
        const monthname = new Intl.DateTimeFormat('en-GB', options).format(ExpiryDate);

        let cardLabel = ' *** ' + card.last4 + ' - Expires(' + monthname + '/' + ExpiryDate.getFullYear() + ')'
        if (card.default) {
            cardLabel += ' - DEFAULT'
        }


        const label = (
            <Box direction="row-responsive">
                <img key={card.id} src={`./image/cards/${card.brand}.png`} alt={card.brand} width="50px" align="bottom" style={{ padding: "0px 10px" }} />
                <Typography variant="body2">{cardLabel}</Typography>
            </Box>
        )

        return {
            label: label,
            value: card.id
        }
    });



    const renderSuccess = () => {
        if (onSuccess != null) {
            onSuccess({
                ...paymentRecord,
                paymentStatus: "paid"
            });
        }
        return (
            <div className="sr-field-success message">
                <h1>Your payment succeeded</h1>
            </div>
        );
    };

    const Preparing = () => {
        return (
            <Loading loadingPrimary={"Preparing Payment Data..."} loadingSecondary="Please wait" />
        );
    };

    const renderForm = () => {
        if (loading || !stripeOptions) return <Preparing />;
        const activeIndex = !hasSavedCards ? 0 : cardType == "newcard" ? 1 : 0;
        return (
            <>
                <Accordion animate={true} multiple={false} margin='small' activeIndex={activeIndex} onActive={(active) => SetCardType(active[0] == 0 ? "saved" : "newcard")}>
                    {hasSavedCards && <AccordionPanel label='Used Saved Card'>
                        <Box background='light-1'>
                            <Box align="left" pad="small">
                                <RadioButtonGroup
                                    name="savedCard"
                                    options={cardOptions}
                                    value={selectedCard?.id}
                                    onChange={handleChangeCard}
                                />
                            </Box>
                            <Box align="left" pad="small">
                                <Elements stripe={stripePromise} options={stripeOptions} >
                                    <CVCCaptureForm label="Please confrim the CVC number for this card(3 digits on the back)" />
                                </Elements>
                            </Box>

                        </Box>
                    </AccordionPanel>}
                    <AccordionPanel label='New Card'>
                        <Box height='medium' background='light-1'>
                            <Elements stripe={stripePromise} options={stripeOptions}>
                                <CheckoutCardForm />
                            </Elements>
                        </Box>
                    </AccordionPanel>
                </Accordion>
                <Box gap="small" align="left" pad="small">
                    <CheckBox
                        tabIndex="-1"
                        checked={consent}
                        label={<Text>Allow future payments to be collected from this card</Text>}
                        onChange={ChangeConsent}
                    />
                </Box>
                <Box gap="small" align="left" pad="small">
                    <CheckBox
                        tabIndex="-1"
                        checked={saveDefault}
                        label={<Text>Set as default card for this account</Text>}
                        onChange={ChangeDefault}
                    />
                </Box>
                <Box>
                    <Box className="error" role="alert">
                        {error && <div className="message sr-field-error">{error}</div>}
                    </Box>
                </Box>
            </>
        );
    };

    return (
        <div className="checkout-form">
            <div className="sr-payment-form">
                <div className="sr-form-row" />
                {paymentStatus == "succeeded" ? renderSuccess() : renderForm()}
            </div>
        </div>
    );
}
