import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Checkout from './Checkout';
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { CheckoutContextProvider } from '../contexts/useCheckout';

export default function BookingButton({ context }) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const { t, i18n } = useTranslation();
    const { availability, resetWidgetData, occasionData, calculatedRate } = context;
    const [calculatedGross, setCalculatedGross] = React.useState(calculatedRate.gross)
    const [minimumOrderValue, setMinimumOrderValue] = React.useState(occasionData.length > 0 ? occasionData[0].minimumOrderValue : 0)
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    // console.log(context)
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {

        if (window.confirm(t('switch.widget.preventcloser'))) setOpen(false);
    };

    if (calculatedGross !== calculatedRate.gross) {
        setCalculatedGross(calculatedRate.gross)
    }



    if (typeof availability != "undefined" && availability.hasOwnProperty('state')) {
        if (availability.state === "available") {
            if (calculatedGross >= minimumOrderValue) {
                return (
                    <React.Fragment>
                        <Button variant="outlined" style={{ color: `${theme.palette.success.main}`, border: '2px solid', borderColor: `${theme.palette.success.main}` }} onClick={handleClickOpen}> {t('switch.widget.buttons.book')}</Button>
                        {open &&
                            <CheckoutContextProvider handleClose={handleClose}>
                                <Dialog id="checkoutDialog" open={open} onClose={handleClose} scroll="body" fullScreen={fullScreen} maxWidth="md" disableEnforceFocus >
                                    <Checkout />
                                </Dialog>
                            </CheckoutContextProvider>
                        }
                    </React.Fragment >
                );
            }
        }
    }
    return (
        <Button variant="contained" disabled> {t('switch.widget.buttons.book')}</Button>
    );


}

