import React, { Fragment, useState } from "react";
import { memo } from "react";
import VenueDetails  from './VenueDataPopup';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { useWidgetContext } from "../widgetContext";
import ResourcesChangedDialog from "./ResourcesChangedDialog";
import SlideShowComponent from "./SlideShowComponent";
import { useTranslation } from 'react-i18next'

const Venue = memo(({ venue, setVenue}) => {
    const { t, i18n } = useTranslation();
    if (!venue) return null;
    const context = useWidgetContext();
    const { id, name, slideShow} = venue;
    const { bookingRequest, removeBookingItem } = context;
    const [removed, setRemoved] = useState(null);
    const [open, setOpen] = useState(false);
    
    function handleAccept() {
        if(removed.rooms.length > 0) {
            removed.rooms.map(item => {
                removeBookingItem(item.id);
            });
        }
        if(removed.food.length > 0) {
            removed.food.map(item => {
                removeBookingItem(item.id);
            });
        }
        if(removed.extras.length > 0) {
            removed.extras.map(item => {
                removeBookingItem(item.id);
            });
        }
        
        setVenue(id);
        setRemoved(null);
        setOpen(false)
    }
    function handleCancel() {
        setRemoved(null)
        setOpen(false)
    }

    function handleSetVenue(){
        if (bookingRequest.Details.length > 0) {
            let all = { 
                rooms: context.sortedRooms.filter(r => bookingRequest.Details.some(x => ( x.ResourceId === r.id && r.venueId !== id ))), 
                food: context.sortedFood.filter(f => bookingRequest.Details.some(x => (x.ResourceId === f.id && f.venueId !== id) )), 
                extras: context.sortedExtras.filter(e => bookingRequest.Details.some(x => (x.ResourceId === e.id && e.venueId !== id) ))
            }
            if (all.rooms.length > 0 || all.food.length > 0 || all.extras.length > 0 ){
                setOpen(true);
                setRemoved(all)
            } else {
                setVenue(id);
            }
        } else {
            setVenue(id);
        }
    }

    return (
        <Fragment>
            <Grid container direction="column" justifyContent="flex-start" alignItems="center" className="room">
                <Grid item xs={12} className="img-container" style={{width:'100%'}}>
                    <SlideShowComponent slideShow={slideShow} />
                    <VenueDetails venue={venue} addVenueToBooking={handleSetVenue} /> 
                </Grid>

                <Tooltip title={<Typography color="inherit" style={{ textAlign: 'center' }}>{t("switch.widget.venue.clikadd")}</Typography>} aria-label={<Typography color="inherit">{t("switch.widget.venue.clikadd")}</Typography>}>
                    <Grid item xs={12} className="room-info" style={{display: 'table',width: '100%',height: '88px',textAlign: 'center',cursor:'pointer'}} onClick={handleSetVenue}>
                            <Typography component="h6"  style={{display: 'table-cell',verticalAlign: 'middle'}}>
                            {name}
                        </Typography> 
                    </Grid>
                </Tooltip>
            </Grid>
            {open &&
                <ResourcesChangedDialog handleAccept={handleAccept} handleCancel={handleCancel} open={open} removed={removed} title={t("switch.widget.venue.change")}/>
            }
        </Fragment>
    );
});
export default Venue;
