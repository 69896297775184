import React, { Component } from "react";
import WidgetContainer from "../components/WidgetContainer";
import ReactGA from 'react-ga';

export default class Widget extends Component {
    constructor(props) {

        super(props);

    }

    render() {
        ReactGA.initialize('UA-155859890-2');
        ReactGA.pageview(window.location.pathname + window.location.search);


        return (
            <>
                <WidgetContainer />
            </>
        );
    };
};
