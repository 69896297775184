import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import loadingGif from "../images/gif/loading-circles-md.svg";


const Loading = () => {
  return (
    <div className="loading">
      <h4>loading booking data....</h4>
        <img src={loadingGif} alt="" />
    </div>
  );
};

export default Loading;
