import React from "react";
import { loadStripe } from "@stripe/stripe-js";

export default function UseStripePromise() {
    const createReactPromise = React.useCallback((key, account) => {
    if (!account || account.length == 0) {
        return loadStripe(key)
    } else {
        return loadStripe(key, {
            stripeAccount: account
        });
    }
    });

    return createReactPromise;
}
