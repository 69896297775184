import React, { Fragment, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { useTheme } from '@material-ui/core/styles';
import { useWidgetContext } from "../widgetContext";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TimeGrid from './TimeGrid'
import Api from '../Api';
import { addDays, format } from 'date-fns';
import { useTranslation } from 'react-i18next'



const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function AvailabilityWeekView({ room, addBookingDetail, startDate }) {
    const context = useWidgetContext();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [open, setOpen] = React.useState(false);
    const [resourceAvailability, setResourceAvailability] = React.useState()
    const [viewStart, setViewStart] = React.useState(startDate);
    const { t } = useTranslation();
    const occasionId = context.bookingRequest.occasionId;

    if (!room) { return null }
    const { id, name, description, slideShow, price, price_type, layouts } = room;
    const endDate = addDays(viewStart, 7);
    const queryString = '?venueid=' + room.venueId + '&resourceId=' + room.id + '&startDate=' + viewStart.toISOString().substring(0, 19) + '&endDate=' + endDate.toISOString().substring(0, 19) + '&occasionid=' + occasionId;

    const API = new Api(
        {
            urlRoot: window.location.origin + "/Dispace/Switch/1.0.0/BookingRequest/",
            //urlRoot: "https://switchdev.azurewebsites.net/Dispace/Switch/1.0.0/BookingRequest/"
            widgetKey: context.widgetKey
        });

    useEffect(() => {
        if (open) {
            API.getResourceAvailability(context.widgetKey, queryString).then((result) => {
                setResourceAvailability(result);
            });
        }
    }, [open, viewStart]);


    const OpenWeekView = () => {
        setOpen(true);
    };

    const CloseWeekView = () => {
        setViewStart(startDate);
        setOpen(false);
    };

    const PrevWeek = () => {
        setViewStart(addDays(viewStart, -7));
    };

    const NextWeek = () => {
        setViewStart(addDays(viewStart, 7));
    };


    let datelist = []
    let nextDate = viewStart;
    for (var index = 0; index < 7; index++) {
        datelist.push(nextDate.toISOString().slice(0, -1).substring(0, 19));
        nextDate = addDays(nextDate, 1);
    }

    return (
        <Fragment>
            <Tooltip title={<Typography color="inherit" style={{ textAlign: 'center' }}>{t("switch.widget.availabilityweekview.buttontooltip")}</Typography>} aria-label="show week view">
                <Button id="showWeekView" onClick={OpenWeekView} style={{ border: '1px solid black' }}>
                    {t("switch.widget.availabilityweekview.button")}
                </Button>
            </Tooltip>
            <Dialog onClose={CloseWeekView} fullScreen={fullScreen} maxWidth="md" fullWidth aria-labelledby="customized-dialog-title" open={open} className="DialogBox">
                <React.Fragment>
                    <MuiDialogTitle id="customized-dialog-title">
                        <Typography>
                            {room.name}
                        </Typography>
                    </MuiDialogTitle>
                    <DialogContent>
                        <Grid item xs={12} className="room-info-container">

                            {datelist.map(item => {
                                const resAvailability = resourceAvailability != null ? resourceAvailability.find(a => a.date == item && a.resourceId == room.id) : null;
                                return (
                                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" style={{ 'max-height': '100px' }}>
                                        <Grid item xs={3} className="room-img-container">
                                            <Typography color="inherit" style={{ textAlign: 'center' }}>{format(new Date(item), 'PPPP')}</Typography>
                                        </Grid>
                                        <Grid item xs={9} id="timeGrid">

                                            <TimeGrid room={room} resAvailability={resAvailability} context={context} addBookingDetail={addBookingDetail} open={open} setOpen={setOpen} size='small' />
                                        </Grid>
                                    </Grid>
                                );
                            })}

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button id="btnPrev" onClick={PrevWeek} disabled={viewStart <= new Date()} color="primary">{t("switch.widget.buttons.prev")}</Button>
                        <Button id="btnNext" onClick={NextWeek} color="primary">{t("switch.widget.buttons.next")}</Button>
                        <Button autoFocus id="btnClose" onClick={CloseWeekView} color="primary">{t("switch.widget.buttons.close")}</Button>
                    </DialogActions>
                </React.Fragment>

            </Dialog>
        </Fragment>
    );
}