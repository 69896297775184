import React from "react";
import { Box, Button, Text, Heading } from "grommet";
import ContentContainer from "./ContentContainer";

export function AddItemSummary(props) {
    const { newItem, resourceList } = props;
    const resource = resourceList ? resourceList.filter(r => r.id == newItem.resourceId)[0] : null
    let layout = "";
    if (resource && resource.resourceType == "Room" && newItem.roomLayoutId) {
        layout = resource.layouts.filter(l => l.id == newItem.roomLayoutId)[0].name;
    }

    return (<Box direction="column" justify="start" align="start" width="xlarge" pad="medium" gap="small" margin="small">
        {resource && <Box>
            <Heading level={3}> Check and confirm details: </Heading>

            <Text> Description: {resource.name}</Text>
            {resource.resourceType == "Room" &&
                <Text> Layout: {layout}</Text>
            }
            <Text> Qty: {newItem.quantity.toString()}</Text>
        </Box>}
    </Box>);
}
