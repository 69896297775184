import React, { Fragment } from "react";
import { Box, Text, Grid } from "grommet";
import { CurrencyFormat } from "./CurrencyFormat";
import RichTextField from "./RichTextField";
import { Dialog } from './Dialog'
import Layouts from './RoomLayoutIcons'

import {TwoDecimalPlaces} from './BookingHeader'

export function DamageDepositInfo(props) {
    const { open, onClose, booking } = props;

    if (!open) return null;
    return (
        <Dialog id="resourceInfo" dialogStyle="EditBox" emoji=":european_post_office:" title="Damage Deposit Info" onOK={onClose} >
            <Box direction="column" justify="between" align="start">
                {!booking.hasDamageDeposit ? <Text> No Data To Display</Text> :
                    <Box>
                        <Box direction="row" justify="between" gap="small" align="center" pad="small" margin="small" flex="grow">
                            <Grid
                                fill
                                areas={[

                                    
                                    { name: 'damage_deposit_paid_label', start: [0, 1], end: [1, 1] },
                                    { name: 'damage_deposit_paid', start: [2, 1], end: [3, 1] },
                                    
                                    { name: 'damage_deposit_deducted_label', start: [0, 2], end: [1, 2] },
                                    { name: 'damage_deposit_deducted', start: [2, 2], end: [3, 2] },
                                    { name: 'damage_deposit_refunded_label', start: [5, 2], end: [6, 2] },
                                    { name: 'damage_deposit_refunded', start: [7, 2], end: [8, 2] },

                                ]}
                                columns={['flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex']}
                                rows={['xxsmall', 'xxsmall', 'xxsmall']}
                                gap='small'
                            >
                                <Box gridArea='damage_deposit_paid_label' justify="center" alignContent="center" weight="bold" >
                                    <Text >Paid:</Text>
                                </Box>
                                <Box gridArea='damage_deposit_paid' justify="center" alignContent="center" round="xsmall" border={{ color: 'brand', size: 'xsmall' }}>
                                    <Text alignSelf="center" size="small">£ {TwoDecimalPlaces(booking.damageDepositPaid)}</Text>
                                </Box> 
                                
                                <Box gridArea='damage_deposit_deducted_label' justify="center" alignContent="center" weight="bold" >
                                    <Text >Deducted:</Text>
                                </Box>
                                <Box gridArea='damage_deposit_deducted' justify="center" alignContent="center" round="xsmall" border={{ color: 'brand', size: 'xsmall' }}>
                                    <Text alignSelf="center" size="small">£ {TwoDecimalPlaces(booking.damageDepositDeducted)}</Text>
                                </Box> 
                                <Box gridArea='damage_deposit_refunded_label' justify="center" alignContent="center" weight="bold" >
                                    <Text >Refunded:</Text>
                                </Box>
                                <Box gridArea='damage_deposit_refunded' justify="center" alignContent="center" round="xsmall" border={{ color: 'brand', size: 'xsmall' }}>
                                    <Text alignSelf="center" size="small">£ {TwoDecimalPlaces(booking.damageDepositRefunded)}</Text>
                                </Box> 

                            </Grid>
                        </Box>
                    </Box>
                }
            </Box>
        </Dialog>
    )
}
