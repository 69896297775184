import React, { Component } from "react";
import {
    Box,
    Button,
    Image,
    Heading,
    Text
} from "grommet";

const LayoutCard = (props) => {
    const { layout, currentSelection, onClickAddItem, ...rest } = props;
    return (
        <Box round="xxsmall" elevation="large" overflow="hidden" width="medium" border={!currentSelection ? null : {
            "all":

            {
                "side": "all"
            },
            color: "brand",
            size: "large",
            style: "solid",
        }} {...rest}>
            {!layout ? <Text> No Layout Info </Text> :
                <React.Fragment>
                    <Box height="small" >
                        {//{layout.image ? <Image src={layout.image.imageUrl} fit="cover" /> : <FeatureEmoji name=":grey_question:" size="64px" />}
                        }
                        <Image src={layout.image ? layout.image.imageUrl : "https://dispace.blob.core.windows.net/switch/Master/defaultLayoutImage.png"} fit="contain" />
                    </Box>
                    <CardHeader {...props} />
                    <CardFooter {...props} />
                </React.Fragment>
            }
        </Box>
    );

}

export default LayoutCard;

function CardHeader(props) {

    const { layout} = props;
    return (
        <Box pad={{ horizontal: "small" }}>
            <Box
                margin={{ top: "small" }}
                direction="row"
                align="center"
                justify="between"
            >
                <Box>
                    <Heading level="4" margin="none">
                        {layout.name}
                    </Heading>
                    <Text color="dark-5" size="small">
                        Seated Capacity {layout.capacitySeated} Standing Capacity {layout.capacityStanding}
                    </Text>
                </Box>
            </Box>
        </Box>
    );
};

function CardFooter(props) {
    const { onClickAddItem, currentSelection, resource } = props;
    return (

        <Box
            tag="footer"
            direction="row"
            alignContent="end"
            justify="end"
            pad={{ left: "small", vertical: "small" }}
        >
            {!currentSelection && <Button
                id="btnAdd"
                margin={{ right: "small" }}
                label="Select"
                onClick={onClickAddItem}
            />}
        </Box>
    );
};
