import React, { Fragment, useEffect } from "react";
import {
    Box, InfiniteScroll, Button, Text, RadioButtonGroup
} from "grommet";
import { BookingHeader } from "./BookingHeader";
import ContentContainer from "./ContentContainer";
import { Agenda } from './Agenda';
import { AddToBookingDialog } from "./AddToBookingDialog";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { SpecialRequests } from "./SpecialRequests";
import { ResourceDetail } from "./ResourceDetail";
import { EditBookingHeader } from "./EditBookingHeader";
import { CancelBookingDialog } from "./CancelBookingDialog";
import { states } from "../pages/states";
import { AmendmentStatus } from "./AmendmentStatus";
import { ResourceTypeEmojiName } from "./emojis/ResourceTypeEmojiName";
import { Features } from './Permissions';
import { Toast } from './Toast';
import { ContractDetails } from "./ContractDetails";
import { AddCircle, StatusGood } from 'grommet-icons'
import SupplementaryQuestions from "./SupplementaryQuestions";

export const data = [
    "View details about this booking item",
    "Change or amend this booking item",
    "Add or view notes about this ",
    "Remove this item from your booking"
];



const ManageBooking = ({ ...rest }) => {
    const { booking, venue, resources, setView, onSave, onCancel, editState, loading, cancelEdit, request_result, nextStep, error, setAmendment, rate, availability, cancelAmends, saveAmends, checkPermissions, questions, updateAnswers, refresh } = rest;

    const [addOpen, setAddOpen] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const [saveRequired, setSaveRequired] = React.useState(false);

    const oldDetails = booking.detailsOld;

    const bookedRooms = !booking ? null : booking.details.filter(room => room.resourceType == "Room");
    const bookedFood = !booking ? null : booking.details.filter(food => food.resourceType == "FoodAndBeverage");
    const bookedParking = !booking ? null : booking.details.filter(park => park.resourceType == "Parking");
    const bookedEquipment = !booking ? null : booking.details.filter(equip => equip.resourceType == "Equipment");
    const bookedAccomodation = !booking ? null : booking.details.filter(accom => accom.resourceType == "Accomodation");

    const bookedRoomsOld = !oldDetails ? null : oldDetails.filter(room => room.resourceType == "Room");
    const bookedFoodOld = !oldDetails ? null : oldDetails.filter(food => food.resourceType == "FoodAndBeverage");
    const bookedParkingOld = !oldDetails ? null : oldDetails.filter(park => park.resourceType == "Parking");
    const bookedEquipmentOld = !oldDetails ? null : oldDetails.filter(equip => equip.resourceType == "Equipment");
    const bookedAccomodationOld = !oldDetails ? null : oldDetails.filter(accom => accom.resourceType == "Accomodation");


    function saveHeaderEdit(data) {
        setAmendment(data);
        setEdit(false)
    }

    function saveDetailEdit(detail) {
        let index = booking.details.findIndex(d => d.id === detail.id);
        let indexOld = !booking.detailsOld ? null : booking.detailsOld.findIndex(d => d.id === detail.id);

        let newDetails = [...booking.details];
        newDetails[index] = detail;

        let newBooking;

        let oldDetail = booking.detailsOld ? [...booking.detailsOld] : [...booking.details];
        if (!oldDetail[index].doNotReplace) {
            oldDetail[index] = {...oldDetail[index], doNotReplace: true };
        }
        newBooking = {
            ...booking,
            detailsOld: oldDetail,
            details: newDetails,
        }
        setAmendment(newBooking);
        
    }

    function saveAddItem(detail) {
        let newDetails = booking.details;
        newDetails.push(detail)
        setAmendment({
            ...booking,
            details: newDetails
        });
    }

    function saveDeleteItem(detail) {
        let newDetails = booking.details.filter(i => i != detail);

        setAmendment({
            ...booking,
            details: newDetails
        });
    }

    const editButtons = {
        canEdit: checkPermissions(Features.AMENDBOOKING),
        canPay: checkPermissions(Features.MAKEPAYMENT),
        canCancel: checkPermissions(Features.CANCELBOOKING)
    };

    useEffect(() => {

        if (editState === states[3] && !loading) {
            setSaveRequired(true)
        }

    }, [editState, loading])

    return (


        <Box gap="medium" fill="light-1">
            <Box
                gap="medium"
                alignSelf="center"
                width="xxlarge"
                background="light-3"
            >
                {edit && (<EditBookingHeader booking={booking} onCancel={() => { setEdit(false) }} onSave={saveHeaderEdit} />)}
                <CancelBookingDialog open={editState === states[1] || editState === states[2]} editState={editState} loading={loading} request_result={request_result} onNext={nextStep} onBack={cancelEdit} error={error} />
                <Box gap="medium" alignSelf="center" width="xlarge" pad="medium">
                    {saveRequired && editState === states[3] &&
                        <Toast position="top" duration={3} modal={false} onClose={() => { setSaveRequired(false) }}>
                            <Box
                                direction="row"
                                align="center"
                                justify="between"
                                gap="small"
                                round
                                pad="small"
                                background="accent-2"
                            >
                                <StatusGood />
                                <Text size="large">You have pending changes for this booking, continue to make any further changes and then submit your request when you are ready</Text>
                            </Box></Toast>
                    }
                    {editState === states[3] &&
                        <AmendmentStatus onCancel={cancelAmends} booking={booking} rate={rate} availability={availability} loading={loading} goToChat={() => { setView(4) }} onSave={() => saveAmends(booking)} />
                    }
                    <BookingHeader booking={booking} venue={venue} editButtons={editButtons} setView={setView} onCancel={onCancel} onEdit={() => { setEdit(true) }} />
                    <ContractDetails booking={booking} canSign={checkPermissions(Features.SIGNCONTRACT)} onSign={() => onSave({ ...booking, contractSignedDate: new Date().toISOString() })} />
                    <Box
                        alignSelf="center"
                        gap="large"
                        margin="small"
                        width="xxlarge"
                    >
                        <ContentContainer
                            emoji=":shopping_trolley:"
                            title="Booking Details"
                        >
                            {bookedRooms && bookedRooms.length > 0 ?
                                <ContentContainer
                                    title="Rooms"
                                    emoji={ResourceTypeEmojiName("Room")}
                                >
                                    <InfiniteScroll items={bookedRooms}>
                                        {(item, index) => (
                                            <ResourceDetail id={"bookingItem" + item.id} key={index} item={item} resourceList={resources.rooms} onSave={saveDetailEdit} onDelete={() => saveDeleteItem(item)} canEdit={checkPermissions(Features.EDITROOM)} />
                                        )}
                                    </InfiniteScroll>
                                    {oldDetails &&
                                        <InfiniteScroll items={bookedRoomsOld}>
                                            {(item, index) => (
                                                <ResourceDetail id={"bookingItemOLD" + item.id} key={index} item={item} resourceList={resources.rooms} onSave={saveDetailEdit} onDelete={() => saveDeleteItem(item)} canEdit={checkPermissions(Features.EDITROOM)} />
                                            )}
                                        </InfiniteScroll>
                                    }
                                </ContentContainer> : null}

                            {bookedFood && bookedFood.length > 0 ?
                                <ContentContainer
                                    title="Food & Drink"
                                    emoji={ResourceTypeEmojiName("FoodAndBeverage")}
                                >
                                    <InfiniteScroll items={bookedFood}>
                                        {(item, index) => (
                                            <ResourceDetail id={"bookingItem" + item.id} key={index} item={item} resourceList={resources.foodandbeverage} onSave={saveDetailEdit} onDelete={() => saveDeleteItem(item)} canEdit={checkPermissions(Features.EDITROOM)} />
                                        )}
                                    </InfiniteScroll>
                                    {bookedFoodOld &&
                                        <InfiniteScroll items={bookedFoodOld}>
                                            {(item, index) => (
                                                <ResourceDetail id={"bookingItemOLD" + item.id} key={index} item={item} resourceList={resources.foodandbeverage} onSave={saveDetailEdit} onDelete={() => saveDeleteItem(item)} canEdit={checkPermissions(Features.EDITROOM)} />
                                            )}
                                        </InfiniteScroll>
                                    }
                                </ContentContainer> : null}

                            {bookedEquipment && bookedEquipment.length > 0 ?
                                <ContentContainer
                                    title="Equipment"
                                    emoji={ResourceTypeEmojiName("Equipment")}
                                >
                                    <InfiniteScroll items={bookedEquipment}>
                                        {(item, index) => (
                                            <ResourceDetail id={"bookingItem" + item.id} key={index} item={item} resourceList={resources.equipment} onSave={saveDetailEdit} onDelete={() => saveDeleteItem(item)} canEdit={checkPermissions(Features.EDITEQUIPMENT)} />
                                        )}
                                    </InfiniteScroll>
                                    {oldDetails &&
                                        <InfiniteScroll items={bookedEquipmentOld}>
                                            {(item, index) => (
                                                <ResourceDetail id={"bookingItemOLD" + item.id} key={index} item={item} resourceList={resources.equipment} onSave={saveDetailEdit} onDelete={() => saveDeleteItem(item)} canEdit={checkPermissions(Features.EDITROOM)} />
                                            )}
                                        </InfiniteScroll>
                                    }
                                </ContentContainer> : null}

                            {  bookedParking && bookedParking.length > 0 ?
                                        <ContentContainer
                                            title="Parking"
                                            emoji={ResourceTypeEmojiName("Parking")}
                                        >
                                               <InfiniteScroll items={bookedParking}>
                                            {(item, index) => (

                                                <ResourceDetail id={"bookingItem" + item.id} key={index} item={item} resourceList={resources.parking} onSave={saveDetailEdit} onDelete={() => saveDeleteItem(item)} canEdit={checkPermissions(Features.EDITPARKING)} />

                                                   )}
                                               </InfiniteScroll>
                                        {oldDetails &&
                                            <InfiniteScroll items={bookedParkingOld}>
                                                {(item, index) => (
                                                    <ResourceDetail id={"bookingItemOLD" + item.id} key={index} item={item} resourceList={resources.parking} onSave={saveDetailEdit} onDelete={() => saveDeleteItem(item)} canEdit={checkPermissions(Features.EDITROOM)} />
                                                )}
                                            </InfiniteScroll>
                                        }
                                           </ContentContainer> : null}

                            {bookedAccomodation && bookedAccomodation.length > 0 ?
                                <ContentContainer
                                    title="Accomodation"
                                    emoji={ResourceTypeEmojiName("Accomodation")}
                                >
                                    <InfiniteScroll items={bookedAccomodation}>
                                        {(item, index) => (
                                            <ResourceDetail id={"bookingItem" + item.id} key={index} item={item} resourceList={resources.accommodation} onSave={saveDetailEdit} onDelete={() => saveDeleteItem(item)} canEdit={checkPermissions(Features.EDITACCOMODATION)} />
                                        )}
                                    </InfiniteScroll>
                                    {oldDetails &&
                                        <InfiniteScroll items={bookedAccomodationOld}>
                                            {(item, index) => (
                                                <ResourceDetail id={"bookingItemOLD" + item.id} key={index} item={item} resourceList={resources.accommodation} onSave={saveDetailEdit} onDelete={() => saveDeleteItem(item)} canEdit={checkPermissions(Features.EDITROOM)} />
                                            )}
                                        </InfiniteScroll>
                                    }
                                </ContentContainer> : null}



                            <Box align="end" gridArea='buttons' pad={{ top: "large" }} >
                                <Button id="btnAdd" primary disabled={!checkPermissions(Features.ADDTOBOOKING)} label="Add To Booking" onClick={() => { setAddOpen(true) }} icon={<AddCircle />} />
                                <AddToBookingDialog open={addOpen} onCancel={() => { setAddOpen(false) }} onSave={saveAddItem} resources={resources} booking={booking} />
                            </Box>
                        </ContentContainer>
                    </Box>
                    {questions && questions.length > 0 &&
                        // <Box
                        //     alignSelf="center"
                        //     width="xxlarge"
                        //     direction="row-responsive"
                        // >
                            <ContentContainer
                                emoji=":white_question_mark:"
                                title="Booking Questions"
                                width="xxlarge"
                            >
                                <SupplementaryQuestions questions={questions} switchURN={booking.switchURN} updateAnswers={updateAnswers} refresh={refresh}/>
                            </ContentContainer>
                            
                        // </Box>
                    }
                    <Box
                        alignSelf="center"
                        gap="large"
                        margin="small"
                        width="xxlarge"
                        direction="row-responsive"
                    >
                        <Agenda booking={booking} onSave={onSave} canEdit={checkPermissions(Features.EDITAGENDA)} />
                        <SpecialRequests saveButton onSave={(text) => onSave({ ...booking, notes: text })} value={booking ? booking.notes : ""} />
                    </Box>
                </Box>
            </Box>
        </Box >
    );
}

export { ManageBooking };



