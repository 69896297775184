import * as React from 'react'
import { createContext, useContext, useState, useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom';

export const usePortal = () => {
    const context = usePortalContext()

    return context
}

export const PortalContext = createContext(undefined)

export const PortalContextProvider = ({ context, children}) =>
{
    const { bookingRef, paymentRef, email } = useParams();
    let location = useLocation();

    const [leadBooker, setLeadBooker] = useState();
    const [signedIn, setSignedIn] = useState(false);
    const [slug, setSlug] = useState(bookingRef);

    const save = window.sessionStorage.getItem("savedState");

    

    useEffect(
        () => {
            if (!leadBooker && location.pathname.toLowerCase().includes('/booking')) setLeadBooker(true);
            if (leadBooker && !location.pathname.toLowerCase().includes('/booking')) setLeadBooker(false);

        },
        [location]
    );

    useEffect(
        () => {
            if (!save) return;
            const cookie = JSON.parse(save);
            setSlug(cookie.slug);
            setSignedIn(cookie.signedIn)
            setLeadBooker(cookie.leadBooker)

        },
        [save]
    );


    let contextData = {
        slug: slug,
        email: email,
        signedIn: signedIn,
        leadBooker: leadBooker,
        bookingRef: bookingRef,
        ...context,
    }

    return (
        < PortalContext.Provider value = { contextData } >
        { children }
        </PortalContext.Provider >
    )
}

export const usePortalContext = () => useContext(PortalContext)