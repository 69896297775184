import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next';
import { useWidgetContext } from "../widgetContext";


const CurrencyFormat = (props) => {
    const context = useWidgetContext();
    const { t, i18n } = useTranslation();
    const {value, prefix, suffix, matUiVariant, capacityMeasure, styleless, noWrapper} = props;
    let localValue = value ? value : 0
    try {
        localValue = Number.parseFloat(localValue)
    } catch (error) {
        console.error(error)
        return null
    }
    if (!prefix && suffix && !styleless) {
        return (
            <Typography variant={matUiVariant}>
                {
                    localValue.toFixed(2) + suffix
                }
            </Typography>
        );
    } else if (capacityMeasure && suffix && !styleless) {
        return (
            <Typography variant={matUiVariant}>
                {
                    localValue.toFixed(2) + suffix + " " + (capacityMeasure === 'guests' ? t('switch.widget.currencyformat.perguest') : (capacityMeasure === 'units' ? t('switch.widget.currencyformat.each') : capacityMeasure))
                }
            </Typography>
        )
    } else if (capacityMeasure && prefix && !styleless) {
        return (
            <Typography variant={matUiVariant}>
                {
                    prefix + localValue.toFixed(2) + " " + (capacityMeasure === 'guests' ? t('switch.widget.currencyformat.perguest') : (capacityMeasure === 'units' ? t('switch.widget.currencyformat.each') : capacityMeasure))
                }
            </Typography>
        )
    } else if (capacityMeasure && styleless) {
        return (
            prefix + localValue.toFixed(2) + " " + (capacityMeasure === 'guests' ? t('switch.widget.currencyformat.perguest') : (capacityMeasure === 'units' ? t('switch.widget.currencyformat.each') : capacityMeasure))
        )
    } else if (noWrapper) {
        return ( 
            prefix + localValue.toFixed(2)
        )
    } else {
        return (
            <Typography variant={matUiVariant}>
                {
                    context.showPrices ? prefix + localValue.toFixed(2) : prefix + t('switch.widget.pricing.poa')
                }
            </Typography>
        );
    }
}

export default CurrencyFormat
