import React, { Fragment, useState } from "react";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import { RRule } from 'rrule';
import { useMultiDayForm } from "../contexts/useMultiDayForm";
import { useTranslation } from 'react-i18next';

export default function Weekly() {

    const { ruleText, SetRepeatInterval, interval, UpdateDayFilter, dayFilter } = useMultiDayForm();
    const { t, i18n } = useTranslation();

    return (
        <Fragment>
            <TextField
                label={t("switch.widget.multidayform.weeklabel")}
                type="number"
                inputProps={{ step: 1 }}
                value={interval}
                onChange={(e) =>  SetRepeatInterval(e.target.value) }
            />
            <FormGroup row>
                <Grid container direction="row" alignItems="center" justifyContent="center">
                    <FormControlLabel
                        control={<Checkbox checked={dayFilter.includes('MO')} onChange={UpdateDayFilter} name="MO" />}
                        label="Mon"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={dayFilter.includes('TU')} onChange={UpdateDayFilter} name="TU" />}
                        label="Tue"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={dayFilter.includes('WE')} onChange={UpdateDayFilter} name="WE" />}
                        label="Wed"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={dayFilter.includes('TH')} onChange={UpdateDayFilter} name="TH" />}
                        label="Thu"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={dayFilter.includes('FR')} onChange={UpdateDayFilter} name="FR" />}
                        label="Fri"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={dayFilter.includes('SA')} onChange={UpdateDayFilter} name="SA" />}
                        label="Sat"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={dayFilter.includes('SU')} onChange={UpdateDayFilter} name="SU" />}
                        label="Sun"
                    />
                </Grid>
            </FormGroup>
            <div>
                {t("switch.widget.multidayform.settingslabel")} : {ruleText}
            </div>
        </Fragment>
    );
}