import React, { Fragment } from "react";
import Occasion from "./Occasion";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import BookingPickersWrapper from './BookingPickersWrapper'
import NoSearchResults from "./NoSearchResults";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 'auto',
        height: 'auto',
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
    lowerContent: {
        maxWidth: '422px'
    }
}));

const OccasionList = ({ occasionBaseData, setOccasionById }) => {
    const classes = useStyles();

    if (!occasionBaseData) return null;
    return (
        <Grid container direction="row" justifyContent="space-evenly" alignItems="center" spacing={5} >
            <BookingPickersWrapper matUiXl="12" />
                {occasionBaseData.length === 0 ? 
                <NoSearchResults title={"switch.widget.notfound.nooccasion"} classes={classes} />
                :
                <Fragment>
                    {occasionBaseData.map(item => {
                        return (
                        <Grid item xs={12} sm={4} key={item.id} className={classes.lowerContent}>
                            <Occasion key={item.id} occasion={item} setOccasionById={setOccasionById} />
                        </Grid>);
                    })}
                </Fragment>
                }
        </Grid>

    );
};

export default OccasionList;
