import React, { Component } from "react";
import ContentContainer from "./ContentContainer";
import { Box, Grid, Text, Button } from "grommet";
import matUiFormatDate from "./matUiFormatDate";
import { View, Checkmark } from 'grommet-icons'

export const ContractDetails = (props) => {
    const { booking, canSign, onSign} = props;

    if (!booking) return null;
    if (booking.contractSentDate == null && booking.contractSignedDate == null) return null;
    return (
        <ContentContainer
            title="Contract Status"
        >

            <Box overflow="auto" align="center" flex="grow" direction="row" pad="small">
                <Box align="baseline" justify="start" direction="row">
                    <Text>
                        Sent Date: {matUiFormatDate(booking.contractSentDate)}
                    </Text>
                    <Text margin={{ "left": "medium", "right": "xsmall" }}>
                        Signed Date: 
                    </Text>
                    {booking.contractSignedDate ?
                        <Text color="status-ok">{matUiFormatDate(booking.contractSignedDate)}</Text> :
                        <Text color="status-critical">Not Signed</Text>
                    }

                    <Text margin="medium"> Version Number:  {booking.contractVersionNumber}</Text>
                </Box>
                <Box align="center" justify="end" direction="row" wrap={false} flex="grow">
                    {canSign && !booking.contractSignedDate && <Box align="end" justify="center" width="small"> <Button id="btnAccept" label="Accept" onClick={onSign} margin="medium" icon={<Checkmark />} size="medium" /> </Box>}
                    {booking.contractDoc && <Box align="end" justify="center" width="small">
                        <Button primary id="btnView" label="View" href={booking.contractDoc.documentUrl} target="_blank" margin="medium" icon={<View />} size="medium" />
                    </Box>
                    }
                </Box>
            </Box>

        </ContentContainer >
    );
}


