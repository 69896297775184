import React from 'react';

import { Button, Box, DataTable, Heading, Meter, Text, Select, CheckBox } from 'grommet';
import matUiFormatDate from './matUiFormatDate';
import { CurrencyFormat } from "./CurrencyFormat";

const columns = [
    {
        property: 'paidDate',
        header: <Text>Date</Text>,
        primary: true,
        render: item => (<Text >{matUiFormatDate(item.paidDate)}</Text>),
        size: '1/4'
    },
    {
        property: 'description',
        render: item => (<Text >{item.paidDate === null ? "Refund" : "Payment"}</Text>),
        size: '1/4'
        
    },
    {
        property: 'method',
        header: <Text>Method</Text>,
        render: item => (<Text >{PaymentMethod(item.paymentMethod.name)}</Text>),
        size: '1/4'
    },
    {
        property: 'amount',
        header: <Text> Amount </Text>,
        render: item => (<Text >{CurrencyFormat(item.amount)}</Text>),
        size: '1/4'
    },
    {
        property: 'refundAmount',
        header: <Text> Refunded</Text>,
        render: item => (<Text >{CurrencyFormat(item.refundAmount)}</Text>),
        size: '1/4'
    },
];


export const PaymentHistory = (props) => {
    const { payments, step } = props;


    return (
        <Box fill="horizontal" pad="medium">
        <DataTable
            columns={columns}
            data={payments}
            step={step}
            />
        </Box>

    );
};

export function PaymentMethod(value) {

    switch (value) {

        case "cardPayment":
            return "Card";
            break;
        default:
            return value;
            break;
    }

}