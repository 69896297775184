import * as React from 'react'
import { createContext, useContext, useState, useEffect } from 'react'
import { useGuestPortal } from './GuestPortalContext'

export const usePortalManagePayment = () => {
    const context = usePortalManagePaymentContext()

    return context
}

export const PortalManagePaymentContext = createContext(undefined)

function compare(a, b) {
    if (a.dueDate < b.dueDate) {
        return -1;
    }
    if (a.dueDate > b.dueDate) {
        return 1;
    }
    // a must be equal to b
    return 0;
}

const paymentChoises = [
    { label: 'Outstanding Balance', value: "1" },
    { label: 'Next Installment', value: "2" },
    { label: 'Over Due', value: "3" },
];


export const PortalManagePaymentContextProvider = ({ context, children }) => {
    const { booking, setBooking } = useGuestPortal();
    const [paymentOpen, SetPaymentOpen] = useState(false);
    const [showCardScreen, SetShowCardScreen] = React.useState(false);
    const [selectedOption, SetSelectedOption] = React.useState(paymentChoises[0].value);
    const [updatedBooking, SetUpdatedBooking] = React.useState(booking);


    const payments = booking
        ? booking.paymentSchedule.filter(
            payment =>
                payment.paymentStatus == "paid" ||
                payment.paymentStatus == "authorised" ||
                payment.paymentStatus == "paidInFull" ||
                payment.paymentStatus == "partiallyRefunded" ||
                payment.paymentStatus == "refunded"
        )
        : null;

    function OpenPaymentDialog() {
        SetPaymentOpen(true);
    }

    function ClosePaymentDialog() {
        if (updatedBooking && JSON.stringify(booking) != JSON.stringify(updatedBooking)) {
            setBooking(updatedBooking)
        }
        SetPaymentOpen(false);

    }

    function DisplayCardSceen() {
        SetShowCardScreen(true);
    }

    function findPaymentIndex(array, id) {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i].id === id) {
                return i;
            }
        }
        return -1;
    }

    function SaveBookingWithPendingPayment(pendingPayment) {
        //  Stripe Does not respond to us, other than to say what we asked for was successfull, stripe posts back to a call back on the API,
        // which we store in a task list for processing, might be up to 20s before the boking record is updated, therefore we need to moce up the payment record until it gets notified back to us.

        const clientBookingView = !updatedBooking ? JSON.parse(JSON.stringify(booking)) : JSON.parse(JSON.stringify(updatedBooking));


        if (pendingPayment) {
            const payIndex = findPaymentIndex(booking.paymentSchedule, pendingPayment.id);

            const balance = booking.outstandingBalance - pendingPayment.amount;

            if (payIndex === -1) {
                clientBookingView.outstandingBalance = balance;
                clientBookingView.paymentSchedule.push({
                    ...pendingPayment,
                    action: "payment",
                    paymentStatus: "paid",
                    paidDate: new Date()
                });
                SetUpdatedBooking(clientBookingView);
            } else {
                if (clientBookingView.paymentSchedule[payIndex].paymentStatus != "paid") {
                    clientBookingView.outstandingBalance = balance;
                    clientBookingView.paymentSchedule[payIndex].paymentStatus = "paid";
                    clientBookingView.paymentSchedule[payIndex].paidDate = new Date();
                    clientBookingView.paymentSchedule[payIndex].amount = pendingPayment.amount;
                    SetUpdatedBooking(clientBookingView);
                }
            }

        }

    }

    const today = new Date();

    const net = booking ? booking.netPrice : 0;
    const discount = booking ? booking.discount : 0;
    const tax = booking ? booking.tax : 0;
    const gross = booking ? booking.grossPrice : 0;
    const paidToDate = booking ? booking.grossPrice - booking.outstandingBalance : 0;
    const outstandingBalance = booking ? booking.outstandingBalance : 0;
    const invoiceUrl = booking && booking.invoiceDoc ? booking.invoiceDoc.documentUrl : null;
    const outstandingPayments = booking ? booking.paymentSchedule ? booking.paymentSchedule.sort(compare).filter(payment => payment.paymentStatus === "awaitingPayment") : null : null;
    const overDuePayments = outstandingPayments ? outstandingPayments.filter(payment => new Date(payment.dueDate) <= today) : null;
    const nextPayment = outstandingPayments && outstandingPayments.length > 0 ? outstandingPayments[0] : null;
    const overDueAmount = overDuePayments && overDuePayments.length > 0 ? overDuePayments.length === 1 ? overDuePayments[0].amount : overDuePayments.reduce((sum, x) => sum + x.amount) : 0;
    const nextPaymentAmount = nextPayment ? nextPayment.amount : null;
    const selectedPaymentAmount = selectedOption == 1 ? outstandingBalance : selectedOption == 2 ? nextPaymentAmount : overDueAmount;

    let paymentOptions = [paymentChoises[0]];
    if (nextPaymentAmount != outstandingBalance) paymentOptions.push(paymentChoises[1]);
    if (overDueAmount != outstandingBalance && overDueAmount != nextPaymentAmount) paymentOptions.push(paymentChoises[2]);


    let contextData = {
        paymentOpen: paymentOpen,
        OpenPaymentDialog: OpenPaymentDialog,
        ClosePaymentDialog: ClosePaymentDialog,
        payments, payments,
        SaveBookingWithPendingPayment, SaveBookingWithPendingPayment,
        net: net,
        discount: discount,
        tax: tax,
        gross: gross,
        paidToDate: paidToDate,
        outstandingBalance: outstandingBalance,
        invoiceUrl: invoiceUrl,
        outstandingPayments: outstandingPayments,
        overDuePayments: overDuePayments,
        nextPayment: nextPayment,
        overDueAmount: overDueAmount,
        nextPaymentAmount: nextPaymentAmount,
        paymentOptions: paymentOptions,
        showCardScreen: showCardScreen,
        DisplayCardSceen: DisplayCardSceen,
        selectedOption: selectedOption,
        SetSelectedOption, SetSelectedOption,
        selectedPaymentAmount: selectedPaymentAmount
    }

    return (
        <PortalManagePaymentContext.Provider value={contextData}>
            {children}
        </PortalManagePaymentContext.Provider>
    )
}

export const usePortalManagePaymentContext = () => useContext(PortalManagePaymentContext)
