import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import BookingFormSingleEvent from "./BookingFormSingleEvent";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import { useTheme } from "@material-ui/core/styles";
import SlideShowComponent from './SlideShowComponent'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
	logo: {
		maxWidth: "134px",
		maxHeight: "114px",
		marginRight: "20px",
	},
	mainContainer: {
		backgroundColor: "#fff",
		padding: "0px",
	},
	appbar: {
		alignItems: "center",
		height: "114px",
	},
	contentBox: {
		padding: "24px",
		"& p": {
			paddingBottom: "11px",
			fontSize: "13px",
		},
	},
	containerBox: {
		padding: "0px 0px 24px 0px",
		height: "100%",
	},
	imageBox: {
		padding: "0px 5px 0px 0px",
	},
	eventImage: {
		width: "100%",
		height: "auto",
	},
	descriptionBox: {
		padding: "0px 0px 0px 5px",
	},
	eventDescription: {
		textAlign: "center",
	},
	formContainer: {
		padding: "0px 24px",
	},
	paper: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	titleLight: {
		flexGrow: 1,
		textAlign: "center",
		color: "#FFFFFF",
	},
	titleDark: {
		flexGrow: 1,
		textAlign: "center",
		color: "#000000",
	},
	px17: {
		fontSize: "17px",
	},
}));

function BookingFormRender({ context }) {
	const classes = useStyles();
	return <BookingFormSingleEvent context={context} />;
}

function WidgetSingleEventBanner({ context }) {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	if (!context) return null;
	const {
		widgetTheme,
		occasionBaseData,
		eventData,
		venue,
		returnURL,
	} = context;

	const hasEventFinished = eventData.length > 0 ? false : true;

	var dateOptions = { year: "numeric", month: "long", day: "numeric" };
	return (
		<Container maxWidth="md" className={classes.mainContainer}>
			<AppBar
				position="static"
				className={classes.appbar}
				style={{ backgroundColor: `${theme.palette.custom.primary}` }}
			>
				<Toolbar>
					{widgetTheme === null ? (
						""
					) : (
						<img
							src={widgetTheme.logoImage.imageUrl}
							className={classes.logo}
						/>
					)}

					{widgetTheme === null ? (
						<Typography variant="h6" className={classes.titleDark}>
							{hasEventFinished ? (
								<React.Fragment>{t("switch.widget.singleevent.notfound")}</React.Fragment>
							) : (
								<React.Fragment>
									{venue[0].name}: {occasionBaseData[0].name} on{" "}
									{new Date(occasionBaseData[0].startDate).toLocaleString(
										"en-GB",
										dateOptions
									)}
								</React.Fragment>
							)}
						</Typography>
					) : (
						<Typography
							variant="h6"
							className={
								widgetTheme.fontStyle === "light"
									? classes.titleLight
									: classes.titleDark
							}
						>
							{hasEventFinished ? (
									<React.Fragment>{t("switch.widget.singleevent.notfound")}</React.Fragment>
							) : (
								<React.Fragment>
									{venue[0].name}: {occasionBaseData[0].name} on{" "}
									{new Date(occasionBaseData[0].startDate).toLocaleString(
										"en-GB",
										dateOptions
									)}
								</React.Fragment>
							)}
						</Typography>
					)}
				</Toolbar>
			</AppBar>
			<Grid container component="main" className={classes.containerBox}>
				<Grid item xs={12}>
					<Grid container className={classes.contentBox}>
						<Grid item xs={12} sm={12} md={12} className={classes.imageBox}>
							{!hasEventFinished && (
								<Grid item xs={12} className="img-container">
										<SlideShowComponent
												slideShow={occasionBaseData[0].slideShow}
												className={classes.eventImage}
										/>
								</Grid>
							)}
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							className={classes.descriptionBox}
						>
							{!hasEventFinished && (
								<Typography
									dangerouslySetInnerHTML={{
										__html: occasionBaseData[0].description,
									}}
									className={classes.eventDescription}
								/>
							)}
							{hasEventFinished && (
								<Typography component="p" className={classes.px17}>
									{t("switch.widget.singleevent.eventend")}
								</Typography>
							)}
							{hasEventFinished && returnURL && (
								<Typography component="p" className={classes.px17}>
									{t("switch.widget.singleevent.return")}{" "}<a href={returnURL}>{t("switch.widget.singleevent.website")}</a>
								</Typography>
							)}
							{hasEventFinished && !returnURL && (
								<Typography component="p" className={classes.px17}>
									{t("switch.widget.singleevent.go")}{" "}
									<span
										id="backLink"
										// eslint-disable-next-line no-restricted-globals
										onClick={() => history.back()}
										style={{ textDecoration: "underline", cursor: "pointer" }}
									>
										{t("switch.widget.singleevent.back")}
									</span>
								</Typography>
							)}
						</Grid>
					</Grid>
				</Grid>
				{!hasEventFinished && (
					<Grid item sm={12} md={12} className={classes.paper}>
						<Container className={classes.formContainer}>
							<Box textAlign="center">
								<BookingFormRender context={context} />
							</Box>
						</Container>
					</Grid>
				)}
			</Grid>
		</Container>
	);
}

export default WidgetSingleEventBanner;
