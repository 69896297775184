import React from "react";
import room1 from "./images/details-1.jpeg";
import room2 from "./images/details-2.jpeg";
import room3 from "./images/details-3.jpeg";
import room4 from "./images/details-4.jpeg";
import img1 from "./images/room-1.jpeg";
import img2 from "./images/room-2.jpeg";
import img3 from "./images/room-3.jpeg";
import img4 from "./images/room-4.jpeg";
import img5 from "./images/room-5.jpeg";
import img6 from "./images/room-6.jpeg";
import img7 from "./images/room-7.jpeg";
import img8 from "./images/room-8.jpeg";
import img9 from "./images/room-9.jpeg";
import img10 from "./images/room-10.jpeg";
import img11 from "./images/room-11.jpeg";
import img12 from "./images/room-12.jpeg";


const loadData = async () => {
    const { bookingRequest, widgetKey } = this.state;
    const url = 'https://localhost:44345/Dispace/Switch/1.0.0/BookingRequest/widgetSettings/' + widgetKey;

    console.log(JSON.stringify(bookingRequest));

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const json = await response.json();
       // console.log('Success:', JSON.stringify(json));
        this.setState(
            {
                json
            });


    } catch (error) {
        console.error('Error:', error);
    };
};

function data() {
    if (typeof this.state === "undefined") {
        loadData();
    }    
    return this.state;
}

export default data;



