import React, {Fragment} from "react";
import { withWidgetConsumer } from "../widgetContext";
import Loading from "./Loading";
import OccasionList from "./OccasionList";
import VenuesList from "./VenuesList";
import RoomsList from "./RoomsList";
import {FoodListTakeAway} from "./FoodListTakeAway";
import FoodTakeAway from "./FoodTakeAwayContainer"
import BookingFilter from "./BookingFilter";
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Grid from '@material-ui/core/Grid';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BookingForm from './BookingFormTakeAway';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import ResourceList from "./ResourceList";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import {useTheme } from '@material-ui/core/styles'
import CurrencyFormat from './CurrencyFormat';
import Details from './TakeAwayDetails'
import {TakeAwayMenuContainer, TakeAwayMenu } from './TakeAwayMenuContainer'
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next'


const drawerWidth = '25%';

const useStyles2 = makeStyles(theme => ({
    root: {
        display: 'flex',
        width: '60%',
    },
    logo: {
        width: '134px',
        marginRight: '20px',
        maxHeight: 200,
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginRight: drawerWidth,
    },
    appbar: {
        alignItems: 'center !important',
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        flexShrink: 0,
        height: "100%",
        disableScrollLock: "true"
    },
    drawerPaper: {
        zIndex: '0',
        padding: '72px 0px 0px 0px',
        height: "100%",
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    menuButton: {
    marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth})`,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        marginBottom: '90px',
        
    },
    cart: {
        cursor: 'pointer',
        backgroundColor: 'rgb(89,92,95)',
        width: '100%',
        padding: 5,
    },
    contentTakeAway: {
        paddingTop: '82px',
    },
    toTop: {
        position: 'fixed',
        bottom: theme.spacing(3),
        left: theme.spacing(1),
    },
}));

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

function Occasions({ data }) {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { occasionBaseData, setOccasionById } = data;

    if (occasionBaseData.length === 0) {
        return null;
    }
    return (
        <div className={classes.root}>
            <ExpansionPanel
                defaultExpanded="true">
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant="h6">{t("switch.widget.takeaway.selectoption")}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <OccasionList occasionBaseData={occasionBaseData} setOccasionById={setOccasionById} />
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>);
}

function PostCode({ data }) {
    const classes = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [postCode, setPostCode] = React.useState({
        target: { 
            value: '',
            type: 'textBox',
            name: 'postCode',
        },
    });

    const _applyPostCode = event => {
        setPostCode({ 
            ...postCode,
            target: { 
                ...postCode.target, 
                value: event.target.value
            }
        });
    }

    const applyPostCode = () => {
        data.handleContactChange(postCode, true);
    }
    
    const PostCodeContent = () => {
        return(
            <Fragment>
                <TextField
                    margin="dense"
                    id="postCode"
                    label={t("switch.widget.takeaway.postcode")}
                    defaultValue={postCode.target.value}
                    onChange={_applyPostCode}
                    autoFocus
                />
                <Button variant="outlined" style={{ color: `${theme.palette.primary.main}`, border: '2px solid', borderColor: `${theme.palette.primary.main}`, marginLeft: '10px', height: '50%', marginTop: '20px' }} size="small" onClick={applyPostCode}>{t("switch.widget.buttons.apply")}</Button>
            </Fragment>
        );
    }

    if (data.occasionBaseData.length === 0) {
        return null;
    }
    return (
        <div className={classes.root}>
            <ExpansionPanel
                defaultExpanded="true">
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant="h6">{t("switch.widget.takeaway.postcodeprompt")}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <PostCodeContent />
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>);
}

function Venues({ data }) {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { venue, setVenue } = data;

    if (venue.length === 0) {
        return null;
    }
    return (
        <div className={classes.root}>
            <ExpansionPanel
                defaultExpanded="true">
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant="h6"> {t("switch.widget.takeaway.venue")}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <VenuesList venue={venue} setVenue={setVenue} />
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>);
}

function Rooms({ data }) {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { sortedRooms, addBookingDetail, removeBookingDetail  } = data;

    if (sortedRooms.length === 0) {
        return null;
    }
    return (
        <div className={classes.root}>
            <ExpansionPanel
                defaultExpanded="true">
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant="h6"> {t("switch.widget.takeaway.room")}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <RoomsList rooms={sortedRooms} addBookingDetail={addBookingDetail} removeBookingDetail={removeBookingDetail} />
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>);
}

function Food({ data }) {
    const classes = useStyles();
    const { sortedFood, addBookingDetail, removeBookingDetail } = data;
    
    if (sortedFood.length === 0) {
        return null;
    }
    return(
        <div className={classes.root}>
            {sortedFood.map(menu => {
                return(
                    <FoodTakeAway key={menu.id} food={menu} addBookingDetail={addBookingDetail} removeBookingDetail={removeBookingDetail} />
                );
            })}
        </div>
    );

}

function Extras({ data }) {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { sortedExtras, addBookingDetail, removeBookingDetail } = data;

    if (sortedExtras.length === 0) {
        return null;
    }
    return (
        <div className={classes.root}>
            <ExpansionPanel
                defaultExpanded="true">
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>{t("switch.widget.takeaway.addons")}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <ResourceList resource={sortedExtras} addBookingDetail={addBookingDetail} removeBookingDetail={removeBookingDetail} />
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>);
}

function BookingFormRender({ context }) {
    const { container } = context;
    const classes = useStyles2();
    const totalItemCount = context.bookingRequest.Details;
    const { t, i18n } = useTranslation();

    const StyledBadge = withStyles(theme => ({
    badge: {
        right: 9,
        top: 9,
        color: `${theme.palette.primary}`,
        padding: '0 4px',
    },
    }))(Badge);
    return (
        <div className={classes.root}>
        <Hidden mdUp implementation="css">
                <Drawer
                    container={container}
                    variant="temporary"
                    anchor="bottom"
                    open={context.isDrawOpen}
                    onClose={context.changeDrawState}
                    classes={{
                    paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    <Grid container direction="row" justify="center" alignItems="center" style={{paddingTop: '20px', paddingBottom: '20px'}} className={classes.cart} onClick={context.changeDrawState}>
                        <Grid item xs={2} sm={1} style={{textAlign: 'right'}}>
                            {totalItemCount ?
                                <StyledBadge badgeContent={totalItemCount.length} color="primary">
                                    <ShoppingCartOutlinedIcon fontSize="large" style={{color: '#000', fontSize:'50px',}} />
                                </StyledBadge>
                            :
                                <Badge badgeContent={0} color="primary">
                                    <ShoppingCartOutlinedIcon fontSize="large" style={{color: '#000', fontSize:'50px',}} />
                                </Badge>
                            }
                        </Grid>
                        <Grid item xs={5} sm={3}>
                        
                            <Typography variant="body2" style={{color: '#fff'}} align="center">
                                {t("switch.widget.takeaway.addons")}
                            </Typography>
                        </Grid>
                        {context.calculatedRate.gross &&
                            <Grid item xs={2} sm={2} style={{textAlign: 'left'}}>
                                <Typography variant="body2" style={{color: '#fff'}}>
                                    {" - "}<CurrencyFormat value={context.calculatedRate.gross} prefix={t("switch.widget.currancysymbol")} matUiVariant="body2" /> 
                                </Typography>
                            </Grid>
                        }
                    </Grid>
                    <BookingForm context={context} />
                </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
                <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="right"
                disableScrollLock="true"
            >
                <BookingForm context={context} />
                <Divider />
            </Drawer>
            </Hidden>
        </div>
    );
}

function WidgetRoomBooking({ context }) {
    const changeDrawState = context.changeDrawState();
    const isDrawOpen = context.isDrawOpen;
    const classes = useStyles2();
    const theme = useTheme();

    if (context.bookingRequest.occasionId === null || context.bookingRequest.occasionId === 0) {
        return (
            <div className={classes.content2}>
                <AppBar position="fixed" className={classes.appbar} style={{backgroundColor: `${theme.palette.custom.primary}`}}>
                    <Toolbar>
                        
                        {context.widgetTheme === null ? ('') : (<img src={context.widgetTheme.logoImage.imageUrl} className={classes.logo} />)}
                        <Typography variant="h6" className={classes.title}>
                            {context.name}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Grid container direction="row" justify="space-around" alignItems="flex-start" className={classes.contentTakeAway}>
                    <Grid item xs={12} md={12}>
                        <Occasions data={context} />
                    </Grid>
                </Grid>
            </div>
        )
    }

const selectedOccasion = context.occasionData.filter( occasion => occasion.id === context.bookingRequest.occasionId)

    if (selectedOccasion[0].bookingType.toLowerCase() === 'foodorder_delivery') {
        if (context.bookingRequest.EnquiryContact === null || context.bookingRequest.EnquiryContact.postCode === undefined) {
            return (
                <div className={classes.content2}>
                    <AppBar position="fixed" className={classes.appbar} style={{backgroundColor: `${theme.palette.custom.primary}`}}>
                        <Toolbar>
                            
                            {context.widgetTheme === null ? ('') : (<img src={context.widgetTheme.logoImage.imageUrl} className={classes.logo} />)}
                            <Typography variant="h6" className={classes.title}>
                                {context.name}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Grid container direction="row" justify="space-around" alignItems="flex-start" className={classes.contentTakeAway}>
                        <Grid item xs={12} md={12}>
                            <PostCode data={context} />
                        </Grid>
                    </Grid>
                </div>
            )
        }
    }

    if (context.bookingRequest.venueId === null || context.bookingRequest.venueId === 0) {
        return (
            <div className={classes.content2}>
                <AppBar position="fixed" className={classes.appbar} style={{backgroundColor: `${theme.palette.custom.primary}`}}>
                    <Toolbar>
                        
                        {context.widgetTheme === null ? ('') : (<img src={context.widgetTheme.logoImage.imageUrl} className={classes.logo} />)}
                        <Typography variant="h6" className={classes.title}>
                            {context.name}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Grid container direction="row" justify="space-around" alignItems="flex-start" className={classes.contentTakeAway}>
                    <Grid item xs={12} md={12}>
                        <Venues data={context} />
                    </Grid>
                </Grid>
            </div>
        )
    }

    if ((context.bookingRequest.Details === null || context.bookingRequest.Details.length === 0) && context.sortedRooms.length > 0) {
        return (
            <div className={classes.content2}>
                <AppBar position="fixed" className={classes.appbar} style={{backgroundColor: `${theme.palette.custom.primary}`}}>
                    <Toolbar>
                        
                        {context.widgetTheme === null ? ('') : (<img src={context.widgetTheme.logoImage.imageUrl} className={classes.logo} />)}
                        <Typography variant="h6" className={classes.title}>
                            {context.name}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Grid container direction="row" justify="space-around" alignItems="flex-start" className={classes.contentTakeAway}>
                    <Grid item xs={12} md={12}>
                        <Rooms data={context} />
                    </Grid>
                </Grid>
            </div>
        )
    }
    else {

    }
    return (
        <React.Fragment>
            <div className={classes.content}>
                <AppBar position="fixed" className={classes.appbar} style={{backgroundColor: `${theme.palette.custom.primary}`}}>
                    <Toolbar>
                        
                        {context.widgetTheme === null ? ('') : (<img src={context.widgetTheme.logoImage.imageUrl} className={classes.logo} />)}
                        <Typography variant="h6" className={classes.title}>
                            {context.name}
                        </Typography>
                    </Toolbar>
                </AppBar>
                
                <BookingFormRender context={context} /> 
                <Grid container direction="row" justify="space-around" alignItems="flex-start" className={classes.contentTakeAway}>
                    <Grid item xs={4} md={3}>
                        <TakeAwayMenuContainer {...context} />
                        <a href={`${context.widgetKey}` + '#root'} className={classes.toTop}>
                            <Fab color="secondary" size="small" aria-label="scroll back to top">
                                <KeyboardArrowUpIcon />
                            </Fab>
                        </a>
                    </Grid>
                    <Grid item xs={8} md={7}>
                        <Details {...context} />
                        <Food data={context} />
                        <Extras data={context} />
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    );
}

export default WidgetRoomBooking;


