import React, { useEffect, useState } from "react";
import { memo } from "react";
import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import CurrencyFormat from './CurrencyFormat';
import VenueDetails from './VenueDataPopup';

import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { useWidgetContext } from "../widgetContext";
import Divider from '@material-ui/core/Divider';

import { useTranslation } from 'react-i18next'
import SlideShowComponent from "./SlideShowComponent";
import { useWidgetResources } from "../contexts/useWidgetResources";

const useStyles = makeStyles(theme => ({
    resourceInfo: {
        background: theme.palette.colors.white,
        width: '100% !important',
        fontWeight: '700',
        padding: '10px',
        display: 'table',
        width: '100%',
        height: '88px',
        border: '1px solid',
        borderColor: theme.palette.custom.secondary,
        borderTop: '0px solid',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
    },
    icons: {
        color: theme.palette.custom.secondary,
        cursor: 'pointer',
        verticalAlign: 'middle'
    },
    resourceItemCount: {
        border: '1px solid',
        borderColor: theme.palette.custom.secondary,
        borderRadius: '10px',
    },
    button: {
        marginTop: '10px',
    },
    qtySelector: {
        marginTop: '18px',
    }
}));

const Resource = memo(({ Resource, addBookingDetail, removeBookingDetail }) => {
    const classes = useStyles();
    const context = useWidgetContext();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { GetResourceBestRate } = useWidgetResources();

    const { id, venueId, name, slideShow, price, capacityMeasure, price_type } = Resource;

    const [rate, setRate] = useState({
        price: price,
        price_type: price_type
    });

    useEffect(
        () => {
            if (!Resource) return;
            const rate = GetResourceBestRate(Resource?.id);
            setRate(rate)
        },
        [Resource]
    );


    if (!Resource) return null;

    const addResourceToBooking = () => {
        addBookingDetail(id, venueId);
    };
    const removeResourceFromBooking = () => {
        removeBookingDetail(id, venueId);
    };
    const existingEntry = context.bookingRequest.Details.filter(x => x.ResourceId === id)
    return (
        <Grid container direction="column" justify="flex-start" alignItems="center" className={context.style === 'takeAway' ? 'takeAway' : 'empty'}>
            <Grid item xs={12} className="img-container">
                <SlideShowComponent slideShow={slideShow} />
            </Grid>

            <Grid item xs={12} className={classes.resourceInfo}>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" >
                    <Grid item xs={12}>
                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" >
                            <Grid item xs={11}>
                                <Typography component="h6" className="occasion-info-title">
                                    {name}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <VenueDetails venue={Resource} addVenueToBooking={addResourceToBooking} />
                            </Grid>
                        </Grid>
                        <Divider variant="middle" style={{ margin: '10px 0px 10px 0px' }} />
                        <CurrencyFormat value={context.showPrices ? rate?.price : null} prefix={`${t("switch.widget.from")} ${t("switch.widget.currancysymbol")}`} matUiVariant="body1" capacityMeasure={price_type == 'AnyPeriod' ? capacityMeasure : rate?.price_type === 'Hour' ? t("switch.widget.room.perhour") : rate?.price_type} />
                    </Grid>
                    {!existingEntry[0] ?
                        <Grid item xs={12}>
                            <Grid container direction="row" justify="center" alignItems="center" >
                                <Button
                                    id="btn-addToBooking"
                                    variant="outlined"
                                    style={{ color: `${theme.palette.custom.secondary}`, border: '2px solid', borderColor: `${theme.palette.custom.secondary}` }}
                                    onClick={addResourceToBooking}
                                    className={classes.button}
                                >
                                    {t("switch.widget.resource.addto")}
                                </Button>
                            </Grid>
                        </Grid>
                        :
                        <Grid item xs={12}>
                            <Grid container direction="row" justify="center" alignItems="center" className={classes.qtySelector}>
                                <Tooltip title={<Typography color="inherit" style={{ textAlign: 'center' }}>{t("switch.widget.resource.reduceqty")}</Typography>} aria-label={<Typography color="inherit">{t("switch.widget.resource.reduceqty")}</Typography>}>
                                    <Grid item xs={1}>
                                        {existingEntry[0] ? <RemoveIcon id="btn-Remove" data-testid="btn-remove" onClick={removeResourceFromBooking} className={classes.icons} /> : ''}
                                    </Grid>
                                </Tooltip>
                                <Grid item xs={1} className={classes.resourceItemCount}>
                                    <Typography style={{ padding: '2px 8px' }}>
                                        {existingEntry[0] ? `${existingEntry[0].Quantity}` : '0'}
                                    </Typography>
                                </Grid>
                                <Tooltip title={<Typography color="inherit" style={{ textAlign: 'center' }}>{t("switch.widget.resource.increaseqty")}</Typography>} aria-label={<Typography color="inherit">{t("switch.widget.resource.increaseqty")}</Typography>}>
                                    <Grid item xs={1}>
                                        <AddIcon id="btn-Add" data-testid="btn-add" onClick={addResourceToBooking} className={classes.icons} />
                                    </Grid>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    );
});

export default Resource;
