import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import ProgressButton from "./ProgressButton";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next'
import { PaymentContextProvider, usePayment } from "../contexts/PaymentContext";
import { useCheckout } from "../contexts/useCheckout";
import CheckoutStepContent from "./CheckoutStepContent";
import { useWidgetContext } from "../widgetContext";
import PayButton from "./PayButton";

const useStyles = makeStyles(theme => ({
    appBar: {
        position: "relative"
    },
    layout: {
        width: "auto",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
            padding: "20px"
        },
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: "auto",
            marginRight: "auto"
        }
    },
    stepper: {
        padding: theme.spacing(3, 0, 5)
    },
    stepperLabel: {
        [theme.breakpoints.down("sm")]: {
            display: "block"
        }
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end"
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1)
    },
    textDiv: {
        marginBottom: '1.5em'
    }
}));


export default function Checkout({ context }) {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const theme = useTheme();

    const {
        activeStep,
        disable,
        paymentInProgress,
        Next,
        Back,
        steps,
        handleClose,
        handleComplete } = useCheckout();

    const { savedCards, stripe, api, booking, amount, saveCard, termsAndConditionsDoc } = useWidgetContext();

    return (
        <React.Fragment>
            <CssBaseline />
            <main className={classes.layout}>
                <PaymentContextProvider context={{ savedCards, stripe, api, booking, amount, saveCard }} handleNext={Next} >
                    <Typography component="h1" variant="h4" align="center">
                        Checkout
                    </Typography>
                    <Stepper activeStep={activeStep} className={classes.stepper}>
                        {steps.map(label => (
                            <Step key={label}>
                                <StepLabel className={classes.stepperLabel}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <React.Fragment>
                        {activeStep === steps.length ? (
                            <React.Fragment>
                                <Typography variant="h5" gutterBottom>
                                    {t("switch.widget.checkout.thanks")}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {t("switch.widget.checkout.reference")} {booking.switchURN}. {t("switch.widget.checkout.nextsteps")}
                                </Typography>
                                <Button
                                    variant="outlined"
                                    style={{
                                        color: `${theme.palette.success.main}`,
                                        border: "2px solid",
                                        borderColor: `${theme.palette.success.main}`
                                    }}
                                    disabled={disable}
                                    onClick={handleComplete}
                                    className={classes.button}
                                >
                                    {t("switch.widget.buttons.close")}
                                </Button>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <CheckoutStepContent />
                                <div className={classes.buttons}>
                                    {activeStep !== 0 &&
                                        activeStep !== 3 && (
                                            <Button
                                                onClick={Back}
                                                className={classes.button}
                                                variant="outlined"
                                                id="btn-back"
                                                style={{
                                                    color: `${theme.palette.colors.black}`,
                                                    border: "2px solid",
                                                    borderColor: `${theme.palette.colors.black}`
                                                }}
                                            >
                                                {t("switch.widget.buttons.back")}
                                            </Button>
                                        )}
                                    {activeStep == 4 ?
                                        paymentInProgress === false ? (
                                            <PayButton />
                                        ) : (
                                            <ProgressButton />
                                        ) :
                                        activeStep != 3 ? (
                                            <Fragment>
                                                <Button
                                                    id="btnCloseDialog"
                                                    variant="outlined"
                                                    style={{
                                                        color: `${theme.palette.error.main}`,
                                                        border: "2px solid",
                                                        borderColor: `${theme.palette.error.main}`
                                                    }}
                                                    onClick={handleClose}
                                                    className={classes.button}
                                                >
                                                    Close
                                                </Button>
                                                <Button
                                                    variant={disable ? "contained" : "outlined"}
                                                    id="btn-next"
                                                    style={{
                                                        color: disable
                                                            ? `${theme.palette.info.main}`
                                                            : `${theme.palette.success.main}`,
                                                        border: "2px solid",
                                                        borderColor: disable
                                                            ? `${theme.palette.info.main}`
                                                            : `${theme.palette.success.main}`
                                                    }}
                                                    disabled={disable}
                                                    onClick={Next}
                                                    className={classes.button}
                                                >
                                                    {t("switch.widget.buttons.next")}
                                                </Button>
                                            </Fragment>
                                        ) : <></>}
                                </div>
                                {termsAndConditionsDoc !== null &&
                                    termsAndConditionsDoc.documentUrl != "" ? (
                                    <Fragment>
                                        <Typography gutterBottom>
                                            {t("switch.widget.checkout.submitstatement")}{" "}
                                            <Link
                                                href={termsAndConditionsDoc.documentUrl}
                                                target="_blank"
                                                color="secondary"
                                            >
                                                {t("switch.widget.checkout.terms")}
                                            </Link>
                                        </Typography>
                                    </Fragment>
                                ) : (
                                    <></>
                                )}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                </PaymentContextProvider>
            </main>
        </React.Fragment>
    );
}
