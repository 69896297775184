import React from "react";
import PropTypes from "prop-types";
import { Anchor, Box, Paragraph } from "grommet";

const Tile = ({ children, action, summary, clickAction, ltid, ...rest }) => (
    <Box basis="medium" align="center" margin="medium">

        <Box height="small" align="center" justify="center" {...rest}>
            <Anchor id={ltid} onClick={clickAction} alignSelf="center">
                {children}
            </Anchor>
        </Box>
        <Paragraph size="large" textAlign="center">
            {summary}
        </Paragraph>
        <Box align="center">
            <Anchor id={ltid} onClick={clickAction} alignSelf="center">
                {action}
            </Anchor>
        </Box>
    </Box >
);

Tile.propTypes = {
    children: PropTypes.node.isRequired,
    action: PropTypes.string.isRequired,
    summary: PropTypes.node.isRequired
};

export { Tile };
