import React from "react";
import { Link } from "react-router-dom";
import defaultLayoutImg from "../images/default_layout_image.jpg";
import PropTypes from "prop-types";
import { memo } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
    Image: {
        "max- width": '20%',
        width: 'auto',
        height: '180px',
        display: 'block',
        "margin-left": 'auto',
        "margin-right": 'auto'
    },
    layoutImage: {
        width: '25px',
        height: '25px',
    }
}));


const Layouts = (props) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    if (props.layouts.length > 0) {
        return (
            <Grid container direction="row" justify="flex-start" alignItems="flex-start" style={{paddingTop: '10px'}}>
                {props.layouts.map((layout, index) => {
                    return (
                        <Grid item xs={2} key={index}>
                            <Tooltip title={
                                <Typography color="inherit" style={{textAlign:'center'}}>
                                    {t("switch.widget.layouticon.layout") +layout.name}
                                    {layout.capacitySeated && <br />}
                                    {layout.capacitySeated && t("switch.widget.layouticon.seatcapacity") +layout.capacitySeated}
                                    {layout.capacitySeated && <br />}
                                    {layout.capacityStanding && t("switch.widget.layouticon.standcapacity") +layout.capacityStanding}
                                </Typography>
                            } aria-label={
                                <Typography color="inherit">
                                    {t("switch.widget.layouticon.clicktoadd") }
                                </Typography>
                            }>
                                <Grid container direction="column" justify="center" alignItems="center">
                                    <Grid item xs={12}>
                                        <img src={layout.image !== null ? layout.image.imageUrl : defaultLayoutImg} alt={layout.name} className={classes.layoutImage} id={"layoutImage-"+index} /><br />
                                        <Typography variant="body2" style={{paddingLeft: '7px', marginTop: '-5px', fontSize: '10px'}}>
                                            {layout.capacitySeated > layout.capacityStanding ? layout.capacitySeated : layout.capacityStanding}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Tooltip>
                        </Grid>
                    )
                })}
            </Grid>
        );
    }
    return null;
};
export default Layouts;
