import React from 'react'
import { useWidgetContext } from "../widgetContext";
import { Typography } from '@material-ui/core';

const ResourceDescription = (props) => {
    const context = useWidgetContext();
    const { id, description, venue } = props;

    if (!description) return null;

    let text = description;

    if (!venue) {
        const occasion = context.occasionBaseData.filter(o => o.id === context.bookingRequest.occasionId)[0];
        const detail = occasion.details.filter(d => d.resources.some(r => r.resourceid === id && r.useAlternateDescription === true))[0];
        const resource = detail ? detail.resources.filter(r => r.resourceid === id)[0] : false;
        text = !resource ? description : resource.alternateDescription;
    }


    return (
        <Typography variant="body2" dangerouslySetInnerHTML={{ __html: text }} />
    )
}

export default ResourceDescription;