import React from "react";
import { withWidgetConsumer } from "../widgetContext";
import Loading from "./Loading";
import VenuesList from "./VenuesList";
import RoomsList from "./RoomsList";
import FoodList from "./FoodList";
import BookingFilter from "./BookingFilter";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BookingForm from './BookingForm';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import ResourceList from "./ResourceList";
import { useTranslation } from 'react-i18next'

const drawerWidth = 480;

const useStyles2 = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginRight: drawerWidth,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        height: "100%",        
        disableScrollLock: "true"
    },
    drawerPaper: {
        height: "100%",
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        width: `calc(100% - ${drawerWidth}px)`,
    },
}));

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));


function Venues({ data }) {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const { venue, setVenue } = data;

    if (venue.length === 0) {
        return null;
    }
    return (
        <div className={classes.root}>
            <ExpansionPanel>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>{t("switch.widget.general.venues")}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <VenuesList venue={venue} setVenue={setVenue} />
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>);
}

function Rooms({ data }) {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { sortedRooms, addBookingDetail } = data;

    if (sortedRooms.length === 0) {
        return null;
    }
    return (
        <div className={classes.root}>
            <ExpansionPanel>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>{t("switch.widget.general.rooms")}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <RoomsList rooms={sortedRooms} addBookingDetail={addBookingDetail} />
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>);
}

function Food({ data }) {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { sortedFood, addBookingDetail, removeBookingDetail } = data;

    if (sortedFood.length === 0) {
        return null;
    }
    return (
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={classes.heading}>{t("switch.widget.general.food")}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <FoodList food={sortedFood} addBookingDetail={addBookingDetail} removeBookingDetail={removeBookingDetail}/>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );

}

function Extras({ data }) {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { sortedExtras, addBookingDetail, removeBookingDetail } = data;

    if (sortedExtras.length === 0) {
        return null;
    }
    return (
        <div className={classes.root}>
            <ExpansionPanel>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>{t("switch.widget.general.addons")}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <ResourceList resource={sortedExtras} addBookingDetail={addBookingDetail}  removeBookingDetail={removeBookingDetail} />
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>);
}

function BookingFormRender({ context }) {
    const classes = useStyles2();
    return (
        <div className={classes.root}>
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="right"
            >
                <div className={classes.toolbar} />
                <BookingForm context={context} />
                <Divider />
            </Drawer>
        </div>
    );
}


function WidgetGeneral({ context }) {
    const classes = useStyles2();    
    return (
        <React.Fragment>
            <div className={classes.content}>
                <BookingFormRender context={context} />
                <BookingFilter rooms={context} />
                <Venues data={context} />
                <Rooms data={context} />
                <Food data={context} />
                <Extras data={context} />
            </div>
        </React.Fragment>
    );
}

export default WidgetGeneral;
