import React from "react";
import Typography from '@material-ui/core/Typography';
import { Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import {useTheme } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import NoSearchResults from "./NoSearchResults";
import { useTranslation } from 'react-i18next'


const useStyles = makeStyles(theme => ({
    detailContainer: {
            padding: '25px 0px',
        },
}));

const EventDetails = ({ details, addBookingDetail, isCompulsoryResource, occasionId }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();

    if (!details) return null;

    if (details.length === 0) {
        return (
            <NoSearchResults title={"switch.widget.notfound.eventdetails"} classes={classes} />
        );
    }

    if (occasionId === 0) {
        return null
    }

    const addExtraToBooking = (event) => {
        addBookingDetail(event, null);
    };

    return (details.map(item => {
        let res = isCompulsoryResource(item.id, occasionId);
        {
            if (res === true) {
                return (null)
            } else {
                return   (

            <Grid pb={2} container direction="row" alignItems="center" style={{paddingBottom: '24px'}}>
                <Grid item md={1} xs={3} style={{textAlign: 'left'}}>
                            <Tooltip title={<Typography color="inherit" style={{ textAlign: 'center' }}>{t("switch.widget.eventdetails.add")} {item.name} {t("switch.widget.eventdetails.toorder")}</Typography>}
                                aria-label={<Typography color="inherit" style={{ textAlign: 'center' }}>{t("switch.widget.eventdetails.add")} {item.name} {t("switch.widget.eventdetails.toorder")}</Typography>}>
                        <IconButton id={"id-"+item.id.toString()} onClick={event => addExtraToBooking(item.id)}>
                            <AddIcon style={{ color: `${theme.palette.success.main}`}}/>
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item md={2} xs={9} style={{textAlign: 'left'}}>
                    <Typography style={{paddingLeft: '13px'}}>
                        {item.name}
                    </Typography>
                </Grid>
                <Grid item  md={8} xs={12} style={{textAlign: 'left'}}>
                    <Typography>
                        <div dangerouslySetInnerHTML={{ __html: item.description }} />
                    </Typography>
                </Grid>
            </Grid>
                )
            }
        };
    }));
}

export default EventDetails;
