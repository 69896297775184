import React from "react";
import { Box, Button, Layer } from "grommet";
import Dialog from "./Dialog";



export function ConfirmDialog(props) {
    const { prompt, onConfirm, onCancel } = props

    return <Dialog id="confirmDialog" dialogStyle="MessageBox" emoji=":exclamation:" title={prompt} onCancel={onCancel} onConfirm={onConfirm} >
        <React.Fragment/>
    </Dialog>

}
