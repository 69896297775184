import React from 'react';
import Button from '@material-ui/core/Button';
import NotesIcon from '@material-ui/icons/Notes';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import RoomLayout from './RoomLayout'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    helperIcon: {
        cursor: 'pointer',
    },
}));

export default function BookingItemNotes({ item, context }) {
    const theme = useTheme();
    const classes =  useStyles();
    const [open, setOpen] = React.useState(false);
    const [notes, setNotes] = React.useState(false);
    const { t, i18n } = useTranslation();

    if (!context) return null;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const room = context.rooms ? context.rooms.filter(x => x.id === item.id) : null

    const addRoomToBookingWithLayout = (id,
        venueId,
        bookingRequest,
        hireType,
        fieldVal,
        removeBookingItem,
        menuDetails,
        courseId,
        menuItemId,
        roomLayoutId,
        resourceType) => {
        context.addBookingDetail(room[0].id, room[0].venueId, undefined, undefined, undefined, undefined, roomLayoutId);
        setOpen(false);
    }

    // takeAway return
    if (context.style === 'takeAway') { 
        return (
            <React.Fragment>
                <Tooltip title={<Typography color="inherit" style={{ textAlign: 'center' }}>{t("switch.widget.bookingitemhelper.tooltip")}</Typography>} aria-label={<Typography color="inherit">{t("switch.widget.bookingitemhelper.tooltip")}</Typography>}>
                    <HelpOutlineIcon id="takeAwayHelpIcon" fontSize="small" style={{ color: `${theme.palette.primary.main}` }} onClick={handleClickOpen} className={classes.helperIcon} />
                </Tooltip>
                <Dialog scroll="body" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{item.name}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                        <div dangerouslySetInnerHTML={{ __html: item.description }}/>
                        </DialogContentText>
                        {room.length > 0 &&  room[0].layouts.length > 0 &&
                            <Typography variant="h6">{t("switch.widget.bookingitemhelper.changelayout")}</Typography>}
                        {room.length > 0 &&  room[0].layouts.length > 0 &&
                            <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                                {room[0].layouts.map(layout => {
                                    return <RoomLayout key={layout.id} layout={layout} addBookingDetail={context.addBookingDetail} room={room[0]} />;
                                })}
                            </Grid>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button id="btn-close" variant="outlined" style={{color: `${theme.palette.error.main}`, border: '2px solid', borderColor: `${theme.palette.error.main}`}} onClick={handleClose}>
                            {t("switch.widget.buttons.close")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }


    // Normal Return
    return (
        <React.Fragment>
            <Tooltip title={<Typography color="inherit" style={{ textAlign: 'center' }}>{t("switch.widget.bookingitemhelper.tooltip")}</Typography>} aria-label={<Typography color="inherit">{t("switch.widget.bookingitemhelper.tooltip")}</Typography>}>
                <HelpOutlineIcon id="helpIcon" fontSize="small" style={{ color: `${theme.palette.primary.main}` }} onClick={handleClickOpen} className={classes.helperIcon} />
            </Tooltip>
            <Dialog scroll="body" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{item.name}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                      <div dangerouslySetInnerHTML={{ __html: item.description }}/>
                    </DialogContentText>
                    {room && room.length > 0 &&  room[0].layouts.length > 0 &&
                        <Typography variant="h6">{t("switch.widget.bookingitemhelper.changelayout")}</Typography>}
                    {room && room.length > 0 &&  room[0].layouts.length > 0 &&
                        <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                            {room[0].layouts.map(layout => {
                                return <RoomLayout key={layout.id} layout={layout} addBookingDetail={context.addBookingDetail} room={room[0]} />;
                            })}
                        </Grid>
                    }
                </DialogContent>
                <DialogActions>
                    <Button id="btn-close" variant="outlined" style={{color: `${theme.palette.error.main}`, border: '2px solid', borderColor: `${theme.palette.error.main}`}} onClick={handleClose}>
                        {t("switch.widget.buttons.close")}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}