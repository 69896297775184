import React, { useEffect }  from "react";
import AppBarComponent from "./AppBarComponent";
import OccasionList from "./OccasionList";
import {
    useNavigate,
    useParams
} from "react-router-dom";

export function OccasionWrapper(params) {
    const { context, classes } = params;
    return (
        <div id="occasionWrapper" className={classes.contentFullWidth}>
            <AppBarComponent
                context={context}
                classes={classes}
                title="switch.widget.occasion.prompt"
            />
            <Occasions data={context} classes={classes} />
        </div>
    );
}

export function Occasions({ data, classes }) {
    const {
        occasions,
        occasionBaseData,
        setOccasionById,
        venue,
        setVenue
    } = data;
    const navigate = useNavigate();
    const { widgetKey } = useParams();

    function pushHistory(params) {
        setOccasionById(params);
        if (venue.length === 1) {
            setVenue(venue[0].id);
            navigate("/" + widgetKey + "/room");
        } else {
            navigate("/" + widgetKey + "/venue");
        }
    }

    let sortedOccasion = [];

    occasions
        .sort(function (a, b) {
            return a.displayOrder - b.displayOrder;
        })
        .forEach(item => {
            const found = occasionBaseData.find(
                element => element.id == item.occasionid
            );
            if (found) {
                sortedOccasion.push(found);
            }
        });

    useEffect(() => {
        if (sortedOccasion.length == 0) return;
        if (sortedOccasion.length > 1) return;
        pushHistory(sortedOccasion[0].id);
    }, [sortedOccasion])


    return (
        <div className={classes.rootTwo}>
            <OccasionList
                occasionBaseData={sortedOccasion}
                setOccasionById={pushHistory}
            />
        </div>
    );
}

export default OccasionWrapper;
