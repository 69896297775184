import React, { Fragment } from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import RoomLayout from './RoomLayout';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import CurrencyFormat from './CurrencyFormat';
import {useTheme } from '@material-ui/core/styles';
import { useWidgetContext } from "../widgetContext";
import Layouts from './RoomLayoutIcons'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ResourceDescription from './ResourceDescription';
import SlideShowComponent from './SlideShowComponent';
import { useTranslation } from 'react-i18next'

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function RoomDetails({ room, addBookingDetail, hardSetOpen }) {
    const { t, i18n } = useTranslation();
    const context = useWidgetContext();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [open, setOpen] = React.useState(false);
    
    if (!room) {return null}
    const { id, name, description, slideShow, price, price_type, layouts} = room;

    const OpenRoomData = () => {
        setOpen(true);
    };
    
    const CloseRoomData = () => {
        setOpen(false);
    };

    if (hardSetOpen && !open) {
        setOpen(true);
    }

    return (
        <Fragment>
            {!hardSetOpen &&
                <Tooltip title={<Typography color="inherit" style={{ textAlign: 'center' }}>{t("switch.widget.roomdetails.clickfordetails")}</Typography>} aria-label={<Typography color="inherit">{t("switch.widget.roomdetails.clickfordetails")}</Typography>}>
                <Button id="onClickOpen" onClick={OpenRoomData} style={{border: '1px solid black'}}>
                        {t("switch.widget.roomdetails.moreinfo")}
                </Button>
                </Tooltip>
            }
            <Dialog onClose={CloseRoomData} fullScreen={fullScreen} maxWidth="md" fullWidth aria-labelledby="customized-dialog-title" open={open} className="DialogBox">
                {hardSetOpen && 
                    <React.Fragment>
                        <DialogTitle id="customized-dialog-title">
                            <Typography>
                                {name}
                            </Typography>
                        </DialogTitle>
                        <DialogContent dividers>
                            <Typography variant="h6" className="select-room-title">
                                {t("switch.widget.roomdetails.selectlayout")}
                            </Typography>
                            <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                            {layouts.map((item, index) => { 
                                if (item.capacitySeated >= context.bookingRequest.numberOfGuests || item.capacityStanding >= context.bookingRequest.numberOfGuests) {
                                    return <RoomLayout key={index} layout={item} addBookingDetail={addBookingDetail} room={room} hardSetOpen={hardSetOpen} />;
                                } else {
                                    return null;
                                }
                            })}
                            </Grid>
                        </DialogContent>
                    </React.Fragment>
                }
                {!hardSetOpen &&
                    <React.Fragment>
                        <DialogContent>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" className="room" style={{border: 'none'}}>
                                <Grid item xs={12} className="room-info-title">
                                    <Typography>
                                        {name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} className="room-info-container">
                                    <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                        <Grid item xs={12} md={4} className="room-img-container">
                                            <SlideShowComponent slideShow={slideShow} />
                                        </Grid>
                                        <Grid item xs={12} md={8} style={{paddingLeft: '10px'}}>
                                            <ResourceDescription id={id} description={description} />
                                        </Grid>
                                        <Grid item xs={6} md={4}>
                                            <Layouts layouts={layouts} />
                                        </Grid>
                                        <Grid item xs={6} md={8}>
                                            <Typography style={{ paddingLeft: '10px' }} component="h6" variant="body2"> {t("switch.widget.roomdetails.pricesfrom")} <CurrencyFormat value={context.showPrices ? price : null} prefix={t("switch.widget.currancysymbol")} matUiVariant="body1" /> {t("switch.widget.roomdetails.per")}  {price_type} </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus id="btnClose" onClick={CloseRoomData} color="primary">{t("switch.widget.buttons.close")}</Button>
                            <Button id="btnAdd" onClick={() => { addBookingDetail(room.id, room.venueId, undefined, undefined, undefined, undefined, undefined, room.resourceType); }} variant="outlined" style={{ color: `${theme.palette.success.main}`, border: '2px solid', borderColor: `${theme.palette.success.main}` }}>{t("switch.widget.buttons.add")}</Button>
                        </DialogActions>
                    </React.Fragment>
                }
            </Dialog>
        </Fragment>
    );
}