import React, { useEffect } from 'react'
import AppBarComponent from "./AppBarComponent";
import VenuesList from "./VenuesList";
import {
    useNavigate,
    useParams
  } from "react-router-dom";

export function VenueWrapper(params) {
    const {context, classes} = params;
    
    useEffect(() => {
        context.refreshFilter()
    }, [])
    
    return(
        <div id="occasionWrapper" className={classes.contentFullWidth}>
            <AppBarComponent context={context} classes={classes} title='switch.widget.venue.prompt' />
            <Venues data={context} classes={classes} />
        </div >
    )
}

function Venues({ data, classes }) {
    const { sortedVenues, setVenue } = data;
    // const venues = venue.filter(v => {v})
    const navigate = useNavigate();
    const { widgetKey } = useParams();


    function pushHistory(params) {
        setVenue(params)
        navigate("/" + widgetKey + "/room");
    }

    return (
        <div className={classes.rootTwo}>
            <VenuesList venue={sortedVenues} setVenue={pushHistory} />
        </div>);
}

export default VenueWrapper;