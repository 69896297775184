
import React, { useState, useEffect } from "react";
import { withStyles } from '@material-ui/core/styles';
import { Badge, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Grid, Typography, Drawer } from '@material-ui/core';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AppBarComponent from "./AppBarComponent";
import BookingPickersWrapper from './BookingPickersWrapper'
import EarlyEmailCheck from './EarlyEmailCheck'
import FoodList from "./FoodList";
import ResourceList from "./ResourceList";
import BookingForm from './BookingForm';
import Hidden from '@material-ui/core/Hidden';
import CurrencyFormat from './CurrencyFormat';
import RoomDetails from './RoomDataPopup';
import { useWidgetContext } from "../widgetContext";
import { useTranslation } from 'react-i18next';
import BackButton from "./BackButton";


export function ExtrasWrapper(params) {
    const {context, classes} = params;
    const {addBookingDetail} = context
    return(
        <div id="occasionWrapper" className={classes.contentFullWidth}>
            <AppBarComponent context={context} classes={classes} title='switch.widget.general.extras' />
            <Grid container direction="row" justify="center" alignItems="flex-start">
                <Grid item xs={12} md={8}>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <BookingPickersWrapper padding="50px" matUiXl="10" />
                        <BackButton matUiXl="12" margin="-33px"/>
                        <Grid item xs={11} className={classes.foodBox}>
                            <ShowLayoutSelector addBookingDetail={addBookingDetail}/> 
                            {context.earlyEmailCheck && 
                            context.skippedEarlyEmailCheck === false &&
                            <EarlyEmailCheck context={context} open />}             
                            <Food data={context} classes={classes} />
                            <Extras data={context} classes={classes} />
                            {context.widgetTheme.adImages.length > 0 &&
                                <AdImages data={context} classes={classes} />
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item mdDown="hide" lg={4} xl={2}>
                    <BookingFormRender context={context} classes={classes} />
                </Grid>
            </Grid>
        </div >
    )
}

export function Food(props) {
    const { data, classes } = props;
    const { sortedFood, addBookingDetail, removeBookingDetail } = data;
    const { t, i18n } = useTranslation();
  
    if (sortedFood.length === 0) {
        return null;
    }
    return (
  
        <ExpansionPanel
            defaultExpanded="true">
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon className={classes.expansionPanelIcon} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.expansionPanel}
            >
                <Typography className={classes.heading}>{t('switch.widget.general.food')} </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <FoodList food={sortedFood} addBookingDetail={addBookingDetail} removeBookingDetail={removeBookingDetail} />
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
  
  }
  
  export function Extras(props) {
    const { data, classes } = props;
    const { sortedExtras, addBookingDetail, removeBookingDetail } = data;
    const { t, i18n } = useTranslation();
  
    if (sortedExtras.length === 0) {
        return null;
    }
    return (
        <ExpansionPanel
            defaultExpanded="true">
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon className={classes.expansionPanelIcon} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.expansionPanel}
            >
                <Typography className={classes.heading}>{t('switch.widget.general.addons')}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <ResourceList resource={sortedExtras} addBookingDetail={addBookingDetail} removeBookingDetail={removeBookingDetail} />
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
  }
  
  export function AdImages(props) {
    const { data, classes } = props;
    const { adImages, adCycleTime } = data.widgetTheme;
    const [ adState, setAdState ]  = useState({ 
        currentAdImage: 0,
        adImages: []
    })
    
    let imgUrls = []
  
    adImages.forEach(element => {
        imgUrls.push(element.image.imageUrl)
    });
  
    if (adState.adImages.length === 0) {
        setAdState({...adState, adImages: imgUrls})
    }
  
    function switchAdImage() {
        if (adState.currentAdImage < adState.adImages.length - 1) {
        setAdState({
            ...adState,
            currentAdImage: adState.currentAdImage + 1
        });
        } else {
        setAdState({
            ...adState,
            currentAdImage: 0
        });
        }
        return adState.currentAdImage;
    }
    
    useEffect(() => {
        const interval = setInterval(switchAdImage, (adCycleTime * 1000));
        return () => clearInterval(interval);
    }, [adState.currentAdImage]);
  
    return(
        <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} className={classes.adBox}>
                <img
                src={adState.adImages[adState.currentAdImage]}
                alt="advert images"
                className={classes.adImage}
                />
            </Grid>
        </Grid>
    )
  }
  
  export function BookingFormRender(props) {
    const { context, classes } = props;
    const { container } = context;
    const totalItemCount = context.bookingRequest.Details;
  
    // Translation hook
    const { t, i18n } = useTranslation();
  
    const StyledBadge = withStyles(theme => ({
    badge: {
        right: 9,
        top: 9,
        color: `${theme.palette.primary}`,
        padding: '0 4px',
    },
    }))(Badge);
    return (
        <div className={classes.root}>
        <Hidden mdUp implementation="css">
                <Drawer
                    container={container}
                    variant="temporary"
                    anchor="bottom"
                    open={context.isDrawOpen}
                    onClose={context.changeDrawState}
                    classes={{
                    paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                    }}
                >
  
                {/* Close Cart Code */}
                    <Grid container direction="row" justify="center" alignItems="center" style={{paddingTop: '20px', paddingBottom: '20px'}} className={classes.cart} onClick={context.changeDrawState}>
                        <Grid item xs={2} sm={1} style={{textAlign: 'right'}}>
                            {totalItemCount ?
                                <StyledBadge badgeContent={totalItemCount.length} color="primary">
                                    <ShoppingCartOutlinedIcon fontSize="large" style={{color: '#000', fontSize:'50px',}} />
                                </StyledBadge>
                            :
                                <Badge badgeContent={0} color="primary">
                                    <ShoppingCartOutlinedIcon fontSize="large" style={{color: '#000', fontSize:'50px',}} />
                                </Badge>
                            }
                        </Grid>
                        <Grid item xs={5} sm={3}>
                            <Typography variant="body2" style={{color: '#fff'}} align="center">
                                {t('switch.widget.extras.closebasket')}
                            </Typography>
                        </Grid>
                        {context.calculatedRate.gross &&
                            <Grid item xs={2} sm={2} style={{textAlign: 'left'}}>
                                <Typography variant="body2" style={{color: '#fff'}}>
                                    {" - "}<CurrencyFormat value={context.calculatedRate.gross} prefix={t('switch.widget.currancysymbol')} matUiVariant="body2" />
                                </Typography>
                            </Grid>
                        }
                    </Grid>
                    <BookingForm context={context} />
                </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
                <BookingForm context={context} />
            </Hidden>
        </div>
    );
  }
  
  export const ShowLayoutSelector = ({addBookingDetail}) => {
    const context = useWidgetContext();
  
    const RoomDetailFromBookingRequest = context.bookingRequest.Details.filter(detail => detail.resourceType === 'Room');
    if (RoomDetailFromBookingRequest.length > 0 && typeof RoomDetailFromBookingRequest[0].roomLayoutId === 'undefined') {
        const RoomDetailsFromContext = context.sortedRooms.filter(room => room.id === RoomDetailFromBookingRequest[0].ResourceId)
        if (RoomDetailsFromContext.length > 0 && RoomDetailsFromContext[0].layouts.length === 1) {
            addBookingDetail(RoomDetailsFromContext[0].id, RoomDetailsFromContext[0].venueId, undefined, undefined, undefined, undefined, RoomDetailsFromContext[0].layouts[0].id)
        }
        if (RoomDetailsFromContext.length === 0 || RoomDetailsFromContext[0].layouts.length === 0) {
            return null;
        }
        return (
            <RoomDetails room={RoomDetailsFromContext[0]} addBookingDetail={addBookingDetail} hardSetOpen={true} />
        )
    }
    return null
  }

export default ExtrasWrapper;