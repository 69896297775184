import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next';

export default function DiscountButton({ context }) {
    const theme = useTheme();
    const [code, setCode] = React.useState(context.bookingRequest.discountcode);
    const { t, i18n } = useTranslation();
    const {setDiscountCode} = context;


    const handleComplete = () => {
        setDiscountCode(code);
    };

    const handleCodeChange = (e) => {
        setCode(e.target.value);
        };
    

    return (
        <Fragment>
            <TextField
                margin="dense"
                id="discountCode"
                label={t('switch.widget.buttons.enterdiscount')}
                value={code}
                onChange={handleCodeChange}
            />
            <Button variant="outlined" style={{ color: `${theme.palette.colors.black}`, border: '2px solid', borderColor: `${theme.palette.colors.black}`, marginLeft: '10px', height: '50%', marginTop: '20px' }} size="small" onClick={handleComplete}>{t('switch.widget.buttons.apply')}</Button>
        </Fragment>
    );


}

