import React from 'react'

import { AppBar, Toolbar, Typography, useTheme, } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const AppBarComponent = (props) => {
  const {context, classes, title } = props;
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  return(
    <AppBar position="static" className={classes.appbar} style={{backgroundColor: `${theme.palette.custom.primary}`}}>
        <Toolbar>
            {context.widgetTheme === null ? ('') : (<img src={context.widgetTheme.logoImage.imageUrl} className={classes.logo} />)}
            <Typography variant="h4" className={classes.title}>
                {t(title)}
            </Typography>
              <div style={{  "margin-left": "auto", "margin-right": "0px"}}>
   
                      {context.widgetTheme === null || context.widgetTheme.secondaryLogo == null ? ('') : (<img src={context.widgetTheme.secondaryLogo.imageUrl} className={classes.logo} />)}
            </div>
        </Toolbar>
    </AppBar>
  )
}

export default AppBarComponent;