import React, { useState} from "react";
import {  Grid, Typography} from '@material-ui/core';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from "react-i18next"

export function PayResult() {
    let [searchParams] = useSearchParams();

    let sessionId = searchParams.get("sessionId"); 
    let provider = searchParams.get("provider");
    let result = searchParams.get("result"); 
    const { t, i18n } = useTranslation();

    const [processed, setProcessed] = useState(false);

    if (!processed && top.location != location) {
        const response = {
            source: "payresult",
            sessionId: sessionId,
            result: result,
            provider: provider,
        }
        parent.postMessage(response, "*");
        setProcessed(true);
    }

    return (
        <Grid container direction="row" justify="center" alignItems="flex-start">
            <Typography>{t("switch.widget.payresult.complete")}</Typography>
        </Grid>
    )
}