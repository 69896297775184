import React, { Component, useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";;
import CurrencyFormat from "./CurrencyFormat";
import { useTranslation } from "react-i18next"
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { usePayment } from "../contexts/PaymentContext";
import HostedPaymentPage from "./HostedPaymentPage";
import loadingGif from "../images/gif/loading-circles-sm.svg";

export default function WindCavePaymentForm({ context }) {
    const { t, i18n } = useTranslation();
    const {
        consent,
        ChangeConsent,
        saveDefault,
        ChangeDefault,
        selectedCard,
        hasSavedCards,
        amount,
        savedCards,
        error,
        cardType,
        handleChangeCard,
        SetCardType,
        processing,
        showIFrame
    } = usePayment();

    const handlePanelChange = (panel) => (event, isExpanded) => {
        SetCardType(isExpanded ? panel : false);
    };


    if (!context) return null;
    if (processing) {
        return (
            <div >
                <Typography variant="h6" gutterBottom>
                    {t("switch.widget.paymentform.prepare")}
                </Typography>

                <img src={loadingGif} alt="" />
            </div>)
    }

    if (showIFrame) {
        return (<HostedPaymentPage />)
    }

    return (
        <React.Fragment>
            <React.Fragment>
                <Typography variant="h6" gutterBottom>
                    {t("switch.widget.paymentform.payfor")}<CurrencyFormat value={amount} prefix={t("switch.widget.currancysymbol")} noWrapper={true} />
                </Typography>
                {hasSavedCards && <Accordion expanded={cardType === 'saved'} onChange={handlePanelChange('saved')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            {t("switch.widget.paymentform.usesavedcard")}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">{t("switch.widget.paymentform.selectcard")}</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                                value={selectedCard?.id}
                                onChange={handleChangeCard}
                            >
                                <Grid container spacing={12}>
                                    {savedCards.map(card => {
                                        const ExpiryDate = new Date(card.expiry);

                                        const options = { month: 'long' };
                                        const monthname = new Intl.DateTimeFormat('en-GB', options).format(ExpiryDate);

                                        let cardLabel = ' *** ' + card.last4 + ' - Expires(' + monthname + '/' + ExpiryDate.getFullYear() + ')'
                                        if (card.default) {
                                            cardLabel += ' - ' + t("switch.widget.paymentform.default")
                                        }


                                        const label = (
                                            <Grid container spacing={12}>
                                                <Grid item xs={2} style={{ padding: "0px 30px 0px 0px" }}>
                                                    <img key={card.id} src={`./image/cards/${card.brand}.png`} alt={card.brand} width="50px" align="bottom" style={{ padding: "0px 10px" }} />
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <Typography variant="body2">{cardLabel}</Typography>
                                                </Grid>
                                            </Grid>)

                                        if (selectedCard?.id != card?.id) {
                                            return (
                                                <Grid item xs={12}>
                                                    <FormControlLabel value={card.id} control={<Radio />} label={label} />
                                                </Grid>)
                                        } else {
                                            return (
                                                <Grid item xs={12}>
                                                    <FormControlLabel value={card.id} control={<Radio />} label={label} />
                                                </Grid>

                                            )
                                        }
                                    })}
                                </Grid>
                            </RadioGroup>
                        </FormControl>


                    </AccordionDetails>
                </Accordion>}
                <Accordion expanded={cardType === 'newcard'} onChange={handlePanelChange('newcard')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>{t("switch.widget.paymentform.newcard")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid containerspacing={3} m={3}>
                            <Grid
                                item
                                xs={12}
                                container
                                spacing={12}
                            >
                                <Typography>{t("switch.widget.paymentform.redirectprompt")}</Typography>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                {error && <Grid
                    item
                    xs={12}
                    className="error"
                    role="alert"
                >
                    <Typography color="error"> {error}</Typography>
                </Grid>}

                <Grid item xs={12}>
                    <FormControlLabel control={<Checkbox checked={saveDefault}
                        onChange={ChangeDefault} />} label={t("switch.widget.paymentform.useasdefault")} />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel control={<Checkbox checked={consent}
                        onChange={ChangeConsent} />} label={t("switch.widget.paymentform.consenttofuture")} />
                </Grid>
            </React.Fragment>
        </React.Fragment >
    );
}
