import React, { Fragment, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next'
import { FormControl, Tooltip, Select, MenuItem } from '@material-ui/core';
import Block from './Block';
import Repeat from './Repeat';
import Adhoc from './Adhoc';
import { useMultiDayForm } from '../contexts/useMultiDayForm';
import Box from '@material-ui/core/Box';

export default function MultidayForm({ context }) {
    const { t, i18n } = useTranslation();
    const {
        ChangeStartDate,
        ChangeEndDate,
        ChangeMultiDayType,
        repeatType,
        startDate,
        endDate, } = useMultiDayForm();

    if (!context) return null;



    function getOption() {
        switch (repeatType) {
            case "block":
                return <Block context={context} />;
            case "repeat":
                return <Repeat context={context} />;
            case "adHoc":
                return <Adhoc context={context} />;
        }
    }

    return (
        <Box m={1} p={2}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography>{t("switch.widget.multidayform.prompt")}</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Box width={1} >
                        <FormControl padding="10px">
                            <Select value={repeatType} onChange={ChangeMultiDayType}>
                                <MenuItem value="block">{t("switch.widget.block.title")}</MenuItem>
                                <MenuItem value="repeat">{t("switch.widget.repeat.title")}</MenuItem>
                                <MenuItem value="adHoc">{t("switch.widget.adhoc.title")}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>

                {repeatType != "adHoc" &&
                    <Grid item xs={12}>                <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <TextField onChange={ChangeStartDate} value={startDate} InputLabelProps={{ shrink: true }} label="Start Date" id="StartDate" name="StartDate" type="date" />
                        <TextField onChange={ChangeEndDate} value={endDate} InputLabelProps={{ shrink: true }} label="End Date" id="EndDate" name="EndDate" type="date" />
                    </Grid>
                    </Grid>}

                {getOption()}

            </Grid>
        </Box>
    );
}