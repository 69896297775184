import React, { Component, useEffect } from "react";
import { NavHeader } from "../guestPortalComponents/NavHeader";
import { GuestActions } from "../guestPortalComponents/GuestActions";
import { Section } from "../guestPortalComponents/section";
import { Footer } from "../guestPortalComponents/footer/footer";
import { ManagePayments } from "../guestPortalComponents/ManagePayments";
import { ManageBooking } from "../guestPortalComponents/ManageBooking";
import { ManageGuests } from "../guestPortalComponents/ManageGuests";
import { Chat } from "../guestPortalComponents/Chat";
import { Toast } from "../guestPortalComponents/Toast";

import { customTheme } from "./theme";

import { Box, Grommet, Stack, Text, Grid, Button, Card } from "grommet";
import { deepMerge } from "grommet/utils";
import { Favorite, View, StatusGood } from "grommet-icons";

import Api from "../Api";
import { states } from "./states";
import { Features } from "../guestPortalComponents/Permissions";
import Spinner from "../guestPortalComponents/Spinner";
import { loadStripe } from "@stripe/stripe-js";
import matUiFormatDate from "../guestPortalComponents/matUiFormatDate";
import { SSEProvider, useSSE } from "react-hooks-sse";

import ErrorBoundary from "./../ErrorBoundary";
import { useGuestPortal } from "../contexts/GuestPortalContext";

export function TimeFormat(time) {
    if (!time) {
        return "";
    } else {
        return time.substr(0, 5);
    }
}

const notification_state = {
    NOT_READ: 1,
    SHOW: 2,
    READ: 3
};

export default function LeadGuestPortal(props) {
    const { bookingRef} = useGuestPortal();

    return (
        <ErrorBoundary>
            <SSEProvider
                endpoint={bookingRef != null ?
                    window.location.origin +
                    "/Dispace/Switch/1.0.0/Notifications/" +
                    bookingRef : null
                }
            >
                <LeadGuestPortalContent {...props} />
            </SSEProvider>
        </ErrorBoundary>
    );
}

export function LeadGuestPortalContent(props) {
    const { portalSettings, booking, venue, contact, chatHistory, resources, logout, checkPermissions, questions, refresh, setBooking } = useGuestPortal();

    if (portalSettings === null) {
        logout();
    }

    //const originalBooking = JSON.parse(JSON.stringify(booking))
    const notification = useSSE("message", {
        BookingReference: "",
        UnreadMessages: 0,
        PaymentRecord: 0,
        PaymentRecord: "",
        LastUpdateTime: booking ? booking.lastAmmendedDate : new Date()
    });
    const [view, setView] = React.useState(0);
    const [updating, setUpdating] = React.useState(false);
    const [savedBooking, setSavedBooking] = React.useState(
        JSON.parse(JSON.stringify(booking))
    );
    const [portalState, updateState] = React.useState({
        booking: booking,
        chatHistory: chatHistory,
        questions: questions.sort((a, b) => (a.question.displayOrder > b.question.displayOrder ? 1 : -1)),
        edit_state: states[0],
        loading: false,
        update_price: false,
        check_availability: false,
        paymentInProgress: false
    });
    const [newMesage, setNewMessage] = React.useState(
        notification_state.NOT_READ
    );
    const [newContract, setNewContract] = React.useState(
        notification_state.NOT_READ
    );
    const [payRequired, setPayRequired] = React.useState(
        notification_state.NOT_READ
    );
    const [questionRequired, setQuestionRequired] = React.useState(
        notification_state.NOT_READ
    );

    const API = new Api({
        urlRoot: window.location.origin + "/Dispace/Switch/1.0.0/BookingRequest/",
        //urlRoot: "https://switchdev.azurewebsites.net/Dispace/Switch/1.0.0/BookingRequest/"
        widgetKey: portalSettings.widgetKey
    });

    //function refresh() {
    //    API.portalSignIn(portalState.booking.switchURN, contact.email).then(
    //        result => {
    //            updateState({
    //                ...portalState,
    //                chatHistory: result.chatHistory,
    //                booking: result.booking
    //            });
    //            setSavedBooking(result.booking);
    //            setUpdating(false);
    //        }
    //    );
    //}

    useEffect(
        () => {
            const PreviousUnread = portalState.chatHistory
                ? portalState.chatHistory.filter(
                    c => c.read === false && c.direction == "send"
                ).length
                : 0;
            if (
                notification &&
                (notification.LastUpdateTime != portalState.booking.lastAmmendedDate ||
                    notification.UnreadMessages > PreviousUnread)
            ) {
                if (notification.UnreadMessages > PreviousUnread) {
                    setNewMessage(notification_state.NOT_READ);
                }
                console.log("Booking has changed");
                refresh();
            }
        },
        [notification]
    );

    useEffect(
        () => {
            const save = JSON.parse(window.sessionStorage.getItem("savedState"));
            window.sessionStorage.setItem(
                "savedState",
                JSON.stringify({
                    ...save,
                    booking: savedBooking,
                    chatHistory: chatHistory
                })
            );            
        },
        [portalState, savedBooking]
    );

    useEffect(
        () => {

            
            if (booking && portalState.booking) {
                if (JSON.stringify(booking) != JSON.stringify(portalState.booking)) {
                    // This is only here while we migrate everything to a context 
                    setBooking(portalState.booking);
                }
            }
        },
        [portalState.booking]
    );
    useEffect(
        () => {
            if (view == 4) {
                if (portalState.chatHistory.filter(c => c.read === false).length > 0) {
                    readMessages();
                }
            }
        },
        [view]
    );

    if (portalState.update_price) {
        calcRate(portalState.booking);
    }

    if (portalState.check_availability) {
        checkAmend(portalState.booking);
    }

    function sendMessage(messageText) {
        setUpdating(true);
        API.sendMessage(booking.switchURN, messageText).then(result => {
            updateState({
                ...portalState,
                chatHistory: result
            });
            setUpdating(false);
        });
    }

    function readMessages() {
        setUpdating(true);
        API.readBookingMessages(booking.switchURN).then(result => {
            updateState({
                ...portalState,
                chatHistory: result
            });
            setUpdating(false);
        });
    }

    function allocateGuestArrivals() {
        setUpdating(true);
        API.allocateGuestArrivals(booking.switchURN).then(result => {
            let newBooking = {
                ...booking,
                guestList: result
            };

            updateState({
                ...portalState,
                booking: newBooking
            });
            setSavedBooking(newBooking);
            setUpdating(false);
        });
    }

    function updateAnswers(questionUpdate) {
        setUpdating(true);
        updateState({
            ...portalState,
            questions: questionUpdate,
        });
        setUpdating(false);
    }

    function sendGuestInvite(data) {
        setUpdating(true);
        API.guestInvite(booking.switchURN, data).then(result => {
            let newBooking = {
                ...booking,
                guestList: result
            };

            updateState({
                ...portalState,
                booking: newBooking
            });
            setSavedBooking(newBooking);
            setUpdating(false);
        });
    }

    function updateGuest(data) {
        setUpdating(true);
        var foundIndex = portalState.booking.guestList.findIndex(
            x => x.id == data.id
        );
        var newGuestList = portalState.booking.guestList;
        newGuestList[foundIndex] = data;

        updateState({
            ...portalState,
            booking: {
                ...booking,
                guestList: newGuestList
            }
        });

        API.updateGuest(booking.switchURN, data).then(result => {
            let index = portalState.booking.guestList.findIndex(
                g => g.id === result.id
            );
            let updatedGuestList = portalState.booking.guestList;
            updatedGuestList[index] = result;

            let newBooking = {
                ...portalState.booking,
                guestList: updatedGuestList
            };

            updateState({
                ...portalState,
                booking: newBooking
            });

            setSavedBooking(newBooking);
            setUpdating(false);
        });
    }

    function updateBookingInfo(data) {
        setUpdating(true);
        API.updateBookingInfo(booking.switchURN, data).then(result => {
            let newBooking = {
                ...booking,
                agendaItems: result.agendaItems,
                notes: result.notes,
                shortDescription: result.shortDescription,
                contractSignedDate: result.contractSignedDate
            };

            updateState({
                ...portalState,
                booking: newBooking
            });
            setSavedBooking(newBooking);
            setUpdating(false);
        });
    }

    function beginCancel() {
        updateState({
            ...portalState,
            edit_state: states[1],
            loading: true,
            request_result: null,
            nextStep: null,
            previous_edit_State: portalState.edit_state
        });
        calcRefund();
    }

    function cancelEditState() {
        updateState({
            ...portalState,
            edit_state: portalState.previous_edit_State,
            loading: false,
            request_result: null,
            nextStep: null,
            error: false
        });
    }

    function cancelAmends() {
        updateState({
            ...portalState,
            edit_state: states[0],
            loading: false,
            request_result: null,
            nextStep: null,
            error: false,
            loading: false,
            update_price: false,
            new_Rate: null,
            availability: null,
            update_price: false,
            check_availability: false,
            booking: savedBooking
        });
    }

    function cancelBooking() {
        setUpdating(true);
        API.cancelBooking(booking.switchURN).then(result => {
            if (result === 200) {
                updateState({
                    ...portalState,
                    edit_state: states[2],
                    loading: false,
                    error: false,
                    request_result: result,
                    nextStep: () => {
                        logout();
                    }
                });
            } else {
                updateState({
                    ...portalState,
                    edit_state: states[1],
                    loading: false,
                    error: true,
                    request_result: result,
                    nextStep: cancelEditState
                });
                setUpdating(false);
            }
        });
    }

    function calcRefund(value) {
        setUpdating(true);
        API.calculateRefund(booking.switchURN, booking)
            .then(result => {
                updateState({
                    ...portalState,
                    edit_state: states[1],
                    loading: false,
                    request_result: result,
                    nextStep: cancelBooking,
                    previous_edit_State: portalState.edit_state
                });
                setUpdating(false);
                return result;
            })
            .catch(error => {
                console.log(error);
            });
    }

    function calcRate(booking) {
        let request = BuildRequestFromBooking(booking, contact);

        API.calculatePrices(request)
            .then(result => {
                updateState({
                    ...portalState,
                    loading: false,
                    update_price: false,
                    new_Rate: result,
                    booking: applyNewRateToStateBooking(result)
                });
                return result;
            })
            .catch(error => {
                console.log(error);
            });
    }

    function requestAmendment(booking) {
        //amendBooking
        let request = BuildRequestFromBooking(booking, contact);
        setUpdating(true);
        API.amendBooking(request)
            .then(result => {
                updateState({
                    ...portalState,
                    loading: false,
                    update_price: false,
                    new_Rate: result,
                    booking: {
                        ...result,
                        guestList: booking.guestList
                    },
                    edit_state: states[0]
                });

                setSavedBooking(result);
                setUpdating(false);
            })
            .catch(error => {
                console.log(error);
            });
    }

    function applyNewRateToStateBooking(newRate) {
        let currentBookingState = {
            ...portalState.booking,
            netPrice: newRate.net,
            tax: newRate.vat,
            grossPrice: newRate.gross,
            outstandingBalance: newRate.newOutstandingBalance
        };
        newRate.lineItems.forEach(function (priceLine, index) {
            const i = currentBookingState.details.findIndex(
                i => i.resourceId == priceLine.item.id
            );
            currentBookingState.details[i] = {
                ...currentBookingState.details[i],
                unitCost: priceLine.net,
                discount: priceLine.discount,
                lineItemCost: priceLine.net,
                tax: priceLine.vat
            };
        });

        return currentBookingState;
    }
    function checkAmend(booking) {
        let request = BuildRequestFromBooking(booking, contact);

        API.checkAvailability(request)
            .then(result => {
                updateState({
                    ...portalState,
                    availability: result,
                    update_price: true,
                    check_availability: false
                });
                return result;
            })
            .catch(error => {
                console.log(error);
            });
    }

    function setAmendment(newData) {
        updateState({
            ...portalState,
            booking: newData,
            edit_state: states[3],
            check_availability: true,
            loading: true,
            availability: null,
            new_Rate: null
        });
    }

    function BuildRequestFromBooking(record, contact) {
        let request = {
            source: "MANUAL ENTRY",
            enquiryContact: contact,
            occasionId: record.occasionId,
            venueId: record.venueId,
            bookingDate: record.bookingDate,
            bookingEndDate: record.bookingDate,
            startTime: record.startTime,
            endTime: record.endTime,
            numberOfDays: 1,
            Discountcode: record.discountId ? "REFERENCE=" + record.discountId : null,
            numberOfGuests: record.numberOfGuests,
            notes: record.notes,
            details: record.details,
            currentBookingId: record.id
        };

        return request;
    }

    const PortalContent = props => {
        const { view, setView } = props;

        switch (view) {
            case 0:
                return (
                    <React.Fragment>
                        <Card
                            pad="small"
                            gap="medium"
                            border={{
                                color: "accent-1",
                                style: "groove",
                                elevation: "small",
                                size: "medium"
                            }}
                        >
                            <Grid
                                areas={[
                                    { name: "bookingRef_label", start: [0, 0], end: [0, 0] },
                                    { name: "bookingRef", start: [1, 0], end: [2, 0] },
                                    { name: "venue_label", start: [3, 0], end: [3, 0] },
                                    { name: "venue", start: [4, 0], end: [6, 0] },

                                    { name: "bookingDate_label", start: [0, 1], end: [0, 1] },
                                    { name: "bookingDate", start: [1, 1], end: [2, 2] },
                                    { name: "start_label", start: [3, 1], end: [4, 1] },
                                    { name: "start", start: [5, 1], end: [6, 1] },

                                    { name: "guests_label", start: [0, 2], end: [0, 2] },
                                    { name: "guests", start: [1, 2], end: [2, 2] },
                                    { name: "end_label", start: [3, 2], end: [4, 2] },
                                    { name: "end", start: [5, 2], end: [6, 2] }
                                ]}
                                columns={[
                                    "flex",
                                    "flex",
                                    "flex",
                                    "flex",
                                    "flex",
                                    "flex",
                                    "flex"
                                ]}
                                rows={["xxsmall", "xxsmall", "xxsmall"]}
                                gap="small"
                            >
                                <Box
                                    gridArea="bookingRef_label"
                                    justify="center"
                                    alignContent="center"
                                    weight="bold"
                                >
                                    <Text>Ref:</Text>
                                </Box>
                                <Box
                                    gridArea="bookingRef"
                                    justify="center"
                                    alignContent="center"
                                    round="xsmall"
                                >
                                    <Text alignSelf="center" size="small">
                                        {booking.switchURN}
                                    </Text>
                                </Box>
                                <Box
                                    gridArea="bookingDate_label"
                                    justify="center"
                                    alignContent="center"
                                    weight="bold"
                                >
                                    <Text>Date:</Text>
                                </Box>
                                <Box
                                    gridArea="bookingDate"
                                    justify="center"
                                    alignContent="center"
                                    round="xsmall"
                                >
                                    <Text alignSelf="center" size="small">
                                        {matUiFormatDate(booking.bookingDate)}
                                    </Text>
                                </Box>
                                <Box
                                    gridArea="start_label"
                                    justify="center"
                                    alignContent="center"
                                    weight="bold"
                                >
                                    <Text>Start:</Text>
                                </Box>
                                <Box
                                    gridArea="start"
                                    justify="center"
                                    alignContent="center"
                                    round="xsmall"
                                >
                                    <Text alignSelf="center" size="small">
                                        {TimeFormat(booking.startTime)}
                                    </Text>
                                </Box>

                                <Box
                                    gridArea="venue_label"
                                    justify="center"
                                    alignContent="center"
                                    weight="bold"
                                >
                                    <Text>Venue:</Text>
                                </Box>
                                <Box
                                    gridArea="venue"
                                    justify="center"
                                    alignContent="center"
                                    round="xsmall"
                                >
                                    <Text alignSelf="center" size="small">
                                        {venue.name}
                                    </Text>
                                </Box>
                                <Box
                                    gridArea="guests_label"
                                    justify="center"
                                    alignContent="center"
                                    weight="bold"
                                >
                                    <Text>Guests:</Text>
                                </Box>
                                <Box
                                    gridArea="guests"
                                    justify="center"
                                    alignContent="center"
                                    round="xsmall"
                                >
                                    <Text alignSelf="center" size="small">
                                        {booking.numberOfGuests}
                                    </Text>
                                </Box>
                                <Box
                                    gridArea="end_label"
                                    justify="center"
                                    alignContent="center"
                                    weight="bold"
                                >
                                    <Text>End:</Text>
                                </Box>
                                <Box
                                    gridArea="end"
                                    justify="center"
                                    alignContent="center"
                                    round="xsmall"
                                >
                                    <Text alignSelf="center" size="small">
                                        {TimeFormat(booking.endTime)}
                                    </Text>
                                </Box>
                            </Grid>
                        </Card>
                        <GuestActions
                            ManageBooking={() => setView(1)}
                            MakePayment={() => setView(2)}
                            ManageGuests={() => setView(3)}
                            MessageVenue={() => setView(4)}
                        />
                    </React.Fragment>
                );
                break;
            case 1:
                return (
                    <ManageBooking
                        booking={portalState.booking}
                        venue={venue}
                        resources={resources}
                        setView={setView}
                        onSave={updateBookingInfo}
                        editState={portalState.edit_state}
                        loading={portalState.loading}
                        onCancel={beginCancel}
                        cancelEdit={cancelEditState}
                        request_result={portalState.request_result}
                        nextStep={portalState.nextStep}
                        error={portalState.error}
                        setAmendment={setAmendment}
                        rate={portalState.new_Rate}
                        availability={portalState.availability}
                        cancelAmends={cancelAmends}
                        saveAmends={requestAmendment}
                        checkPermissions={checkPermissions}
                        questions={portalState.questions}
                        updateAnswers={updateAnswers}
                        refresh={refresh}
                    />
                );
                break;
            case 2:
                return (

                    <ManagePayments />

                );
                break;
            case 3:
                return (
                    <ManageGuests
                        portalSettings={portalSettings}
                        booking={portalState.booking}
                        venue={venue}
                        resources={resources}
                        updateGuest={updateGuest}
                        sendGuestInvite={sendGuestInvite}
                        checkPermissions={checkPermissions}
                        allocateGuestArrivals={allocateGuestArrivals}
                    />
                );
                break;
            case 4:
                return (
                    <Chat
                        booking={portalState.booking}
                        venue={venue}
                        chatHistory={portalState.chatHistory}
                        onSend={messageText => {
                            sendMessage(messageText);
                        }}
                        enabled={checkPermissions(Features.CHAT)}
                    />
                );
                break;
        }
    };

    function Processing(props) {
        const { children } = props;
        return (
            <Stack anchor="center">
                <Box
                    round
                    border={{ color: "grey" }}
                    pad="medium"
                    gap="small"
                    direction="column"
                    background="white"
                >
                    {children}
                </Box>
                <Box pad="medium" round="medium">
                    <Spinner />
                </Box>
            </Stack>
        );
    }

    const BookingNotification = props => {
        const { onClose, onGoTo, label } = props;
        return (
            <Toast position="left">
                <Box
                    direction="row"
                    elevation="medium"
                    pad={{ vertical: `xsmall`, horizontal: `large` }}
                    background="light-3"
                    gap="small"
                >
                    <Box gap="xsmall" align="center" direction="row">
                        <Text size="small">{label}</Text>
                    </Box>
                    <Box pad="small" border="left" align="center">
                        <Button
                            plain
                            label="ok"
                            icon={<StatusGood color="status-warning" />}
                            onClick={onClose}
                        />
                    </Box>
                    <Box pad="small" border="left" align="center">
                        <Button
                            plain
                            label="view"
                            icon={<View color="status-ok" />}
                            onClick={onGoTo}
                        />
                    </Box>
                </Box>
            </Toast>
        );
    };

    useEffect(
        () => {
            if (
                newMesage &&
                newMesage === notification_state.NOT_READ &&
                portalState.chatHistory.filter(
                    c => c.read === false && c.direction == "send"
                ).length > 0
            ) {
                setNewMessage(notification_state.SHOW);
            }
        },
        [portalState.chatHistory]
    );

    useEffect(
        () => {
            if (
                questions &&
                questionRequired === notification_state.NOT_READ &&
                questions.filter(q => q.answered === false).length > 0
            ) {
                setQuestionRequired(notification_state.SHOW);
            }
        },
        [portalState.questions]
    );

    useEffect(
        () => {
            if (
                newContract &&
                newContract === notification_state.NOT_READ &&
                portalState.booking.contractSentDate != null &&
                portalState.booking.contractSignedDate == null
            ) {
                setNewContract(notification_state.SHOW);
            }
        },
        [portalState.booking]
    );

    useEffect(() => {
        const today = new Date();
        const OutstandingPayments = booking
            ? booking.paymentSchedule
                .sort(compare)
                .filter(payment => payment.paymentStatus === "awaitingPayment")
            : null;
        const OverDuePayments = OutstandingPayments
            ? OutstandingPayments.filter(
                payment => new Date(payment.dueDate) <= today
            )
            : null;

        if (OverDuePayments.length > 0) {
            setPayRequired(notification_state.SHOW);
        }
    }, booking);

    const venueTheme = !venue.portalTheme
        ? customTheme
        : deepMerge(customTheme, {
            global: {
                font: {
                    family: venue.portalTheme.font
                },
                colors: {
                    brand: {
                        dark: venue.portalTheme.brandColour,
                        light: venue.portalTheme.brandColour
                    }
                }
            }
        });

    const currentTheme = !updating
        ? venueTheme
        : deepMerge(venueTheme, {
            global: {
                colors: {
                    text: {
                        dark: "light-5",
                        light: "light-5"
                    }
                }
            }
        });
    return (
        <Grommet theme={currentTheme} full>
            <NavHeader
                portalSettings={portalSettings}
                onHome={() => setView(0)}
                onManageBooking={() => setView(1)}
                onMakePayment={() => setView(2)}
                onManageGuests={() => setView(3)}
                onMessageVenue={() => setView(4)}
                view={view}
                contact={contact}
                onLogout={logout}
                onRefresh={() => {
                    refresh();
                }}
            />
            <Box align="center" pad="medium">
                {newMesage &&
                    newMesage == notification_state.SHOW && (
                        <BookingNotification
                            label="You have a new message"
                            onClose={() => setNewMessage(notification_state.READ)}
                            onGoTo={() => {
                                setNewMessage(notification_state.READ);
                                setView(4);
                            }}
                        />
                    )}
                {newContract &&
                    newContract == notification_state.SHOW && (
                        <BookingNotification
                            label="Please review the current contract"
                            onClose={() => setNewContract(notification_state.READ)}
                            onGoTo={() => {
                                setNewContract(notification_state.READ);
                                setView(1);
                            }}
                        />
                    )}
                {payRequired &&
                    payRequired == notification_state.SHOW && (
                        <BookingNotification
                            label="You need to make a payment"
                            onClose={() => setPayRequired(notification_state.READ)}
                            onGoTo={() => {
                                setPayRequired(notification_state.READ);
                                setView(2);
                            }}
                        />
                    )}
                {questionRequired &&
                    questionRequired === notification_state.SHOW && (
                        <BookingNotification
                            label="You have unanswered questions"
                            onClose={() => setQuestionRequired(notification_state.READ)}
                            onGoTo={() => {
                                setQuestionRequired(notification_state.READ);
                                setView(1);
                            }}
                        />
                    )
                }
                {!updating ? (
                    <PortalContent view={view} setView={setView} />
                ) : (
                    <Processing>
                        <PortalContent view={view} setView={setView} />
                    </Processing>
                )}
            </Box>
            <Section>
                <Footer portalSettings={portalSettings} />
            </Section>
        </Grommet>
    );
}

function compare(a, b) {
    if (a.dueDate < b.dueDate) {
        return -1;
    }
    if (a.dueDate > b.dueDate) {
        return 1;
    }
    // a must be equal to b
    return 0;
}
