import React from "react";
import { Box, Heading, Text, Paragraph, Button, Image, Layer, InfiniteScroll } from "grommet";
import ContentContainer from "./ContentContainer";
import { CurrencyFormat } from "./CurrencyFormat";
import { Dialog } from './Dialog'

export function FoodMenu(props) {
    const { open, onClose, menu } = props;

    if (!open) return null;

    return <Dialog id="foodMenu" dialogStyle="EditBox" emoji=":book:" title={!menu ? "Menu" : menu.name} onOK={onClose} >
        <Box direction="column" justify="between" align="start">
            {!menu ? <Text> No Menu Data </Text> :
                <Box direction="column" flex="grow" overflow="auto" >

                    {menu.courses && <InfiniteScroll items={menu.courses.sort(compare)}>
                        {(course) => (

                            <Box key={"course" + course.id} direction="column" >
                                <Box direction="column" >
                                    <Heading color="neutral-3" pad="xxsmall">{course.name}</Heading>
                                    {course.description &&
                                        <Paragraph margin="none" fill pad="xsmall">
                                            {course.description}
                                        </Paragraph>
                                    }
                                </Box>

                                <InfiniteScroll items={course.items.sort(compare)}>
                                    {(item) => (
                                        <Box key={"item" + item.id} direction="column" >
                                            <Heading level="4" color="neutral-2" pad="small">{item.name}</Heading>
                                            {item.description &&
                                                <Paragraph margin="medium" fill pad="xsmall">
                                                    {item.description}
                                                </Paragraph>
                                            }
                                            {(item.costPerHead === null) ? (<></>) : ((item.costPerHead === 0 ? (<></>) : (
                                                <Text textAlign="end" weight="bold" color="neutral-1"> Extra - {CurrencyFormat(item.costPerHead)} per head </Text>)))}
                                        </Box>
                                    )}
                                </InfiniteScroll>
                            </Box>)}
                    </InfiniteScroll>
                    }

                </Box>
            }
        </Box>
    </Dialog>
}

export function compare(a, b) {
    if (a.displayOrder < b.displayOrder) {
        return -1;
    }
    if (a.displayOrder > b.displayOrder) {
        return 1;
    }
    // a must be equal to b
    return 0;
}