import React from 'react';
import {
    Box,
    Button,
    Grommet,
    Keyboard,
    Text,
    Calendar,
    MaskedInput,
    DropButton,
} from 'grommet';
import { Schedule } from 'grommet-icons';

export function DateInput(props) {
    const [date, setDate] = React.useState(props.value);
    const [open, setOpen] = React.useState();

    const onClose = (nextDate, nextTime) => {
        setDate(nextDate);
        setOpen(false);
        setTimeout(() => setOpen(undefined), 1);
        if (props.onChange) {
            props.onChange(nextDate);
        }
    };

    const dateSelect = (value) => {
        setDate(value);
        if (props.onChange) {
            props.onChange({ target: { name: props.name, value: value }});
        }
        setOpen(false);
    }


    const DropContent = ({ date: initialDate, time: initialTime, onClose }) => {

        return (
            <Box align="center">
                <Calendar
                    animate={false}
                    date={date || initialDate}
                    onSelect={dateSelect}
                    showAdjacentDays={false}
                />

            </Box>
        );
    };

    return (
        <Box align="center" pad="large">
            <DropButton
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                dropContent={
                    <DropContent date={date} onClose={onClose} />
                }
            >
                <Box direction="row" gap="medium" align="center" pad="small">
                    <Text color={date ? undefined : 'dark-5'}>
                        {date
                            ? `${new Date(date).toLocaleDateString('en-GB')}`
                            : 'Select date '}
                    </Text>
                    <Schedule />
                </Box>
            </DropButton>
        </Box>
    );
};
