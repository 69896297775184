import React, { Component, useEffect } from 'react';
import { Box, Heading, Text, Paragraph, Button, Image, Layer, TextInput, RadioButtonGroup } from "grommet";
import ContentContainer from "./ContentContainer";
import { CurrencyFormat } from "./CurrencyFormat";
import CheckOutForm from './CheckOutForm';
import { BookingHeader } from "./BookingHeader";
import { NavHeader } from "./NavHeader";
import { PaymentContextProvider } from '../contexts/PaymentContext';
import { usePayment } from "../contexts/PaymentContext";
import { usePaymentPage } from '../contexts/PaymentPageContext';
import PaymentForm from './PaymentForm';
import { Loading } from "react-admin";

const PayButton = (props) => {
    const { onFinish } = props;

    const {
        cardType,
        loading,
        paymentStatus,
        ready,
        processingType,
        PerformRedirect,
        savedCardStepWindCave,
        setSavedCardStepWindCave,
        savedCards,
        iFrameResult,
        processing,
        preRedirectStateSetter
    } = usePayment();

    if (paymentStatus !== "succeeded") {

        switch (processingType) {
            case "windCave":
            case "pay360SCP":
            case "WPM":
                return (
                    <>
                        {savedCardStepWindCave && (
                            <Button
                                primary
                                disable={loading}
                                type="submit"
                                label={savedCards ? "Pay now" : "Next"}
                                onClick={() => {
                                    setSavedCardStepWindCave(false);
                                    PerformRedirect();
                                }}
                            />
                        )}
                        {!savedCardStepWindCave && iFrameResult && (
                            <Button
                                id="payConfirm"
                                primary
                                label={iFrameResult?.result === "success" ? "Finish" : "Retry"}
                                onClick={iFrameResult?.result === "success" ? onFinish : preRedirectStateSetter}
                                disabled={processing}
                            />
                        )}
                    </>
                );
            default: // stripe
                return (
                    <Button
                        id="payConfirm"
                        primary
                        label={
                            paymentStatus === "processing" ? "Processing" : "Pay"
                        }
                        disabled={
                            loading || !ready || paymentStatus === "processing"
                        }
                        form={cardType === "newcard" ? "cardDetails" : "cvcRecapture"}
                        type="submit"
                    />
                );

        }
    }
    return (
        <Button
            id="payConfirm"
            primary
            label="Finish"
            onClick={onFinish}
        />
    );
};


const CancelButton = (props) => {
    const { onCancel } = props;
    const {
        paymentStatus
    } = usePayment();

    if (paymentStatus != "succeeded") return (<Button id="payCancel" label="Cancel" onClick={onCancel} />);
    return null;
}
export default function StandAlonePayment(props) {
    const { amount, API, onCancel, onPay, booking, venue, contact, savedCards, stripeSettings, monitoring } = usePaymentPage();
    const [showForm, setShowForm] = React.useState(false);

    if (monitoring) return <Loading loadingPrimary={"Waiting for payment provider response"} loadingSecondary="Please wait" />

    return (
        <Box direction="column" pad="small" margin="small" overflow="auto" justify="center" align="center">
            <NavHeader contact={contact} guest />
            <Box pad="medium" >
                <BookingHeader booking={booking} venue={venue} />
            </Box>
            <PaymentContextProvider context={{ savedCards: savedCards, stripe: stripeSettings, api: API, booking: booking, amount: amount }}>
                <ContentContainer emoji=":credit_card:" title="Make a payment" content="Please check and confirm the payment amount" flex="grow" width="large">
                    {!showForm &&
                        <React.Fragment>
                            <Box direction="column" flex="grow" overflow="auto" pad="small" >
                                <Box align="center" direction="row" gap="small" pad="large">
                                    <Text size="large">Amount Due:</Text>
                                    <Box height="50px" width="200px" border={{ color: 'inherit', size: 'small' }} align="end" justify="center" pad="small">
                                        <Text size="large" >{CurrencyFormat(amount)}</Text>
                                    </Box>
                                </Box>

                            </Box>
                            <Box tag="footer" margin={{ top: "medium" }} direction="row" justify="between">
                                <Button id="payCancel" label="Cancel" onClick={onCancel} />
                                <Button id="payNext" primary label="Next" onClick={() => { setShowForm(true); }} />
                            </Box>
                        </React.Fragment>}
                    {showForm &&
                        <Box>
                            <PaymentForm />
                            <Box tag="footer" margin={{ top: "medium" }} direction="row" justify="between">
                                <CancelButton onCancel={onCancel} />
                                <PayButton onFinish={onPay} />
                            </Box>
                        </Box>
                    }
                </ContentContainer>
            </PaymentContextProvider>
        </Box>
    );
}
