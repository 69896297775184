import * as React from 'react'
import { createContext, useContext, useState, useEffect } from 'react'
import { useRecordContext } from 'react-admin'
import Api from '../Api'
import { useParams, useSearchParams } from 'react-router-dom';

export const usePaymentPage = () => {
    const context = usePaymentPageContext()

    return context
}

export const PaymentPageContext = createContext(undefined)

export const PaymentPageContextProvider = ({ context, children }) => {
    const record = useRecordContext()

    const [signedIn, setSignedIn] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState(0);
    const [rights, setRights] = useState([]);
    const [errorText, setErrorText] = useState();
    const [payPageData, setPayPageData] = useState(null);
    const [booking, setBooking] = useState();
    const [venue, setVenue] = useState();
    const [contact, setContact] = useState();
    const [savedCards, setSavedCards] = useState();
    const [payment, setPayment] = useState();
    const [stripeSettings, setStripeSettings] = useState();
    const [monitoring, setMonitoring] = useState(false);

    const { bookingRef: bookingRefParam, paymentRef: paymentRefParam } = useParams();
    const [bookingRef, setBookingRef] = useState(bookingRefParam)
    const [paymentRef, setPaymentRef] = useState(paymentRefParam)

    let [params, setParams] = useSearchParams();
    let _monitor = params.get("monitor"); // is the string "Jonathan Smith".


    const [monitor, setMonitor] = useState(_monitor != undefined ? true : false);

    useEffect(() => {
        if (!record) return
        if (!bookingRef) setBookingRef(record?.bookingRef)
        if (!paymentRef) setPaymentRef(record?.id)

    }, [record])



    let API = new Api(
        {
            urlRoot: window.location.origin + "/Dispace/Switch/1.0.0/BookingRequest/",
            widgetKey: payPageData != null && payPageData.portalSettings != null ? payPageData.portalSettings.widgetKey : null,
        });


    const logout = () => {
        setSignedIn(false);
    }

    const checkPermissions = (feature) => {
        return Permissions(rights, feature);
    }

    function MonitorPaymentStatus() {
        setMonitoring(true)
        API.MonitorPayment(paymentRef).then((result) => {
            setMonitoring(false)
            setPayment(result)
            if (result.paymentStatus !== "awaitingPayment") setErrorText("Payment Completed");
        });

    }

    const doSignIn = () => {
        setLoading(true);
        setErrorText(null);
        API.getPayPageData(bookingRef, paymentRef).then((result) => {
            if (result.validated) {
                setPayPageData(result);
                setSignedIn(true);
                setAmount(result.payment.amount);
                setLoading(false);
            }
            else {
                setPayPageData(result);
                setSignedIn(false);
                setLoading(false);
            }
        });
    }


    useEffect(
        () => {
            if (payPageData != null) {
                if (monitor && payPageData.payment?.paymentStatus === "awaitingPayment") MonitorPaymentStatus()
                setErrorText(payPageData.errorText);
                setBooking(payPageData.booking);
                setVenue(payPageData.venue);
                setContact(payPageData.contact);
                setSavedCards(payPageData.savedCards);
                setAmount(payPageData.amount);
                setRights(payPageData.rights);
                setPayment(payPageData.payment)
                setStripeSettings(payPageData.portalSettings ? {

                    publishableKey: payPageData.portalSettings.stripeKey,
                    connectedAccountId: payPageData.portalSettings.stripeAccountId

                } : null);
            }
        },
        [payPageData]
    );

    let contextData = {
        bookingRef: bookingRef,
        paymentRef: paymentRef,
        signedIn: signedIn,
        redirect: redirect,
        loading: loading,
        amount, amount,
        API: API,
        checkPermissions, checkPermissions,
        logout: logout,
        errorText: errorText,
        onCancel: () => (setRedirect(true)),
        onPay: () => (setRedirect(true)),
        booking: booking,
        venue: venue,
        contact: contact,
        savedCards: savedCards,
        stripeSettings: stripeSettings,
        doSignIn: doSignIn,
        payment: payment,
        monitoring,
    }

    return (
        <PaymentPageContext.Provider value={contextData}>
            {children}
        </PaymentPageContext.Provider>
    )
}

export const usePaymentPageContext = () => useContext(PaymentPageContext)
