import React from "react";
import Food from "./Food";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import NoSearchResults from "./NoSearchResults";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 'auto',
        height: 'auto',
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
}));



const FoodList = ({ food, addBookingDetail, removeBookingDetail }) => {
    const classes = useStyles();

    if (food === undefined) return null;

    if (food.length === 0) {
        return (
            <NoSearchResults title={"switch.widget.notfound.nofood"} classes={classes} />
        );
    }
    return (
        <Grid container direction="row" justify="space-evenly" alignItems="center" spacing={4}>
            {food.map(item => {
                return (
                <Grid item xs={12} lg={4} xl={2} key={item.id}>
                    <Food id={"foodItem-"+item.id} food={item} key={item.id} food={item} 
                            onAdd={() => addBookingDetail(item.id, item.venueId)} 
                            onRemove={() => removeBookingDetail(item.id, item.venueId)} />
                </Grid>);
            })}
        </Grid>
    );
};

export default FoodList;
