import React, { Component } from "react";
import { Section } from "../guestPortalComponents/section";
import { Footer } from "../guestPortalComponents/footer/footer";
import { GuestPersonalView } from '../guestPortalComponents/GuestPersonalView';
//import { booking, venue, contact, chatHistory, resources} from '../testData/data';

import { customTheme } from "./theme";

import { Box, Grommet } from "grommet";
import { deepMerge } from "grommet/utils";
import Api from '../Api';
import { Features } from "../guestPortalComponents/Permissions";

import ErrorBoundary from './../ErrorBoundary'

const API = new Api(
    {
        urlRoot: window.location.origin + "/Dispace/Switch/1.0.0/BookingRequest/"
        //urlRoot: "https://switchdev.azurewebsites.net/Dispace/Switch/1.0.0/BookingRequest/"
    });



export default function GuestPortal(props) {
    const { booking, venue, resources, contact, checkPermissions , logout} = props;
    const [currentBooking, setBooking] = React.useState(booking);
    const [fetchTime, setFetch] = React.useState(new Date().toISOString());
    const [updated, setUpdated] = React.useState(false)

    function updateGuest(data) {
        API.updateGuest(booking.switchURN, data).then((result) => {

            let index = booking.guestList.findIndex(g => g.id === result.id);
            let updatedGuestList = booking.guestList;
            updatedGuestList[index ] = result; 

            setBooking({
                ...booking,
                guestList: updatedGuestList,
            });
            setFetch(new Date().toISOString());
            setUpdated(true);
        });
    }

    const venueTheme = !venue.portalTheme ? customTheme : deepMerge(customTheme, {
        global: {
            font: {
                family: venue.portalTheme.font,
            },
            colors: {
                brand: {
                    dark: venue.portalTheme.brandColour,
                    light: venue.portalTheme.brandColour
                },
            }
        }
    }
    );

    return (
        <ErrorBoundary>
            <Grommet theme={venueTheme} full>
                <Box align="center" pad="medium">
                    <GuestPersonalView fetchTime={fetchTime} booking={currentBooking} venue={venue} resources={resources} contact={contact} onSave={updateGuest} checkPermissions={checkPermissions} onLogout={logout} updated={updated} setUpdated={setUpdated} />
                </Box>
                <Section>
                    <Footer />
                </Section>

            </Grommet>
        </ErrorBoundary>
    );
}


