import React, { Fragment } from "react";
import { Box } from "grommet";
import { Tile } from "./Tile";
import { FeatureEmoji } from "./emojis/FeatureEmoji";

export const data = [
    "View and update your current booking details, manage agendas, confirm service times, add additonal items to your booking.",
    "View invoices, check your payment schedule and make payments for your booking",
    "Invite your guests, check on their responses and send them copies of your menu and the agenda for your booking.",
    "View your message history, Contact our team about any issues related to your booking"
];


const GuestActions = ({ ...rest }) => {
    
    const { ManageBooking, MakePayment, ManageGuests, MessageVenue } = rest;
    return (
    <React.Fragment>
        <Box
            direction="row-responsive"
            justify="center"
            align="center"
            {...rest}
            margin={{ top: "small" }}
        >
                <Tile direction="row" action="Manage Booking" summary={data[0]} clickAction={ManageBooking} ltid="mb1">
                    <FeatureEmoji name=":spiral_calendar_pad:" size="128px"/>
            </Tile>
                <Tile direction="row" action="Make a Payment" summary={data[1]} clickAction={MakePayment} ltid="mp1">
                    <FeatureEmoji name=":credit_card:" size="128px"/>
            </Tile>
                <Tile direction="row" action="Manage Guests" summary={data[2]} clickAction={ManageGuests} ltid="mg1">
                    <FeatureEmoji name=":email:" size="128px"/>
            </Tile>
                <Tile direction="row" action="Message Venue" summary={data[3]} clickAction={MessageVenue} ltid="mv1">
                    <FeatureEmoji name=":speech_balloon:" size="128px" />
            </Tile>
        </Box>

    </React.Fragment >
);
}

export { GuestActions };
