import React from "react";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CurrencyFormat from './CurrencyFormat';

import { useTranslation } from 'react-i18next'
import BookingItemHelperInfo from './BookingItemHelperInfo';

const useStyles = makeStyles(theme => ({
    listItem: {
        padding: theme.spacing(1, 0),
    },
    total: {
        fontWeight: '700',
    },
    title: {
        marginTop: theme.spacing(2),
    },
}));

const BookingSummary = ({ rate, showTax, hideSecureBooking }) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    if (!rate) return null;
    const { net, vat, gross, discountValue, depositRequired, damageDeposit } = rate;

    const repeatedVars = {
        align: hideSecureBooking ? "left" : "right",
        disablePadding: hideSecureBooking ? true : false,
        className: hideSecureBooking ? classes.listItem : '',
        matUiVariant: hideSecureBooking ? "body2" : "body1",
    }

    if (typeof rate === "undefined" || !rate.hasOwnProperty('gross')) return null;
    return (
        <List aria-label="booking summary" disablePadding={repeatedVars.disablePadding}>
            {showTax === true ? (
                <ListItem  className={repeatedVars.className}>
                    <ListItemText primary={t('switch.widget.bookingsummary.net')} align={repeatedVars.align} />
                    <CurrencyFormat value={net} prefix={t('switch.widget.currancysymbol')} matUiVariant="body1" />
                </ListItem>) : (<></>)}
            {typeof discountValue === "undefined" || Number.parseInt(discountValue) === 0 ? (<></>) : (
                <ListItem className={repeatedVars.className}>
                    <ListItemText primary={t('switch.widget.bookingsummary.discount')+":"}  align={repeatedVars.align} />
                    <CurrencyFormat value={discountValue} prefix={t('switch.widget.currancysymbol')} matUiVariant="body1" />
                </ListItem>)}
            {showTax === true ? (
            <ListItem  className={repeatedVars.className}>
                    <ListItemText primary={t('switch.widget.bookingsummary.vat')+":"}  align={repeatedVars.align} />
                    <CurrencyFormat value={vat} prefix={t('switch.widget.currancysymbol')} matUiVariant="body1" /> 
                
                </ListItem>) : (<></>)}
            <ListItem  className={repeatedVars.className}>
                <ListItemText primary={showTax === true ? t('switch.widget.bookingsummary.gross') + ":" : t('switch.widget.bookingsummary.total')+":"}  align={repeatedVars.align} />
                <CurrencyFormat value={gross} prefix={t('switch.widget.currancysymbol')} matUiVariant="body1" />
            </ListItem>
            {damageDeposit < 1 ? (<></>) :(
                <ListItem  className={repeatedVars.className}>
                    <ListItemText primary={t('switch.widget.bookingsummary.damagedeposit')+":"} align={repeatedVars.align} />
                    <BookingItemHelperInfo item={{ name: "Damage Deposit Info", description: "<b>" + t('switch.widget.bookingsummary.damagedeposit_info')+"</b>"}} context={{id:null}} />
                    <CurrencyFormat value={damageDeposit} prefix={t('switch.widget.currancysymbol')} matUiVariant={repeatedVars.matUiVariant} />
                </ListItem>
            )}
            {depositRequired === gross || hideSecureBooking ? (<></>) : (
            <ListItem  className={repeatedVars.className}>
                    <ListItemText primary={t('switch.widget.bookingsummary.securebooking')+":"}  align={repeatedVars.align} />
                    <CurrencyFormat value={depositRequired} prefix={t('switch.widget.currancysymbol')} matUiVariant={repeatedVars.matUiVariant} />
            </ListItem>)}
        </List>

    );
};

export default BookingSummary;