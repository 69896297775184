import React from "react";
import { Box, TextInput, FormField } from "grommet";
export const GuestDetailsForm = (props) => {
    const { contact, onChange , enabled} = props;


    return (<Box align="baseline" justify="center" overflow="auto" width="medium" pad="medium">
        <Box align="stretch" justify="between"direction="column" gap="small" flex="grow" pad="medium">
        <FormField label="First Name">
            <TextInput id="tiFirstName" name="firstName" placeholder="First name..." value={contact ? contact.firstName : ""} onChange={onChange} disabled={!enabled}/>
        </FormField>
        <FormField label="Last Name">
            <TextInput id="tiLastName" name="lastName" placeholder="Last name..." value={contact ? contact.lastName : ""} onChange={onChange} disabled={!enabled}/>
        </FormField>
        <FormField label="E-mail">
            <TextInput id="tiEmail" name="email" placeholder="E-Mail..." value={contact ? contact.email : ""} onChange={onChange} disabled={!enabled}/>
        </FormField>
        <FormField label="Mobile">
            <TextInput id="tiMobile" name="mobile" placeholder="Mobile..." value={contact ? contact.mobile : ""} onChange={onChange} disabled={!enabled}/>
        </FormField>
        </Box>
    </Box>);
};
