import React, { Component } from "react";
import {
	Box,
	Grommet,
	grommet,
	ResponsiveContext,
	Text,
	TextArea,
	Button,
	InfiniteScroll,
	Markdown,
} from "grommet";
import { BookingHeader } from "./BookingHeader";
import ContentContainer from "./ContentContainer";
import RichTextField from "./RichTextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	imageSizing: {
		"& img": {
			maxWidth: "100%",
		},
	},
}));

const Chat = ({ ...rest }) => {
	const { booking, venue, chatHistory, onSend, enabled } = rest;
	const [newMessageText, setNewMessageText] = React.useState();
	const classes = useStyles();

	const sortedMessages = !chatHistory
		? null
		: chatHistory.sort(
				(a, b) => new Date(b.sentTimeStamp) - new Date(a.sentTimeStamp)
		  );
	const lastMessageFromVenue = !sortedMessages
		? null
		: sortedMessages.filter((message) => message.direction == "send")[0];

	const onChange = (event) => setNewMessageText(event.target.value);

	return (
		<Box gap="medium" fill background="light-1">
			<Box gap="medium" alignSelf="center" width="xxlarge" background="light-3">
				<Box gap="medium" alignSelf="center" width="xlarge" pad="medium">
					<BookingHeader booking={booking} venue={venue} />
					<Box
						alignSelf="center"
						gap="large"
						margin="small"
						width="xxlarge"
						direction="row-responsive"
					>
						<Box gap="large" width="xlarge" direction="column">
							<ContentContainer height="large" title="Last Message From Venue">
								<Box overflow="auto">
									<RichTextField
										className={classes.imageSizing}
										id="lastmessage"
										value={
											!lastMessageFromVenue ? "" : lastMessageFromVenue.message
										}
									/>
								</Box>
							</ContentContainer>
						</Box>
						<Box gap="large" width="xlarge" direction="column">
							<ContentContainer height="large" title="Compose Message">
								<TextArea
									id="newMessageText"
									fill
									placeholder="type here"
									value={newMessageText}
									onChange={onChange}
									disabled={!enabled}
								/>
								<Box
									justify="end"
									gap="large"
									margin="small"
									direction="row"
									height="xxsmall"
								>
									<Button
										primary
										id="sendbutton"
										label="Send"
										onClick={() => {
											onSend(newMessageText);
										}}
										disabled={!newMessageText || newMessageText.length === 0}
									/>
								</Box>
							</ContentContainer>
						</Box>
					</Box>
					<Box alignSelf="center" width="xlarge">
						<ContentContainer title="Message History">
							{sortedMessages ? (
								<InfiniteScroll items={sortedMessages}>
									{(item) => (
										<Box
											key={item.id}
											round="xlarge"
											flex={false}
											pad="large"
											width="large"
											alignSelf={item.direction === "send" ? "start" : "end"}
											background={
												item.direction === "send" ? "accent-1" : "accent-3"
											}
											margin="medium"
										>
											<RichTextField
												className={classes.imageSizing}
												value={item.message}
											/>
										</Box>
									)}
								</InfiniteScroll>
							) : (
								<Text> No Messages Found</Text>
							)}
						</ContentContainer>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
export { Chat };
