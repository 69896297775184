import React from "react";
import { Box, Text, Grid, Button } from "grommet";
import ContentContainer from "./ContentContainer";
import { GuestInvite } from "./GuestInvite";
import { UploadGuestList } from "./UploadGuestList";
import Api from '../Api';
import { MailOption, DocumentUpload, Clock} from 'grommet-icons';

const API = new Api(
    {
        urlRoot: window.location.origin + "/Dispace/Switch/1.0.0/BookingRequest/"
        //urlRoot: "https://switchdev.azurewebsites.net/Dispace/Switch/1.0.0/BookingRequest/"
    });



export function GuestInvitePanel(props) {
    const { booking, portalSettings, sendGuestInvite, enabled, allocateGuestArrivals } = props;
    const [inviteOpen, setInviteOpen] = React.useState(false);
    const [uploadOpen, setUploadOpen] = React.useState(false);

    function handleSend(data) {
        sendGuestInvite([data]);
        setInviteOpen(false)
    }

    function handleBulkUpload(data) {
        let invites = [];

        data.forEach((guest) => {
            let invitation = {
                contact: {
                    firstName: guest.firstname ? guest.firstname : "",
                    lastName: guest.surname ? guest.surname : "",
                    email: guest.email,
                    mobile: guest.mobile ? guest.mobile : ""
                },
                description: guest.description ? guest.description : "",
                arrivalTime: null,
                message: portalSettings ? portalSettings.guestInviteTemplate : ""
            }
            invites.push(invitation);
            //sendGuestInvite(invitation);
        }
        );
        sendGuestInvite(invites);
        setInviteOpen(false)
    }

    return (<ContentContainer height={{ "min": "400px" }} title="Manage Guests">


        <Grid areas={[
            { name: 'InvitesRemaining_label', start: [0, 0], end: [0, 0] },
            { name: 'InvitesRemaining', start: [1, 0], end: [1, 0] },
            { name: 'MenusCompleted_label', start: [0, 1], end: [0, 1] },
            { name: 'MenusCompleted', start: [1, 1], end: [1, 1] },
        ]} columns={['flex', 'flex']} rows={['xxsmall', 'xxsmall']} gap='small'>
            <Box gridArea='InvitesRemaining_label' justify="center" alignContent="center" weight="bold">
                <Text>Invites Remaining:</Text>
            </Box>
            <Box gridArea='InvitesRemaining' justify="center" alignContent="center" round="xsmall" border={{ color: 'brand', size: 'xsmall' }}>
                <Text alignSelf="center">{!booking || !booking.guestList ? 0 : booking.numberOfGuests - booking.guestList.length}</Text>
            </Box>
            <Box gridArea='MenusCompleted_label' justify="center" alignContent="center" weight="bold">
                <Text>Menus Selection Completed:</Text>
            </Box>
            <Box gridArea='MenusCompleted' justify="center" alignContent="center" round="xsmall" border={{ color: 'brand', size: 'xsmall' }}>
                <Text alignSelf="center">0</Text>
            </Box>
        </Grid>
        <Box pad="small" alignSelf="end" justify="end" gap="large" direction="row">
            <Button plain icon={<MailOption color="brand" />} id="btnInviteGuests" label="Invite Guests" onClick={() => { setInviteOpen(true); }} disabled={!enabled} />
            <Button plain icon={<DocumentUpload color="brand"/>} id="btnUploadGuestList" label="Upload Guest List" disabled={!enabled} onClick={() => { setUploadOpen(true); }} />
            <Button plain icon={<Clock color="brand"/>} id="btnAllocateArrivals" onClick={allocateGuestArrivals} label="Allocate Staggered Arrivals" disabled={!enabled} />
        </Box>

        <GuestInvite
            portalSettings={portalSettings}
            open={inviteOpen}
            onCancel={() => { setInviteOpen(false); }}
            onSend={(data) => { handleSend(data); }}
            bookingStart={booking.startTime}
        />

        <UploadGuestList
            portalSettings={portalSettings}
            open={uploadOpen}
            onCancel={() => { setUploadOpen(false); }}
            onSend={(data) => { handleBulkUpload(data); }}
        />
    </ContentContainer>);
}
