import React from 'react'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const NoSearchResults = ({title, classes}) => {
  const { t } = useTranslation();
  
  return(
    <div className="empty-search">
      <Typography variant="h5" className={classes && classes.title}>
              {title ? t(title) : t("switch.widget.notfound.notfound")}
      </Typography>
    </div>
  )
}

export default NoSearchResults;
