import React, { Fragment, useEffect, useState } from "react";
import
{
    TextField,
    Button,
    FormControl,
    Checkbox,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormGroup
} from "@material-ui/core";
import ProgressButton from "./ProgressButton";
import { Typography } from "@material-ui/core";
import Api from "../Api";
import { useTranslation } from 'react-i18next'
import { useWidgetContext } from "../widgetContext";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({    
    buttons: {
        display: "flex",
        justifyContent: "flex-end"
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1)
    },
    textDiv: {
        marginBottom: '1.5em'
    }
}));

const API = new Api({
    urlRoot: window.location.origin + "/Dispace/Switch/1.0.0/AnswerQuestion/"
});

const QuestionInputs = props =>
{
    const { q, handleAnswer, disable, loading, theme } = props;
    const [value, setValue] = useState(q.response);
    const { t, i18n } = useTranslation();

    switch (q.question.responseType)
    {
        case "fixedOptions":
        // return (
        //   <FormControl component="fieldset">
        //     <FormGroup required={q.question.required} row>
        //       {q.question.availableResponses.map(availableResponses => {
        //           return(
        //             <FormControlLabel id={availableResponses.id} value={availableResponses.value} control={<Checkbox />} label={availableResponses.value} labelPlacement="end" />
        //           )
        //         })}
        //     </FormGroup>
        //   </FormControl>
        // )
        // break;
        case "boolean":
            return (
                <FormControl required={q.question.compulsory}>
                    <RadioGroup
                        row
                        name={"radio-buttons-group-" + q.questionId}
                        id={q.questionId}
                        required={q.question.compulsory}
                        onChange={handleAnswer}
                        value={q.response}
                    >
                        {q.question.availableResponses.map(availableResponses =>
                        {
                            return (
                                <FormControlLabel
                                    id={availableResponses.id}
                                    value={availableResponses.value}
                                    control={<Radio />}
                                    label={availableResponses.value}
                                />
                            );
                        })}
                    </RadioGroup>
                </FormControl>
            );
            break;
        case "numeric":
            return (
                <TextField
                    required={q.question.compulsory}
                    id={q.questionId}
                    name={"numeric-input-" + q.questionId}
                    fullWidth
                    value={q.response}
                    onChange={handleAnswer}
                />
            );
            break;
        case "freeText":
            return (
                <Fragment>
                    <TextField
                        required={q.question.compulsory}
                        margin="dense"
                        id={q.questionId}
                        name={"free-text-" + q.questionId}
                        fullWidth
                        multiline
                        rows="8"
                        value={value}
                        onChange={e => setValue(e.target.value)}
                        placeholder="..."
                        variant="outlined"
                    />
                    <Button
                        variant={disable || loading ? "contained" : "outlined"}
                        disabled={q.response == value}
                        style={{
                            color: disable || loading ? `${theme.palette.info.main}` : `${theme.palette.success.main}`,
                            border: "2px solid",
                            borderColor: disable || loading ? `${theme.palette.info.main}` : `${theme.palette.success.main}`
                        }}
                        onClick={e =>
                            handleAnswer({
                                target: { name: "free-text-" + q.questionId, value: value }
                            })
                        }
                    >
                        {t("switch.widget.buttons.submit")}
                    </Button>
                </Fragment>
            );
            break;
        default:
            break;
    }
};

const SupplementaryQuestions = props =>
{
    const classes = useStyles();
    const theme = useTheme();

    const { questions, setQuestions, widgetKey, booking } = useWidgetContext();
    const { t, i18n } = useTranslation();
    const sortedQuestions = questions.filter(q => q.question.displayOnWidget == true).sort(
        (a, b) =>
            a.question.displayOrder < b.question.displayOrder
                ? -1
                : a.question.displayOrder > b.question.displayOrder
                    ? 1
                    : 0
    );
    const [loading, setLoading] = useState(false);
    const [disable, setDisable] = useState(false);
    const compulsoryQuestions = questions.filter(
        q => q.question.compulsory === true
            && q.answered === false
            && q.question.displayOnWidget == true
    );

    React.useEffect(
        () =>
        {
            if (compulsoryQuestions.length > 0)
            {
                setDisable(true);
            } else
            {
                setDisable(false);
            }
        },
        [compulsoryQuestions]
    );

    const handleAnswer = e =>
    {
        var questionsClone = questions;
        var foundIndex = questionsClone.findIndex(
            q => q.questionId === Number.parseInt(e.target.name.split("-").pop())
        );
        let updatedQuestion = questionsClone[foundIndex];
        updatedQuestion = {
            ...updatedQuestion,
            answered: true,
            response: e.target.value
        };
        questionsClone[foundIndex] = updatedQuestion;
        setLoading(true);
        API.answerQuestions(widgetKey, booking.id, [
            updatedQuestion
        ])
            .then(result =>
            {
                setQuestions(result.data);
            })
            .finally(() =>
            {
                setLoading(false);
            });
        setQuestions(questionsClone);
    };

    if (!loading)
    {
        const incompleteQuestion = sortedQuestions.filter(x => x.answered != true);
        if (incompleteQuestion.length == 0)
        {
            setQuestions([]);
        }
    }

    return (
        <Fragment>
            <div className={classes.textDiv}>
                <Typography variant="h5" gutterBottom>
                    {t("switch.widget.questions.thanks")}
                </Typography>
                <Typography variant="subtitle1">
                    {t("switch.widget.questions.pleasecomplete")}
                </Typography>
            </div>
            {sortedQuestions.map(q =>
            {
                let questionText = q.question.questionText;
                if (q.question.compulsory)
                {
                    questionText =
                        q.question.questionText +
                        "<span style='color: red; font-weight: 700'> *</span>";
                }
                return (
                    <Fragment>
                        <div dangerouslySetInnerHTML={{ __html: questionText }} />
                        <QuestionInputs q={q} handleAnswer={e => handleAnswer(e)} disable={disable} loading={loading} theme={theme} />
                    </Fragment>
                );
            })}
            {compulsoryQuestions &&
                compulsoryQuestions.length > 0 && (
                    <div>
                        <p style={{ color: "red", fontWeight: "700" }}>
                            {t("switch.widget.questions.compulsory")}
                        </p>
                    </div>
                )}
            <div className={classes.buttons}>
                {loading ? <ProgressButton /> :
                    <Button
                        variant={disable ? "contained" : "outlined"}
                        style={{
                            color: disable ? `${theme.palette.info.main}` : `${theme.palette.success.main}`,
                            border: "2px solid",
                            borderColor: disable ? `${theme.palette.info.main}` : `${theme.palette.success.main}`
                        }}
                        disabled={disable || loading}
                        onClick={e => setQuestions([])}
                        className={classes.button}
                    >
                        Skip
                    </Button>
                }
            </div>
        </Fragment>
    );
};

export default SupplementaryQuestions;
