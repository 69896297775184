import React from 'react';

import { Button, Box, DataTable, Text, Select, CheckBox } from 'grommet';
import TimeInput from './TimeInput';




export const GuestList = (props) => {
    const { guests, step, onEdit, onSave , canEdit} = props;


    if (!guests) return null;

    const columns = [
        {
            property: 'name',
            header: <Text>Name</Text>,
            primary: true,
            render: response => (<Text >{response.contact.firstName + ' ' + response.contact.lastName}</Text>)
        },
        {
            property: 'description',
            header: <Text>Description</Text>,
        },
        {
            property: 'status',
            header: <Text>Response</Text>,
            render: response => (
                <Box align="start" gridArea='Response'  >
                    <Select
                        name="size"
                        options={["notSelected", "invited", "interested", "going", "declined"]}
                        value={response.status || ""}
                        onChange={event => { onSave({ ...response, status: event.value }) }}
                        disabled={!canEdit}
                    />
                </Box>
            )
        },
        {
            property: 'startTime',
            header: <Text>Arrival</Text>,
            render: response => (<TimeInput disabled={!canEdit} value={response.arrivalTime} onChange={event => { onSave({ ...response, arrivalTime: event }) }} />)
        },
        {
            property: 'menu',
            header: <Text>Menu Selection</Text>,
            render: response => (<Box align="center" pad="large">
                <CheckBox disabled checked={!response.menuSelections ? false : response.menuSelections.length > 0} />
            </Box>)
        },
        {
            property: 'editButton',
            render: response => (<Box align="end" size="small" >
                <Button id={"btnEdit" + response.id} primary label={canEdit ? "Edit" : "View"} onClick={() => onEdit(response)} />
            </Box>)
        },
    ];


    return (
        <DataTable
            columns={columns}
            data={guests}
            step={step}
        />

    );
};