import React from "react";
import { useContext } from "react";
import { RoomContext } from "../context";
import Title from "./Title";
import { WidgetContext } from "../widgetContext";
import { useTranslation } from 'react-i18next';

// get all unique values
const getUnique = (items, value) => {
    return [...new Set(items.map(item => item[value]))];
};

const BookingFilter = ({ data }) => {
    // react hooks
    const context = useContext(WidgetContext);
    const { t, i18n } = useTranslation();
    const {
        occasionData,
        occasionBaseData,
        equipment,
        handleChange,
        rooms,
        type,
        price,
        minPrice,
        maxPrice,
        setGuestNumbers,
        minSize,
        hasparking,
        hasaccomodation,
        setOccasion
    } = context;

    let OccasionType = getUnique(occasionBaseData, "name");
    // add all
    let occasionAll = t('occasionAll');
    OccasionType = [occasionAll, ...OccasionType];
    // map to jsx
    OccasionType = OccasionType.map((item, index) => (
        <option key={index} value={item}>
            {item}
        </option>
    ));



    let extras = equipment;
    
    extras = extras.map((item, index) => (        
        <div className="single-extra">
            < input
                type="checkbox"
                name={"has" + item.name}
                id={item.id}
                checked={context["has" + item.name]}
                onChange={handleChange}
            />
            <label htmlFor="{item.name}">{item.name}</label>
        </div>
    ));


    // get unique types
    let types = getUnique(rooms, "name");
    // add all
    types = ["all", ...types];
    // map to jsx
    types = types.map((item, index) => (
        <option key={index} value={item}>
            {item}
        </option>
    ));
    // get unique capacity
    let people = getUnique(rooms, "maximumCapacity");
    people = people.map((item, index) => (
        <option key={index} value={item}>
            {item}
        </option>
    ));
    return (
        <section className="filter-container">
            <Title title="search" />
            <form className="filter-form">
                {/* select type */}
                <div className="form-group">
                    <label htmlFor="type">{t('switch.widget.bookingFilter.occasion')}</label>
                    <select
                        name="type"
                        id="type"
                        onChange={setOccasion}
                        className="form-control"
                        value={type}
                    >
                        {OccasionType}
                    </select>
                </div>
                {/* end of select type */}
                {/* size */}
                <div className="form-group">
                    <label htmlFor="capacity">{t('switch.widget.bookingFilter.guests')}</label>
                    <div className="size-inputs">
                        <input
                            type="number"
                            name="minSize"
                            value={minSize}
                            onChange={setGuestNumbers}
                            className="size-input"
                        />
                    </div>
                </div>
                {/* room price */}
                <div className="form-group">
                    <label htmlFor="price">{t('switch.widget.bookingFilter.budget')}{price}</label>
                    <input
                        type="range"
                        name="price"
                        min={minPrice}
                        max={maxPrice}
                        id="price"
                        value={price}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                {/* end of room price*/}

                {/* end of select type */}
                {/* extras */}
                <div className="form-group">
                        <div className="single-extra">
                        <input
                            type="checkbox"
                            name="hasparking"
                            id="hasparking"
                            checked={hasparking}
                            onChange={handleChange}
                        />
                        <label htmlFor="hasparking">{t('switch.widget.bookingFilter.parking')}</label>
                    </div>
                    <div className="single-extra">
                        <input
                            type="checkbox"
                            name="hasaccomodation"
                            id="hasaccomodation"
                            checked={hasaccomodation}
                            onChange={handleChange}
                        />
                        <label htmlFor="hasaccomodation">{t('switch.widget.bookingFilter.accomodation')}</label>
                    </div>
                    {extras}
                </div>
                {/* end of extras type */}
            </form>
        </section>
    );
};

export default BookingFilter;
