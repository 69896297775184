import React from "react";
import PropTypes from "prop-types";
import { Box, Heading, Text } from "grommet";
import { FeatureEmoji } from "./emojis/FeatureEmoji";

export const ContentContainer = ({ children, title, content, ...rest }) => (
    <Box
        round
        border={{ color: "grey" }}
        pad="medium"
        gap="small"
        direction="column"
        background="white"
        {...rest}
    >
        <Heading level={2} margin="none" size="small">
            <Box direction="row" align="center" gap="small">
                {!rest.emoji ? null : <FeatureEmoji name={rest.emoji} size="60px" />}
                {title}
            </Box>
        </Heading>
        {content && (<Text color="gray" size="small">
              {content}
            </Text>)
        }
        {children}

    </Box>
);

ContentContainer.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    children: PropTypes.node.isRequired
};

export default ContentContainer;
