import React from "react";
import { Anchor, Box } from "grommet";
import { FacebookOption, Instagram, Twitter } from "grommet-icons";

const SocialMedia = (props) => {
    const { portalSettings } = props;
    return (
        <Box direction="row" gap="xxsmall" justify="center">
            <Anchor
                target="_blank"
                a11yTitle="Share feedback on Github"
                href={!portalSettings || !portalSettings.social || !portalSettings.social.instagram ? "https://www.instagram.com/" : portalSettings.social.instagram}
                icon={<Instagram color="brand" size="medium" />}
            />
            <Anchor
                target="_blank"
                a11yTitle="Chat with us on Slack"
                href={!portalSettings || !portalSettings.social || !portalSettings.social.facebook ? "https://www.facebook.com/" : portalSettings.social.facebook}
                icon={<FacebookOption color="brand" size="medium" />}
            />
            <Anchor
                target="_blank"
                a11yTitle="Follow us on Twitter"
                href={!portalSettings || !portalSettings.social || !portalSettings.social.twitter ? "https://twitter.com/" : portalSettings.social.twitter}
                icon={<Twitter color="brand" size="medium" />}
            />
        </Box>
    );
}
export { SocialMedia };
