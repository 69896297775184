import React from 'react'
import { useTheme } from "@material-ui/core/styles";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from "@material-ui/core";
import { useTranslation } from 'react-i18next'

const ResourcesChangedDialog = (props) => {

    const theme = useTheme();
    const { handleCancel, handleAccept, open, removed, title } = props;
    const { venues, rooms, food, extras } = removed;
    const { t, i18n } = useTranslation();

    return (
        <Dialog id="resourceChangeDialog" onClose={handleCancel} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleCancel}>
                {title}
            </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    <h4>{t("switch.widget.resourcechange.removeitems")}</h4>
                    {(venues && venues.length > 0) &&
                        <div style={{ marginBottom: '1.5em' }}>
                            <h6 style={{ marginBottom: '0px' }}>{t("switch.widget.resourcechange.venue")}</h6>
                            {venues.map(v => {
                                return <p>{v.name}</p>
                            })}
                        </div>
                    }
                    {(rooms && rooms.length > 0) &&
                        <div style={{ marginBottom: '1.5em' }}>
                            <h6 style={{ marginBottom: '0px' }}>{t("switch.widget.resourcechange.room")}</h6>
                            {rooms.map(r => {
                                return <p>{r.name}</p>
                            })}
                        </div>
                    }
                    {(food && food.length > 0) &&
                        <div style={{ marginBottom: '1.5em' }}>
                            <h6 style={{ marginBottom: '0px' }}>{t("switch.widget.resourcechange.food")}</h6>
                            {food.map(f => {
                                return <p>{f.name}</p>
                            })}
                        </div>
                    }
                    {(extras && extras.length > 0) &&
                        <div style={{ marginBottom: '1.5em' }}>
                            <h6 style={{ marginBottom: '0px' }}>{t("switch.widget.resourcechange.extras")}</h6>
                            {extras.map(e => {
                                return <p>{e.name}</p>
                            })}
                        </div>
                    }
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button id="btn-cancel" onClick={handleCancel} variant="outlined" color="primary">{t("switch.widget.buttons.cancel")}</Button>
                <Button id="btn-add" onClick={handleAccept} variant="outlined" style={{ color: `${theme.palette.success.main}`, border: '2px solid', borderColor: `${theme.palette.success.main}` }}>{t("switch.widget.buttons.ok")}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ResourcesChangedDialog;