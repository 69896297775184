import React from "react";
import { Box, Tabs, Tab, TextArea, FormField, Text } from "grommet";
import ResourceCard from './ResourceCard';
import LayoutCard from './LayoutCard';
import Slider from "react-slick";
import TimeInput from './TimeInput';
import { Transaction, CircleInformation, FormSubtract, FormAdd, Tty, AddCircle, Clock } from 'grommet-icons';
import { QuantityEditor } from "./QuantityEditor";
import { RichTabTitle } from "./RichTabTitle";
import { Dialog } from "./Dialog";
import { ResourceTypeEmojiName } from "./emojis/ResourceTypeEmojiName";

export function ResourceEditDialog(props) {
    const { open, item, resourceList, onChange, onCancel, onSave, onDelete } = props;
    const [thisItem, setItem] = React.useState();
    const [index, setIndex] = React.useState();
    const [layouts, setLayouts] = React.useState();
    const [layoutIdCheck, setLayoutIdCheck] = React.useState(false);

    const onActive = nextIndex => setIndex(nextIndex);

    React.useEffect(() => {
        if (thisItem) {
            if (thisItem.resourceType === "Room" || thisItem.resourceType === "room") {
                const newLayouts = resourceList.filter(r => r.id === thisItem.resourceId)[0].layouts;
                if (newLayouts) {
                    const layoutId = newLayouts.filter(l => l.id === thisItem.roomLayoutId)[0];
                    if (!layoutId) {
                        setLayoutIdCheck(true);
                    } else {
                        setLayoutIdCheck(false);
                    }
                    setLayouts(newLayouts)
                }
            }           
        }
      }, [thisItem]);

    if (!open && thisItem)
        setItem(null);
    if (open && !thisItem && item) {
        setItem(item);
    }

    if (!open || !thisItem)
        return null;

    const resource = !resourceList ? null : resourceList.filter(r => r.id === item.resourceId)[0];

    if (!resource) return null;

    if (!Number.isInteger(index)) {
        setIndex(resource.maxBookingQty > 1 && resource.maxBookingQty != resource.minBookingQty ? 0 : 1);
    }

    let resourceCards = resourceList.map((resourceItem, index) => (<Box key={index} pad="small" margin="small">
        <ResourceCard id={"resourceCard" + resourceItem.id} resource={resourceItem} height="270px" currentSelection={resourceItem.id === thisItem.resourceId} action="Update" onClickAddItem={() => { handleChange({ ...thisItem, resourceId: resourceItem.id }); }} />
    </Box>));
    let layoutCards = !layouts ? null :
        layouts.map((layout, index) => (<Box key={index} pad="small" margin="small">
            <LayoutCard id={"layoutCard" + layout.id} layout={layout} height="270px" currentSelection={layout.id === thisItem.roomLayoutId} onClickAddItem={() => { handleChange({ ...thisItem, roomLayoutId: layout.id }); }} />
        </Box>));
    const sliderSettings = {
        dots: true,
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: resourceList.length < 3 ? resourceList.length : 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        cssEase: "linear"
    };

    function handleChange(change) {

        if (onChange) {
            onChange(change);
        }
        // if (change.resourceType === "Room" && change.roomLayoutId !== null) {
        //     setItem({...change, roomLayoutId: null})
        // } else {
            setItem(change);
        // }
    }

    return <Dialog id="editResource" dialogStyle="TabbedEditBox" emoji={ResourceTypeEmojiName(resource.resourceType)} title={resource.name} onCancel={onCancel} onSave={() => onSave(thisItem)} onDelete={onDelete} >
        <Box direction="column" justify="between" align="start">
            <Tabs activeIndex={index} onActive={onActive}>
                {resource.maxBookingQty > 1 && resource.maxBookingQty != resource.minBookingQty && <Tab
                    id="tabQuantity"
                    title={
                        <RichTabTitle

                            icon={<AddCircle color="accent-1" />}
                            label="Change Quantity"
                        />
                    }
                >
                    <Box direction="column" height="medium" align="center" justify="center" gap="small">

                        <QuantityEditor onChange={(update) => { handleChange(update); }} item={thisItem} min={resource.minBookingQty} max={resource.maxBookingQty} />
                    </Box>
                </Tab>}
                <Tab
                    id="tabReplaceItem"
                    title={
                        <RichTabTitle

                            icon={<Transaction color="accent-2" />}
                            label="Replace Item"
                        />
                    }
                >
                    <Box align="center" justify="center" width="xlarge" gap="small">
                        {layoutIdCheck ? 
                                <Text margin="none" pad="none" textAlign="center" size="small" style={{color: 'red'}}>
                                    Please set a layout for the new room selected.
                                </Text>
                        : '' }
                        <Box width="xlarge" pad={layoutIdCheck ? {vertical: "none", horizontal: "small"} : "small"} margin={layoutIdCheck ? {vertical: "none", horizontal: "small"} : "small"}>
                            <Slider {...sliderSettings}>
                                {resourceCards}
                            </Slider>
                        </Box>
                    </Box>
                </Tab>
                {resource.layouts && <Tab
                    id="tabLayout"
                    title={
                        <RichTabTitle
                            icon={<Transaction color="accent-3" />}
                            label="Change Layout"
                        />
                    }
                >
                    <Box align="center" justify="center" width="xlarge" gap="small">

                        <Box width="xlarge" pad="small" margin="small">
                            <Slider {...sliderSettings}>
                                {layoutCards}
                            </Slider>
                        </Box>
                    </Box>
                </Tab>}
                {resource.resourceType === "FoodAndBeverage" &&
                    <Tab
                        id="tabServingTime"
                        title={
                            <RichTabTitle
                                icon={<Clock color="accent-3" />}
                                label="Set Serving Time"
                            />
                        }
                    >
                        <Box direction="column" height="medium" align="center" justify="center" gap="small">

                            <Box width="medium">
                                <TimeInput
                                    //{...options}
                                    id="tiServingTime"
                                label="Serving Time" value={thisItem.servingTime} onChange={(event) => { handleChange({ ...thisItem, servingTime: event}); }} />
                            </Box>
                        </Box>

                    </Tab>}
                <Tab
                    id="tabNotes"
                    title={
                        <RichTabTitle
                            icon={<CircleInformation color="accent-4" />}
                            label="Notes"
                        />
                    }
                >

                    <Box direction="column" height="medium" align="center" justify="center" width="xlarge" overflow="auto" gap="small">

                        <FormField label="Notes">
                            <TextArea id="txtNotes" name="notes" height="medium" placeholder="Please confirm any special requirements for this item..." value={thisItem.notes} onChange={(event) => handleChange({ ...thisItem, notes: event.target.value })} />
                        </FormField>
                    </Box>
                </Tab>
            </Tabs>
        </Box>
    </Dialog >

}
