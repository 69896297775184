import React, { useState} from "react";
import Typography from "@material-ui/core/Typography";
import {
    MuiPickersUtilsProvider,
    TimePicker,
    DatePicker
} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";

import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { useTheme, makeStyles } from "@material-ui/core/styles";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import { ShowLayoutSelector } from "./WidgetRoomBooking";
import EditIcon from "@material-ui/icons/Edit";
import InputAdornment from "@material-ui/core/InputAdornment";
import ConvertHexToRGBA from "./ConvertHexToRGBA";
import TimeInput from './../guestPortalComponents/TimeInput';
import startOfToday from 'date-fns/startOfToday'
import addHours from 'date-fns/addHours'
import addMinutes from 'date-fns/addMinutes'

export const TimeSelect = props => {

    const { id, label, labelStyle, value, defaultValue, onChange, style, earliest, latest, interval, TimeList, bookingDate} = props;
    const labelId = id + '-label';
    const [internal, setInternal] = useState(value.toLocaleTimeString('en-GB') );

    let validTimes = TimeList != null && TimeList.length > 0 ? TimeList : []

    if (TimeList == null || TimeList.length == 0) {
        let start = bookingDate;
        start = addHours(start, earliest.split(":")[0]);
        start = addMinutes(start, earliest.split(":")[1]);

        let end = bookingDate;
        end = addHours(end, latest.split(":")[0]);
        end = addMinutes(end, latest.split(":")[1]);


        let offset = bookingDate.getTimezoneOffset();
        if (offset !== 0) {
            start = addMinutes(start, offset);
            end = addMinutes(end, offset);
        }

        let next = start

        while (next <= end) {
            validTimes.push({label: next.toLocaleTimeString('en-GB') , value: next});
            next = addMinutes(next, interval);
        }
        
    }

    const handleChange = (event) => {
        setInternal(event.target.value);
        const selectedItem = validTimes.find(item => item.label === event.target.value);
        onChange(selectedItem.value);
    }
    //let selectedValue = bookingDate;
    //if (value != null) {
    //    selectedValue = addHours(selectedValue, value.split(":")[0]);
    //    selectedValue = addMinutes(selectedValue, value.split(":")[1]);
    //}
    

    return (<React.Fragment>
        <InputLabel
            id={labelId}
            style={labelStyle}
        >
            {label}
        </InputLabel>
        <Select
            labelId={labelId}
            id={id}
            value={internal}
            onChange={handleChange}
            style={style}
        >
            {validTimes.map((item, index) => {
                return (
                    <MenuItem key={index} value={item.label}>
                        {item.label.split(":")[0]}:{item.label.split(":")[1]}
                    </MenuItem>
                );
            })}
        </Select>
    </React.Fragment>)
}