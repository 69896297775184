import React from "react";
import {
    Box,
    Button,
    Image,
    Heading,
    Text,
    Anchor
} from "grommet";

import { CurrencyFormat } from "./CurrencyFormat";
import { ResourceInfo } from "./ResourceInfo";
import { FoodMenu } from "./FoodMenu";

const ResourceCard = (props) => {
    const { resource, currentSelection, onClickAddItem, action, ...rest } = props;
    const [ResourceView, SetResourceViewOpen] = React.useState(false);
    const [MenuView, SetMenuViewOpen] = React.useState(false);
    return (
        <Box round="xxsmall" elevation="small" overflow="hidden" width="medium" border={!currentSelection ? null : {
            "all":

            {
                "side": "all"
            },
            color: "brand",
            size: "large",
            style: "solid",
        }} {...rest}>
            {!resource ? < Text > No Resource Data </ Text> : (
                <Box>
                    <Box height="xsmall">
                        {resource.image && <Image src={resource.image.imageUrl} fit="cover" />}
                    </Box>
                    <CardHeader  {...props} SetResourceViewOpen={SetResourceViewOpen} SetMenuViewOpen={SetMenuViewOpen} />
                    <CardFooter {...props} />
                </Box>
            )}
            <FoodMenu open={MenuView} onClose={() => { SetMenuViewOpen(false); }} menu={resource ? resource.menu : null} />
            <ResourceInfo open={ResourceView} onClose={() => { SetResourceViewOpen(false) }} resourceName={resource ? resource.name : ""} resource={resource} />
        </Box>
    );

}

export default ResourceCard;

function CardHeader(props) {

    const { resource, SetResourceViewOpen, SetMenuViewOpen } = props;
    return (
        <Box pad={{ horizontal: "small" }}>
            <Box
                margin={{ top: "small" }}
                direction="row"
                align="center"
                justify="between"
            >
                <Box>
                    <Heading level="4" margin="none">
                        {resource.name}
                    </Heading>
                    <Text color="dark-5" size="small">
                        Prices from {CurrencyFormat(resource.price)} {resource.resourceType === "Room" ? "per " + resource.price_type : ""} {resource.price_inc_tax ? "(inc VAT)" : "(ex VAT)"}
                    </Text>
                    {resource.resourceType === "Room" && resource.maximumCapacity && 
                        <Text color="dark-5" size="small">
                            Maximum Capacity: {resource.maximumCapacity}
                        </Text>
                    }
                </Box>
            </Box>
            <Box direction="row-responsive" justify="between" style={{marginTop: '10px'}}>
                <Anchor id="linkDetails" alignSelf="start" label="Details" onClick={() => { SetResourceViewOpen(true) }} size="small"/>
                {resource.menu && <Anchor id="linkMenu" alignSelf="end" label="Menu" onClick={() => { SetMenuViewOpen(true) }} size="small"/>}
            </Box>
        </Box>
    );
};

function CardFooter(props) {
    const { currentSelection, onClickAddItem,  action } = props;
    return (
        <Box direction="column" alignContent="end" justify="end" align="end" pad={{ top: "medium" }}>
            <Box
                tag="footer"
                direction="row"
                alignContent="end"
                justify="end"
                pad={{ left: "small", vertical: "small" }}
            >
                {!currentSelection && <Button alignSelf="end"
                    id="btnAdd"
                    margin={{ right: "small" }}
                    label={action}
                    onClick={onClickAddItem}
                />}
            </Box>
        </Box>
    );
};
