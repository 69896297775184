import React from "react";
import { Anchor, Box, Text, ResponsiveContext } from "grommet";
import { Logo } from "./Logo";
import { SocialMedia } from "./SocialMedia";
import { FeatureEmoji } from "./emojis/FeatureEmoji";
import { CustLogo } from "./CustLogo";

const NavHeader = (props) => {
    const { portalSettings, view, onManageBooking, onMakePayment, onManageGuests, onMessageVenue, onHome, onRefresh, onLogout, contact, guest } = props;
    return (
        <ResponsiveContext.Consumer>
            {size => (
                <Box
                    direction="row"
                    justify={view === 0 ? "start" : "between"}
                    alignSelf="center"
                    gap="medium"
                    pad={{ top: "large", horizontal: "xlarge" }}
                >
                    <CustLogo portalSettings={portalSettings} onHome={onHome} />
                    {view != 0 ?

                        <React.Fragment>

                            <Box
                                alignContent="between"
                                direction="row"
                                justify="center"
                                alignSelf="center"
                                gap="medium"
                                width={!guest ? "medium" : "large"}
                                height="xsmall"
                                background='brand'
                                round

                            >
                                {contact && <Text alignSelf="center" size="large"> {contact.firstName} {contact.lastName} </Text>}
                                {!contact && <Text alignSelf="center" size="large"> Guest booking portal</Text>}
                            </Box>
                            {!guest ?
                                <Box direction="row" gap="small" justify="center">
                                    <Anchor
                                        onClick={onManageBooking}
                                        color="black"
                                        label="Booking"
                                        className="mb1"
                                        icon={<FeatureEmoji name=":spiral_calendar_pad:" size="30px" />}
                                    />


                                    <Anchor
                                        onClick={onMakePayment}
                                        color="black"
                                        label="Pay"
                                        icon={<FeatureEmoji name=":credit_card:" size="30px" />}
                                    />

                                    <Anchor
                                        onClick={onManageGuests}
                                        color="black"
                                        label="Guests"
                                        icon={<FeatureEmoji name=":email:" size="30px" />}
                                    />

                                    <Anchor
                                        onClick={onMessageVenue}
                                        color="black"
                                        label="Chat"
                                        icon={<FeatureEmoji name=":speech_balloon:" size="30px" />}
                                    />

                                    <Anchor
                                        onClick={onRefresh}
                                        color="black"
                                        label="Refresh"
                                        icon={<FeatureEmoji name=":arrows_counterclockwise:" size="30px" />}

                                    />

                                    <Anchor
                                        onClick={onLogout}
                                        color="black"
                                        label="Logout"
                                        icon={<FeatureEmoji name=":door:" size="30px" />}
                                    />
                                </Box>
                                : <React.Fragment> <Box direction="row" gap="small" justify="end"> <Anchor
                                    onClick={onLogout}
                                    color="black"
                                    label="Logout"
                                    icon={<FeatureEmoji name=":door:" size="30px" />}
                                />
                                </Box></React.Fragment>
                            }</React.Fragment> :
                        <Box />}
                </Box>
            )}
        </ResponsiveContext.Consumer>
    );
}

export { NavHeader };