import { usePaymentPage } from '../contexts/PaymentPageContext';
import Stack from '@mui/material/Stack';
import { Anchor } from "grommet";
function IconTickCircle(props) {
    return (
        <svg fill="none" fill-rule="nonzero" id="Page-1" stroke="none" stroke-width="1" {...props}>

            <path d="M34.5,55.1 C32.9,53.5 30.3,53.5 28.7,55.1 C27.1,56.7 27.1,59.3 28.7,60.9 L47.6,79.8 C48.4,80.6 49.4,81 50.5,81 C50.6,81 50.6,81 50.7,81 C51.8,80.9 52.9,80.4 53.7,79.5 L101,22.8 C102.4,21.1 102.2,18.5 100.5,17 C98.8,15.6 96.2,15.8 94.7,17.5 L50.2,70.8 L34.5,55.1 Z" fill="#17AB13" id="Shape" />

            <path d="M89.1,9.3 C66.1,-5.1 36.6,-1.7 17.4,17.5 C-5.2,40.1 -5.2,77 17.4,99.6 C28.7,110.9 43.6,116.6 58.4,116.6 C73.2,116.6 88.1,110.9 99.4,99.6 C118.7,80.3 122,50.7 107.5,27.7 C106.3,25.8 103.8,25.2 101.9,26.4 C100,27.6 99.4,30.1 100.6,32 C113.1,51.8 110.2,77.2 93.6,93.8 C74.2,113.2 42.5,113.2 23.1,93.8 C3.7,74.4 3.7,42.7 23.1,23.3 C39.7,6.8 65,3.9 84.8,16.2 C86.7,17.4 89.2,16.8 90.4,14.9 C91.6,13 91,10.5 89.1,9.3 Z" fill="#4A4A4A" id="Shape" />
        </svg>
    );
}



export const PaymentReceived = () => {
    const { payment, bookingRef } = usePaymentPage();

    return (
        <div style={{ "max-width": "380px", margin: "30px auto", overflow: "hidden" }} >

            <div style={{ position: "relative", overflow: "hidden" }} >
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <div style={{ "margin-left": "160px" }} >
                        <IconTickCircle />
                    </div>
                    <div style={{
                        "font-size": "22px",
                        "text-align": "center",
                        "color": "#666",
                        "font- weight": "bold",
                        "margin-bottom": "16px"
                    }}>
                        Payment Complete
                    </div>
                    <div style={{
                        "font-size": "15px",
                        "line-height": "21px",
                        color: "#999",
                        "text-align": "center",
                        "margin-bottom": "24px"
                    }}>
                        Your payment for £ {parseFloat(payment.amount).toFixed(2)} has been recieved.
                    </div>
                    <div style={{
                        "text-align": "center", "color": "#333", "font-weight": "bold"
                    }}>
                        <div style={{ "font-size": "18px", "margin-bottom": "8px" }}>Booking Reference</div>
                        <div style={{
                            "border-top": "1px solid #ccc",
                            "border-bottom": "1px solid #ccc",
                            "line-height": "48px",
                            "font-size": "18px,",
                            "padding": "8px 0",
                            "margin-bottom": "24px"
                        }}>{bookingRef}</div>
                    </div>
                    {payment?.receipt != null ? <div style={{
                        "text-align": "center",
                        "line-height": "18px",
                        "font-size": "18px",
                        "margin-bottom": "8px",
                        "font-weight": "bold",
                        "color": "#999"
                    }}> <Anchor label="view receipt" href={payment.receipt} /></div> : <></>}
                    <div style={{
                        "text-align": "center",
                        "line-height": "18px",
                        "font-size": "18px",
                        "margin-bottom": "8px",
                        "font-weight": "bold",
                        "color": "#999"
                    }}>You can close this page!</div>
                </Stack>
            </div >
        </div >
    )
}

