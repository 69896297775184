import React, { Component, useContext } from "react";
import addDays from 'date-fns/addDays'
import addHours from "date-fns/addHours";
import addMinutes from "date-fns/addMinutes";
import startOfToday from "date-fns/startOfToday";
import startOfDay from "date-fns/startOfDay";
import start_of_tomorrow from "date-fns/startOfTomorrow";
import addYears from "date-fns/addYears";
import setYear from "date-fns/addYears";
import format from "date-fns/format";
import isWithinInterval from "date-fns/isWithinInterval";
import Api from "./Api";
import ReactGA from "react-ga";
import parseISO from 'date-fns/parseISO'
import { WidgetResourcesContextProvider } from "./contexts/useWidgetResources";

const WidgetContext = React.createContext();

export const useWidgetContext = () => useContext(WidgetContext);

function CheckPath(pathname) {
    const routes = ["occasion", "venue", "room", "extras", "payresult"];
    if (routes.some(route => pathname.includes(route))) {
        return pathname.split("/")[1];
    }
    return pathname.substring(1);
}

const API = new Api({
    widgetKey: CheckPath(window.location.pathname), //"aad47dd6-8bb3-4d66-8226-fcf79f33a8f6",
    urlRoot: window.location.origin + "/Dispace/Switch/1.0.0/BookingRequest/"
    //urlRoot: "https://switchdev.azurewebsites.net/Dispace/Switch/1.0.0/BookingRequest/"
});

let items = [];
let error = false;
const today = startOfToday();
const tomorrow = start_of_tomorrow();
const maxYear = setYear(today, 5);
let defaultStartTime = addHours(tomorrow, 9);
let defaultEndTime = addHours(tomorrow, 17);

const SET = "SET";
const APPLYFILTERS = "APPLYFILTERS";
const APPLYOCCASIONRULES = "APPLYOCCASIONRULES";
const SET_NEWRATE = "SET_NEWRATE";
const SET_NEW_AVAILABILITY = "SET_NEW_AVAILABILITY";
const SET_BOOKING_INVALID = "SET_BOOKING_INVALID";
const ADD_BOOKING_DETAIL = "ADD_BOOKING_DETAIL";
const REMOVE_BOOKING_DETAIL = "REMOVE_BOOKING_DETAIL";
const SET_GUEST_NUMBERS = "SET_GUEST_NUMBERS";
const SET_LAST_REQUEST = "SET_LAST_REQUEST";
const SET_BOOKING_DATE = "SET_BOOKING_DATE";
const SET_START_TIME = "SET_START_TIME";
const SET_THIS_PERIOD = "SET_THIS_PERIOD";
const SET_END_TIME = "SET_END_TIME";
const SET_CONTACT_DETAILS = "SET_CONTACT_DETAILS";
const ADD_BOOKING_DETAIL_NOTES = "ADD_BOOKING_DETAIL_NOTES";
const ADD_BOOKING_NOTES = "ADD_BOOKING_NOTES";
const ADD_BOOKING_DESCRIPTION = "ADD_BOOKING_DESCRIPTION";
const COPY_BOOKING_TO_ENQUIRY = "COPY_BOOKING_TO_ENQUIRY";
const SET_ENQUIRY_REQUEST = "SET_ENQUIRY_REQUEST";
const SET_VENUE = "SET_VENUE";
const SET_DISCOUNT_CODE = "SET_DISCOUNT_CODE";
const SET_EARLY_EMAIL_CHECK = "SET_EARLY_EMAIL_CHECK";

function set(obj, path, value) {
    var schema = obj; // a moving reference to internal objects within obj
    var pList = path.split(".");
    var len = pList.length;
    for (var i = 0; i < len - 1; i++) {
        var elem = pList[i];
        if (!schema[elem]) schema[elem] = {};
        schema = schema[elem];
    }

    schema[pList[len - 1]] = value;

    return obj;
}

const reducer = action => (state, props) => {
    let { bookingRequest } = state;

    switch (action.type) {
        case SET:
            return {
                [action.name]: action.value
            };
        case APPLYFILTERS:
            return applyFilters(state);
        case APPLYOCCASIONRULES:
            logEvent(
                "Occasion",
                "Selected Occasion " + state.type,
                state.widgetData.name
            );
            return applyOccasionRules(state, action.widget);
        case SET_NEWRATE:
            return {
                lastbookingRequest: action.request,
                booking: {},
                calculatedRate: action.rate
            };
        case SET_NEW_AVAILABILITY:
            return {
                lastbookingRequest: action.request,
                booking: {},
                availability: action.availability
            };
        case SET_GUEST_NUMBERS:
            bookingRequest.numberOfGuests = Number.parseInt(action.value);
            logEvent(
                "Booking",
                "Set Guest Numbers : " + action.value,
                state.widgetData.name
            );
            updateRate(
                bookingRequest,
                state.lastbookingRequest,
                state.selectedStart,
                state.selectedEnd,
                state.widgetKey,
                action.widget.setPrices,
                action.widget.setavailability,
                action.widget.setbookingInvlaid,
                action.widget.setlastRequest
            );
            return {
                selectedGuests: action.value,
                minSize: action.value,
                bookingRequest: bookingRequest
            };
        case SET_DISCOUNT_CODE:
            bookingRequest.discountcode = action.value;
            logEvent(
                "Discount",
                "Apply Discount : " + action.value,
                state.widgetData.name
            );
            updateRate(
                bookingRequest,
                state.lastbookingRequest,
                state.selectedStart,
                state.selectedEnd,
                state.widgetKey,
                action.widget.setPrices,
                action.widget.setavailability,
                action.widget.setbookingInvlaid,
                action.widget.setlastRequest
            );
            return {
                bookingRequest: bookingRequest
            };
        case SET_EARLY_EMAIL_CHECK:
            updateRate(
                bookingRequest,
                state.lastbookingRequest,
                state.selectedStart,
                state.selectedEnd,
                state.widgetKey,
                action.widget.setPrices,
                action.widget.setavailability,
                action.widget.setbookingInvlaid,
                action.widget.setlastRequest
            );
            return {
                bookingRequest: bookingRequest
            };
        case SET_BOOKING_INVALID:
            return {
                booking: {},
                availability: {},
                calculatedRate: {},
                lastbookingRequest: action.newRequest
            };
        case ADD_BOOKING_DETAIL:
            let hireType = GetResourceHireType(
                bookingRequest.occasionId,
                action.newId,
                state
            );
            let item = GetResourceData(action.newId, state);

            logEvent(
                "Booking",
                "Add Item: " + item.resourceType + item.name,
                state.widgetData.name
            );
            //logEvent("ecommerce", "addItem", state.widgetData.name, {
            //    'name': item.name,
            //    'id': item.id,
            //    'price': item.price,
            //    'category': item.resourceType,
            //});

            let newRequest = addBookingDetail(
                action.newId,
                action.newVenue,
                action.request,
                hireType,
                action.fieldVal,
                action.removeBookingItem,
                action.menuDetails,
                action.courseId,
                action.menuItemId,
                action.roomLayoutId,
                item.resourceType
            );
            applyFilters(state);
            updateRate(
                newRequest,
                state.lastbookingRequest,
                state.selectedStart,
                state.selectedEnd,
                state.widgetKey,
                action.widget.setPrices,
                action.widget.setavailability,
                action.widget.setbookingInvlaid,
                action.widget.setlastRequest
            );
            return {
                bookingRequest: newRequest
            };
        case REMOVE_BOOKING_DETAIL:
            let removeItem = GetResourceData(action.newId, state);
            logEvent(
                "Booking",
                "Remove Item: " + removeItem.resourceType + removeItem.name,
                state.widgetData.name
            );

            let removeRequest = removeBookingDetail(
                action.newId,
                action.request,
                action.removeBookingItem
            );
            applyFilters(state);
            updateRate(
                removeRequest,
                state.lastbookingRequest,
                state.selectedStart,
                state.selectedEnd,
                state.widgetKey,
                action.widget.setPrices,
                action.widget.setavailability,
                action.widget.setbookingInvlaid,
                action.widget.setlastRequest
            );
            return {
                bookingRequest: removeRequest
            };
        case ADD_BOOKING_DETAIL_NOTES:
            bookingRequest = addBookingDetailNotes(
                action.id,
                action.notes,
                bookingRequest
            );
            return {
                bookingRequest: bookingRequest
            };
        case ADD_BOOKING_NOTES:
            bookingRequest.notes = action.notes;
            return {
                bookingRequest: bookingRequest
            };
        case ADD_BOOKING_DESCRIPTION:
            bookingRequest.publicEventDescription = action.description;
            return {
                bookingRequest: bookingRequest
            };
        case SET_LAST_REQUEST:
            return {
                lastbookingRequest: action.request
            };
        case SET_BOOKING_DATE:
            bookingRequest.bookingDate = action.value;
            bookingRequest.bookingEndDate = action.value;

            logEvent(
                "Booking",
                "Change Date: " + bookingRequest.bookingDate,
                state.widgetData.name
            );
            let newbdStart = new Date(bookingRequest.bookingDate);
            newbdStart.setHours(
                state.selectedStart.getHours(),
                state.selectedStart.getMinutes()
            );

            let newbdEnd = new Date(bookingRequest.bookingDate);
            newbdEnd.setHours(
                state.selectedEnd.getHours(),
                state.selectedEnd.getMinutes()
            );
            if (newbdEnd.getHours() < 4) {
                newbdEnd = addDays(newbdEnd, 1);
            }
            updateRate(
                bookingRequest,
                state.lastbookingRequest,
                newbdStart,
                newbdEnd,
                state.widgetKey,
                action.widget.setPrices,
                action.widget.setavailability,
                action.widget.setbookingInvlaid,
                action.widget.setlastRequest
            );
            return {
                bookingRequest: bookingRequest,
                selectedStart: newbdStart,
                selectedEnd: newbdEnd
            };
        case SET_THIS_PERIOD:
            return {
                period: action.value
            };
        case SET_START_TIME:
            logEvent(
                "Booking",
                "Set Start Time: " + action.value,
                state.widgetData.name
            );
            bookingRequest.startTime = format(action.value, "HH:mm:ss");
            let newStart = new Date(bookingRequest.bookingDate);
            newStart.setHours(action.value.getHours(), action.value.getMinutes());
            updateRate(
                bookingRequest,
                state.lastbookingRequest,
                newStart,
                state.selectedEnd,
                state.widgetKey,
                action.widget.setPrices,
                action.widget.setavailability,
                action.widget.setbookingInvlaid,
                action.widget.setlastRequest
            );
            return {
                bookingRequest: bookingRequest,
                selectedStart: newStart
            };
        case SET_END_TIME:
            logEvent(
                "Booking",
                "Set End Time: " + action.value,
                state.widgetData.name
            );
            bookingRequest.endTime = format(action.value, "HH:mm:ss");
            let newEnd = new Date(bookingRequest.bookingDate);
            newEnd.setHours(action.value.getHours(), action.value.getMinutes());
            if (action.value.getHours() < 4) {
                newEnd = addDays(newEnd, 1);
            }
            updateRate(
                bookingRequest,
                state.lastbookingRequest,
                state.selectedStart,
                newEnd,
                state.widgetKey,
                action.widget.setPrices,
                action.widget.setavailability,
                action.widget.setbookingInvlaid,
                action.widget.setlastRequest
            );
            return {
                bookingRequest: bookingRequest,
                selectedEnd: newEnd
            };
        case SET_VENUE:
            logEvent(
                "Booking",
                "Select Venue: " + action.value,
                state.widgetData.name
            );
            state.bookingRequest.venueId = action.value;
            // REMOVED BEN FIX
            // state.bookingRequest.Details=[]
            applyFilters(state);
            return {
                bookingRequest: bookingRequest
            };
        case SET_CONTACT_DETAILS:
            // logEvent(
            //   "Contact",
            //   "Update Info: " + action.name + " " + action.value,
            //   state.widgetData.name
            // );

            let path = "bookingRequest.EnquiryContact." + action.name;
            state = set(state, path, action.value);

            let contact = state.bookingRequest.EnquiryContact;

            if (
                contact.firstName.length === 0 ||
                contact.lastName.length === 0 ||
                contact.mobile.length === 0 ||
                contact.email.length === 0
                // contact.address1.length === 0 ||
                // contact.address2.length === 0 ||
                // contact.city.length === 0 ||
                // contact.postCode.length === 0
            ) {
                state.contactComplete = false;
            } else {
                state.contactComplete = true;
            }

            return {
                bookingRequest: state.bookingRequest,
                contactComplete: state.contactComplete
            };
        case COPY_BOOKING_TO_ENQUIRY:
            let { enquiryRequest, calculatedRate } = state;
            let er = copyBookingToEnquriy(
                bookingRequest,
                enquiryRequest,
                calculatedRate
            );
            return {
                enquiryRequest: er
            };
        case SET_ENQUIRY_REQUEST:
            let ereq = action.request;
            return {
                enquiryRequest: ereq
            };
        default:
            return null;
    }
};

const copyBookingToEnquriy = (
    bookingRequest,
    enquiryRequest,
    calculatedRate
) => {
    enquiryRequest.source = bookingRequest.source;
    enquiryRequest.EnquiryContact = bookingRequest.EnquiryContact;
    enquiryRequest.occasionId = bookingRequest.occasionId;
    enquiryRequest.venueId = bookingRequest.venueId;
    enquiryRequest.bookingDate = bookingRequest.bookingDate.toISOString();
    enquiryRequest.bookingEndDate = bookingRequest.bookingEndDate.toISOString();
    enquiryRequest.startTime = bookingRequest.startTime;
    enquiryRequest.endTime = bookingRequest.endTime;
    enquiryRequest.numberOfDays = 1;
    enquiryRequest.numberOfGuests = bookingRequest.numberOfGuests;
    enquiryRequest.notes = bookingRequest.notes;
    enquiryRequest.budget = calculatedRate.gross;
    enquiryRequest.Details = bookingRequest.Details;
    enquiryRequest.discountcode = bookingRequest.discountcode;
    enquiryRequest.publicEventDescription = bookingRequest.publicEventDescription;

    return enquiryRequest;
};

const testContainsRestriction = (target, bookingRequest) => {
    let result = false;

    if (target.restrictions != null && target.restrictions.length > 0) {
        target.restrictions.forEach(restriction => {
            const bookingItem = bookingRequest.Details[bookingRequest.Details.findIndex(d => d.ResourceId == restriction.childResourceId)]
            if (restriction.restriction == "notContains" && restriction.groupOperator == "and" && bookingItem != null) {
                result = true;
            }
        })
    }

    return result;
}

const isResourceRestricted = (resourceID, state) => {
    const { occasionBaseData, bookingRequest } = state;
    let result = false;
    if (bookingRequest.Details != null && bookingRequest.Details.length > 0) {
        let idSet = GetOccasionResources(state);
        let resources = GetAllResourceData(state);
        let target = resources[resources.findIndex(r => r.id == resourceID)]


        let rrId = bookingRequest.Details.map(resource => resource.ResourceId)
        if (!rrId.includes(resourceID)) {
            rrId.push(resourceID)
        }
        const requestedResources = resources.filter(r => rrId.includes(r.id));

        let restrictionList = []
        requestedResources.forEach(resource => {
            const itemRestictions = CheckResourceRestriction(resource, requestedResources);
            restrictionList = restrictionList.concat(itemRestictions);
        })

        if (restrictionList != null && restrictionList.length > 0) {
            const targetRestrictions = restrictionList.filter(r => r.restriction.childResourceId == target.id && r.restriction.restriction == "notContains");
            if (targetRestrictions != null && targetRestrictions.length > 0) {
                result = true;
            }
        }

    }

    return result;
}

const CheckResourceRestriction = (resource, requestedResources) => {
    let result = [];
    if (resource.restrictions != null && resource.restrictions.length > 0) {
        let restrictionResult = resource.restrictions[0].GroupOperator == 'and' ? true : false;
        resource.restrictions.forEach(restriction => {
            const item = requestedResources[requestedResources.findIndex(r => r.id == restriction.childResourceId)];
            const resourceIsPresent = item != null;
            restrictionResult = TestResourceRestrictionRule(restriction, restrictionResult, resourceIsPresent);
        })

        if (!restrictionResult) {
            resource.restrictions.forEach(restriction => {
                const item = requestedResources[requestedResources.findIndex(r => r.id == restriction.childResourceId)];
                const resourceIsPresent = item != null;
                var groupResult = resource.restrictions[0].GroupOperator == 'and' ? true : false;
                var ruleResult = TestResourceRestrictionRule(restriction, groupResult, resourceIsPresent);
                if (!ruleResult) {
                    result.push({ restriction: restriction, resource: resource });
                }
            })
        }
    }
    return result;
}
const TestResourceRestrictionRule = (restriction, GroupResult, ResourceIsPresent) => {
    let result = true;
    let test = restriction.restriction == "contains" ? ResourceIsPresent : !ResourceIsPresent;


    switch (restriction.groupOperator) {
        case "and":
            result = GroupResult && test;
            break;
        case "or":
            result = GroupResult || test;
            break;
        case "xOr":
            result = GroupResult ^ test;
            break;
    }

    return result;
}

const applyFilters = state => {
    // const { bookingRequest, occasionData, type } = state;
    state.sortedVenues = filterVenues(state);
    state.sortedRooms = filterRooms(state);
    state.sortedFood = filterfood(state);
    state.sortedExtras = filterEquipment(state);
    state.skiprooms = !OccasionHasRooms(state);

    return state;
};


const getVenueSortOrder = state => {
    let { rooms, bookingRequest, occasionBaseData } = state;

    let tempRooms = [...rooms];

    let sortOrder = [];

    let tempOccasion = occasionBaseData.filter(
        o => o.id === bookingRequest.occasionId
    )[0];
    if (tempOccasion) {

        let roomid = GetOccasionResources(state);
        tempRooms = tempRooms.filter(room => roomid.includes(room.id));
        tempRooms = tempRooms.map(value => { return { ...value, displayOrder: roomid.findIndex(order => order === value.id) } })
        tempRooms = tempRooms.sort(function (a, b) {
            return a.displayOrder - b.displayOrder;
        });
    }

    tempRooms.forEach(room => {
        if (!sortOrder.find(({ venueId }) => venueId === room.venueId)) {
            sortOrder.push({
                venueId: room.venueId,
                displayOrder: room.displayOrder
            })
        }
    })

    return sortOrder;

}

const filterVenues = state => {
    let { venue, rooms, bookingRequest, occasionBaseData, minSize } = state;

    let tempVenues = [...venue];
    let tempOccasion = occasionBaseData.filter(
        o => o.id === bookingRequest.occasionId
    )[0];
    if (tempOccasion) {


        let tempRooms = rooms.filter(rm =>
            tempOccasion.details.some(d =>
                d.resources.some(r => r.resourceid === rm.id)
            )
        );

        if (minSize !== 1) {
            tempRooms = tempRooms.filter(room => room.maximumCapacity >= minSize);
        }

        tempVenues = tempVenues.filter(v =>
            tempRooms.some(r => r.venueId === v.id)
        );

        let sort = getVenueSortOrder(state);
        tempVenues = tempVenues.sort(function (a, b) {
            let left = sort.find(({ venueId }) => venueId === a.id)
            let right = sort.find(({ venueId }) => venueId === b.id)
            return left.displayOrder - right.displayOrder;
        });

    }

    return tempVenues;
};

const OccasionHasRooms = state => {
    let { rooms, type } = state;
    let result = true;
    let tempRooms = [...rooms];

    if (type !== "all") {
        let roomid = GetOccasionResources(state);
        tempRooms = tempRooms.filter(room => roomid.includes(room.id));
        result = tempRooms.length > 0;
    }

    return result;
};

const filterRooms = state => {
    let { rooms, type, capacity, price, minSize, bookingRequest } = state;

    let tempRooms = [...rooms];
    // transform values
    // get capacity
    capacity = parseInt(capacity);
    price = parseInt(price);
    // filter by type
    if (type !== "all") {
        let roomid = GetOccasionResources(state);
        tempRooms = tempRooms.filter(room => roomid.includes(room.id));
        tempRooms = tempRooms.map(value => { return { ...value, displayOrder: roomid.findIndex(order => order === value.id) } })
    }

    if (bookingRequest.venueId !== null && bookingRequest.venueId !== 0) {
        tempRooms = tempRooms.filter(
            room => room.venueId === bookingRequest.venueId
        );
    }

    // filter by capacity
    if (minSize !== 1) {
        tempRooms = tempRooms.filter(room => room.maximumCapacity >= minSize);
    }
    // filter by price
    if (price > 0) {
        tempRooms = tempRooms.filter(room => room.price <= price);
    }

    tempRooms = tempRooms.filter(room => !isResourceRestricted(room.id, state));

    tempRooms = tempRooms.sort(function (a, b) {
        return a.displayOrder - b.displayOrder;
    });

    return tempRooms;
};

const checkCompulsoryResource = (resourceId, occasionId, occasionBaseData) => {
    let tempOccasion = [...occasionBaseData];
    let result = false;
    tempOccasion = tempOccasion.filter(occasion => occasion.id === occasionId);
    if (tempOccasion.length > 0) {
        tempOccasion = tempOccasion[0];

        tempOccasion.details.forEach(function (options, index) {
            let resInOptions = options.resources.filter(
                res => res.resourceid === resourceId
            );
            if (resInOptions.length > 0) {
                if (
                    options.minimumSelection === options.resources.length &&
                    options.minimumSelection === options.resources.length
                ) {
                    result = true;
                }
            }
        });
    }

    return result;
};

const isEvent = (id, state) => {
    const { eventData } = state;
    let event = eventData.filter(event => event.id === id);
    if (event !== null && event.length > 0) {
        return true;
    } else {
        return false;
    }
};
const filterfood = state => {
    let {
        foodAndBeverage,
        type,
        capacity,
        price,
        minSize,
        bookingRequest
    } = state;

    let tempfood = [...foodAndBeverage];
    // transform values
    // get capacity
    capacity = parseInt(capacity);
    price = parseInt(price);
    // filter by type
    if (type !== "all") {
        let roomid = GetOccasionResources(state);
        tempfood = tempfood.filter(room => roomid.includes(room.id));
        tempfood = tempfood.map(value => { return { ...value, displayOrder: roomid.findIndex(order => order === value.id) } })
    }

    if (bookingRequest.venueId !== null && bookingRequest.venueId !== 0) {
        tempfood = tempfood.filter(room => room.venueId === bookingRequest.venueId);
    }
    // filter by capacity
    if (minSize !== 1) {
        tempfood = tempfood.filter(room => room.maximumCapacity >= minSize);
    }
    // filter by price
    if (price > 0) {
        tempfood = tempfood.filter(room => room.price <= price);
    }

    tempfood = tempfood.filter(room => !isResourceRestricted(room.id, state));

    tempfood = tempfood.sort(function (a, b) {
        return a.displayOrder - b.displayOrder;
    });

    return tempfood;
};
const filterEquipment = state => {
    let {
        equipment,
        parking,
        accommodation,
        tables,
        type,
        bookingRequest
    } = state;

    let tempExtras = [...equipment];

    tempExtras = tempExtras.concat([...parking]);
    tempExtras = tempExtras.concat([...tables]);
    tempExtras = tempExtras.concat([...accommodation]);

    // filter by type
    if (type !== "all") {
        let equipmentId = GetOccasionResources(state);
        tempExtras = tempExtras.filter(extra => equipmentId.includes(extra.id));
        tempExtras = tempExtras.map(value => { return { ...value, displayOrder: equipmentId.findIndex(order => order === value.id) } })
    }

    if (bookingRequest.venueId !== null && bookingRequest.venueId !== 0) {
        tempExtras = tempExtras.filter(
            room => room.venueId === bookingRequest.venueId
        );
    }

    tempExtras = tempExtras.filter(room => !isResourceRestricted(room.id, state));

    tempExtras = tempExtras.sort(function (a, b) {
        return a.displayOrder - b.displayOrder;
    });
    return tempExtras;
};
const GetOccasionResources = state => {
    let { occasionData, occasionBaseData, type } = state;

    let tempOccasion = [...occasionBaseData];

    if (type !== "all") {
        tempOccasion = tempOccasion.filter(occasion => occasion.name == type);
        let occDets = [].concat.apply(
            [],
            tempOccasion.map(resource => resource.details)
        );
        let occResources = [].concat.apply(
            [],
            occDets.map(resource => resource.resources.map(x => { return { ...x, displayOrder: (resource.displayOrder * 100 + x.displayOrder) } }))
        );
        let resourceId = [].concat.apply(
            [],
            occResources.sort(function (a, b) {
                return a.displayOrder - b.displayOrder;
            }).map(resource => resource.resourceid)
        );
        return resourceId;
    }
};
const applyOccasionRules = (state, widget) => {
    let {
        bookingRequest,
        occasionBaseData,
        occasionData,
        eventData,
        type,
        minDate,
        maxDate,
        hideBookingEndTime,
        selectedStart,
        selectedEnd,
        showPrices
    } = state;
    let tempOccasion = [...occasionBaseData];

    if (type !== "all") {
        tempOccasion = tempOccasion.filter(occasion => occasion.name == type);
        tempOccasion = tempOccasion[0];
        if (!isEvent(tempOccasion.id, state)) {
            let tempOccasion = [...occasionData];
            tempOccasion = tempOccasion.filter(occasion => occasion.name == type);
            tempOccasion = tempOccasion[0];
            tempOccasion.startDate = tempOccasion.startDate || today;
            tempOccasion.endDate = tempOccasion.endDate || maxYear;

            minDate = new Date(tempOccasion.startDate);
            maxDate = new Date(tempOccasion.endDate);
            showPrices = tempOccasion.allowLivePricing;
            if (minDate < today) {
                minDate = today;
            }

            bookingRequest.occasionId = tempOccasion.id;
            bookingRequest.bookingType = tempOccasion.bookingType;
            // REMOVED BEN FIX
            // bookingRequest.venueId = null;
            // bookingRequest.Details = [];
            if (minDate > maxDate ||
                !isWithinInterval(bookingRequest.bookingDate, {
                    start: minDate,
                    end: maxDate
                })
            ) {
                //new Date(bookingRequest.bookingDate) <= minDate || new Date(bookingRequest.bookingDate) >= maxDate) {
                //setBookingDate(minDate);
                bookingRequest.bookingDate = minDate;
                bookingRequest.bookingEndDate = minDate;
            }

            if (tempOccasion.minDurationMinutes === tempOccasion.maxDurationMinutes) {
                // This is a fixed length booking.
                hideBookingEndTime = true;
                tempOccasion.periods.forEach(function (period, index) {
                    let Start = defaultStartTime;
                    if (period.period && period.period.fixedStart) {
                        let fixedStartArray = period.period.fixedStart.split(":");
                        Start = addHours(
                            bookingRequest.bookingDate,
                            Number.parseInt(fixedStartArray[0])
                        );
                        Start = addMinutes(Start, Number.parseInt(fixedStartArray[1]));
                    }
                    //  x.setStartTime(Start);
                    selectedStart = Start;
                    bookingRequest.startTime = format(selectedStart, "HH:mm:ss");

                    let End = defaultEndTime;

                    if (period.period && period.period.fixedEnd) {
                        let fixedEndArray = period.period.fixedEnd.split(":");
                        End = addHours(
                            bookingRequest.bookingDate,
                            Number.parseInt(fixedEndArray[0])
                        );
                        End = addMinutes(End, Number.parseInt(fixedEndArray[1]));
                    } else {
                        End = addMinutes(Start, period.period.lengthInMinutes);
                    }
                    //x.setEndTime(End);
                    selectedEnd = End;
                    bookingRequest.endTime = format(selectedEnd, "HH:mm:ss");
                });
            } else {
                hideBookingEndTime = false;
            }

            tempOccasion.details.forEach(function (options, index) {
                if (
                    options.minimumSelection === options.resources.length &&
                    options.minimumSelection === options.resources.length
                ) {
                    // All of the resources in this group are compulsory so just add them.
                    options.resources.forEach(function (resource, i) {
                        let Res = GetResourceData(resource.resourceid, state);
                        let resourceType = Res.resourceType;
                        let roomLayoutId = undefined;
                        if (Res.layouts && Res.layouts.length === 1) {
                            roomLayoutId = Res.layouts[0].id;
                        }
                        bookingRequest = addBookingDetail(
                            Res.id,
                            Res.venueId,
                            bookingRequest,
                            options.hireType,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            roomLayoutId,
                            resourceType
                        );
                    });
                }
            });
        } else {
            let tempEvent = [...eventData];
            tempEvent = tempEvent.filter(event => event.id === tempOccasion.id);
            tempEvent = tempEvent[0];
            minDate = new Date(tempEvent.startDate);
            maxDate = new Date(tempEvent.endDate);
            bookingRequest.occasionId = tempEvent.id;
            bookingRequest.bookingType = tempEvent.bookingType;
            bookingRequest.venueId = tempEvent.venueId;
            minDate = startOfDay(minDate);
            let offset = minDate.getTimezoneOffset();
            if (offset !== 0) {
                minDate = addMinutes(minDate, offset * -1);
            }
            var localISOTime = minDate.toISOString().slice(0, -1);

            bookingRequest.bookingDate = localISOTime;
            bookingRequest.bookingEndDate = localISOTime;
            hideBookingEndTime = true;

            let startTimeArray = tempEvent.startTime.split(":");
            selectedStart = addHours(
                new Date(bookingRequest.bookingDate),
                Number.parseInt(startTimeArray[0])
            );
            selectedStart = addMinutes(
                selectedStart,
                Number.parseInt(startTimeArray[1])
            );
            bookingRequest.startTime = format(selectedStart, "HH:mm:ss");

            let endTimeArray = tempEvent.endTime.split(":");
            selectedEnd = addHours(
                new Date(bookingRequest.bookingDate),
                Number.parseInt(endTimeArray[0])
            );
            selectedEnd = addMinutes(selectedEnd, Number.parseInt(endTimeArray[1]));
            bookingRequest.endTime = format(selectedEnd, "HH:mm:ss");

            tempEvent.details.forEach(function (options, index) {
                if (
                    options.minimumSelection === options.resources.length &&
                    options.minimumSelection === options.resources.length
                ) {
                    // All of the resources in this group are compulsory so just add them.
                    options.resources.forEach(function (resource, i) {
                        let Res = GetResourceData(resource.resourceid, state);
                        let roomLayoutId = undefined;
                        if (Res.layouts && Res.layouts.length === 1) {
                            roomLayoutId = Res.layouts[0].id;
                        }
                        bookingRequest = addBookingDetail(
                            Res.id,
                            Res.venueId,
                            bookingRequest,
                            options.hireType,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            roomLayoutId,
                            Res.resourceType
                        );
                    });
                }
            });
        }
    }

    state.minDate = minDate;
    state.maxDate = maxDate;
    state.selectedStart = selectedStart;
    state.selectedEnd = selectedEnd;
    state.bookingRequest = bookingRequest;
    state.hideBookingEndTime = hideBookingEndTime;
    state.showPrices = showPrices;


    updateRate(
        bookingRequest,
        state.lastbookingRequest,
        selectedStart,
        selectedEnd,
        state.widgetKey,
        widget.setPrices,
        widget.setavailability,
        widget.setbookingInvlaid,
        widget.setlastRequest
    );
    //this.setState({
    //    minDate: minDate,
    //    maxDate: maxDate,
    //    bookingRequest: bookingRequest,
    //    hideBookingEndTime: hideBookingEndTime
    //});

    return {
        ...state, bookingRequest: bookingRequest
    };
};
const bookingRequestHasChanged = (bookingRequest, lastbookingRequest) => {
    let result = false;
    if (bookingRequest !== lastbookingRequest) {
        return true;
    }
    result =
        result === true
            ? true
            : bookingRequest.bookingDate.toString() !==
            lastbookingRequest.bookingDate.toString();
    result =
        result === true
            ? true
            : bookingRequest.numberOfGuests !== lastbookingRequest.numberOfGuests;
    result =
        result === true
            ? true
            : bookingRequest.startTime !== lastbookingRequest.startTime;
    result =
        result === true
            ? true
            : bookingRequest.endTime !== lastbookingRequest.endTime;
    result =
        result === true
            ? true
            : bookingRequest.bookingType !== lastbookingRequest.bookingType;
    result =
        result === true
            ? true
            : bookingRequest.Details.length !== lastbookingRequest.Details.length;
    return result;
};
const bookingRequestIsValid = (bookingRequest, selectedStart, selectedEnd) => {
    let result = true;
    if (bookingRequest?.Details?.length === 0) {
        result = false;
    }
    if (bookingRequest.bookingDate < today) {
        result = false;
    }
    if (selectedStart > selectedEnd) {
        result = false;
    }

    return result;
};

const GetAllResourceData = (state) => {
    let {
        rooms, foodAndBeverage, equipment, parking, accommodation, tables
    } = state;

    let tempResources = [...rooms];

    tempResources = tempResources.concat([...parking]);
    tempResources = tempResources.concat([...tables]);
    tempResources = tempResources.concat([...accommodation]);
    tempResources = tempResources.concat([...equipment]);
    tempResources = tempResources.concat([...foodAndBeverage]);
    return tempResources;
}

const GetResourceData = (id, state) => {
    let tempResources = GetAllResourceData(state);

    let resource = tempResources.filter(res => res.id === id);

    return resource[0];
};

const GetResourceHireType = (OccasionId, ResourceId, state) => {
    let { occasionBaseData } = state;

    let occasion = occasionBaseData.filter(o => o.id == OccasionId);
    if (occasion.length !== 0) {
        occasion = occasion[0];
        let f = occasion.details.entries();
        for (let x of f) {
            let detail = x[1].resources.filter(o => o.resourceid == ResourceId);
            if (detail.length !== 0) {
                return x[1].hireType;
            }
        }
    }

    //    let resource = tempResources.filter(res => res.id === id);

    return "FULLHIRE";
};

const addBookingDetail = (
    id,
    venueId,
    bookingRequest,
    hireType,
    fieldVal,
    removeBookingItem,
    menuDetails,
    courseId,
    menuItemId,
    roomLayoutId,
    resourceType
) => {
    let existingEntry = bookingRequest?.Details?.filter(x => x.ResourceId === id);

    // TakeAway bookingRequest.Details builder
    if (menuDetails) {
        if (menuItemId) {
            if (existingEntry.length === 0) {
                let NewDetail = {
                    ResourceId: id,
                    Quantity: 1,
                    HireType: hireType,
                    notes: "",
                    linkTimesToHeader: true,
                    menuSelections: [
                        {
                            menuId: menuDetails.menuId,
                            courseId: courseId,
                            Quantity: 1,
                            menuItemId: menuItemId,
                            modifiers: null,
                            specialRequests: ""
                        }
                    ]
                };
                bookingRequest.Details.push(NewDetail);
                return bookingRequest;
            } else {
                var foundIndex = bookingRequest.Details.findIndex(
                    x => x.ResourceId == id
                );
                let newMenuSelections = {
                    menuId: menuDetails.menuId,
                    courseId: courseId,
                    Quantity: 1,
                    menuItemId: menuItemId,
                    modifiers: null,
                    specialRequests: ""
                };
                bookingRequest.Details[foundIndex].menuSelections.push(
                    newMenuSelections
                );
                return bookingRequest;
            }
        }
    }

    // Original unique resourceId bookingRequest.Details builder
    if (!menuDetails) {
        if (!existingEntry || existingEntry?.length === 0) {
            let NewDetail = {
                ResourceId: id,
                Quantity: 1,
                roomLayoutId: roomLayoutId,
                HireType: hireType,
                notes: "",
                resourceType: resourceType,
                linkTimesToHeader: true,
            };
            if (venueId) {
                bookingRequest.venueId = venueId;
            }
            bookingRequest.Details.push(NewDetail);
        } else if (existingEntry?.length > 0 && roomLayoutId) {
            var foundIndex = bookingRequest.Details.findIndex(
                x => x.ResourceId == id
            );
            bookingRequest.Details[foundIndex].roomLayoutId = roomLayoutId;
        } else if (existingEntry?.length > 0 && resourceType.toLowerCase() == 'room') {
            return bookingRequest;
        } else {
            var foundIndex = bookingRequest?.Details.findIndex(
                x => x.ResourceId == id
            );
            if (fieldVal === undefined) {
                bookingRequest.Details[foundIndex].Quantity += 1;
            } else {
                if (fieldVal <= 0) {
                    removeBookingItem(id);
                } else if (fieldVal === "NaN") {
                    removeBookingItem(id);
                } else if (fieldVal === NaN) {
                    removeBookingItem(id);
                } else if (fieldVal === null) {
                    removeBookingItem(id);
                } else {
                    bookingRequest.Details[foundIndex].Quantity = fieldVal;
                }
            }
        }
        return bookingRequest;
    }
};
const removeBookingDetail = (id, bookingRequest, removeBookingItem) => {
    let existingEntry = bookingRequest.Details.filter(x => x.ResourceId === id);
    if (existingEntry.length !== 0 && existingEntry[0].Quantity <= 1) {
        removeBookingItem(id);
    } else {
        var foundIndex = bookingRequest.Details.findIndex(x => x.ResourceId == id);
        if (existingEntry.length !== 0) bookingRequest.Details[foundIndex].Quantity -= 1;
    }
    return bookingRequest;
};
const addBookingDetailNotes = (id, notes, bookingRequest) => {
    let details = bookingRequest.Details.filter(X => X.ResourceId === id);
    if (details.length > 0) {
        let detailEntry = details[0];
        detailEntry.notes = notes;
        var foundIndex = bookingRequest.Details.findIndex(x => x.ResourceId == id);
        items[foundIndex] = detailEntry;
    }
    return bookingRequest;
};

const updateRate = (
    bookingRequest,
    lastbookingRequest,
    selectedStart,
    selectedEnd,
    widgetKey,
    setPrices,
    setavailability,
    setbookingInvlaid,
    setlastRequest
) => {
    let newRequest = JSON.parse(JSON.stringify(bookingRequest));
    if (
        bookingRequestIsValid(bookingRequest, selectedStart, selectedEnd) === true
    ) {
        if (bookingRequestHasChanged(bookingRequest, lastbookingRequest)) {
            API.calculatePrices(bookingRequest).then(setPrices);
            API.checkAvailability(bookingRequest).then(setavailability);
            setlastRequest(newRequest);
        }
    } else {
        setbookingInvlaid(newRequest);
    }
};

const logEvent = (category, action, label, ecommerce, value) => {
    if (ecommerce != null) {
        ReactGA.plugin.execute("ecommerce", action, ecommerce);
        ReactGA.plugin.execute("ecommerce", "send");
    } else {
        ReactGA.event({
            category: category,
            action: action,
            label: label,
            value: value
        });
    }
};

const isAWidget = () => {
    const path = window.location.pathname.toLowerCase();
    if (path.includes("booking") || path.includes("payment") || path.includes("guest") || path.includes("screen") || path.includes("payresult"))   {
        return false;
    }
    else {
        return true;
    }
}

export default class WidgetProvider extends Component {
    state = {
        id: 0,
        name: "widget",
        description: "The Description",
        widgetKey: "no key",
        style: 0,
        venue: [],
        occasions: [],
        events: [],
        rooms: [],
        occasionData: [],
        eventData: [],
        occasionBaseData: [],
        foodAndBeverage: [],
        equipment: [],
        accommodation: [],
        tables: [],
        parking: [],
        bookings: [],
        sortedExtras: [],
        showAvailabilityCalendar: false,
        collectMarketingConsent: false,
        collect3rdPartyMarketingConsent: false,
        hideBookingEndTime: false,
        allowEnquiry: true,
        loading: true,
        contactComplete: false,
        period: {},
        saveCard: false,
        //
        ready: false,
        type: "all",
        capacity: 1,
        price: 0,
        minPrice: 0,
        maxPrice: 0,
        minSize: 1,
        maxSize: 1,
        selectedGuests: 1,
        breakfast: false,
        pets: false,
        sortedRooms: [],
        sortedVenues: [],
        sortedFood: [],
        featuredRooms: [],
        availability: {},
        booking: {},
        questions: [],
        enquiry: {},
        checkInOutData: {},
        bookingRequest: {},
        enquiryRequest: {},
        calculatedRate: {},
        availability: {},
        widgetData: {},
        widgetTheme: {},
        termsAndConditionsDoc: {},
        privacyPolicyDoc: {},
        returnURL: {},
        earlyEmailCheck: {},
        isDrawOpen: false,
        stripe: {},
        hubSpotChatId: "",
        showPrices: true,
        translations: null,
        performCovidChecks: false,
        isResourceRestricted: {}
    };

    componentDidMount() {
        if (isAWidget()) {
            ReactGA.plugin.require("ecommerce");
            API.getData().then(result => {
                if (result !== null && result !== undefined) {
                    items = result;
                }
            }).catch(function (e) {
                error = e;
            }).finally(o => {
                this.resetWidgetData();
            });
        }
    }

    resetWidgetData = () => {
        if (!error) {
            let rooms = items.rooms;
            let id = items.id;
            let name = items.name;
            let description = items.description;
            let widgetKey = items.widgetKey;
            let style = items.style;
            let venue = items.venue;
            let occasions = items.occasions;
            let occasionData = items.occasionData;
            let events = items.events;
            let eventData = items.eventData;
            let occasionBaseData = items.occasionBaseData;
            let foodAndBeverage = items.foodAndBeverage;
            let equipment = items.equipment;
            let accommodation = items.accommodation;
            let tables = items.tables;
            let parking = items.parking;
            let bookings = items.bookings;
            let availabilityData = items.availabilityData;
            let showAvailabilityCalenda = items.showAvailabilityCalendar;
            let collectMarketingConsent = items.collectMarketingConsent;
            let collect3rdPartyMarketingConsent = items.collect3rdPartyMarketingConsent;
            let isDrawOpen = false;
            let featuredRooms = rooms; //rooms.filter(room => room.featured === true);
            //
            let maxPrice = 1000000; // Math.max(...rooms.map(item => item.price))+1;
            let maxSize = !rooms
                ? 0
                : Math.max(...rooms.map(item => item.maximumCapacity));
            let widgetTheme = items.widgetTheme;
            let termsAndConditionsDoc = items.termsAndConditionsDoc;
            let privacyPolicyDoc = items.privacyPolicyDoc;
            let returnURL = items.returnURL;
            let earlyEmailCheck = items.earlyEmailCheck;
            let performCovidChecks = items.performCovidChecks;
            let stripe = items.stripe;
            let hubSpotChatId = items.hubSpotChatId;
            let translations = items.translations;
            this.setState({
                id: id,
                name: name,
                description: description,
                widgetKey: widgetKey,
                style: style,
                venue: venue,
                occasions: occasions,
                rooms: rooms,
                occasionData: occasionData,
                events: events,
                eventData: eventData,
                occasionBaseData: occasionBaseData,
                foodAndBeverage: foodAndBeverage,
                sortedFood: foodAndBeverage,
                equipment: equipment,
                accommodation: accommodation,
                tables: tables,
                parking: parking,
                bookings: bookings,
                sortedExtras: equipment,
                availabilityData: availabilityData,
                showAvailabilityCalendar: showAvailabilityCalenda,
                collectMarketingConsent: collectMarketingConsent,
                collect3rdPartyMarketingConsent: collect3rdPartyMarketingConsent,
                loading: false,
                hasaccomodation: false,
                hasparking: false,
                hideBookingEndTime: false,
                allowEnquiry: true,
                calculating: false,
                skippedEarlyEmailCheck: false,
                contactComplete: false,
                period: {},
                saveCard: false,
                currentPaymentId: "",
                type: "all",
                ready: false,
                capacity: 1,
                price: 0,
                minPrice: 0,
                maxPrice: 0,
                minSize: 1,
                maxSize: 1,
                selectedGuests: 1,
                featuredRooms,
                sortedRooms: rooms,
                sortedVenues: venue,
                price: maxPrice,
                maxPrice,
                maxSize: maxSize,
                minDate: today,
                maxDate: maxYear,
                selectedStart: defaultStartTime,
                selectedEnd: defaultEndTime,
                booking: {},
                questions: [],
                checkInOutData: {},
                bookingRequest: {
                    source: widgetKey,
                    EnquiryContact: {
                        firstName: "",
                        lastName: "",
                        email: "",
                        mobile: "",
                        address1: "",
                        address2: "",
                        city: "",
                        postCode: "",
                        EnquiryCompany: {
                            name: ""
                        },
                        allowMarketing: false,
                        allow3rdPartyMarketing: false
                    },
                    occasionId: 0,
                    venueId: 0,
                    bookingDate: tomorrow,
                    bookingType: 0,
                    startTime: null,
                    endTime: null,
                    bookingEndDate: tomorrow,
                    numberOfGuests: 1,
                    discountcode: null,
                    IsPublicEvent: false,
                    publicEventDescription: null,
                    Notes: null,
                    Details: []
                },
                enquiryRequest: {
                    source: widgetKey,
                    EnquiryContact: {
                        firstName: "",
                        lastName: "",
                        email: "",
                        mobile: "",
                        address1: "",
                        address2: "",
                        city: "",
                        postCode: "",
                        EnquiryCompany: {
                            name: ""
                        },
                        allowMarketing: false,
                        allow3rdPartyMarketing: false
                    },
                    occasionId: 0,
                    venueId: 0,
                    bookingDate: tomorrow,
                    bookingType: 0,
                    startTime: null,
                    endTime: null,
                    bookingEndDate: tomorrow,
                    numberOfGuests: 1,
                    discountcode: null,
                    IsPublicEvent: false,
                    publicEventDescription: null,
                    Notes: null,
                    Details: []
                },
                calculatedRate: {},
                lastbookingRequest: {
                    source: widgetKey,
                    Details: []
                },
                removedDetails: [],
                widgetTheme: widgetTheme,
                termsAndConditionsDoc: termsAndConditionsDoc,
                privacyPolicyDoc: privacyPolicyDoc,
                returnURL: returnURL,
                earlyEmailCheck: earlyEmailCheck,
                performCovidChecks: performCovidChecks,
                isDrawOpen: isDrawOpen,
                stripe: stripe,
                hubSpotChatId: hubSpotChatId,
                translations: translations,
                savedCards: [],
                translations: translations,
                skiprooms: false
            });

            this.setBookingDate(
                items.defaultDate == null ? tomorrow : new Date(items.defaultDate)
            );

            const defaultDate =
                items.defaultDate === null ? tomorrow : new Date(items.defaultDate);

            defaultStartTime = addHours(defaultDate, 9);
            defaultEndTime = addHours(defaultDate, 17);

            this.setStartTime(defaultStartTime);
            this.setEndTime(defaultEndTime);
            if (venue.length === 1) {
                this.setVenue(venue[0].id);
            }
            if (occasionBaseData.length === 1) {
                this.setOccasion({
                    target: {
                        type: "init",
                        value: occasionBaseData[0].name
                    }
                });
            }
        }
        else {
            this.setState({ loading: false });
        }
    };

    skipEarlyEmailCheck = () => {
        this.setValue("skippedEarlyEmailCheck", true);
        logEvent("Check Email", "Skipped Early Email Check", this.state.name);
    };

    setQuestions = (newQuestions) => {
        this.setValue(
            "questions", newQuestions
        );
    }

    setReady = (bool) => {
        this.setValue(
            "ready", bool,
        )
    }

    setThisPeriod = period => {
        this.setState(
            reducer({
                type: SET_THIS_PERIOD,
                value: period,
                widget: this
            })
        );

        this.refreshFilter();
    };

    setValue = (obj, newValue) => {
        this.setState(
            reducer({
                type: SET,
                name: obj,
                value: newValue
            })
        );
    };

    setBookingDate = date => {
        this.setValue("calculating", true);
        date = startOfDay(date);
        let offset = date.getTimezoneOffset();
        if (offset !== 0) {
            date = addMinutes(date, offset * -1);
        }
        this.setState(
            reducer({
                type: SET_BOOKING_DATE,
                value: date,
                widget: this
            })
        );

        this.refreshFilter();
    };
    setStartTime = date => {
        this.setValue("calculating", true);
        this.setState(
            reducer({
                type: SET_START_TIME,
                value: date,
                widget: this
            })
        );

        this.refreshFilter();
    };
    setEndTime = date => {
        this.setValue("calculating", true);
        this.setState(
            reducer({
                type: SET_END_TIME,
                value: date,
                widget: this
            })
        );

        this.refreshFilter();
    };
    setOccasion = event => {
        const { bookingRequest, occasionData, occasionBaseData } = this.state;

        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;

        this.setValue("type", value);

        if (value !== "all") {
            // An Occasion has been selected
            let tempOccasion = [...occasionBaseData];
            tempOccasion = tempOccasion.filter(occasion => occasion.name === value);
            tempOccasion = tempOccasion[0];
            if ((bookingRequest.occasionId || -1) != tempOccasion.id) {
                this.setValue("calculating", true);

                this.setState(
                    reducer({
                        type: APPLYOCCASIONRULES,
                        widget: this
                    })
                );

                //state = applyOccasionRules(state);
            }
        }
        // this.updateRate();
    };

    setOccasionById = id => {
        const { bookingRequest, occasionBaseData } = this.state;
        let tempOccasion = [...occasionBaseData];
        tempOccasion = tempOccasion.filter(occasion => occasion.id === id);
        tempOccasion = tempOccasion[0];

        if ((bookingRequest.occasionId || -1) != id) {
            this.setValue("type", tempOccasion.name);
            this.setValue("calculating", true);

            this.setState(
                reducer({
                    type: APPLYOCCASIONRULES,
                    widget: this
                })
            );
        }
    };

    refreshFilter = () => {
        const { type } = this.state;

        this.setState(
            reducer({
                type: APPLYFILTERS
            })
        );
    };

    createEnquiryData = () => {
        this.setState(
            reducer({
                type: COPY_BOOKING_TO_ENQUIRY
            })
        );
    };

    setPrices = result => {
        this.setState(
            reducer({
                type: SET_NEWRATE,
                rate: result || {}
            })
        );
        this.setValue("calculating", false);
    };

    setavailability = result => {
        this.setState(
            reducer({
                type: SET_NEW_AVAILABILITY,
                availability: result || {}
            })
        );
    };

    setlastRequest = lastRequest => {
        this.setState(
            reducer({
                type: SET_LAST_REQUEST,
                request: lastRequest || {}
            })
        );
    };

    setbookingInvlaid = newRequest => {
        this.setState(
            reducer({
                type: SET_BOOKING_INVALID,
                request: newRequest || {}
            })
        );
        this.setValue("calculating", false);
    };

    setEnquiryRequest = enquiryRequest => {
        this.setState(
            reducer({
                type: SET_ENQUIRY_REQUEST,
                request: enquiryRequest || {}
            })
        );
    };

    handleChange = event => {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        // console.log(name, value);

        this.setValue(name, value);

        this.refreshFilter();
    };

    changeDrawState = event => {
        if (event) {
            this.setState({ isDrawOpen: !this.state.isDrawOpen });
        }
    };

    handleContactChange = (event, deliveryPostCode) => {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        // console.log(name, value);

        this.setState(
            reducer({
                type: SET_CONTACT_DETAILS,
                value: value,
                name: name
            })
        );
    }; 

    calculateRate = () => {
        API.calculatePrices(this.state.bookingRequest).then(this.setPrices)

    }

    setGuestNumbers = event => {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        // const name = target.name;
        // console.log(name, value);
        logEvent("Guest Number", "Change Guests To" + value, this.state.name);
        this.setValue("calculating", true);
        this.setState(
            reducer({
                type: SET_GUEST_NUMBERS,
                value: value,
                widget: this
            })
        );

        this.refreshFilter();
    };

    setDiscountCode = code => {
        logEvent("Discount Code", "Enter Discount" + code, this.state.name);
        this.setValue("calculating", true);
        this.setState(
            reducer({
                type: SET_DISCOUNT_CODE,
                value: code,
                widget: this
            })
        );

        this.refreshFilter();
    };

    setEarlyEmailCheck = () => {
        this.setValue("calculating", true);
        this.setValue("earlyEmailCheck", false);
        this.setState(
            reducer({
                type: SET_EARLY_EMAIL_CHECK,
                widget: this
            })
        );
    };

    setVenue = venueId => {
        this.setState(
            reducer({
                type: SET_VENUE,
                value: venueId,
                widget: this
            })
        );

        this.refreshFilter();
    };

    addBookingDetail = (
        id,
        venueId,
        fieldVal,
        menuDetails,
        courseId,
        menuItemId,
        roomLayoutId,
        resourceType
    ) => {
        const { bookingRequest } = this.state;
        this.setValue("calculating", true);
        logEvent(
            "Booking",
            "Add Item : " + resourceType + " : " + id,
            this.state.name
        );

        this.setState(
            reducer({
                type: ADD_BOOKING_DETAIL,
                request: bookingRequest,
                newId: id,
                newVenue: venueId,
                fieldVal: fieldVal,
                removeBookingItem: this.removeBookingItem,
                widget: this,
                menuDetails: menuDetails,
                courseId: courseId,
                menuItemId: menuItemId,
                roomLayoutId: roomLayoutId,
                resourceType: resourceType
            })
        );

        this.refreshFilter();
    };

    removeBookingDetail = (id, venueId) => {
        const { bookingRequest } = this.state;
        this.setValue("calculating", true);

        logEvent("Booking", "Remove Item : " + id, this.state.name);

        this.setState(
            reducer({
                type: REMOVE_BOOKING_DETAIL,
                request: bookingRequest,
                newId: id,
                removeBookingItem: this.removeBookingItem,
                widget: this
            })
        );
    };

    addBookingDetailNotes = (id, notes) => {
        if (id === 0) {
            this.setState(
                reducer({
                    type: ADD_BOOKING_NOTES,
                    notes: notes
                })
            );
        } else {
            this.setState(
                reducer({
                    type: ADD_BOOKING_DETAIL_NOTES,
                    id: id,
                    notes: notes
                })
            );
        }
    };

    addPublicDescription = (description) => {
        this.setState(
            reducer({
                type: ADD_BOOKING_DESCRIPTION,
                description: description
            })
        );
    }

    removeBookingItem = id => {
        this.setValue("calculating", true);
        const { bookingRequest } = this.state;
        bookingRequest.Details = bookingRequest.Details.filter(function (item) {
            return item.ResourceId !== id;
        });
        this.updateRate();
        applyFilters(this.state);
    };

    updateRate = () => {
        const {
            bookingRequest,
            lastbookingRequest,
            selectedStart,
            selectedEnd,
            widgetKey
        } = this.state;
        this.setValue("calculating", true);
        // console.log("Update Rate:" + JSON.stringify(bookingRequest));
        updateRate(
            bookingRequest,
            lastbookingRequest,
            selectedStart,
            selectedEnd,
            widgetKey,
            this.setPrices,
            this.setavailability,
            this.setbookingInvlaid,
            this.setlastRequest
        );
    };



    isCardPaymentReady = () => {
        return true;
    };

    isCompulsoryResource = resourceId => {
        const { occasionBaseData, bookingRequest } = this.state;
        return checkCompulsoryResource(
            resourceId,
            bookingRequest.occasionId,
            occasionBaseData
        );
    };

    generateEnquiry = (r) => {
        const { enquiryRequest } = this.state;

        const req = !r ? enquiryRequest : r;

        logEvent(
            "Enquiry",
            "Requested",
            this.state.name,
            null,
            enquiryRequest.budget
        );



        API.generateEnquiry(req).then(result => {
            if (typeof result !== "undefined" || result.hasOwnProperty("id")) {
                this.setState({
                    enquiry: result || {}
                });
            } else {
                this.setState({
                    enquiry: {}
                });
            }
        });
    };

    customerCheckin = checkinRequest => {
        logEvent("Visitor Book", "Check In", this.state.name);
        API.customerCheckin(checkinRequest).then(result => {
            this.setState({
                checkInOutData: result
            });
        });
    };

    customerCheckOut = (sourceKey, guestKey, instantCheckOut) => {
        logEvent("Visitor Book", "Check Out", this.state.name);
        API.customerCheckOut(sourceKey, guestKey).then(result => {
            if (!instantCheckOut) {
                this.setState({
                    checkInOutData: result
                });
            } else {
                console.log(result);
            }
        });
    };

    requestBooking = () => {
        const {
            booking,
            bookingRequest,
            calculatedRate,
            availability,
            selectedStart,
            selectedEnd
        } = this.state;
        logEvent("Booking", "Request", this.state.name, null, calculatedRate.gross);
        if (
            bookingRequestIsValid(bookingRequest, selectedStart, selectedEnd) ===
            true &&
            availability.state === "available"
        ) {
            if (typeof booking != "undefined" && booking.hasOwnProperty("id")) {
                // We already have a booking object - check if it matches current request and if so return it.
                if (
                    (booking.bookingDate === bookingRequest.bookingDate &&
                        booking.startTime === bookingRequest.startTime &&
                        booking.endTime === bookingRequest.endTime &&
                        booking.numberOfGuests === bookingRequest.numberOfGuests &&
                        booking.details.length === bookingRequest.length,
                        booking.gross === calculatedRate.gross)
                ) {
                    return booking;
                }
            }
            API.requestBooking(bookingRequest).then(result => {
                if (typeof result !== "undefined" || result.hasOwnProperty(booking)) {
                    this.setState({
                        booking: result.booking || {},
                        questions: result.questions || [],
                        savedCards: result.availableCards || []
                    });
                } else {
                    this.setState({
                        booking: {},
                        questions: [],
                        savedCards: []
                    });
                }
            });
        }
    };

    timeFromString = timeString => {
        let newTimeObject = new Date();
        let timeElement = timeString.split(":");
        newTimeObject.setHours(timeElement[0]);
        newTimeObject.setMinutes(timeElement[1]);
        newTimeObject.setSeconds(timeElement[2]);

        return newTimeObject;
    };

    render() {
        return (
            <WidgetContext.Provider
                value={{
                    ...this.state,
                    error: error,
                    getRoom: this.getRoom,
                    getOccasion: this.getOccasion,
                    handleChange: this.handleChange,
                    handleContactChange: this.handleContactChange,
                    completePayment: this.completePayment,
                    setBookingDate: this.setBookingDate,
                    setThisPeriod: this.setThisPeriod,
                    setStartTime: this.setStartTime,
                    setEndTime: this.setEndTime,
                    setGuestNumbers: this.setGuestNumbers,
                    addBookingDetail: this.addBookingDetail,
                    removeBookingDetail: this.removeBookingDetail,
                    removeBookingItem: this.removeBookingItem,
                    requestBooking: this.requestBooking,
                    setupPayment: API.setupPayment,
                    resetWidgetData: this.resetWidgetData,
                    setOccasion: this.setOccasion,
                    setOccasionById: this.setOccasionById,
                    addBookingDetailNotes: this.addBookingDetailNotes,
                    createEnquiryData: this.createEnquiryData,
                    setEnquiryRequest: this.setEnquiryRequest,
                    generateEnquiry: this.generateEnquiry,
                    customerCheckin: this.customerCheckin,
                    customerCheckOut: this.customerCheckOut,
                    setVenue: this.setVenue,
                    setDiscountCode: this.setDiscountCode,
                    setEarlyEmailCheck: this.setEarlyEmailCheck,
                    skipEarlyEmailCheck: this.skipEarlyEmailCheck,
                    refreshFilter: this.refreshFilter,
                    setQuestions: this.setQuestions,
                    setReady: this.setReady,
                    api: API,
                    isCompulsoryResource: this.isCompulsoryResource,
                    drawOpener: this.drawOpener,
                    isDrawOpen: this.state.isDrawOpen,
                    changeDrawState: this.changeDrawState,
                    addPublicDescription: this.addPublicDescription,
                    calculateRate: this.calculateRate
                }}
            >
                <WidgetResourcesContextProvider>
                    {this.props.children}
                </WidgetResourcesContextProvider>
            </WidgetContext.Provider>
        );
    }
}
const WidgetConsumer = WidgetContext.Consumer;

export { WidgetProvider, WidgetConsumer, WidgetContext };



export function withWidgetConsumer(Component) {
    return function ConsumerWrapper(props) {
        return (
            <WidgetConsumer>
                {value => <Component {...props} context={value} />}
            </WidgetConsumer>
        );
    };
}
