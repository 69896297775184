import React from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import CurrencyFormat from './CurrencyFormat';
import { useTranslation } from 'react-i18next'

const drawerWidth = 480; 

const useStyles = makeStyles(theme => ({
    root: {
        padding: 0,
    },
    footer: {
        position: 'fixed',
        bottom: '0px',
        [theme.breakpoints.up('md')]: {
            width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    footerTakeAway: {
        position: 'fixed',
        bottom: '0px',
        [theme.breakpoints.up('md')]: {
            width: '75%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    switch: {
        backgroundColor: theme.palette.grey[200],
    },
    cart: {
        padding: 5,
        backgroundColor: 'rgb(89,92,95)',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
        },
}));

const WidgetFooterCart = ({ isTakeAway, context }) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    if (!context) return null
    const { gross } = context.calculatedRate;
    const { privacyPolicyDoc } = context;
    const totalItemCount = context.bookingRequest.Details;


    const StyledBadge = withStyles(theme => ({
    badge: {
        right: 9,
        top: 9,
        color: `${theme.palette.primary}`,
        padding: '0 4px',
    },
    }))(Badge);

    return (
        <footer className={isTakeAway ? classes.footerTakeAway : classes.footer}>
            <Container fullScreen className={classes.root} style={{width: isTakeAway ? '75% !important' : '100%', maxWidth: '100%'}}>
                <Grid container direction="row" justify="center" alignItems="center" className={classes.cart} onClick={context.changeDrawState} style={{cursor: 'pointer',}} >
                    <Grid item xs={2} sm={1} style={{textAlign: 'right'}}>
                        {totalItemCount ?
                            <StyledBadge badgeContent={totalItemCount.length} color="primary">
                                <ShoppingCartOutlinedIcon fontSize="large" style={{color: '#000', fontSize:'50px',}} />
                            </StyledBadge>
                        :
                            <Badge badgeContent={0} color="primary">
                                <ShoppingCartOutlinedIcon fontSize="large" style={{color: '#000', fontSize:'50px',}} />
                            </Badge>
                        }
                    </Grid>
                    <Grid item xs={5} sm={3}>
                    
                        <Typography variant="body2" style={{color: '#fff'}} align="center">
                            {t("switch.widget.footercart.viewbasket")}
                        </Typography>
                    </Grid>
                    {gross &&
                        <Grid item xs={2} sm={2} style={{textAlign: 'left'}}>
                            <Typography variant="body2" style={{color: '#fff'}}>
                                {" - "}<CurrencyFormat value={gross} prefix={t("switch.widget.currancysymbol")} matUiVariant="body2" />
                            </Typography>
                        </Grid>
                    }
                </Grid>
                <Grid container direction="row"  justify="center"  alignItems="center" className={classes.switch}>
                    <Grid item xs={7} sm={5}>
                        <Typography variant="body2" color="textSecondary" align="center">
                            {t("switch.widget.footer.powerdby")}
                            <Link href="https://switch.dispace.co" target="_blank" color="secondary"> {t("switch.widget.footer.switch")}</Link>
                            {" ©"}
                        </Typography>
                    </Grid>
                    {privacyPolicyDoc !== null && privacyPolicyDoc.documentUrl != "" ? (
                        <Grid item xs={4}>
                            <Typography variant="body2" color="textSecondary" align="center">
                                <Link href={privacyPolicyDoc.documentUrl} target="_blank" color="secondary"> {t("switch.widget.footer.privacy")}</Link>
                            </Typography>
                        </Grid>)
                        : (<></>)}
                </Grid>
            </Container>
        </footer>
    );
};

export default WidgetFooterCart;
