import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import BookingFormSingleEvent from './BookingFormSingleEvent';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import { useTheme } from '@material-ui/core/styles'
import CustomerCapture from './../pages/CustomerCapture'
import BookingFormPickers from './BookingFormPickers';
import SlideShowComponent from './SlideShowComponent'

const useStyles = makeStyles(theme => ({
    logo: {
        width: '134px',
        marginRight: '20px',
        maxHeight: 200,
    },
    mainContainer: {
        backgroundColor: '#fff',
        padding: '0px',
    },
    appbar: {
        alignItems: 'center',
    },
    contentBox: {
        padding: '24px',
        '& p': {
            paddingBottom: '11px',
            fontSize: '13px',
        }
    },
    containerBox: {
        padding: '0px 0px 24px 0px',
        height: '100%',
    },
    imageBox: {
        padding: '0px 5px 0px 0px'
    },
    eventImage: {
        width: '100%',
        height: 'auto',
    },
    descriptionBox: {
        padding: '0px 0px 0px 5px'
    },
    eventDescription: {
        textAlign: 'center',
    },
    formContainer: {
        padding: '0px 24px',
    },
    paper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    titleLight: {
        flexGrow: 1,
        textAlign: 'center',
        color: '#FFFFFF'
    },
    titleDark: {
        flexGrow: 1,
        textAlign: 'center',
        color: '#000000'
    },
}));


function BookingFormRender({ context }) {
    const classes = useStyles();
    if (context.bookingRequest.EnquiryContact.email) {
        return (
            <React.Fragment>
                <BookingFormPickers context={context} hideSettings={
                    {
                        hideOccasion: true,
                        hideDatePicker: false,
                        hideTimePicker: false,
                        hideGuestPicker: true
                    }} />
                <BookingFormSingleEvent context={context} hideDiscount={true}/>
            </React.Fragment>

        )
    }
    else {
        return (
            <CustomerCapture context={context} />
        )

    }
}


function WidgetCustomerCollection({ context }) {
    const classes = useStyles();
    const theme = useTheme();

    var dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    return (
        <Container maxWidth="md" className={classes.mainContainer}>
            <AppBar position="static" className={classes.appbar} style={{ backgroundColor: `${theme.palette.custom.primary}` }}>
                <Toolbar>
                    {context.widgetTheme === null ? ('') : (<img src={context.widgetTheme.logoImage.imageUrl} className={classes.logo} />)}

                    {/* {context.widgetTheme === undefined ? '' : 
                    <img src={context.widgetTheme.logoImage.imageUrl} className={classes.logo} />} */}
                    {context.widgetTheme === null ? (
                        <Typography variant="h6" className={classes.titleDark}>
                            {context.venue[0].name}:  {context.occasionBaseData[0].name}
                        </Typography>
                    ) : (
                            context.widgetTheme.fontStyle === 'light' ? (
                                <Typography variant="h6" className={classes.titleLight}>
                                    {context.widgetTheme === undefined ? (`${context.venue[0].name}` + ':') : ''}  {context.occasionBaseData[0].name}
                                </Typography>
                            ) : (
                                    <Typography variant="h6" className={classes.titleDark}>
                                        {context.widgetTheme === undefined ? (`${context.venue[0].name}` + ':') : ''}  {context.occasionBaseData[0].name}
                                    </Typography>
                                )
                        )
                    }
                </Toolbar>
            </AppBar>
            <Grid container component="main" className={classes.containerBox}>
                <Grid item xs={12} >
                    <Grid container className={classes.contentBox}>
                        <Grid item xs={12} sm={4} md={4} className={classes.imageBox}>
                            <SlideShowComponent
                                slideShow={context.occasionBaseData[0].slideShow}
                                className={classes.eventImage}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} className={classes.descriptionBox}>
                            <Typography dangerouslySetInnerHTML={{ __html: context.occasionBaseData[0].description }} className={classes.eventDescription} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={12} md={12} className={classes.paper} >
                    <Container className={classes.formContainer}>
                        <Box textAlign="center">
                            <BookingFormRender context={context} />
                        </Box>
                    </Container>
                </Grid>
            </Grid>
        </Container>
    );
}

export default WidgetCustomerCollection;