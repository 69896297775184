import React from 'react';
import { Grid, Typography, Button, Divider, Box } from '@material-ui/core';
import BookingItemList from './BookingItemList';
import BookingSummary from './BookingSummary';
import BookingButton from './BookingButton';
import ProgressButton from "./ProgressButton"
import EnquiryButton from './EnquiryButton';
import DiscountButton from './DiscountButton';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import ListCartErrors from './ListCartErrors';
import BookingItemHelperInfo from './BookingItemHelperInfo';

const getUnique = (items, value) => {
    return [...new Set(items.map(item => item[value]))];
};

const useStyles = makeStyles(theme => ({
    cartDesktopContainer: {
        padding: '20px',
        marginTop: '14px',
        minWidth: '450px',
        maxWidth: '500px',
        background: theme.palette.colors.white,
        borderRadius: '10px',
        border: '1px solid',
        borderColor: theme.palette.custom.secondary,
        [theme.breakpoints.down('sm')]: {
            minWidth: '100%',
            margin: '0px',
            padding: '10px',
            border: '0px',
            overflowX: 'hidden',
        },
    },
    border: {
        borderLeft: '1px solid #000'
    },
}));


function BookingForm({ context }) {
    const theme = useTheme();
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    if (!context) return null;
    const {
        occasionData,
        occasionBaseData,
        bookingRequest,
        calculatedRate,
        type,
        resetWidgetData,
        calculating
    } = context;
    const all = 'all'
    let OccasionType = getUnique(occasionBaseData, "name");
    // add all

    OccasionType = [all, ...OccasionType];
    // map to jsx
    OccasionType = OccasionType.map((item, index) => (
        <option key={index} value={item}>
            {item}
        </option>
    ));


    // ------------
    // Get occasion from data matching currently selected occasion
    let Occasion = occasionData.filter(x => x.name === type)

    //Calculate Occasion length
    let OccasionLength = '';
    if (Occasion.length > 0) {
        if (Occasion[0].minDurationMinutes === Occasion[0].maxDurationMinutes) {
            OccasionLength = Occasion[0].minDurationMinutes;
        } else {
            OccasionLength = Occasion[0].maxDurationMinutes - Occasion[0].minDurationMinutes;
        }
    }

    function DateConverter(dateIn) {
        let date = new Date(dateIn);
        const options = { weekday: 'long', year: 'short', month: 'numeric', day: 'numeric' };
        let dateOut = date.toLocaleDateString('en-GB');
        return dateOut;
    }



    return (
        <React.Fragment>
            <ListCartErrors context={context} />
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" className={classes.cartDesktopContainer}>
                <Grid item xs={12}>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                        <Grid item xs={12} className="center">
                            <Typography variant="h6">{t('switch.widget.bookingform.basketTitle')}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                                <Grid item xs={3} md={6}>
                                    <Typography variant="body2" style={{ fontWeight: '900' }}>{t('switch.widget.bookingform.date') + ":"}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2">{DateConverter(bookingRequest.bookingDate)}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                                <Grid item xs={3} md={6}>
                                    <Typography variant="body2" style={{ fontWeight: '900' }}>{t('switch.widget.bookingform.time') + ":"}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2">{bookingRequest.startTime !== null && bookingRequest.startTime.substring(0, 5) + "-" + bookingRequest.endTime !== null && bookingRequest.endTime.substring(0, 5)}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                                <Grid item xs={3} md={4}>
                                    <Typography variant="body2" style={{ fontWeight: '900' }}>{t('switch.widget.bookingform.guest') + ":"}</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="body2">{bookingRequest.numberOfGuests}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider variant="middle" style={{ margin: '20px 0px' }} />
                    {calculating === true ? (
                        <Grid item xs={12}>
                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                <Grid item xs={4}>
                                    <ProgressButton /><Typography variant="body2" style={{ fontWeight: '900' }}>{t('switch.widget.bookingform.calculating')}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <BookingItemList context={context} />
                    )}


                    <Typography gutterBottom align="center"><Box fontStyle="italic" component="span" fontSize={10} fontWeight="fontWeightLight">{t('switch.widget.bookingform.availabilityterms')}</Box></Typography >

                    <Divider variant="middle" style={{ margin: '20px 0px 0px 0px' }} />
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" m={1} p={1} bgcolor="background.paper">
                        <DiscountButton context={context} />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <BookingSummary rate={calculatedRate} showTax={true} />
                </Grid>
                <Grid item xs={12}>
                    <Box fontStyle="italic" component="span" fontSize={12} >
                        <Typography gutterBottom align="right">
                            <Box fontStyle="italic" component="span" fontSize={12} >
                                {t('switch.widget.bookingform.infofooter.regularhireprompt')}
                                <BookingItemHelperInfo item={{ name: "Regular Bookings", description: "<b>" + t('switch.widget.bookingform.infofooter.regularhireinfo') + "</b>" }} context={{ id: null }} />
                            </Box>
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-evenly" m={2}>
                        <Button variant="outlined" style={{ border: '2px solid', borderColor: `${theme.palette.colors.black}`, color: `${theme.palette.colors.black}` }} onClick={resetWidgetData}>{t('switch.widget.buttons.reset')}</Button>
                        <EnquiryButton context={context} />
                        <BookingButton context={context} />
                    </Box>
                </Grid>
                <InfoFooter context={context} />
            </Grid>
        </React.Fragment>
    );

};


function InfoFooter({ context }) {
    const { availability } = context;
    const { t, i18n } = useTranslation();
    let showUnavailable = true;

    if (typeof availability != "undefined" && availability.hasOwnProperty('state')) {
        if (availability.state === "available") {
            showUnavailable = false
        }
    }

    return (<Typography gutterBottom align="center">
        {showUnavailable &&
            <Box fontStyle="italic" component="span" fontSize={12} >
                {t('switch.widget.bookingform.infofooter.unavailbeinfo')}
            </Box>}
      </Typography >);

}

export default BookingForm;