import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Link } from "react-router-dom";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CurrencyFormat from './CurrencyFormat';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import ResourceDescription from './ResourceDescription';
import { useTranslation } from "react-i18next"

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    }
});

const useStyles = makeStyles(theme => ({
    infoIcon: {
        cursor: 'pointer',
        color: theme.palette.custom.secondary,
    },
    courseTitle: {
        fontWeight: '700',
        fontSize: '16px',
        marginTop: '10px',
    }
}));

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function compare(a, b) {
    if (a.displayOrder < b.displayOrder) {
        return -1;
    }
    if (a.displayOrder > b.displayOrder) {
        return 1;
    }
    // a must be equal to b
    return 0;
}

export default function MenuDetails({ food, addFoodToBooking }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [open, setOpen] = React.useState(false);

    if (!food) return null;

    const { id, name, description, image, price, price_type, menu, capacityMeasure } = food;
    const addFoodToBookingChild = () => {
        addFoodToBooking()
        setOpen(false);
    };

    const OpenMenuData = () => {
        setOpen(true);
    };
    const CloseMenuData = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Tooltip title={<Typography color="inherit" style={{ textAlign: 'center' }}>{t("switch.widget.venue.clikadd")}</Typography>} aria-label={<Typography color="inherit">{t("switch.widget.venue.clikadd")}</Typography>}>
                <IconButton id="openMenuDetails" onClick={OpenMenuData} style={{padding: '0px'}}>
                    <InfoIcon fontSize="small"  className={classes.infoIcon} />
                </IconButton>
            </Tooltip>
            <Dialog id="menuDataPopup" fullScreen={fullScreen} onClose={CloseMenuData} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={CloseMenuData}>
                    {menu.name ? menu.name : name}
                </DialogTitle>
                <DialogContent dividers>

                    <Typography variant="body1">
                        <ResourceDescription id={id} description={description} />
                    </Typography>

                    <Typography variant="body2" gutterBottom style={{ marginTop: '10px' }}>
                        {t("switch.widget.menudata.pricefrom")} <CurrencyFormat value={price} prefix={t("switch.widget.currancysymbol")} capacityMeasure={capacityMeasure} styleless />
                    </Typography>

                    {menu.courses.sort(compare).map((course, index) => {
                        return <React.Fragment key={index}>
                            <Typography variant="h6" color="secondary" className={classes.courseTitle} >{course.name} </Typography>
                            <Typography variant="body2" gutterBottom>{course.description} </Typography>
                            <ul>
                            {course.items.sort(compare).map((item, index) => {
                                            return <React.Fragment key={index}>
                                                <li>
                                                    <Typography variant="body2">{item.name}</Typography>
                                                    {(item.costPerHead === null) 
                                                        ? (<></>) 
                                                        : ((item.costPerHead === 0 
                                                            ? (<></>) 
                                                            : (<Typography variant="subtitle2" gutterBottom>
                                                                    <Box textAlign="right" fontWeight="fontWeightBold" fontStyle="italic">
                                                                    {t("switch.widget.menudata.extra")} <CurrencyFormat value={item.costPerHead} prefix={t("switch.widget.currancysymbol")} matUiVariant="body1" />  {t("switch.widget.menudata.perhead")} 
                                                                    </Box>
                                                                </Typography>
                                                                )))}
                                                    <Typography variant="body2" gutterBottom>{item.description} </Typography>
                                                </li>
                                </React.Fragment>;
                            })}
                            </ul>
                        </React.Fragment>;
                    })}
                </DialogContent>
                <DialogActions>
                    <Button id="btn-close" autoFocus onClick={CloseMenuData} color="primary">{t("switch.widget.buttons.close")} </Button>
                    <Button id="btn-add" onClick={addFoodToBookingChild} variant="outlined" style={{color: `${theme.palette.success.main}`, border: '2px solid', borderColor: `${theme.palette.success.main}`}}>Add</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}