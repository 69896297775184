import { Suspense, lazy } from 'react'
import CircularProgress from '@mui/material/CircularProgress';


const Portal = lazy(() => import("@switch/portal").then(module => ({ default: module.Portal })));

export const PortalLoader = () => {

    return (<Suspense fallback={<CircularProgress />}>
        <Portal />
    </Suspense>

    );
}