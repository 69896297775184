import React from 'react';
import { Box,Text, Button, Layer, RadioButtonGroup } from "grommet";
import ContentContainer from "./ContentContainer";
import { CurrencyFormat } from "./CurrencyFormat";
import { PaymentContextProvider, usePayment } from '../contexts/PaymentContext';
import { useGuestPortal } from '../contexts/GuestPortalContext';
import { usePortalManagePayment } from '../contexts/PortalManagePaymentContext';
import PaymentForm from './PaymentForm';


const PayButton = () => {
    const { ClosePaymentDialog } = usePortalManagePayment();
    const {
        cardType,
        loading,
        paymentStatus,
        ready,
        processingType,
        setSavedCardStepWindCave,
        savedCardStepWindCave,
        savedCards,
        PerformRedirect,
        processing,
        iFrameResult,
        preRedirectStateSetter
    } = usePayment();

    if (paymentStatus !== "succeeded") {

        switch (processingType) {
            case "windCave":
            case "pay360SCP":
            case "WPM":
                return (
                    <>
                        {savedCardStepWindCave && (
                            <Button
                                primary
                                disable={loading}
                                type="submit"
                                label={savedCards ? "Pay now" : "Next"}
                                onClick={() => {
                                    setSavedCardStepWindCave(false);
                                    PerformRedirect();
                                }}
                            />
                        )}
                        {!savedCardStepWindCave && iFrameResult && (
                            <Button
                                id="payConfirm"
                                primary
                                label={iFrameResult?.result === "success" ? "Finish" : "Retry"}
                                onClick={iFrameResult?.result === "success" ? ClosePaymentDialog : preRedirectStateSetter}
                                disabled={processing}
                            />
                        )}
                    </>
                );
            default: // stripe
                return (
                    <Button
                        id="payConfirm"
                        primary
                        label={
                            paymentStatus === "processing" ? "Processing" : "Pay"
                        }
                        disabled={
                            loading || !ready || paymentStatus === "processing"
                        }
                        form={cardType === "newcard" ? "cardDetails" : "cvcRecapture"}
                        type="submit"
                    />
                );

        }
    }

    return (
        <Button
            id="payConfirm"
            primary
            label="Finish"
            onClick={ClosePaymentDialog}
        />
    );
};


const CancelButton = () => {
    const { ClosePaymentDialog } = usePortalManagePayment();
    const { paymentStatus } = usePayment();

    if (paymentStatus != "succeeded") return (<Button id="payCancel" label="Cancel" onClick={ClosePaymentDialog} />);
    return null;
}

const NextButton = () => {
    const {
        PerformRedirect,
        processingType,
        savedCards
    } = usePayment();
    const {
        DisplayCardSceen
    } = usePortalManagePayment();
    function handleClick() {
        if (processingType === "windCave" && savedCards <= 1) {
            null
        } else if (processingType === "windCave" && savedCards > 1) {
            PerformRedirect();
        }
        DisplayCardSceen();
    };

    return (<>
        <Button id="payNext" onClick={handleClick} primary label="Next" />
    </>);
}


export function Payment() {
    const { savedCards, stripeSettings, API, booking } = useGuestPortal();
    const {
        ClosePaymentDialog,
        paymentOptions,
        showCardScreen,
        selectedOption,
        SetSelectedOption,
        selectedPaymentAmount,
    } = usePortalManagePayment();


    return <Layer id="paymentDialog" onClose={ClosePaymentDialog}>
        <Box direction="column" pad="small" margin="small" overflow="auto">
            <PaymentContextProvider context={{ savedCards: savedCards, stripe: stripeSettings, api: API, booking: booking, amount: selectedPaymentAmount }}>
                <ContentContainer emoji=":credit_card:" title="Make a payment" content="Please check and confirm the payment amount" flex="grow" width="large">

                    {!showCardScreen &&
                        <React.Fragment>
                            <Box direction="column" flex="grow" overflow="auto" pad="small" >
                                {paymentOptions.length > 1 &&
                                    <RadioButtonGroup
                                        direction="row"
                                        name="doc"
                                        options={paymentOptions}
                                        value={selectedOption}
                                        onChange={(event) => SetSelectedOption(event.target.value)}
                                    />}

                                <Box align="center" direction="row" gap="small" pad="large">
                                    <Text size="large">Amount Due:</Text>
                                    <Box height="50px" width="200px" border={{ color: 'brand', size: 'small' }} align="end" justify="center" pad="small">
                                        <Text size="large" >{CurrencyFormat(selectedPaymentAmount)}</Text>
                                    </Box>
                                </Box>

                            </Box>
                            <Box tag="footer" margin={{ top: "medium" }} direction="row" justify="between">
                                <Button id="payCancel" label="Cancel" onClick={ClosePaymentDialog} />
                                <NextButton />
                            </Box>
                        </React.Fragment>}
                    {showCardScreen &&
                        <Box>

                            <PaymentForm />
                            <Box tag="footer" margin={{ top: "medium" }} direction="row" justify="between">
                                <CancelButton />
                                <PayButton />
                            </Box>
                        </Box>

                    }

                </ContentContainer>
            </PaymentContextProvider>

        </Box>
    </Layer>;
}



