import React, { useContext } from "react";
import Grid from '@material-ui/core/Grid'
import BookingFormPickers from './BookingFormPickers';
import { WidgetContext } from "../widgetContext";

const BookingPickersWrapper = (props) => {

  const context = useContext(WidgetContext);

  return (
        <Grid item xs={10} xl={props.matUiXl} style={{paddingTop: props.padding ? props.padding : '' }}>
          <Grid container direction="row" justify="space-around" alignItems="center" spacing={4}>
            <BookingFormPickers context={context} hideSettings={{
                  hideOccasion: true,
                hideDatePicker: true,
                hideTimePicker: false,
                hideGuestPicker: true,
                roomPickers: true
            }}/>
          </Grid>
        </Grid>
  );
};

export default BookingPickersWrapper;
