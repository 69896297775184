import React, { useState } from "react";
import { Box, DataTable, Select, Text, CheckBox } from "grommet";
import 'react-quill/dist/quill.bubble.css';
import { Dialog } from "./Dialog";

import {
    useCSVReader,
    lightenDarkenColor,
    formatFileSize,
} from 'react-papaparse';

export function UploadGuestList(props) {

    const GREY = '#CCC';
    const GREY_LIGHT = 'rgba(255, 255, 255, 0.4)';
    const DEFAULT_REMOVE_HOVER_COLOR = '#A01919';
    const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
        DEFAULT_REMOVE_HOVER_COLOR,
        40
    );
    const GREY_DIM = '#686868';

    const { open, onCancel, onSend } = props;
    const [csvData, setCsvData] = React.useState(null);
    const [checked, setChecked] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [columns, setColumns] = React.useState([]);
    const [allocations, SetAllocations] = React.useState(null);
    const [field, SetField] = React.useState(null);
    const { CSVReader } = useCSVReader();
    const onChange = event => setChecked(event.target.checked);
    const [zoneHover, setZoneHover] = useState(false);
    const [removeHoverColor, setRemoveHoverColor] = useState(
        DEFAULT_REMOVE_HOVER_COLOR
    );
    const options = ['First Name', 'Surname', 'Description', 'Email', 'Mobile'];



    const styles = {
        zone: {
            alignItems: 'center',
            border: `2px dashed ${GREY}`,
            borderRadius: 20,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            padding: 20,
        },
        file: {
            background: 'linear-gradient(to bottom, #EEE, #DDD)',
            borderRadius: 20,
            display: 'flex',
            height: 120,
            width: 120,
            position: 'relative',
            zIndex: 10,
            flexDirection: 'column',
            justifyContent: 'center',
        },
        info: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: 10,
            paddingRight: 10,
        },
        size: {
            backgroundColor: GREY_LIGHT,
            borderRadius: 3,
            marginBottom: '0.5em',
            justifyContent: 'center',
            display: 'flex',
        },
        name: {
            backgroundColor: GREY_LIGHT,
            borderRadius: 3,
            fontSize: 12,
            marginBottom: '0.5em',
        },
        progressBar: {
            bottom: 14,
            position: 'absolute',
            width: '100%',
            paddingLeft: 10,
            paddingRight: 10,
        },
        zoneHover: {
            borderColor: GREY_DIM,
        },
        default: {
            borderColor: GREY,
        },
        remove: {
            height: 23,
            position: 'absolute',
            right: 6,
            top: 6,
            width: 23,
        },
    };

    function cancel() {
        setCsvData(null);
        setChecked(false);
        setColumns([]);
        SetAllocations(null);
        onCancel();
    }

    const setSelection = (column, value) => {
        SetField({ [column]: value });
    }


    React.useEffect(() => {
        if (field != null) {
            let updates = field;
            if (allocations != null) {
                const newValue = field[Object.keys(field)[0]];
                for (const [key, value] of Object.entries(allocations)) {
                    if (value === newValue) {
                        updates[key] = null
                    }
                }
            }
            SetAllocations({
                ...allocations,
                ...updates,
            });
        }
    }, [field]);

    React.useEffect(() => {
        let columnUpdate = columns;
        let columnCount = 0;

        columnUpdate.forEach((column) => {
            column.header = <ColumnSelector id={columnCount} setSelection={setSelection} value={allocations["column" + columnCount]} />
            columnCount++;
        });

        setColumns(columnUpdate);
        setLoading(true)
    }, [allocations])

    React.useEffect(() => {
        setLoading(false)
    }, [loading])

    function handleOnFileLoad(data, setSelection, setColumns, setCsvData) {

        let dataImport = [];
        let columnsList = [];

        data.forEach((row) => {

            let rowData = {}
            let columnCount = 0;

            row.forEach((column) => {
                rowData['column' + columnCount] = column;
                if (columnCount > columnsList.length - 1) {
                    columnsList.push({
                        property: 'column' + columnCount,
                        header: <ColumnSelector id={columnCount} setSelection={setSelection} />,
                    })
                }
                columnCount++;
            });


            dataImport.push(rowData);
        });

        setColumns(columnsList);
        setCsvData(dataImport);

    }

    const ColumnSelector = (props) => {
        const { id, setSelection, value } = props;

        return (<Select id={"select" + id} name={"select" + id} value={value} options={options} onChange={({ option }) => { setSelection('column' + id, option); }} valueLabel={<Box width="xsmall" round="small" overflow="hidden" align="center">
            {value || 'Unallocated'}
        </Box>} />);
    };

    if (!open) return null;

    function EmailSelected() {
        let result = false;
        if (allocations != null) {
            for (const [key, value] of Object.entries(allocations)) {
                if (value === "Email") {
                    result = true;
                    break;
                }
            }
        }
        return result;
    }


    function BuildInvites() {
        let invites = [];
        let guestData = checked ? csvData.filter(i => i != csvData[0]) : csvData;

        guestData.forEach((row) => {
            let guest = {}
            for (const [key, value] of Object.entries(allocations)) {
                if (value != null) {
                    guest[value.replace(/\s/g, "").toLowerCase()] = row[key];
                }
            }
            if (guest.email != null) {
                invites.push(guest);
            }

        });

        return invites;
    }

    return (<Dialog id="uploadGuestListDialog" dialogStyle="EditBox" emoji=":man_in_tuxedo:" title="Upload Guest List" content={!csvData ? "Please select a CSV file containing your guest list details" : "Please allocate fields for import"} onCancel={cancel} onSend={EmailSelected() ? () => onSend(BuildInvites()) : null} >
        <Box direction="column" flex="grow">
            <Box direction="column">
                {!csvData ?
                    <CSVReader
                        onUploadAccepted={(results) => {
                            handleOnFileLoad(results?.data, setSelection, setColumns, setCsvData) 
                            setZoneHover(false);
                        }}
                        onDragOver={(event) => {
                            event.preventDefault();
                            setZoneHover(true);
                        }}
                        onDragLeave={(event) => {
                            event.preventDefault();
                            setZoneHover(false);
                        }}
                        noDrag
                    >
                        {({
                            getRootProps,
                            acceptedFile,
                            ProgressBar,
                            getRemoveFileProps,
                            Remove,
                        }) => (
                            <>
                                <div
                                    {...getRootProps()}
                                    style={Object.assign(
                                        {},
                                        styles.zone,
                                        zoneHover && styles.zoneHover
                                    )}
                                >
                                    {acceptedFile ? (
                                        <>
                                            <div style={styles.file}>
                                                <div style={styles.info}>
                                                    <span style={styles.size}>
                                                        {formatFileSize(acceptedFile.size)}
                                                    </span>
                                                    <span style={styles.name}>{acceptedFile.name}</span>
                                                </div>
                                                <div style={styles.progressBar}>
                                                    <ProgressBar />
                                                </div>
                                                <div
                                                    {...getRemoveFileProps()}
                                                    style={styles.remove}
                                                    onMouseOver={(event) => {
                                                        event.preventDefault();
                                                        setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                                                    }}
                                                    onMouseOut={(event) => {
                                                        event.preventDefault();
                                                        setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                                                    }}
                                                >
                                                    <Remove color={removeHoverColor} />
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        'Click to upload'
                                    )}
                                </div>
                            </>
                        )}
                    </CSVReader>
                    :
                    loading ? <React.Fragment /> :
                        <React.Fragment>
                            <Box direction="row" pad="small" gap="xlarge">
                                <CheckBox label="Ignore 1st Row" checked={checked} onChange={onChange} />
                                {!EmailSelected() && <Text color="status-error"> Email is a compulsory field</Text>}
                            </Box>
                            <Box direction="row">

                                <DataTable
                                    columns={columns}
                                    data={checked ? csvData.filter(i => i != csvData[0]) : csvData}
                                    step={10}
                                />
                            </Box>
                        </React.Fragment>}
            </Box>
        </Box>
    </Dialog>);
}