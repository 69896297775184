import * as React from 'react';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

export const removeTags = (input) =>
    input ? input.replace(/<[^>]+>/gm, '') : '';


export default function RichTextField(props) {
    const { className, emptyText, value, stripTags } = props;

    const theme = createTheme({
        typography: {
            fontFamily: ["-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Arial", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"] ,
            fontSize: 17,
            fontWeight: 500,
        },
        body2: {
            fontSize: 17,
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Typography
                className={className}
                variant="body2"
                component="span"
            >
                {value == null && emptyText ? (
                    emptyText
                ) : stripTags ? (
                    removeTags(value)
                ) : (
                            <span dangerouslySetInnerHTML={{ __html: value }} />
                        )}
            </Typography >
        </ThemeProvider>
    );
}
 //{ RichTextField };

/*        <Typography
 className = { className }
 variant = "body2"
 component = "span"
     >
     { value == null && emptyText ? (
         emptyText
     ) : stripTags ? (
         removeTags(value)
     ) : (
             <span dangerouslySetInnerHTML={{ __html: value }} />
         )}
         </Typography >
         */
