import React, { Fragment, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useTheme } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { useTranslation } from 'react-i18next'

function Copyright({ context }) {
    const { privacyPolicyDoc } = context;
    const { t, i18n } = useTranslation();
    return (
        <Fragment>
            <Typography variant="body2" color="textSecondary" align="center">
                {t("switch.widget.checkin.poweredby")}
                <Link href="https://switch.dispace.co" target="_blank" color="secondary">{t("switch.widget.checkin.switch")}</Link>
                {" ©"}
            </Typography>
            {privacyPolicyDoc !== null && privacyPolicyDoc.documentUrl != "" ? (
                <Typography variant="body2" color="textSecondary" align="center">
                    <Link href={privacyPolicyDoc.documentUrl} target="_blank" color="secondary">{t("switch.widget.checkin.privacypolicy")}</Link>
                </Typography>
            )
                : (<></>)}
        </Fragment>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(6),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.custom.primary,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    bold: {
        fontWeight: '700',
    },
    logo: {
        width: '134px',
        marginRight: '20px',
        maxHeight: 200,
    },
}));

export default function SignIn({ context }) {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const url = window.location.href
    const checkOutKey = url.split('=').pop()
    const dateNow = new Date().toISOString();
    const [filteredBookings, setFilteredBookings] = useState()
    const [checks, setChecks] = useState({
        "alert": false,
        "success": false,
        "multiVenue": false,
        "multiOccasion": false,
        "loadingCheckin": false,
        "loadingCheckOut": false,
        "InstantCheckOut": false,
        "step": 1,
        "selectRoom": false,
    });
    const [request, setRequest] = useState({
        "contact": {
            "firstName": "",
            "lastName": "",
            "email": "",
            "mobile": "",
            "allowMarketing": false,
            "allow3rdPartyMarketing": false,
            "enquiryCompany": {
                "name": "",
            },
        },
        "venueId": 0,
        "occasionId": 0,
        "resourceId": 0,
        "bookingReference": 0,
        "checkInTime": dateNow,
        "archiveAfter21Days": false,
        "consentCheck": false,
        "highTemperatureConfirm": false,
        "continuousCoughConfirm": false,
        "lossOfSmellOrTasteConfirm": false,
    })

    const firstLastName = request.contact.firstName === "" ? "" : request.contact.firstName + " " + request.contact.lastName;

    const [theseOccasions, setTheseOccasions] = useState([])
    const [selectedRoom, setSelectedRoom] = useState(0)

    if (!context) return null;

    const { occasionData, bookings, resetWidgetData } = context;
    // const [venueSelect, setVenueSelect] = useState([])

    const occasionFromVenueId = (vId) => {
        const room = context.rooms.find(r => r.venueId === vId);
        const result = context.occasionBaseData.filter(o => o.details.some(d => d.resources.some(r => r.resourceid === room.id)))
        if (result.length === 1) {
            const newRequest = {
                ...request,
                "venueId": vId,
                "occasionId": result[0].id,
            }
            setRequest(newRequest)
            setTheseOccasions(result)
        } else {
            setTheseOccasions(result)
        }
    }


    // Set Booking reference
    const getBookingReference = event => {
        if (event) {
            event.preventDefault();
            const reference = event.target.value;
            const newRequest = {
                ...request,
                "bookingReference": reference,
            }
            setRequest(newRequest)
        }

    }

    // Set venueId
    const getVenueId = () => {
        // if (!event){
        // if only one room select the venueId
        if (context.venue.length === 1) {
            occasionFromVenueId(context.venue[0].id)
            bookingOptions(context.venue[0].id)
        }
        // If Multiple rooms set flag to show selector
        if (context.venue.length > 1 && context.occasionBaseData.length > 1) {
            const newChecks = {
                ...checks,
                "multiVenue": true,
            }
            setChecks(newChecks)
        }
        if (context.occasionBaseData.length === 1 && !checks.selectRoom) {
            const newChecks = {
                ...checks,
                "selectRoom": true,
            }
            setChecks(newChecks)
        }
        // }
        // if (event) {
        //     const id = event.target.value;
        //     let vId = context.rooms.filter(room => room.id === id);
        //     const newRequest = {
        //         ...request,
        //         "venueId": vId[0].venueId,
        //     }
        //     setRequest(newRequest)
        //     bookingOptions()
        // }
    }

    const setVenueId = event => {
        const vId = event.target.value
        const newRequest = {
            ...request,
            "venueId": vId,
        }
        setRequest(newRequest)
        occasionFromVenueId(vId)
        bookingOptions(vId)
    }

    const setVenueFromRoom = event => {
        const room = context.rooms.find(r => r.id === event.target.value)
        const newRequest = {
            ...request,
            "venueId": room.venueId,
        }
        setRequest(newRequest)
        setSelectedRoom(event.target.value)
        occasionFromVenueId(room.venueId)
        bookingOptions(room.venueId)
    }

    const setOccasion = event => {
        const newRequest = {
            ...request,
            "occasionId": event.target.value,
        }
        setRequest(newRequest)
    }

    if (request.venueId === 0 && !checks.multiVenue) {
        getVenueId()
    }

    async function localCustomerCheckin() {
        await context.customerCheckin(request)

    }

    function handleNext(event) {
        event.preventDefault();
        if (request.contact.email.length < 5 || request.venueId === 0 || request.contact.firstName.length < 1) {
            const newChecks = {
                ...checks,
                "alert": true,
            }
            setChecks(newChecks)
            return null
        }
        if (request.bookingReference === "notBooked") {
            let newRequest = {
                ...request,
                "bookingReference": null,
            }
            setRequest(newRequest)
        }

        if (context.performCovidChecks)
        {
            setChecks({
                ...checks,
                "step": 2,
            })
        }
        else
        {
            localCustomerCheckin();
        }
    }

    function handleSubmit(event) {
        event.preventDefault();
        setChecks({
            ...checks,
            "loadingCheckin": true,
        })

        // Check Email & Covid-19 checks are true
        if (request.contact.email.length < 5 || !request.highTemperatureConfirm || !request.continuousCoughConfirm || !request.lossOfSmellOrTasteConfirm || request.venueId === 0) {
            const newChecks = {
                ...checks,
                "alert": true,
            }
            setChecks(newChecks)
            return null
        }
        if (request.highTemperatureConfirm && request.continuousCoughConfirm && request.lossOfSmellOrTasteConfirm) {
            localCustomerCheckin()
        }
    }

    function handleChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        if (name === "fullName") {
            let first = value.split(" ")[0];
            let last = value.split(" ")[1];
            setRequest({
                ...request,
                contact: {
                    ...request.contact,
                    "firstName": first,
                    "lastName": last,
                }
            });

        } else if (name === "mobile" || name === "email") {
            setRequest({
                ...request,
                contact: {
                    ...request.contact,
                    [name]: value
                }
            });
        } else if (name === "enquiryCompany") {
            setRequest({
                ...request,
                contact: {
                    ...request.contact,
                    enquiryCompany: {
                        ...request.contact.enquiryCompany,
                        "name": value
                    }

                }
            });
        } else if (name === "consentCheck") {
            if (request.consentCheck === false) {
                setRequest({
                    ...request,
                    contact: {
                        ...request.contact,
                        allowMarketing: true,
                        allow3rdPartyMarketing: true,
                    },
                    archiveAfter21Days: false,
                    consentCheck: true
                });
            } else {
                setRequest({
                    ...request,
                    contact: {
                        ...request.contact,
                        allowMarketing: false,
                        allow3rdPartyMarketing: false,
                    },
                    archiveAfter21Days: true,
                    consentCheck: false
                });
            }
        } else {
            setRequest({
                ...request,
                [name]: value
            });
        }
    };

    function handleCheckOut(event) {
        event.preventDefault();
        setChecks({
            ...checks,
            "loadingCheckOut": true,
        })
        const data = context.checkInOutData;
        context.customerCheckOut(data.widgetKey, data.checkOutKey)
    }

    function instantCheckOut() {
        const widgetKey = window.location.pathname.substring(1)
        context.customerCheckOut(widgetKey, checkOutKey)
    }

    function bookingOptions(vId) {
        const theseBookings = bookings.filter(b => b.venueId === vId)
        if (theseBookings.length === 0) {
            if (request.bookingReference !== 0) {
                const newRequest = {
                    ...request,
                    "bookingReference": 0,
                    "venueId": vId,
                }
                setRequest(newRequest)
            }

            return (
                <Fragment>
                </Fragment>
            )
        }
        return (
            <Fragment>
                <Select
                    variant="outlined"
                    id="booking"
                    name="booking"
                    fullWidth
                    style={{ marginTop: '20px' }}
                    value={request.bookingReference}
                    onChange={getBookingReference}
                >
                    <MenuItem value="0" disabled>
                        {t("switch.widget.checkin.choosebooking")}
                    </MenuItem>
                    {theseBookings.map(booking => {
                        return <MenuItem key={booking.id} value={booking.id}>{booking.contact.lastName} {"- (" + booking.startTime.substring(0, booking.startTime.length - 3)}-{booking.endTime.substring(0, booking.endTime.length - 3) + ")"} </MenuItem>
                    })}
                    <MenuItem value="notBooked">
                        {t("switch.widget.checkin.notbooked")}
                    </MenuItem>
                </Select>
            </Fragment>
        );
    }

    if (checkOutKey.length > 1 && checkOutKey.toLowerCase().indexOf(window.location.pathname.substring(1)) === -1) {
        return (
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <div className={classes.paper}>
                    {context.widgetTheme === null ?
                        (<Avatar className={classes.avatar}>
                            <DoneOutlinedIcon />
                        </Avatar>
                        ) : (
                            <img src={context.widgetTheme.logoImage.imageUrl} className={classes.logo} />)
                    }
                    <Typography component="h1" variant="h5">
                        {occasionData[0].name}
                    </Typography>
                    {typeof context.checkInOutData.checkOutTime === 'undefined' ?
                        <Fragment>
                            <Typography component="h1" variant="h6">
                                {t("switch.widget.checkin.clicktocheckout")}
                            </Typography>
                            <Button
                                type="submit"
                                fullWidth
                                id="btn-checkout"
                                variant="contained"
                                color="primary"
                                style={{ backgroundColor: `${theme.palette.custom.primary}`, color: `${theme.palette.type === 'light' ? theme.palette.colors.white : theme.palette.colors.black}` }}
                                className={classes.submit}
                                onClick={instantCheckOut}
                            >
                                {!checks.loadingCheckOut ? t("switch.widget.checkin.checkout") : t("switch.widget.checkin.loading")}
                            </Button>
                        </Fragment>
                        :
                        <Fragment>
                            <Typography component="h1" variant="h6">
                                {t("switch.widget.checkin.checkoutconfirm")}
                            </Typography>
                            <Typography component="p" variant="p">
                                {t("switch.widget.checkin.thanks")}
                            </Typography>
                        </Fragment>}
                </div>
                <Box mt={4}>
                    <Copyright context={context} />
                </Box>
            </Container>
        );
    }

    // ERROR RETURN
    if (context.checkInOutData.status) {
        return (
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <div className={classes.paper}>
                    {context.widgetTheme === null ?
                        (<Avatar className={classes.avatar}>
                            <DoneOutlinedIcon />
                        </Avatar>
                        ) : (
                            <img src={context.widgetTheme.logoImage.imageUrl} className={classes.logo} />)
                    }
                    <Typography component="h1" variant="h5" style={{ textAlign: 'center' }}>
                        {t("switch.widget.checkin.error")}
                    </Typography>
                    <Fragment>
                        <Typography component="p" variant="p">
                            {context.checkInOutData.title} {context.checkInOutData.title}
                        </Typography>
                        <Typography component="p" variant="p">
                            TraceId: {context.checkInOutData.traceId}
                        </Typography>

                        <Button
                            type="submit"
                            fullWidth
                            id="errorReset"
                            variant="contained"
                            color="error"
                            style={{ backgroundColor: `${theme.palette.error.main}`, color: `${theme.palette.type === 'light' ? theme.palette.colors.white : theme.palette.colors.black}` }}
                            className={classes.submit}
                            onClick={e => {
                                e.preventDefault();
                                setChecks({ ...checks, "step": 1, "loadingCheckin": false, })
                                resetWidgetData()
                            }}
                        >
                            {t("switch.widget.buttons.reset")}
                        </Button>
                    </Fragment>
                </div>
                <Box mt={4}>
                    <Copyright context={context} />
                </Box>
            </Container>
        )
    }

    // Normal Return
    if (typeof context.checkInOutData.checkOutKey === 'undefined') {
        return (
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <div className={classes.paper}>
                    {context.widgetTheme === null ?
                        (<Avatar className={classes.avatar}>
                            <DoneOutlinedIcon />
                        </Avatar>
                        ) : (
                            <img src={context.widgetTheme.logoImage.imageUrl} className={classes.logo} />)
                    }
                    <Typography component="h1" variant="h5">
                        {context.name}
                    </Typography>
                    {checks.step === 1 &&
                        <form className={classes.form} noValidate>
                            <Typography component="p" variant="p" dangerouslySetInnerHTML={{ __html: context.description }} />
                            {checks.multiVenue &&
                                <Select
                                    variant="outlined"
                                    id="venueId"
                                    name="venueId"
                                    fullWidth
                                    value={request.venueId}
                                    onChange={setVenueId}
                                    autoFocus
                                >
                                    <MenuItem value="0" disabled>
                                        {t("switch.widget.checkin.selectvenue")}
                                    </MenuItem>
                                    {context.venue.map(venue => {
                                        return <MenuItem key={venue.id} value={venue.id}>{venue.name}</MenuItem>
                                    })}

                                </Select>
                            }
                            {context.occasionBaseData.length > 1 &&
                                <Select
                                    variant="outlined"
                                    id="occasion"
                                    name="occasion"
                                    fullWidth
                                    value={request.occasionId}
                                    onChange={setOccasion}
                                    style={{ marginTop: '16px' }}
                                >
                                    <MenuItem value="0" disabled>
                                        {t("switch.widget.checkin.visitpurpose")}
                                    </MenuItem>
                                    {theseOccasions.map(occasion => {
                                        return <MenuItem key={occasion.id} value={occasion.id}>{occasion.name}</MenuItem>
                                    })}

                                </Select>
                            }

                            {context.occasionBaseData.length === 1 && checks.selectRoom &&
                                <Select
                                    variant="outlined"
                                    id="room"
                                    name="room"
                                    fullWidth
                                    value={selectedRoom}
                                    onChange={setVenueFromRoom}
                                    autoFocus
                                >
                                    <MenuItem value="0" disabled>
                                        {t("switch.widget.checkin.selectroom")}
                                    </MenuItem>
                                    {context.rooms.map(room => {
                                        return <MenuItem key={room.id} value={room.id}>{room.name}</MenuItem>
                                    })}

                                </Select>
                            }
                            {bookings.length > 0 && request.venueId > 0 &&
                                bookingOptions(request.venueId)
                            }
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="email"
                                label={t("switch.widget.checkin.email")}
                                name="email"
                                autoComplete="email"
                                onChange={handleChange}
                                defaultValue={request.email}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="fullName"
                                label={t("switch.widget.checkin.fullname")}
                                name="fullName"
                                autoComplete="name"
                                onChange={handleChange}
                                defaultValue={firstLastName}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="enquiryCompany"
                                label={t("switch.widget.checkin.company")} 
                                name="enquiryCompany"
                                autoComplete="company"
                                onChange={handleChange}
                                defaultValue={request.contact.enquiryCompany.name}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="mobile"
                                label={t("switch.widget.checkin.mobile")} 
                                name="mobile"
                                autoComplete="mobile"
                                onChange={handleChange}
                                defaultValue={request.phone}
                            />
                            <FormControlLabel
                                control={<Checkbox color="secondary" name="consentCheck" id="consentCheck" checked={request.consentCheck} onChange={handleChange} style={{ color: `${theme.palette.custom.primary}` }} />}
                                label={t("switch.widget.checkin.consent")} 
                            />
                            <Button
                                type="submit"
                                fullWidth
                                id="btn-next"
                                variant="contained"
                                color="primary"
                                style={{ backgroundColor: `${theme.palette.custom.primary}`, color: `${theme.palette.type === 'light' ? theme.palette.colors.white : theme.palette.colors.black}` }}
                                className={classes.submit}
                                onClick={handleNext}
                            >
                                {t("switch.widget.checkin.next")} 
                            </Button>
                            {checks.alert &&
                                <Fragment>
                                    {request.contact.email < 5 &&
                                        <Fragment>
                                            <Paper variant="outlined" elevation={3} style={{ backgroundColor: '#f44336', color: '#FFF' }}><ReportProblemOutlinedIcon /> {t("switch.widget.checkin.enteremail")} </Paper>
                                            <br />
                                        </Fragment>
                                    }
                                    {request.contact.firstName < 1 &&
                                        <Fragment>
                                            <Paper variant="outlined" elevation={3} style={{ backgroundColor: '#f44336', color: '#FFF' }}><ReportProblemOutlinedIcon />  {t("switch.widget.checkin.enterename")}</Paper>
                                            <br />
                                        </Fragment>
                                    }
                                    {request.venueId === 0 &&
                                        <Fragment>
                                            <Paper variant="outlined" elevation={3} style={{ backgroundColor: '#f44336', color: '#FFF' }}><ReportProblemOutlinedIcon />  {t("switch.widget.checkin.selectvenue")}</Paper>
                                            <br />
                                        </Fragment>
                                    }
                                    {request.occasionId === 0 &&
                                        <Fragment>
                                            <Paper variant="outlined" elevation={3} style={{ backgroundColor: '#f44336', color: '#FFF' }}><ReportProblemOutlinedIcon /> {t("switch.widget.checkin.visitpurpose")}</Paper>
                                            <br />
                                        </Fragment>
                                    }
                                </Fragment>
                            }
                        </form>
                    }
                    {checks.step === 2 &&
                        <form className={classes.form} noValidate>
                            <Typography component="p" variant="h6">
                                {t("switch.widget.checkin.covidlabel1")}<span className="classes.bold"> {t("switch.widget.checkin.covidlabel2")}</span>  {t("switch.widget.checkin.covidlabel3")}
                            </Typography>
                            <FormControlLabel
                                control={<Checkbox color="secondary" name="highTemperatureConfirm" id="highTemperatureConfirm" required checked={request.highTemperatureConfirm} onChange={handleChange} style={{ color: `${theme.palette.custom.primary}` }} />}
                                label={t("switch.widget.checkin.covidtemp")}
                            /><br />
                            <FormControlLabel
                                control={<Checkbox color="secondary" name="continuousCoughConfirm" id="continuousCoughConfirm" required checked={request.continuousCoughConfirm} onChange={handleChange} style={{ color: `${theme.palette.custom.primary}` }} />}
                                label={t("switch.widget.checkin.covidcough")}
                            />
                            <FormControlLabel
                                control={<Checkbox color="secondary" name="lossOfSmellOrTasteConfirm" id="lossOfSmellOrTasteConfirm" required checked={request.lossOfSmellOrTasteConfirm} onChange={handleChange} style={{ color: `${theme.palette.custom.primary}` }} />}
                                label={t("switch.widget.checkin.covidsmell")}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                id="btn-chkin"
                                variant="contained"
                                color="primary"
                                style={{ backgroundColor: `${theme.palette.custom.primary}`, color: `${theme.palette.type === 'light' ? theme.palette.colors.white : theme.palette.colors.black}` }}
                                className={classes.submit}
                                onClick={handleSubmit}
                            >
                                {!checks.loadingCheckin ? t("switch.widget.checkin.checkin") : t("switch.widget.checkin.loading")}
                            </Button>
                            {checks.alert &&
                                <Fragment>
                                    {!request.highTemperatureConfirm &&
                                        <Fragment>
                                            <Paper variant="outlined" elevation={3} style={{ backgroundColor: '#f44336', color: '#FFF' }}><ReportProblemOutlinedIcon />{t("switch.widget.checkin.covidalerttemp")}</Paper>
                                            <br />
                                        </Fragment>
                                    }
                                    {!request.continuousCoughConfirm &&
                                        <Fragment>
                                            <Paper variant="outlined" elevation={3} style={{ backgroundColor: '#f44336', color: '#FFF' }}><ReportProblemOutlinedIcon /> {t("switch.widget.checkin.covidalertcough")}</Paper>
                                            <br />
                                        </Fragment>
                                    }
                                    {!request.lossOfSmellOrTasteConfirm &&
                                        <Fragment>
                                            <Paper variant="outlined" elevation={3} style={{ backgroundColor: '#f44336', color: '#FFF' }}><ReportProblemOutlinedIcon /> {t("switch.widget.checkin.covidalertsmell")}</Paper>
                                            <br />
                                        </Fragment>
                                    }
                                </Fragment>
                            }
                        </form>
                    }
                </div>
                <Box mt={4}>
                    <Copyright context={context} />
                </Box>
            </Container>
        );
    }
    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <div className={classes.paper}>
                {context.widgetTheme === null ?
                    (<Avatar className={classes.avatar}>
                        <DoneOutlinedIcon />
                    </Avatar>
                    ) : (
                        <img src={context.widgetTheme.logoImage.imageUrl} className={classes.logo} />)
                }
                <Typography component="h1" variant="h5">
                    {occasionData[0].name}
                </Typography>
                {context.checkInOutData.checkOutTime === "0001-01-01T00:00:00" ?
                    <Fragment>
                        <Typography component="h1" variant="h6">
                            {t("switch.widget.checkin.thankscheckin")}
                        </Typography>
                        <Typography component="p" variant="p">
                            {t("switch.widget.checkin.confirm1")} <br />
                            {t("switch.widget.checkin.confirm2")}
                        </Typography>

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            style={{ backgroundColor: `${theme.palette.custom.primary}`, color: `${theme.palette.type === 'light' ? theme.palette.colors.white : theme.palette.colors.black}` }}
                            className={classes.submit}
                            onClick={handleCheckOut}
                        >
                            {!checks.loadingCheckOut ? t("switch.widget.checkin.checkout") : t("switch.widget.checkin.loading")}
                        </Button>
                    </Fragment>
                    :
                    <Fragment>
                        <Typography component="h1" variant="h6">
                            {t("switch.widget.checkin.checkoutconfirm") }
                        </Typography>
                        <Typography component="p" variant="p">
                            {t("switch.widget.checkin.thanks")}
                        </Typography>
                    </Fragment>}
            </div>
            <Box mt={4}>
                <Copyright context={context} />
            </Box>
        </Container>
    );
}