import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Enquiry from './Enquiry';
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useTranslation } from 'react-i18next'
import { MultiDayFormContextProvider } from '../contexts/useMultiDayForm';

export default function EnquiryButton({ context }) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const { t, i18n } = useTranslation();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    if (!context) return null;

    const { resetWidgetData } = context;


    const handleClickOpen = () => {
        setOpen(true);
        context.createEnquiryData();
    };

    const handleClose = () => {
        if (window.confirm(t('switch.widget.preventcloser'))) setOpen(false);
    };

    const handleComplete = () => {
        if (context.returnURL === null || context.returnURL === "" || context.returnURL === "undefined") {
            resetWidgetData();
            setOpen(false);
        }
        else {
            window.location.replace(context.returnURL);
        }

    };


    return (
        <>
            <Button variant="outlined" style={{ color: `${theme.palette.warning.main}`, border: '2px solid', borderColor: `${theme.palette.warning.main}` }} onClick={handleClickOpen}>{t('switch.widget.buttons.enquiry')}</Button>
            <Dialog id="enquiryDialog" open={open} onClose={handleClose} scroll="body" fullScreen={fullScreen} maxWidth="md">
                <MultiDayFormContextProvider>
                    <Enquiry context={{ handleComplete, handleClose, ...context }} />
                </MultiDayFormContextProvider>
            </Dialog>
        </>
    );


}

