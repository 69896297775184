import React, { Component } from "react";
const { REACT_APP_API_KEY } = process.env;


export default class Api extends Component {
    constructor(props) {
        super(props);
        this.widgetKey = props.widgetKey;
        this.urlRoot = props.urlRoot; //'https://localhost:44345/Dispace/Switch/1.0.0/BookingRequest/
    }

    async generateEnquiry(enquiryRequest) {
        const url = this.urlRoot + "enquiry/" + this.widgetKey;

        console.log(JSON.stringify(enquiryRequest));

        try {
            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify(enquiryRequest),
                headers: {
                    "Content-Type": "application/json",
                    "SWITCH-API-KEY": REACT_APP_API_KEY
                }
            });
            const json = await response.json();
            // console.log('Success:', JSON.stringify(json));
            return json;
        } catch (error) {
            console.error("Error:", error);
        }
    }

    async setupPayment(booking, saveCard, amount, response, paymentId, selectedCard) {
        let url =
            this.urlRoot +
            "PaymentSession/" +
            this.widgetKey +
            "?bookingId=" +
            booking.id; //+ '&saveCard=' + saveCard;

        if (paymentId) {
            url += "&paymentScheduleId=" + paymentId;
        }

        if (selectedCard) {
            url += "&selectedCard=" + selectedCard.toString();
        }

        if (response && response?.length > 0) {
            url += "&responseRoute=" + response
        }
        try {
            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify(amount),
                headers: {
                    "Content-Type": "application/json",
                    "SWITCH-API-KEY": REACT_APP_API_KEY
                }
            });

            if (response.status == 200) {
                const json = await response.json();
                return json;
            } else {
                let json;
                try {
                    json = await response.json();
                } catch (e) {
                    // not json, no big deal
                }
                return {
                    error: {
                        message: (json && json.message) || response.statusText,
                        status: response.status,
                        body: json
                    }
                };
            }
        } catch (error) {
            console.error("Error:", error);
        }
    }

    async saveCardPreferences(contactId, cardId, saveDefault, consentToUse, providerRef, provider) {
        let url =
            this.urlRoot +
            "saveCardPrefernces/" +
            this.widgetKey +
            "?contactId=" +
            contactId +
            "&saveDefault=" +
            saveDefault +
            "&consentToUse=" +
            consentToUse +
            "&providerRef=" +
            providerRef +
            "&provider=" +
            provider;

        if (cardId != null) {
            url = url + "&cardId=" +
                cardId
        }



        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "SWITCH-API-KEY": REACT_APP_API_KEY
                }
            });

            return this.HandleResponse(response);
        } catch (error) {
            console.error("Error:", error);
        }
    }

    async getPaymentStatus(bookingId,paymentId) {
        let url =
            this.urlRoot +
            "getPaymentStatus/" +
            this.widgetKey +
            "?bookingId=" +
            bookingId +
            "&paymentId=" +
            paymentId;

        try {
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "SWITCH-API-KEY": REACT_APP_API_KEY
                }
            });

            return this.HandleResponse(response);
        } catch (error) {
            console.error("Error:", error);
        }
    }

    async MonitorPayment(paymentId) {
        const API_URL = this.urlRoot + 'MonitorPayment/' + this.widgetKey;
        let url = API_URL + '?paymentId=' + paymentId


        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "SWITCH-API-KEY": REACT_APP_API_KEY
            }
        });

        const json = await response.json();

        return json;
    }

    async requestBooking(bookingRequest) {
        const url = this.urlRoot + "booking/" + this.widgetKey;

        console.log(JSON.stringify(bookingRequest));

        try {
            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify(bookingRequest),
                headers: {
                    "Content-Type": "application/json",
                    "SWITCH-API-KEY": REACT_APP_API_KEY
                }
            });
            const json = await response.json();
            // console.log('Success:', JSON.stringify(json));
            return json;
        } catch (error) {
            console.error("Error:", error);
        }
    }

    async getData() {
        if (this.widgetKey != "payresult") {
            const url = this.urlRoot + "widgetSettings/" + this.widgetKey;

            try {
                const response = await fetch(url, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "SWITCH-API-KEY": REACT_APP_API_KEY
                    }
                });
                return this.HandleResponse(response);
            } catch (error) {
                console.error("Error:", error);
            }
        }
    }

    async getResourceRates(resourceList,Occasion,Contact) {
        const url = this.urlRoot + "ResourceRates/" + this.widgetKey;

        const requestBody = {
            ResourceIds: resourceList,
            OccasionId: Occasion,
            ContactEmail: Contact
        }


        try {
            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify(requestBody),
                headers: {
                    "Content-Type": "application/json",
                    "SWITCH-API-KEY": REACT_APP_API_KEY
                }
            });
            return this.HandleResponse(response);
        } catch (error) {
            console.error("Error:", error);
        }
    }

    async getScreenData() {
        const url = this.urlRoot + "DisplayScreen/" + this.widgetKey;

        try {
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "SWITCH-API-KEY": REACT_APP_API_KEY
                }
            });
            return this.HandleResponse(response);
        } catch (error) {
            console.error("Error:", error);
        }
    }

    async EndBooking(id, isBooking) {
        const url =
            this.urlRoot +
            "EndBooking/" +
            this.widgetKey +
            "?id=" +
            id +
            "&isBooking=" +
            isBooking;

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "SWITCH-API-KEY": REACT_APP_API_KEY
                }
            });
            const json = await response.json();
            // console.log('Success:', JSON.stringify(json));
            return json;
        } catch (error) {
            console.error("Error:", error);
        }
    }

    async StartBooking(id, isBooking) {
        const url =
            this.urlRoot +
            "StartBooking/" +
            this.widgetKey +
            "?id=" +
            id +
            "&isBooking=" +
            isBooking;

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "SWITCH-API-KEY": REACT_APP_API_KEY
                }
            });
            const json = await response.json();
            // console.log('Success:', JSON.stringify(json));
            return json;
        } catch (error) {
            console.error("Error:", error);
        }
    }

    async CheckBookingPin(pin) {
        const url =
            this.urlRoot + "ContactLookup/" + this.widgetKey + "?pinCode=" + pin;

        try {
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "SWITCH-API-KEY": REACT_APP_API_KEY
                }
            });
            const json = await response.json();
            // console.log('Success:', JSON.stringify(json));
            return json;
        } catch (error) {
            console.error("Error:", error);
        }
    }

    async AbandonBooking(id, isBooking) {
        const url =
            this.urlRoot +
            "AbandonBooking/" +
            this.widgetKey +
            "?id=" +
            id +
            "&isBooking=" +
            isBooking;

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "SWITCH-API-KEY": REACT_APP_API_KEY
                }
            });
            const json = await response.json();
            // console.log('Success:', JSON.stringify(json));
            return json;
        } catch (error) {
            console.error("Error:", error);
        }
    }

    async ExtendBooking(id, time, isBooking) {
        const url =
            this.urlRoot +
            "ExtendBooking/" +
            this.widgetKey +
            "?id=" +
            id +
            "&time=" +
            time +
            "&isBooking=" +
            isBooking;

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "SWITCH-API-KEY": REACT_APP_API_KEY
                }
            });
            const json = await response.json();
            // console.log('Success:', JSON.stringify(json));
            return json;
        } catch (error) {
            console.error("Error:", error);
        }
    }

    async calculatePrices(bookingRequest) {
        const url = this.urlRoot + "calculateRate/" + this.widgetKey;

        console.log(JSON.stringify(bookingRequest));

        try {
            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify(bookingRequest),
                headers: {
                    "Content-Type": "application/json",
                    "SWITCH-API-KEY": REACT_APP_API_KEY
                }
            });
            const json = await response.json();
            // console.log('Success:', JSON.stringify(json));
            return json;
        } catch (error) {
            console.error("Error:", error);
        }
    }
    async checkAvailability(bookingRequest) {
        const url = this.urlRoot + "checkavailability/" + this.widgetKey;

        console.log(JSON.stringify(bookingRequest));

        try {
            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify(bookingRequest),
                headers: {
                    "Content-Type": "application/json",
                    "SWITCH-API-KEY": REACT_APP_API_KEY
                }
            });
            const json = await response.json();
            // console.log('Success:', JSON.stringify(json));
            return json;
        } catch (error) {
            console.error("Error:", error);
        }
    }
    async amendBooking(bookingRequest) {
        const url = this.urlRoot + "amend/" + this.widgetKey;

        console.log(JSON.stringify(bookingRequest));

        try {
            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify(bookingRequest),
                headers: {
                    "Content-Type": "application/json",
                    "SWITCH-API-KEY": REACT_APP_API_KEY
                }
            });
            const json = await response.json();
            // console.log('Success:', JSON.stringify(json));
            return json;
        } catch (error) {
            console.error("Error:", error);
        }
    }

    async customerCheckin(checkinRequest) {
        const url = this.urlRoot + "GuestCheckIn/" + this.widgetKey;

        console.log(JSON.stringify(checkinRequest));

        try {
            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify(checkinRequest),
                headers: {
                    "Content-Type": "application/json",
                    "SWITCH-API-KEY": REACT_APP_API_KEY
                }
            });
            const json = await response.json();
            return json;
        } catch (error) {
            console.error("Error:", error);
        }
    }

    async customerCheckOut(sourceKey, guestKey) {
        const url = this.urlRoot + "CheckOut/" + sourceKey + "/" + guestKey;
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "SWITCH-API-KEY": REACT_APP_API_KEY
                }
            });
            const json = await response.json();
            return json;
        } catch (error) {
            console.error("Error:", error);
        }
    }

    async portalSignIn(bookingRef, email) {
        const url = this.urlRoot + "portalSettings/" + bookingRef;

        try {
            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify(email),
                headers: {
                    "Content-Type": "application/json",
                    "SWITCH-API-KEY": REACT_APP_API_KEY
                }
            });
            const json = await response.json();
            // console.log('Success:', JSON.stringify(json));
            return json;
        } catch (error) {
            console.error("Error:", error);
        }
    }

    async getPayPageData(bookingRef, payRef) {
        const url = this.urlRoot + "PayPageData/" + bookingRef + "/" + payRef;

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "SWITCH-API-KEY": REACT_APP_API_KEY
                }
            });
            const json = await response.json();
            // console.log('Success:', JSON.stringify(json));
            return json;
        } catch (error) {
            console.error("Error:", error);
        }
    }

    async sendMessage(bookingRef, messageText) {
        const url = this.urlRoot + "message/" + bookingRef;

        try {
            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify(messageText),
                headers: {
                    "Content-Type": "application/json",
                    "SWITCH-API-KEY": REACT_APP_API_KEY
                }
            });
            const json = await response.json();
            // console.log('Success:', JSON.stringify(json));
            return json;
        } catch (error) {
            console.error("Error:", error);
        }
    }

    async guestInvite(bookingRef, inviteData) {
        const url = this.urlRoot + "guestinvite/" + bookingRef;

        try {
            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify(inviteData),
                headers: {
                    "Content-Type": "application/json",
                    "SWITCH-API-KEY": REACT_APP_API_KEY
                }
            });
            const json = await response.json();
            // console.log('Success:', JSON.stringify(json));
            return json;
        } catch (error) {
            console.error("Error:", error);
        }
    }

    async allocateGuestArrivals(bookingRef) {
        const url = this.urlRoot + "guestArrivalAllocation/" + bookingRef;

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "SWITCH-API-KEY": REACT_APP_API_KEY
                }
            });
            const json = await response.json();
            // console.log('Success:', JSON.stringify(json));
            return json;
        } catch (error) {
            console.error("Error:", error);
        }
    }

    async updateGuest(bookingRef, guestData) {
        const url = this.urlRoot + "updateguest/" + bookingRef;

        try {
            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify(guestData),
                headers: {
                    "Content-Type": "application/json",
                    "SWITCH-API-KEY": REACT_APP_API_KEY
                }
            });
            const json = await response.json();
            // console.log('Success:', JSON.stringify(json));
            return json;
        } catch (error) {
            console.error("Error:", error);
        }
    }

    async updateBookingInfo(bookingRef, bookingData) {
        const url = this.urlRoot + "updateBookingInfo/" + bookingRef;

        try {
            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify(bookingData),
                headers: {
                    "Content-Type": "application/json",
                    "SWITCH-API-KEY": REACT_APP_API_KEY
                }
            });
            const json = await response.json();
            // console.log('Success:', JSON.stringify(json));
            return json;
        } catch (error) {
            console.error("Error:", error);
        }
    }

    async calculateRefund(bookingRef, bookingData) {
        const url = this.urlRoot + "calculateRefund/" + bookingRef;

        try {
            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify(bookingData),
                headers: {
                    "Content-Type": "application/json",
                    "SWITCH-API-KEY": REACT_APP_API_KEY
                }
            });
            const json = await response.json();
            // console.log('Success:', JSON.stringify(json));
            return json;
        } catch (error) {
            console.error("Error:", error);
        }
    }

    async cancelBooking(bookingRef) {
        const url = this.urlRoot + "cancel/" + bookingRef;

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "SWITCH-API-KEY": REACT_APP_API_KEY
                }
            });
            return response.status;
        } catch (error) {
            console.error("Error:", error);
        }
    }

    async getResourceAvailability(sourceKey, queryString) {
        const url =
            this.urlRoot + "GetResourceAvailability/" + sourceKey + queryString;

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "SWITCH-API-KEY": REACT_APP_API_KEY
                }
            });
            const json = await response.json();
            return json;
        } catch (error) {
            console.error("Error:", error);
        }
    }

    async readBookingMessages(bookingRef) {
        const url = this.urlRoot + "Readmessage/" + bookingRef;

        try {
            const response = await fetch(url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "SWITCH-API-KEY": REACT_APP_API_KEY
                }
            });
            const json = await response.json();
            // console.log('Success:', JSON.stringify(json));
            return json;
        } catch (error) {
            console.error("Error:", error);
        }
    }

    async answerQuestions(sourceKey, bookingId, questions) {
        let url;
        if (sourceKey) {
            url = this.urlRoot + sourceKey + "?bookingId=" + bookingId;
        } else {
            url = this.urlRoot + bookingId;
        }

        try {
            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify(questions),
                headers: {
                    "Content-Type": "application/json",
                    "SWITCH-API-KEY": REACT_APP_API_KEY
                }
            });
            const json = response.json();
            return json;
        } catch (error) {
            console.error("Error:", error);
        }
    }

    async HandleResponse(response) {
        if (response.status == 200) {
            const json = await response.json();
            return json;
        } else {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                // not json, no big deal
            }
            throw json && json.message;
        }
    }
}
