import React from "react";

import {
    Box, RadioButtonGroup, Text, Button,
} from "grommet";


import { BookingHeader } from "./BookingHeader";
import ContentContainer from "./ContentContainer";
import TimeInput from './TimeInput';
import { Agenda } from './Agenda';
import { SpecialRequests } from "./SpecialRequests";
import { GuestDetailsForm } from './GuestDetailsForm';
import { MenuSelector } from './MenuSelector';
import { ConfirmDialog } from "./confirmDialog";
import { NavHeader } from "./NavHeader";
import { MarketingPreferences } from './MarketingPreferences';
import { Features } from "./Permissions";

const GuestPersonalView = ({ ...rest }) => {

    const { booking, venue, resources, contact, onSave, onLogout, fetchTime, checkPermissions, updated, setUpdated } = rest;

    const [lastTime, setTime] = React.useState(fetchTime);
    const [decline, setDecline] = React.useState(false);
    const [currentGuest, setCurrentGuest] = React.useState(booking && contact ? booking.guestList.filter(x => x.contactId === contact.id)[0] : null);
    //const [status, setStatus] = React.useState(currentGuest ? currentGuest.status.charAt(0).toUpperCase() + currentGuest.status.slice(1) : null);
    const [status, setStatus] = React.useState(currentGuest ? currentGuest.status : null);
    const [updating, setUpdating] = React.useState(false);

    if (!currentGuest && booking && contact) {
        let guest = booking.guestList.filter(x => x.contactId === contact.id)[0];
        setCurrentGuest(guest)
        setStatus(guest.status);
    }

    function updateCurrentGuest(args) {
        setUpdated(false);
        setCurrentGuest(args);
    }

    const attendanceOptions =
        [
            { label: 'Interested', value: 'interested' },
            { label: 'Going', value: 'going' },
            { label: 'Decline', value: 'declined' }
        ];


    //const Announcer = ({ announce, message, mode, role }) => {
    //    React.useEffect(() => {
    //        const timeout = 3000;
    //        announce(message, mode, timeout);
    //    }, [announce, message, mode]);

    //    return (
    //        <Text align="center" role={role} aria-live={mode}>
    //            {message}
    //        </Text>
    //    );
    //};

    if (status === "Declined" && decline) {
        setDecline(false);
    }

    if (updating && fetchTime != lastTime) {

        setTime(fetchTime);
        setCurrentGuest(booking.guestList.filter(x => x.contactId === contact.id)[0])
        setUpdating(false);
    }

    function setAttendanceStatus(value) {

        if (value === attendanceOptions[2].value) {
            setDecline(true);
        }
        else {
            saveStatus(value);
        }


    }

    function saveStatus(value) {


        setStatus(value);

        let updatedGuest = {
            ...currentGuest,
            status: value,
            dateConfirmed: value === attendanceOptions[1].value ? new Date().toISOString() : null
        }


        setCurrentGuest(updatedGuest);

        let ammendedGuestList = booking && contact ? booking.guestList.filter(x => x.contactId != contact.id) : [];
        ammendedGuestList.push(updatedGuest);
        if (onSave) {
            onSave(updatedGuest);
        }
    }

    function setMenuSelections(data) {
        let updatedGuest = {
            ...currentGuest,
            menuSelections: data
        }

        setCurrentGuest(updatedGuest);
    }

    function Save() {
        setUpdating(true);
        if (onSave) {
            onSave(currentGuest);
        }
    }

    const newUser = !currentGuest || !currentGuest.contact ? false : currentGuest.contact.allowMarketing === null && currentGuest.contact.allow3rdPartyMarketing === null;



    function handleContactChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        setCurrentGuest({
            ...currentGuest,
            contact: {
                ...currentGuest.contact,
                [name]: value
            }

        });
    }

    function saveMarketing(data) {
        setUpdating(true);
        setCurrentGuest(data);
        if (onSave) {
            onSave(data);
        }
    }
    //if (updating) {
    //    return (
    //        <Box justify="center" align="center" background="brand" fill>
    //            <Heading>Updating...</Heading>
    //            <AnnounceContext.Consumer>
    //                {announce => <Announcer announce={announce} />}
    //            </AnnounceContext.Consumer>
    //        </Box>
    //    );
    //}


    return (
        <React.Fragment>
            <NavHeader contact={contact} guest onLogout={onLogout} />
            <Box gap="medium" fill background="light-1">
                <MarketingPreferences open={newUser} guest={currentGuest} prompt="Site Preferences?" onSave={saveMarketing} />
                {decline && <ConfirmDialog prompt="Decline Invitation?" onConfirm={() => { saveStatus(attendanceOptions[2].value) }} onCancel={() => { setDecline(false) }} />}
                <Box
                    gap="medium"
                    alignSelf="center"
                    width="xxlarge"
                    background="light-3"
                >
                    <Box gap="medium" alignSelf="center" width="xlarge" pad="medium">
                        <BookingHeader booking={booking} venue={venue} guest />
                        <Box
                            alignSelf="center"
                            gap="large"
                            margin="small"
                            width="xxlarge"
                            direction="row-responsive"
                        >
                            <ContentContainer
                                title="Personal Details"
                                width="large"
                            >

                                <GuestDetailsForm contact={currentGuest ? currentGuest.contact : null} onChange={handleContactChange} enabled={checkPermissions(Features.EDITGUEST)} />

                            </ContentContainer>

                            <ContentContainer
                                title="Attendance Status"
                                width="large"
                                content="Please let us know if you will be attending"
                            >
                                <Box align="center" pad="large">
                                    <RadioButtonGroup
                                        id="rbAttendance"
                                        fill="horizontal"
                                        justify="between"
                                        direction="row"
                                        name="radio"
                                        value={status}
                                        disabled={!checkPermissions(Features.EDITGUEST)}
                                        options={attendanceOptions}
                                        onChange={event => setAttendanceStatus(event.target.value)}
                                    />
                                </Box>
                                {status === attendanceOptions[0].value && <Text size="medium" color="status-ok">We have notified the booking organiser you are interested in attending.  Please return to this page and update your status when you wish to confirm your booking</Text>}
                                {status === attendanceOptions[2].value && <Text size="medium" color="status-warning">Thanks for updating our records.  We have notified the booking organiser you are unbable to attend.</Text>}
                                {status === attendanceOptions[1].value &&
                                    <ContentContainer
                                        title="Allocated Arrival Time"
                                        content="To comply with social distancing measures we have allocated staggered arrival times, Please arrive no earlier than 5 minutes before your allocated time."
                                    >
                                        <TimeInput value={currentGuest.arrivalTime} disabled />
                                    </ContentContainer>
                                }

                            </ContentContainer>
                        </Box>
                        {status === attendanceOptions[1].value &&
                            <React.Fragment>
                                <ContentContainer
                                    title="Food Selections"
                                >
                                    <Box align="center" justify="start" direction="column" height="medium" width="xlarge" overflow="auto" gap="small">
                                        <Box align="start" justify="center" direction="column" flex={false} pad="small">
                                            <MenuSelector guest={currentGuest} onChange={setMenuSelections} booking={booking} resources={resources} enabled={checkPermissions(Features.EDITMENUSELECTIONS)} />
                                        </Box>
                                    </Box>
                                </ContentContainer>
                                <Box
                                    alignSelf="center"
                                    gap="large"
                                    margin="small"
                                    width="xxlarge"
                                    direction="row-responsive"
                                >

                                    <Agenda width="large" booking={booking} canEdit={false} />
                                    <SpecialRequests width="large" onChange={(value) => { updateCurrentGuest({ ...currentGuest, notes: value }) }} value={currentGuest.notes} enabled={checkPermissions(Features.EDITGUESTNOTES)} />

                                </Box>
                            </React.Fragment>
                        }
                        <Box direction="column" justify="start">
                            <Box direction="row-responsive" fill="horizontal" justify="end" size="medium">
                                {updated && <Text size="medium" color="status-ok" style={{marginBottom: '10px', marginRight: '13px'}}>Booking updated!</Text>}
                            </Box>
                            <Box direction="row-responsive" fill="horizontal" justify="end" size="medium" margin={{ "bottom": "large" }}  >
                                <Button primary id="btnSave" label="Save Changes" onClick={Save} disabled={booking && currentGuest ? booking.guestList.filter(g => g.id === currentGuest.id)[0] === currentGuest : false} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box >
        </React.Fragment>


    );
}
export { GuestPersonalView };
