import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
    menu: {
        padding: '5% 0px 0px 35%',
    },
    menuLink: {
        fontSize: 12,
        color: 'rgba(0, 0, 0, 0.87)',
    }
}));



export const TakeAwayMenu = props => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    return (
        <React.Fragment>
            {props.data.menu.courses.map(course => {
                return (
                    <Grid item xs={12} >
                        <Typography component="p">
                            <a href={`${props.widgetKey}` + "#" + "course-" + `${course.id}`} className={classes.menuLink}>{course.name}</a>
                        </Typography>
                    </Grid>
                )
            })}
        </React.Fragment>
    );
};

export const TakeAwayMenuContainer = context => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    if (!context.widgetKey) return null;
    const widgetKey = context.widgetKey;

    return (
        <Grid container direction="column" justify="center" alignItems="flex-start" spacing={4} className={classes.menu}>
            <Grid item xs={12} >
                <Typography component="h6" >    {t("switch.widget.takeawaymenu.select")}</Typography>
          </Grid>
            {context.foodAndBeverage.map(menu => {
                return (
                    <TakeAwayMenu widgetKey={widgetKey} data={menu} />
                )
            })}
        </Grid>
    );
};