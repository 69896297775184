import React, { Fragment, useState } from "react";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import { useTranslation } from 'react-i18next';
import { useMultiDayForm } from "../contexts/useMultiDayForm";

export default function Monthly() {

    const { ruleText, dayOfMonth, SetDayOfTheMonth, SetRepeatInterval, interval, monthOption,
        setMonthOption, IEnumMonthOption, weekOfMonth, setWeekOfMonth, selectedDaysOfWeek,
        setSelectedDaysOfWeek } = useMultiDayForm()
    const { t, i18n } = useTranslation();



    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TextField
                    label={t("switch.widget.multidayform.monthlabel")}
                    type="number"
                    inputProps={{ step: 1 }}
                    value={interval}
                    onChange={(e) => SetRepeatInterval(e.target.value)}
                />
            </Grid>

            <Grid item xs={6}>
                <RadioGroup
                    aria-label="monthly-options"
                    name="monthly-options"
                    value={monthOption}
                    onChange={(e) => setMonthOption(e.target.value)}
                >
                    <FormControlLabel
                        value={IEnumMonthOption.DATE}
                        control={<Radio />}
                        label={t("switch.widget.multidayform.ondate")}
                    />
                    <FormControlLabel
                        value={IEnumMonthOption.POSITION}
                        control={<Radio />}
                        label={t("switch.widget.multidayform.onthe")}
                    />
                </RadioGroup>
            </Grid>
            {monthOption === IEnumMonthOption.DATE && (
                <Grid item xs={6}>
                    <TextField
                        type="number"
                        inputProps={{ step: 1, min: 1, max: 31 }}
                        value={dayOfMonth}
                        onChange={(e) => SetDayOfTheMonth(e.target.value)}
                    />
                </Grid>
            )}

            {monthOption === IEnumMonthOption.POSITION && (
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <FormControl>
                                <Select
                                    labelId="week-of-month-label"
                                    id="week-of-month-select"
                                    value={weekOfMonth}
                                    onChange={(e) => setWeekOfMonth(e.target.value)}
                                >
                                    <MenuItem value={1}>First</MenuItem>
                                    <MenuItem value={2}>Second</MenuItem>
                                    <MenuItem value={3}>Third</MenuItem>
                                    <MenuItem value={4}>Fourth</MenuItem>
                                    <MenuItem value={-1}>Last</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl style={{ "maxWidth": "250px" }} >
                                <Select
                                    labelId="days-of-week-label"
                                    id="days-of-week-select"
                                    multiple
                                    value={selectedDaysOfWeek}
                                    onChange={(e) => setSelectedDaysOfWeek(e.target.value)}
                                >
                                    <MenuItem value={0} >Monday</MenuItem>
                                    <MenuItem value={1} >Tuesday</MenuItem>
                                    <MenuItem value={2} >Wednesday</MenuItem>
                                    <MenuItem value={3} >Thursday</MenuItem>
                                    <MenuItem value={4} >Friday</MenuItem>
                                    <MenuItem value={5} >Saturday</MenuItem>
                                    <MenuItem value={6} >Sunday</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            )}

            <div>
                {t("switch.widget.multidayform.settingslabel")} : {ruleText}
            </div>
        </Grid>
    );
}



