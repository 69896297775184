import React, { Fragment } from 'react';
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {
    MuiPickersUtilsProvider,
    TimePicker,
    DatePicker,
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import BookingItemList from './BookingItemList';
import BookingSummary from './BookingSummary';
import BookingButton from './BookingButton';
import Box from '@material-ui/core/Box';
import ProgressButton from "./ProgressButton"
import EnquiryButton from './EnquiryButton';
import DiscountButton from './DiscountButton';
import {useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles';
import BookingItemNotes from './BookingItemNotes';
import EventDetails from "./EventDetails";
import { useTranslation } from 'react-i18next';

const getUnique = (items, value) => {
    return [...new Set(items.map(item => item[value]))];
};

const useStyles = makeStyles(theme => ({
    cardMod: {
        [theme.breakpoints.down('sm')]: {
            margin: '0px',
        },
    },
}));

function Food({ data }) {
    const classes = useStyles();
    const { sortedFood, addBookingDetail, isCompulsoryResource, bookingRequest} = data;

    if (sortedFood.length === 0) {
        return null;
    }
    return (
        <EventDetails details={sortedFood} addBookingDetail={addBookingDetail} isCompulsoryResource={isCompulsoryResource} occasionId={bookingRequest.occasionId} />
    );

}

function Extras({ data }) {
    const classes = useStyles();
    const { sortedExtras, addBookingDetail, isCompulsoryResource, bookingRequest } = data;

    if (sortedExtras.length === 0) {
        return null;
    }
    return (
        <EventDetails details={sortedExtras} addBookingDetail={addBookingDetail} isCompulsoryResource={isCompulsoryResource} occasionId={bookingRequest.occasionId}/>
    );
}


export default function BookingForm(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    if (!props.context) return null;

    const {
        occasionData,
        occasionBaseData,
        bookingRequest,
        setBookingDate,
        handleChange,
        calculatedRate,
        type,
        minDate,
        maxDate,
        selectedGuests,
        selectedStart,
        selectedEnd,
        hideBookingEndTime,
        setStartTime,
        setEndTime,
        setGuestNumbers,
        resetWidgetData,
        removeBookingItem,
        setOccasion,
        calculating,
        addBookingDetailNotes


    } = props.context;


    return (
        <React.Fragment>
        {
                    calculating === true ? (
                    <div style={{zIndex:'99',position: 'absolute'}}>
                    <ProgressButton />
                    </div>) : ('')
                }
        <Card>   
            <CardHeader title="&nbsp;">
            </CardHeader>
            <CardContent>
                    <Grid container justify="flex-start">

                    <Grid item xs={12} md={11}>
                        <BookingItemList context={props.context} />
                    </Grid>
                </Grid>
                <Grid container justify="flex-start">
                    <Grid item xs={0} md={1}>
                    </Grid>
                    <Grid item xs={11}>
                            <Food data={props.context} />
                            <Extras data={props.context} />
                    </Grid>
                </Grid>
                <div>
                    <Divider variant="middle" />
                        {!props.hideDiscount &&
                            <Box display="flex" justifyContent="flex-end">
                            <DiscountButton context={props.context} />
                            </Box>
                        }
                    <BookingSummary rate={calculatedRate} />
                    <Box display="flex" justifyContent="space-evenly" m={2}>
                            <Button variant="outlined" style={{ border: '2px solid', borderColor: `${theme.palette.colors.black}`, color: `${theme.palette.colors.black}` }} onClick={resetWidgetData}> {t("switch.widget.bookingform.reset")}</Button>
                            <EnquiryButton context={props.context} />
                            <BookingButton context={props.context} showTax={false} />
                    </Box>
                </div>
            </CardContent>
        </Card>
        </React.Fragment>
    );

};