
export const Features = Object.freeze(
    {
        "ADDTOBOOKING": 1,
        "EDITAGENDA": 2,
        "AMENDBOOKING": 3,
        "MAKEPAYMENT": 4,
        "CANCELBOOKING": 5,
        "EDITROOM": 6,
        "EDITEQUIPMENT": 7,
        "EDITPARKING": 8,
        'EDITACCOMODATION': 9,
        'CHAT': 10,
        'SENDINVITES': 11,
        'EDITGUEST': 12,
        'EDITMENUSELECTIONS': 13,
        'EDITGUESTNOTES': 14,
        'SIGNCONTRACT': 15,
    })

export default function Permissions(rights,feature_request) {
    let result = false;

    switch (feature_request) {
        case Features.ADDTOBOOKING:
        case Features.EDITAGENDA:
        case Features.AMENDBOOKING:
        case Features.EDITROOM:
        case Features.EDITEQUIPMENT:
        case Features.EDITPARKING:
        case Features.EDITACCOMODATION:
        case Features.SIGNCONTRACT:
            result = rights.editBooking;
            break;
        case Features.MAKEPAYMENT:
            result = rights.makePayment;
            break;
        case Features.CANCELBOOKING:
            result = rights.cancelBooking;
            break;
        case Features.CHAT:
            result = rights.chat;
            break;
        case Features.SENDINVITES:
        case Features.EDITGUEST:
        case Features.EDITMENUSELECTIONS:
        case Features.EDITGUESTNOTES:
            result = rights.manageGuests;
            break;
    }

    return result;
}