import React from "react";
import { Box, Button } from "grommet";
import ContentContainer from "./ContentContainer";
import LayoutCard from './LayoutCard';
import Slider from "react-slick";


export function SelectLayout(props) {
    const { onClick, roomList, onCancel, onBack, newItem } = props;

    if (!roomList || !newItem) return null;
    const resource = roomList.filter(r => r.id === newItem.resourceId)[0];

    if (!resource || !resource.layouts || resource.layouts.length === 0) return null;

    const sliderSettings = {
        dots: true,
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: roomList.length < 3 ? roomList.length : 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        cssEase: "linear"
    };


    let layoutCards = resource.layouts.map((layout, index) => (<Box key={index} pad="small" margin="small">
            <LayoutCard id={"card" + layout.id} layout={layout} height="medium" onClickAddItem={() => { onClick(layout.id); }} />
        </Box>));
    return (
        <Box width="xlarge" pad="small" margin="small">
            <Slider {...sliderSettings}>
                {layoutCards}
            </Slider>
        </Box>
    );
}
