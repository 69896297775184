import React, { Component } from "react";
import StandAlonePayment from '../guestPortalComponents/StandAlonePayment';
import { useNavigate } from "react-router-dom";
import { Box, Grommet, Anchor, Heading } from "grommet";
import { customTheme } from "./theme";
import { PaymentPageContextProvider, usePaymentPage } from "../contexts/PaymentPageContext";
import { PaymentReceived } from "./PaymentReceived";

export function PayPage() {
    const { loading, signedIn, doSignIn, redirect, errorText, payment } = usePaymentPage();
    const navigate = useNavigate();

    if (redirect) {
        navigate("/Booking")
    }

    if (payment && payment?.paymentStatus != "awaitingPayment" && payment?.paymentStatus != "cancelled") {
        return (<PaymentReceived />);
    }

    if (errorText) {
        return (
            <Box justify="center" align="center" background="status-ok" fill>
                <Heading>{errorText}</Heading>
                {payment && payment.receipt ? <Anchor label="view receipt" href={payment.receipt} /> : null}
            </Box>
        );
    }
    if (!loading && !signedIn) {
        doSignIn();
    }
    if (signedIn) {
        return (
            <StandAlonePayment />
        );
    }
    else {

        return (<Box justify="center" align="center" background="black" fill>
            <Heading color="white" >Loading Booking Data</Heading>
        </Box>
        );


    }
}


export default class Widget extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <Grommet theme={customTheme} full>
                <PaymentPageContextProvider >
                    <PayPage />
                </PaymentPageContextProvider>
            </Grommet>)
    };
};

