import React, { useContext } from "react";
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import NotesIcon from '@material-ui/icons/Notes';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CurrencyFormat from './CurrencyFormat';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useTheme } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import BookingItemHelperInfo from './BookingItemHelperInfo';
import Grid from '@material-ui/core/Grid'
import { useWidgetContext } from "../widgetContext";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    popover: {
        pointerEvents: 'none',
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
    itemName: {
        paddingRight: '20px'
    },
}));

const BookingItem = ({ lineitem, onRemove, icon, availabilityHelper }) => {
    const classes = useStyles();
    const theme = useTheme();
    const context = useWidgetContext();
    const { t, i18n } = useTranslation();
    const [fieldVal, setFieldVal] = React.useState(0);

    if (!lineitem) return null;

    const { net, item } = lineitem;
    // console.log(name);

    let ItemEntry = '';
    if (context.style !== 'takeAway') { ItemEntry = context.bookingRequest.Details.filter(x => x.ResourceId === item.id) }


    const defaultVal = (event) => {

        let fieldVal = event.target.valueAsNumber;
        context.addBookingDetail(item.id, item.venueId, fieldVal)

    };

    let roomLayout = false;
    if (item && item.resourceType === 'Room') {
        const roomLayoutDetailFromBookingRequest = context.bookingRequest.Details.filter(detail => detail.ResourceId === item.id)
        if (typeof roomLayoutDetailFromBookingRequest[0].roomLayoutId != 'undefined') {
            const roomDetails = context.sortedRooms.filter(room => room.id === item.id)
            if (roomDetails.length === 0) {
                roomLayout = "None"
            } else {
                roomLayout = roomDetails[0].layouts.filter(layout => layout.id === roomLayoutDetailFromBookingRequest[0].roomLayoutId)
                roomLayout = roomLayout[0].name;
            }
        }
    }

    // Take Away return
    if (context.style === 'takeAway') {
        return (
            <React.Fragment>
                {lineitem.map((lineItem, index) => {
                    return (
                        <ListItem key={index}>
                            <Grid container direction="row" alignItems="center">
                                <Grid item md={1} xs={3}>
                                    <Tooltip title={<Typography color="inherit" style={{ textAlign: 'center' }}>{availabilityHelper}</Typography>}
                                        aria-label={<Typography color="inherit" style={{ textAlign: 'center' }}>{availabilityHelper}</Typography>}>
                                        <ListItemIcon>
                                            {icon}
                                        </ListItemIcon>
                                    </Tooltip>
                                </Grid>
                                <Grid item md={6} xs={9}>
                                    <ListItemText className={classes.itemName}>
                                        {lineItem.name}
                                        <BookingItemHelperInfo item={lineItem} context={context} />
                                    </ListItemText>
                                </Grid>
                                <Grid item md={3} xs={12} style={{ textAlign: 'right' }}>
                                    <CurrencyFormat value={context.showPrices ? lineItem.costPerHead : t('switch.widget.pricing.poa')} prefix="£" matUiVariant="body1" />
                                       <IconButton id={'btn-remove-' + lineItem.id} aria-label="remove" onClick={() => onRemove(lineItem.id)}>
                                        <DeleteIcon color="error" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </ListItem>
                    );
                })}
            </React.Fragment>
        );
    }

    // Normal return
    return (
        <ListItem>
            <Grid container direction="row" alignItems="center">
                <Grid item md={1} xs={3}>
                    <TextField
                        id="standard-number"
                        label=""
                        type="number"
                        value={ItemEntry[0] ? ItemEntry[0].Quantity : '0'}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={defaultVal}
                    />
                </Grid>
                <Grid item md={1} xs={3}>
                    <Tooltip title={<Typography color="inherit" style={{ textAlign: 'center' }}>{availabilityHelper}</Typography>}
                        aria-label={<Typography color="inherit" style={{ textAlign: 'center' }}>{availabilityHelper}</Typography>}>
                        <ListItemIcon>
                            {icon}
                        </ListItemIcon>
                    </Tooltip>
                </Grid>
                <Grid item md={6} xs={6}>
                    <ListItemText className={classes.itemName}>
                        {item.name}
                        <BookingItemHelperInfo item={item} context={context} />
                        {roomLayout && <Typography color="inherit" style={{ fontSize: '9px' }}> {t("switch.widget.bookingitem.layout")}: {roomLayout}</Typography>}
                    </ListItemText>
                </Grid>
                <Grid item md={4} xs={12} style={{ textAlign: 'right' }}>
                    <CurrencyFormat value={net} prefix="£" matUiVariant="body1" />
                    <IconButton id={'btn-remove-' + item.id} aria-label="remove" onClick={() => onRemove(item.id)}>
                        <DeleteIcon color="error" />
                    </IconButton>
                </Grid>
            </Grid>
        </ListItem>
    );
};


export default BookingItem;