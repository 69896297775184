import React from "react";
import { Box, Text, Grid } from "grommet";
import ContentContainer from "./ContentContainer";


export function AttendanceSummary(props) {
    const { booking } = props;

    const Attending = !booking || !booking.guestList ? 0 : booking.guestList.filter(guest => guest.status === "going").length;
    const Interested = !booking || !booking.guestList ? 0 : booking.guestList.filter(guest => guest.status === "interested").length;
    const Declined = !booking || !booking.guestList  ? 0 : booking.guestList.filter(guest => guest.status === "declined").length;
    const NotResponded = !booking || !booking.guestList ? 0 : booking.guestList.filter(guest => guest.status === "invited" || guest.status === "notSelected").length;


    return (<ContentContainer height={{ "min": "400px" }} title="Attendance">


        <Grid areas={[
            { name: 'Invited_label', start: [0, 0], end: [0, 0] },
            { name: 'Invited', start: [1, 0], end: [1, 0] },
            { name: 'Attending_label', start: [0, 1], end: [0, 1] },
            { name: 'Attending', start: [1, 1], end: [1, 1] },
            { name: 'Interested_label', start: [0, 2], end: [0, 2] },
            { name: 'Interested', start: [1, 2], end: [1, 2] },
            { name: 'Declined_label', start: [0, 3], end: [0, 3] },
            { name: 'Declined', start: [1, 3], end: [1, 3] },
            { name: 'NotResponded_label', start: [0, 4], end: [0, 4] },
            { name: 'NotResponded', start: [1, 4], end: [1, 4] },
        ]} columns={['flex', 'flex']} rows={['xxsmall', 'xxsmall', 'xxsmall', 'xxsmall', 'xxsmall']} gap='small'>
            <Box gridArea='Invited_label' justify="center" alignContent="center" weight="bold">
                <Text>Invited:</Text>
            </Box>
            <Box gridArea='Invited' justify="center" alignContent="center" round="xsmall" border={{ color: 'brand', size: 'xsmall' }}>
                <Text alignSelf="center">{!booking || !booking.guestList  ? 0 : booking.guestList.length}</Text>
            </Box>
            <Box gridArea='Attending_label' justify="center" alignContent="center" weight="bold">
                <Text>Attending:</Text>
            </Box>
            <Box gridArea='Attending' justify="center" alignContent="center" round="xsmall" border={{ color: 'brand', size: 'xsmall' }}>
                <Text alignSelf="center">{Attending}</Text>
            </Box>
            <Box gridArea='Interested_label' justify="center" alignContent="center" weight="bold">
                <Text>Interested:</Text>
            </Box>
            <Box gridArea='Interested' justify="center" alignContent="center" round="xsmall" border={{ color: 'brand', size: 'xsmall' }}>
                <Text alignSelf="center">{Interested}</Text>
            </Box>
            <Box gridArea='Declined_label' justify="center" alignContent="center" weight="bold">
                <Text>Declined:</Text>
            </Box>
            <Box gridArea='Declined' justify="center" alignContent="center" round="xsmall" border={{ color: 'brand', size: 'xsmall' }}>
                <Text alignSelf="center">{Declined}</Text>
            </Box>
            <Box gridArea='NotResponded_label' justify="center" alignContent="center" weight="bold">
                <Text>Not Responded:</Text>
            </Box>
            <Box gridArea='NotResponded' justify="center" alignContent="center" round="xsmall" border={{ color: 'brand', size: 'xsmall' }}>
                <Text alignSelf="center">{NotResponded}</Text>
            </Box>
        </Grid>
    </ContentContainer>);
}
