import React from "react";
import ContentContainer from "./ContentContainer";
import { Box, Button, FormField, Tabs, Tab, Layer, TextArea, Footer } from "grommet";
import { CircleInformation, Cafeteria, Notes } from 'grommet-icons';
import { GuestDetailsForm } from "./GuestDetailsForm";
import { MenuSelector } from "./MenuSelector";
import { RichTabTitle } from "./RichTabTitle";
import { Dialog } from './Dialog'

export function EditGuestDialog(props) {
    const { open, editGuest, onCancel, onSave, booking, resources, onChange, canEditGuest, canEditMenu, canEditNotes } = props;

    const [local, setLocals] = React.useState(editGuest);

    if (!open && local) setLocals(null);
    if (open && !local) {
        setLocals(editGuest);
    }

    function handleContactChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const newState =
        {
            ...local,
            contact: {
                ...local.contact,
                [name]: value
            }

        }

        setLocals(newState);
        if (onChange) {
            onChange(newState)
        }
    }

    function handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        const newState = {
            ...local,
            [name]: value
        }

        setLocals(newState);

        if (onChange) {
            onChange(newState)
        }

    }

    if (!open || local === null) return null;

    return (<Dialog id="editGuestDialog" dialogStyle="TabbedEditBox" emoji=":man_in_tuxedo:" title="Edit Guest" onCancel={onCancel} onSave={() => { onSave(local) }} >
        <Box direction="column" justify="between" align="start">
            <Tabs>
                <Tab
                    id="tabGuestInfo"
                    title={
                        <RichTabTitle
                            icon={<CircleInformation color="accent-2" />}
                            label="Guest Info"
                        />
                    }
                >
                    <Box align="center" justify="center" direction="column" gap="small" height="medium" pad="medium">
                            <GuestDetailsForm contact={local.contact} onChange={handleContactChange} enabled={canEditGuest} />
                    </Box>

                </Tab>
                <Tab
                    id="tabMenu"
                    title={
                        <RichTabTitle icon={<Cafeteria color="neutral-2" />} label="Menu Selections" />
                    }
                    pad="small"
                >
                    <Box align="center" justify="start" direction="column" height="medium" width="xlarge" overflow="auto" gap="small" pad="small">
                        <Box align="start" justify="center" direction="column" flex={false} pad="small">
                            <MenuSelector name="menuSelections" guest={local} booking={booking} resources={resources} onChange={(selections) => handleChange({ target: { name: "menuSelections", value: selections } })} enabled={canEditMenu} />
                        </Box>
                    </Box>
                </Tab>
                <Tab
                    id="tabNotes"
                    title={
                        <RichTabTitle icon={<Notes color="neutral-3" />} label="Notes" />
                    }
                >
                    <Box direction="column" height="large" overflow="auto" >

                        <FormField label="Guest Notes">
                            <TextArea id="tiNotes" name="notes" height="medium" placeholder="Please confirm any special dietary or access requirements ..." value={local.notes ? local.notes : ""} onChange={handleChange} disabled={!canEditNotes} />
                        </FormField>
                    </Box>
                </Tab>
            </Tabs>
        </Box>
    </Dialog>);

}

