import React, { Component, useState } from "react";
import ContentContainer from "./ContentContainer";

import { Box, Grid, Text, Button, Anchor } from "grommet";
import matUiFormatDate from "./matUiFormatDate";
import ToolTip from "./ToolTip";
import { CreditCard, Trash, Edit } from 'grommet-icons'
import { DamageDepositInfo } from "./DamageDepositInfo";

export function TwoDecimalPlaces(number) {
    if (!number) {
        number = 0.00
    }

    return (Math.round(number * 100) / 100).toFixed(2);
}


export function TimeFormat(time) {
    if (!time) {
        return ""
    }
    else {
        return (time.substr(0, 5));
    }

}

export const BookingHeader = (props) => {
    const { booking, venue, editButtons, guest, setView, onCancel, onEdit } = props;
    const [damageDepositView, setDamageDepositView] = useState(false)

    if (!booking) {
        return (<ContentContainer
            title="No Booking Found"
        >
            <Text> No Booking Data </Text>
        </ContentContainer>
        );
    }

    return (
        <ContentContainer
            title={"Booking: " + booking.switchURN}
        >

            <Grid
                fill
                areas={[
                    { name: 'bookingRef_label', start: [0, 0], end: [1, 0] },
                    { name: 'bookingRef', start: [3, 0], end: [4, 0] },
                    { name: 'venue_label', start: [5, 0], end: [6, 0] },
                    { name: 'venue', start: [7, 0], end: [11, 0] },


                    { name: 'bookingDate_label', start: [0, 1], end: [1, 1] },
                    { name: 'bookingDate', start: [3, 1], end: [4, 1] },
                    { name: 'start_label', start: [5, 1], end: [6, 1] },
                    { name: 'start', start: [7, 1], end: [8, 1] },
                    { name: 'status_label', start: [9, 1], end: [9, 1] },
                    { name: 'status', start: [10, 1], end: [11, 1] },

                    { name: 'guests_label', start: [0, 2], end: [1, 2] },
                    { name: 'guests', start: [3, 2], end: [4, 2] },
                    { name: 'end_label', start: [5, 2], end: [6, 2] },
                    { name: 'end', start: [7, 2], end: [8, 2] },
                    { name: 'price_label', start: [9, 2], end: [9, 2] },
                    { name: 'price', start: [10, 2], end: [11, 2] },

                    { name: 'damage_deposit_value_label', start: [0, 3], end: [1, 3] },
                    { name: 'damage_deposit_value', start: [3, 3], end: [4, 3] },
                    { name: 'damage_deposit_paid_label', start: [5, 3], end: [6, 3] },
                    { name: 'damage_deposit_paid', start: [7, 3], end: [8, 3] },
                    { name: 'damage_deposit_refunded_label', start: [9, 3], end: [9, 3] },
                    { name: 'damage_deposit_refunded', start: [10, 3], end: [11, 3] },

                ]}
            
                columns={['flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex'] }
                rows={['xxsmall', 'xxsmall', 'xxsmall', 'xxsmall'] }
                gap='small'
            >
                <Box gridArea='nav' background='accent-2' />
                <Box gridArea='main' background='brand' />
                <Box gridArea='side' background='accent-3' />
                <Box gridArea='foot' background='accent-1' />
                <Box gridArea='bookingRef_label' justify="center" alignContent="center" weight="bold" >
                    <Text >Reference:</Text>
                </Box>
                <Box gridArea='bookingRef' justify="center" alignContent="center" round="xsmall" border={{ color: 'brand', size: 'xsmall' }}>
                    <Text alignSelf="center" size="small">{booking.switchURN}</Text>
                </Box>
                <Box gridArea='bookingDate_label' justify="center" alignContent="center" weight="bold" >
                    <Text >Date:</Text>
                </Box>
                <Box gridArea='bookingDate' justify="center" alignContent="center" round="xsmall" border={{ color: 'brand', size: 'xsmall' }}>
                    <Text alignSelf="center" size="small">{matUiFormatDate(booking.bookingDate)}</Text>
                </Box>
                <Box gridArea='start_label' justify="center" alignContent="center" weight="bold" >
                    <Text >Start:</Text>
                </Box>
                <Box gridArea='start' justify="center" alignContent="center" round="xsmall" border={{ color: 'brand', size: 'xsmall' }}>
                    <Text alignSelf="center" size="small">{TimeFormat(booking.startTime)}</Text>
                </Box>
                {!guest && <React.Fragment> <Box gridArea='status_label' justify="center" alignContent="center" weight="bold" >
                    <Text >Status:</Text>
                </Box>
                    <Box gridArea='status' justify="center" alignContent="center" round="xsmall" border={{ color: 'brand', size: 'xsmall' }}>
                        <Text alignSelf="center" size="small">{booking.bookingStatus}</Text>
                    </Box> </React.Fragment>}


                <Box gridArea='venue_label' justify="center" alignContent="center" weight="bold" >
                    <Text >Venue:</Text>
                </Box>
                <Box gridArea='venue' justify="center" alignContent="center" round="xsmall" border={{ color: 'brand', size: 'xsmall' }}>
                    <Text alignSelf="center" size="small">{venue.name}</Text>
                </Box>
                <Box gridArea='guests_label' justify="center" alignContent="center" weight="bold" >
                    <Text >Guests:</Text>
                </Box>
                <Box gridArea='guests' justify="center" alignContent="center" round="xsmall" border={{ color: 'brand', size: 'xsmall' }}>
                    <Text alignSelf="center" size="small">{booking.numberOfGuests}</Text>
                </Box>
                <Box gridArea='end_label' justify="center" alignContent="center" weight="bold" >
                    <Text >End:</Text>
                </Box>
                <Box gridArea='end' justify="center" alignContent="center" round="xsmall" border={{ color: 'brand', size: 'xsmall' }}>
                    <Text alignSelf="center" size="small">{TimeFormat(booking.endTime)}</Text>
                </Box>
                {!guest &&
                    <React.Fragment>
                        <Box gridArea='price_label' justify="center" alignContent="center" weight="bold" >
                            <Text >Price:</Text>
                        </Box>
                        <Box gridArea='price' justify="center" alignContent="center" round="xsmall" border={{ color: 'brand', size: 'xsmall' }}>
                            <Text alignSelf="center" size="small">£ {TwoDecimalPlaces(booking.grossPrice)}</Text>
                        </Box>
                    </React.Fragment>
                }


                {!guest && booking.hasDamageDeposit &&
                    <React.Fragment>
                        <DamageDepositInfo open={damageDepositView} onClose={() => setDamageDepositView(false)} booking={booking} />
                        <Box gridArea='damage_deposit_value_label' justify="center" alignContent="center" weight="bold" >
                            <ToolTip tip="damage deposit details"> <Anchor id="damageDeposit" onClick={() => { setDamageDepositView(true); }}> <Text >Damage Deposit:</Text></Anchor></ToolTip>
                        </Box>
                        <Box gridArea='damage_deposit_value' justify="center" alignContent="center" round="xsmall" border={{ color: 'brand', size: 'xsmall' }}>
                            <Text alignSelf="center" size="small">£ {TwoDecimalPlaces(booking.damageDepositAmount)}</Text>
                        </Box>
                        <Box gridArea='damage_deposit_paid_label' justify="center" alignContent="center" weight="bold" >
                            <Text >Paid:</Text>
                        </Box>
                        <Box gridArea='damage_deposit_paid' justify="center" alignContent="center" round="xsmall" border={{ color: 'brand', size: 'xsmall' }}>
                            <Text alignSelf="center" size="small">£ {TwoDecimalPlaces(booking.damageDepositPaid)}</Text>
                        </Box>
                        <Box gridArea='damage_deposit_refunded_label' justify="center" alignContent="center" weight="bold" >
                            <Text >Refunded:</Text>
                        </Box>
                        <Box gridArea='damage_deposit_refunded' justify="center" alignContent="center" round="xsmall" border={{ color: 'brand', size: 'xsmall' }}>
                            <Text alignSelf="center" size="small">£ {TwoDecimalPlaces(booking.damageDepositRefunded)}</Text>
                        </Box>
                    </React.Fragment>
                }

            </Grid>
            {editButtons && (
                <Box direction="row" justify="end" >

                    {editButtons.canPay && <Box gridArea='cancel' justify="center" alignContent="center" pad="small" width="small" >
                        <ToolTip tip="Make a payment"><Button primary id="btnPayment" label="Payment" onClick={() => { setView(2) }} disabled={booking.outstandingBalance == 0} icon={<CreditCard />} /></ToolTip>
                    </Box>}
                    {editButtons.canCancel && <Box gridArea='cancel' justify="center" alignContent="center" pad="small" width="small" >
                        <ToolTip tip="Cancel Booking"><Button primary id="bh_cancel" label="Cancel" onClick={onCancel} icon={<Trash />} /></ToolTip>
                    </Box>}
                    {editButtons.canEdit && <Box gridArea='edit' justify="center" alignContent="center" pad="small" width="small">
                        <ToolTip tip="Amend Booking"><Button primary id="bh_edit" label="Edit" onClick={onEdit} icon={<Edit />} /></ToolTip>
                    </Box>}
                </Box>)
            }


        </ContentContainer>
    );
}


