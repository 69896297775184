import React, { useCallback, useRef } from 'react';
import { Application, TimePicker } from 'react-rainbow-components';


export default function TimeInput(props) {



    const theme = {
        rainbow: {
            palette: {
                success: '#44d7b6',
                error: '#f14336',
                warning: '#f7b500',
                brand: '#FCD110',
            },
        },
    };

    //const handleChange = useCallback(value => {
    //    value ? input.onChange(value) : input.onChange(null);
    //}, []);

    return (
        <div className="picker" >
            <Application theme={theme}>
            
            <TimePicker {...props} />
            </Application>
        </div >
    )
}