import { useEffect } from 'react'
import { usePayment } from "../contexts/PaymentContext";
import { useTranslation } from "react-i18next"
import Typography from '@material-ui/core/Typography';


const SubmitButton = () => {
    const {
        paymentRecord
    } = usePayment();

    useEffect(() => {
        if (!paymentRecord?.sessionData) return;
        document.redirectToPayment.submit()
    }, [JSON.stringify(!paymentRecord?.sessionData)]);

    return (<></>)

}

export default function WPMRedirect({ context }) {
    const { t, i18n } = useTranslation();
    const {
        processing,
        paymentRecord,
        SetWPMRedirect
    } = usePayment();


    if (processing) {
        return (
            <>
                <Typography align='center' sx={{ p: 2, }}>{t("switch.widget.paymentform.prepare")}</Typography>
            </>
        )
    }
    if (!paymentRecord) return;
    return (<>
        <form name="redirectToPayment" action={paymentRecord.redirectURL} method="post">
            <textarea hidden="hidden" id="testXML" cols="80" rows="20" name="xml">{SetWPMRedirect(paymentRecord?.sessionData)}</textarea>
            <SubmitButton />
        </form>
    </>
    )


};