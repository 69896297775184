import React from "react";
import { Anchor, Box, Image } from "grommet";

export const CustLogo = (props) => {
    const { onHome, portalSettings } = props;
    const logoSource = !portalSettings || !portalSettings.masterLogo ? "https://dispace.blob.core.windows.net/switch/Master/switch_logo.png" : portalSettings.masterLogo;
    return (
        <Anchor
            onClick={onHome}
        >  <Box
            height="xsmall"
            width="xsmall"
            alignSelf="start"
            pad="none"
        >
                <Image
                    fit="contain"
                    src={logoSource} />

            </Box>
        </Anchor>);
};
