import React, { Component } from "react";

import {
    Box, Grommet, grommet, ResponsiveContext, InfiniteScroll, DropButton, Select, Button,
} from "grommet";


import { BookingHeader } from "./BookingHeader";
import ContentContainer from "./ContentContainer";
import { GuestList } from './GuestList';
import { EditGuestDialog } from "./EditGuestDialog";
import { AttendanceSummary } from "./AttendanceSummary";
import { GuestInvitePanel } from "./GuestInvitePanel";
import { Features } from './Permissions';

const ManageGuests = ({ ...rest }) => {

    const { booking, venue, resources, portalSettings, sendGuestInvite, updateGuest, checkPermissions, allocateGuestArrivals} = rest;

    function onEdit(data) {
        updateGuest(data);
        setEditGuest(null);
    }

    const [editGuest, setEditGuest] = React.useState(false);
    return (

        <Box gap="medium" fill background="light-1">
            <Box
                gap="medium"
                alignSelf="center"
                width="xxlarge"
                background="light-3"
            >
                <Box gap="medium" alignSelf="center" width="xlarge" pad="medium">
                    <BookingHeader booking={booking} venue={venue} />
                    <Box
                        alignSelf="center"
                        gap="large"
                        margin="medium"
                        width="xxlarge"
                        direction="row-responsive"
                    >
                        <Box gap="medium" width="xlarge" direction="column">
                            <AttendanceSummary booking={booking} />
                        </Box>
                        <Box gap="medium" width="xlarge" direction="column">
                            <GuestInvitePanel portalSettings={portalSettings} booking={booking} sendGuestInvite={sendGuestInvite} allocateGuestArrivals={allocateGuestArrivals} enabled={checkPermissions(Features.SENDINVITES)} />
                        </Box>
                    </Box>
                    <Box alignSelf="center" width="xlarge" >

                        <ContentContainer
                            title="Guest List"
                        >
                            <Box
                                pad="small"
                                alignSelf="start"
                                margin="medium"
                                justify="evenly"
                                direction="row"
                                fill
                            >
                                <GuestList step={10} guests={!booking ? null : booking.guestList} onEdit={(guestData) => setEditGuest(guestData)} onSave={(data) => { onEdit(data) }} canEdit={checkPermissions(Features.EDITGUEST)} />
                            </Box>
                        </ContentContainer>
                    </Box>
                </Box>
            </Box>
            <EditGuestDialog
                open={ editGuest ?  true : false}
                editGuest={editGuest}
                onCancel={() => setEditGuest(null)}
                onSave={(data) => { onEdit(data) }}
                booking={booking}
                resources={resources}
                canEditGuest={checkPermissions(Features.EDITGUEST)}
                canEditMenu={checkPermissions(Features.EDITMENUSELECTIONS)}
                canEditNotes={checkPermissions(Features.EDITGUESTNOTES)}
            />
        </Box >


    );
}
export { ManageGuests };


