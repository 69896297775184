import React, { Fragment } from "react";
import {
    Button,
    Grid,
    TextField,
    FormControl,
    Box,
} from "@material-ui/core";
import { useMultiDayForm } from "../contexts/useMultiDayForm";

export default function Adhoc() {

    const { adHocDates, AddAdHocDate, UpdateAdHocDates } = useMultiDayForm()

    return (
        <Fragment>

            <Grid container spacing={2}>
                {adHocDates.map((booking, index) => (
                    <Grid container item xs={12} spacing={1} key={index}>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Date"
                                    type="date"
                                    value={booking.bookingDate}
                                    onChange={(e) => UpdateAdHocDates(index, "bookingDate", e.target.value)}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Start Time"
                                    type="time"
                                    value={booking.startTime}
                                    onChange={(e) => UpdateAdHocDates(index, "startTime", e.target.value)}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <TextField
                                    label="End Time"
                                    type="time"
                                    value={booking.endTime}
                                    onChange={(e) => UpdateAdHocDates(index, "endTime", e.target.value)}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
            <Box mt={2}>
                <Button variant="contained" color="primary" onClick={AddAdHocDate}>
                    Add
                </Button>
            </Box>
        </Fragment>
    );
}