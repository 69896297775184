import React, { useEffect } from 'react'
import { Layer } from 'grommet'


export const Toast = (props) => {
    const { children, modal, position, full, ...rest } = props;
    let timeoutAutoHide = null;


    function startAutoHideTimeout(props) {
        const { duration, onClose } = props
        if (duration) {
            timeoutAutoHide = setTimeout(() => {
                onClose()
            }, duration * 1000);
        }
    }

    useEffect(() => {
        startAutoHideTimeout(props);
    })


    //useEffect(() => {
    //    if (timeoutAutoHide) {
    //        clearTimeout(timeoutAutoHide)
    //    }
    //}, []);

    return (
        <Layer
            position={position || 'top'}
            full={full}
            modal={modal}
            margin="none"
            responsive
            plain={modal ? false : true}
            {...rest}
        >
            {children}
        </Layer>
    )
}
