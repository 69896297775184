import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import format from 'date-fns/format';
import CurrencyFormat from './CurrencyFormat';
import BookingSummary from './BookingSummary';
import { useTranslation } from 'react-i18next'
import { useWidgetContext } from '../widgetContext';

const useStyles = makeStyles(theme => ({
    listItem: {
        padding: theme.spacing(1, 0),
    },
    total: {
        fontWeight: '700',
    },
    title: {
        marginTop: theme.spacing(2),
    },
}));


function DateConverter(dateIn) {
    let date = new Date(dateIn);
    const options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
    let dateOut = date.toLocaleDateString('en-GB', options);
    return dateOut;
}


export default function Review() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { booking, calculatedRate } = useWidgetContext();

    if (!booking) return;

    const getName = resourceid => {

        if (typeof calculatedRate === "undefined") {
            return t("switch.widget.review.bookingitem");
        }
        let aList = calculatedRate.lineItems.filter(x => x.item.id === resourceid);
        let found = aList.length > 0 ? true : false;
        if (found) {
            return (aList[0].item.name);
        }
        return t("switch.widget.review.bookingitem");
    };

    const getLineItemPrice = resourceid => {

        if (typeof calculatedRate === "undefined") {
            return t("switch.widget.review.bookingitem");
        }
        let aList = booking.details.filter(x => x.resourceId === resourceid);
        let found = aList.length > 0 ? true : false;
        if (found) {
            return (aList[0].lineItemCost + aList[0].tax);
        }
        return t("switch.widget.review.bookingitem");
    };


    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                {t("switch.widget.review.title")}
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Typography gutterBottom>  {t("switch.widget.review.date")} {DateConverter(booking.bookingDate)}</Typography>
                    <Typography gutterBottom>{t("switch.widget.review.guests")} {booking.numberOfGuests}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography gutterBottom>{t("switch.widget.review.arriving")}  {booking.startTime.substring(0, 5)}</Typography>
                    <Typography gutterBottom>{t("switch.widget.review.departing")}  {booking.endTime.substring(0, 5)}</Typography>
                </Grid>
            </Grid>
            <List disablePadding>
                {booking.details.map(product => (
                    <ListItem className={classes.listItem} key={product.id}>
                        <ListItemText primary={getName(product.resourceId)} secondary={product.notes} />
                        <CurrencyFormat value={getLineItemPrice(product.resourceId)} prefix={t("switch.widget.currancysymbol")} matUiVariant="body2" />
                    </ListItem>
                ))}
            </List>
            <BookingSummary rate={calculatedRate} showTax hideSecureBooking />
            <List disablePadding>
                <Typography variant="h6" gutterBottom>{t("switch.widget.review.payschedule")}</Typography>
                {booking.paymentSchedule.map(payment => (
                    <ListItem className={classes.listItem} key={payment.dueDate}>
                        <ListItemText primary={format(new Date(payment.dueDate), "do MMMM yyyy")} secondary={payment.paymentMethod.name} />
                        <CurrencyFormat value={payment.amount} prefix={t("switch.widget.currancysymbol")} matUiVariant="body2" />
                    </ListItem>
                ))}
            </List>
        </React.Fragment>
    );
}
