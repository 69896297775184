import * as React from 'react'
import { createContext, useContext, useState, useEffect } from 'react'
import { useWidgetContext } from '../widgetContext'
import Api from '../Api';

export const useWidgetResources = () => {
    const context = useWidgetResourcesContext()

    return context
}

export const WidgetResourcesContext = createContext(undefined)

export const WidgetResourcesContextProvider = ({ context, children }) => {

    const { widgetKey, bookingRequest, accommodation, equipment, foodAndBeverage, parking, rooms, tables, showPrices } = useWidgetContext();
    const [resourceRates, SetResourceRates] = useState();
    const [resources, SetResources] = useState([]);

    const occasionId = bookingRequest?.occasionId;
    const contactEmail= bookingRequest?.EnquiryContact?.email;

    const API = new Api(
        {
            urlRoot: window.location.origin + "/Dispace/Switch/1.0.0/BookingRequest/",
            //urlRoot: "https://switchdev.azurewebsites.net/Dispace/Switch/1.0.0/BookingRequest/"
            widgetKey: widgetKey
        });

    // Use Effects

    useEffect(
        () => {
            const resourceList = accommodation.concat(equipment, foodAndBeverage, parking, rooms, tables);
            SetResources(resourceList);
        },
        [accommodation, equipment, foodAndBeverage, parking, rooms, tables]
    );

    useEffect(
        () => {
            if (!showPrices) return;
            if (!resources || resources?.length === 0) return;
            const rlist = resources.map((res, index) => {
                return res?.id
            });

            API.getResourceRates(rlist, occasionId, contactEmail).then((result) => {
                SetResourceRates(result);
            });
        },
        [resources, occasionId, contactEmail]
    );

    // Conext Functions

    function GetResourceBestRate(resourceId) {
        if (!showPrices) return;
        if (!resources) return;
        if (!resourceRates) {
            const resourceRecord = resources.find(r => r.id === resourceId);
            return {
                price: resourceRecord?.price,
                price_type: resourceRecord?.price_type
            }
        }
        else {
            const rateRecord = resourceRates.find(r => r.resourceId === resourceId);
            return rateRecord;
        }
    }


    let contextData = {
        resourceRates,
        GetResourceBestRate,
        ...context,
    }

    return (
        < WidgetResourcesContext.Provider value={contextData} >
            {children}
        </WidgetResourcesContext.Provider >
    )
}

export const useWidgetResourcesContext = () => useContext(WidgetResourcesContext)