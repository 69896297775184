import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import ContactForm from './ContactForm';
import EnquiryReview from './EnquiryReview';
import Loading from "./Loading";
import ProgressButton from './ProgressButton';
import EnquiryForm from './EnquiryForm';
import { useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next';
import MultidayForm from './MultidayForm';
import { useMultiDayForm } from '../contexts/useMultiDayForm';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        height: '750px',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            padding: '20px'
        },
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    stepperLabel: {
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}));

let enquiryRequested = false;

export default function Enquiry({ context }) {
    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const [disable, setDisable] = React.useState(true);
    const { t, i18n } = useTranslation();
    const steps = [t("switch.widget.enquiry.step1"), t("switch.widget.enquiry.step2"), t("switch.widget.enquiry.stepMD"), t("switch.widget.enquiry.step4")];
    const { isMultiDay, GetRepeatDataRequest } = useMultiDayForm()

    function getStepContent(step, context, handleNext) {
        switch (step) {
            case 0:
                enquiryRequested = false;
                context.contactComplete = false;
                return <ContactForm context={{ handleNext, ...context }} />;
            case 1:
                enquiryRequested = false;
                return <EnquiryForm context={context} />;
            case 2:
                if (isMultiDay) {
                    enquiryRequested = false;
                    return <MultidayForm context={context} />;
                } else {
                    setActiveStep(activeStep + 1);
                    return null;
                }
            case 3:
                enquiryRequested = false;
                return <EnquiryReview context={context} />;
            default:
                throw new Error('Unknown step');
        }
    }
    // Disable button in initial render.
    React.useEffect(() => {
        setDisable(true);
    }, []);

    React.useEffect(() => {
        setDisable(perfromStepvalidation());
    }, [context.contactComplete, activeStep]);

    const perfromStepvalidation = () => {
        const { bookingRequest, requestEnquiry } = context;
        switch (activeStep) {
            case 0:
                //                 
                //let booking = requestBooking();
                const { EnquiryContact } = bookingRequest;
                if (EnquiryContact.firstName.length === 0 ||
                    EnquiryContact.lastName.length === 0 ||
                    EnquiryContact.mobile.length === 0 ||
                    EnquiryContact.email.length === 0) {
                    return true;
                }
                return false;
            case 1:

                return false;
            case 2:
                return false;
            case 3:
                return false;
            case 4:

                return false;
        }
    }


    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        const steps = !isMultiDay && activeStep == 3 ? 2 : 1;

        setActiveStep(activeStep - steps);
    };

    const handleSubmit = () => {
        if (!enquiryRequested) {
            if (isMultiDay) {
                const request = GetRepeatDataRequest();
                enquiryRequested = true;
                context.generateEnquiry(request);
            }
            else {
                enquiryRequested = true;
                context.generateEnquiry();
            }
        }
        setActiveStep(activeStep + 1);
    };

    return (
        <React.Fragment>
            <CssBaseline />
            <main className={classes.layout}>
                <Typography component="h1" variant="h4" align="center">
                    {t("switch.widget.enquiry.title")}
                </Typography>
                <Stepper activeStep={activeStep} className={classes.stepper}>
                    {steps.map((label, index) => {
                        if (index === 2 && !isMultiDay) {
                            return null;
                        }
                        return (
                            <Step key={label}>
                                <StepLabel className={classes.stepperLabel}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <React.Fragment>
                    {activeStep === steps.length ? ((typeof context.enquiry === "undefined" || !context.enquiry.hasOwnProperty('id')) ? <Loading /> :
                        <React.Fragment>
                            <Typography variant="h5" gutterBottom>
                                {t("switch.widget.enquiry.thanks")}
                            </Typography>
                            <Typography variant="subtitle1">
                                {t("switch.widget.enquiry.reference")} {context.enquiry.switchURN}. {t("switch.widget.enquiry.nextsteps")}
                            </Typography>
                            <Button
                                id="btn-complete-close"
                                variant="outlined"
                                style={{ color: `${theme.palette.success.main}`, border: '2px solid', borderColor: `${theme.palette.success.main}` }}
                                disabled={disable}
                                onClick={context.handleComplete}
                                className={classes.button}
                            >
                                {t("switch.widget.buttons.close")}
                            </Button>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {getStepContent(activeStep, context, handleNext)}
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="flex-end"
                            >
                                <div className={classes.buttons}>
                                    {activeStep !== 0 && (
                                        <Button onClick={handleBack} className={classes.button}
                                            id="btn-back"
                                            variant="outlined"
                                            style={{ color: `${theme.palette.colors.black}`, border: '2px solid', borderColor: `${theme.palette.colors.black}` }} >
                                            {t("switch.widget.buttons.back")}
                                        </Button>
                                    )}
                                    {activeStep !== steps.length - 1 ? (
                                        <React.Fragment>
                                            <Button
                                                id="btn-close"
                                                variant="outlined"
                                                style={{ color: `${theme.palette.error.main}`, border: '2px solid', borderColor: `${theme.palette.error.main}` }}
                                                onClick={context.handleClose}
                                                className={classes.button}
                                            >
                                                {t("switch.widget.buttons.close")}
                                            </Button>
                                            <Button
                                                id="btn-next"
                                                variant="outlined"
                                                style={{ color: disable ? `${theme.palette.colors.black}` : `${theme.palette.success.main}`, border: '2px solid', borderColor: disable ? `${theme.palette.info.main}` : `${theme.palette.success.main}` }}
                                                disabled={disable}
                                                onClick={handleNext}
                                                className={classes.button}
                                            >
                                                {t("switch.widget.buttons.next")}
                                            </Button>
                                        </React.Fragment>
                                    ) :
                                        enquiryRequested === true ? <ProgressButton /> :
                                            <Button
                                                id="btn-submit"
                                                variant="outlined"
                                                style={{ color: `${theme.palette.success.main}`, border: '2px solid', borderColor: `${theme.palette.success.main}` }}
                                                className={classes.button}
                                                onClick={handleSubmit}
                                            >
                                                {t("switch.widget.buttons.submit")}
                                            </Button>}
                                </div>
                                {context.termsAndConditionsDoc !== null && context.termsAndConditionsDoc.documentUrl != "" ? (
                                    <React.Fragment>
                                        <Typography gutterBottom>
                                            {t("switch.widget.enquiry.submitstatement")}{" "} <Link href={context.termsAndConditionsDoc.documentUrl} target="_blank" color="secondary">{t("switch.widget.enquiry.terms")}</Link>
                                        </Typography>
                                    </React.Fragment>) : (<></>)}
                            </Grid>
                        </React.Fragment>

                    )}
                </React.Fragment>
            </main>
        </React.Fragment>
    );
}
