import React, { Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { NumericFormat } from 'react-number-format';
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react';
import { useMultiDayForm } from '../contexts/useMultiDayForm';
import { useDebounce } from '@switch/core';

export default function EnquiryForm({ context }) {
    const { t, i18n } = useTranslation();
    if (!context) return null;
    const { enquiryRequest, setEnquiryRequest, addPublicDescription } = context
    const { isMultiDay, setisMultiDay } = useMultiDayForm()
    const defaultDescription = !enquiryRequest?.publicEventDescription || enquiryRequest?.publicEventDescription.length <= 0
        ? 'Enquiry - ' + context.bookingRequest.EnquiryContact.firstName + ' ' + context.bookingRequest.EnquiryContact.lastName
        : enquiryRequest?.publicEventDescription;

    const [description, setDescription] = React.useState(defaultDescription);
    const publicDesciption = useDebounce(description, 300)


    useEffect(() => {
        addPublicDescription(publicDesciption);
        enquiryRequest.publicEventDescription = publicDesciption;
        setEnquiryRequest(enquiryRequest);
    }, [publicDesciption])


    useEffect(() => { enquiryRequest.multiday = false }, [])
    const handleChange = event => {
        switch (event.target.name) {
            case "notes":
                enquiryRequest.notes = event.target.value;
                break;
            case "flexible":
                enquiryRequest.flexibleOnDateTime = event.target.checked;
                break;
            case "multiday":
                setisMultiDay(event.target.checked);
                break;
        }
        setEnquiryRequest(enquiryRequest);
    };
    const handleBudgetChange = value => {
        enquiryRequest.budget = value.floatValue;
        setEnquiryRequest(enquiryRequest);
    };

    return (
        <Fragment>
            <Typography>{t("switch.widget.enquiryform.prompt")}</Typography>
            <Typography variant="h6" gutterBottom>{t("switch.widget.enquiryform.title")}</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography>{t("switch.widget.enquiryform.descriptionprompt")}</Typography>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="publicDescription"
                        name="publicDescription"
                        label={t("switch.widget.enquiryform.descriptionlabel")}
                        fullWidth
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="notes"
                        name="notes"
                        label={t("switch.widget.enquiryform.notes")}
                        fullWidth
                        multiline
                        rows="8"
                        value={enquiryRequest.notes}
                        onChange={handleChange}
                        placeholder={t("switch.widget.enquiryform.notesplaceholder")}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControlLabel
                        control={<Checkbox color="secondary" name="flexible" id="flexible" onChange={handleChange} checked={enquiryRequest.flexibleOnDateTime} />}
                        label={t("switch.widget.enquiryform.flexibledates")}
                    />
                    <FormControlLabel
                        control={<Checkbox color="secondary" name="multiday" id="multiday" onChange={handleChange} checked={isMultiDay} />}
                        label={t("switch.widget.enquiryform.multiday")}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography>{t("switch.widget.enquiryform.budget")}</Typography>
                    <NumericFormat allowNegative={false} name="budget" id="budget" customInput={TextField} thousandSeparator={true} prefix={t("switch.widget.currancysymbol")} onValueChange={handleBudgetChange} value={enquiryRequest.budget} />
                </Grid>

            </Grid>
        </Fragment>
    );
}