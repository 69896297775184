import React from "react";
import { Box, Text, Anchor, FormField, Button, RangeInput} from "grommet";
import { FeatureEmoji } from "./emojis/FeatureEmoji";
import ToolTip from './ToolTip';

import { Add, Subtract } from 'grommet-icons';


export function QuantityEditor(props) {
    const { item, onChange, min, max } = props;

    function updateQuanitity(updateValue) {
        setQuantity(item.quantity + updateValue);
    }

    function setQuantity(value) {


        const newItem = {
            ...item,
            quantity: Number.parseInt(value)
        }

        onChange(newItem);
    }

    //<ToolTip tip="Reduce quanitty"><Anchor onClick={() => { setItem({ ...editItem, quantity: editItem.quantity - 1 }); }}> <FeatureEmoji name=":heavy_minus_sign: " size="30px" /> </Anchor></ToolTip>
    //return (<Box align="center" direction="row" gap="small">
    //    <ToolTip tip="Reduce quanitty"><Anchor id="btnMinus" onClick={() => { updateQuanitity(-1) }}> <FeatureEmoji name=":heavy_minus_sign: " size="30px" /> </Anchor></ToolTip>
    //    <Box height="50px" width="50px" border={{ color: 'brand', size: 'small' }} align="center" justify="center">
    //        <Text size="large">{item ? item.quantity : 0}</Text>
    //    </Box>
    //    <ToolTip tip="Increase quanitty"><Anchor id="btnPlus" onClick={() => { updateQuanitity(1) }}> <FeatureEmoji name=":heavy_plus_sign:" size="30px" /> </Anchor></ToolTip>
    //</Box>);
    return (
        <FormField label={item ? "Quantity: " + item.quantity.toString() : "Quantity: 0"}>
            <Box direction="row" align="center" pad="small" gap="small">
                <Button
                    id="btnMinus"
                    plain={false}
                    disabled={!item ? true : item.quantity === min}
                    icon={<Subtract color="neutral-2" />}
                    onClick={() => updateQuanitity(-1)}
                />
                <RangeInput id="qtyEdit" name="quantity" min={min} max={max} value={!item ? 0 : item.quantity} onChange={(event) => { setQuantity(event.target.value) }} />
                <Button
                    id="btnPlus"
                    plain={false}
                    disabled={!item ? true : item.quantity === max}
                    icon={<Add color="neutral-2" />}
                    onClick={() => updateQuanitity(1)}
                />
            </Box>
        </FormField>);
}
