import React, { useState, Fragment } from "react";
import {
    Grid,
    Paper,
    Button,
    Typography,
    Tooltip,
    LinearProgress,
    useMediaQuery,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    InputAdornment
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import EditIcon from "@material-ui/icons/Edit";
import { TimeSelect } from './TimeSelect';
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
    root: {
        display: "inline-flex",
        flexWrap: "nowrap",
        "& > *": {
            margin: "2px",
        }
    },
    progress: {
        width: "100%",
        height: "3px",
        backgroundColor: "#fff",
        "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: theme.palette.custom.secondary
        }
    }
}));

export default function TimeGrid({
    room,
    resAvailability,
    context,
    addBookingDetail,
    size
}) {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [open, setOpen] = useState(false);
    if (!context) return null;
    const { setStartTime, setEndTime, selectedEnd, selectedStart, setBookingDate, bookingRequest } = context;

    const { id, venueId, resourceType } = room;
    const occasion = context.occasionData.find(
        o => o.id === context.bookingRequest.occasionId
    );
    let availability;
    if (resAvailability && resAvailability.availabilities) {
        availability = resAvailability.availabilities.sort(
            (a, b) => (a.startTime > b.startTime ? 1 : -1)
        );
    }
    let times = [];

    function TimeInMinutes(time) {
        const hms = time;
        const a = hms.split(":");
        let minutes = +a[0] * 60 + +a[1];
        return minutes;
    }

    function TimeArray(start, end, booked) {
        times = [];
        const minDurationMinutes = occasion.minDurationMinutes;
        const ap = ["AM", "PM"];
        let st = TimeInMinutes(start);
        let ft = TimeInMinutes(end);
        const fixedPeriods = occasion.minDurationMinutes === occasion.maxDurationMinutes

        //if (ft - st === 1) {
        //    return null
        //}

        // if (timeDif !== undefined && timeDif !== 0) {
        //   st = st + (60 - timeDif);
        // }


        // if (indexCheck) {
        //   ft = ft - occasion.minDurationMinutes;
        // }

        if (!booked) {
            for (var i = 0; st < 24 * 60 && st < ft; i++) {
                var hh = Math.floor(st / 60);
                var mm = st % 60;
                times[i] =
                    ("" + (hh == 12 ? 12 : hh % 12)).slice(-2) +
                    ":" +
                    ("0" + mm).slice(-2) +
                    ap[Math.floor(hh / 12)];
                if (!fixedPeriods  && st % 60 > 0) {
                    st = st + (st % 60)
                } else {
                    st = st + minDurationMinutes;
                }

            }
        } else {
            times[0] = start.slice(0, -3) + ap[Math.floor(Math.floor(st / 60) / 12)];
            times[1] = end.slice(0, -3) + ap[Math.floor(Math.floor(ft / 60) / 12)];
        }
        // if ( (TimeInMinutes(start) - TimeInMinutes(end)) < 59 && times.length < 2 ) {
        //   var hh = Math.floor(ft / 60);
        //   var mm = ft % 60;
        //   times[1] =
        //     ("" + (hh == 12 ? 12 : hh % 12)).slice(-2) +
        //     ":" +
        //     ("0" + mm).slice(-2) +
        //     ap[Math.floor(hh / 12)];
        // }
    }

    function handleClick(event) {
        // event.preventDefault();
        let time = event.target.id;
        time = time.split(":");
        const isPM = time[1].charAt(2);
        if (isPM === "P" && time[0] !== "12") {
            time[0] = Number.parseInt(time[0]) + 12;
        }
        // let newDate = new Date(context.bookingRequest.bookingDate)
        // newDate.setHours(Number.parseInt(time[0]))
        // newDate.setMinutes(Number.parseInt(time[1].substring(0,2)))
        // context.setStartTime(time)
        let newTime = new Date(context.bookingRequest.bookingDate);
        newTime.setHours(Number.parseInt(time[0]));
        newTime.setMinutes(Number.parseInt(time[1].substring(0, 2)));
        //const newTime = Date.parse("01-01-2020T12:00:00");
        setBookingDate(new Date(resAvailability.date));
        setStartTime(newTime);
        if (occasion.minDurationMinutes === occasion.maxDurationMinutes) {
            handleOk();
        } else {
            setOpen(true);
        }
        // setEndTime(newTime.getTime() + (occasion.minDurationMinutes * 60000))
        // addBookingDetail(id, venueId, undefined, undefined, undefined, undefined, undefined, resourceType)
    }

    function handleOk() {
        addBookingDetail(
            id,
            venueId,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            resourceType
        );
    }

    const handleClose = () => {
        setOpen(false);
    };

    const sstart = selectedStart.getTime();
    const eend = selectedEnd.getTime();
    const milliseconds = Math.abs(eend - sstart);
    const seconds = parseInt(milliseconds / 1000);
    const minutes = parseInt(seconds / 60);
    const hours = parseInt(minutes / 60);
    const days = parseInt(hours / 24);
    const time = hours % 24 + ":" + minutes % 60;

    return (
        <Fragment>
            <Dialog
                onClose={handleClose}
                fullScreen={fullScreen}
                maxWidth="xs"
                fullWidth
                aria-labelledby="customized-dialog-title"
                open={open}
                className="DialogBox"
            >
                <DialogTitle id="customized-dialog-title">{t("switch.widget.timegrid.confirmend")}</DialogTitle>
                <DialogContent dividers>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item xs={12}>
                            <TimeSelect id="end-time-picker" label='End Time' value={selectedEnd} defaultValue={selectedEnd} onChange={setEndTime} style={{ margin: 'normal' }} earliest={resAvailability ? resAvailability.openingTime : "05:00"} latest={resAvailability ? resAvailability.closingTime : "24:00"} interval={15} bookingDate={bookingRequest.bookingDate} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>{t("switch.widget.timegrid.duration")} {time}</Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        data-test="dialog-cancel"
                        onClick={handleClose}
                        style={{ border: "1px solid black" }}
                    >
                        {t("switch.widget.buttons.cancel")}
                    </Button>
                    <Button
                        data-test="dialog-ok"
                        onClick={handleOk}
                        variant="outlined"
                        style={{
                            color: `${theme.palette.success.main}`,
                            border: "2px solid",
                            borderColor: `${theme.palette.success.main}`
                        }}
                    >
                        {t("switch.widget.buttons.ok")}
                    </Button>
                </DialogActions>
            </Dialog>

            <div className={classes.root} style={{ width: "100%", height: size == 'small' ? "70px" : "214.88px" }}>
                {!resAvailability && (
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={10}>
                            <Typography variant="h6" style={{ textAlign: "center" }}>
                                {t("switch.widget.timegrid.loading")} 
                            </Typography>
                            <LinearProgress className={classes.progress} />
                        </Grid>
                    </Grid>
                )}
                {resAvailability &&
                    availability.length === 0 && (
                        <Paper
                            elevation={1}
                            style={{ width: "100%", backgroundColor: "#C0C0C0" }}
                        >
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                            >
                                <Grid item xs={12}>
                                    <Typography variant="body2" style={{ textAlign: "center" }}>
                                    {t("switch.widget.timegrid.venueclosed")} 
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    )}
                {resAvailability &&
                    availability.map((a, i) => {
                        // const startTime = TimeInMinutes(a.startTime);
                        // const endTime = TimeInMinutes(a.endTime);
                        const TimeDif = TimeInMinutes(a.startTime) % 60;
                        if (a.availabilityStatus === "availableEnum") {
                            let indexCheck = false;
                            if (i !== availability.length - 1) {
                                indexCheck = true;
                            }
                            TimeArray(a.startTime, a.endTime);
                            return (
                                <React.Fragment key={i}>
                                    {times.map((item, index) => {
                                        return (
                                            <Tooltip
                                                key={index}
                                                title={
                                                    <Typography
                                                        color="inherit"
                                                        style={{ textAlign: "center" }}
                                                    >
                                                        {t("switch.widget.timegrid.starttime")} {item}
                                                    </Typography>
                                                }
                                            >
                                                <Paper
                                                    key={index}
                                                    data-test={"time-slot-" + index}
                                                    id={item}
                                                    elevation={1}
                                                    style={{
                                                        cursor: "pointer",
                                                        width: "7%",
                                                        backgroundColor: "#F0FFF0"
                                                    }}
                                                    onClick={handleClick}
                                                >
                                                    <Typography
                                                        key={index}
                                                        variant="body2"
                                                        style={{ textAlign: "center" }}
                                                    >
                                                        {" "}
                                                        {item}{" "}
                                                    </Typography>
                                                </Paper>
                                            </Tooltip>
                                        );
                                    })}
                                </React.Fragment>
                            );
                        }
                        if (
                            a.availabilityStatus === "bookedEnum" ||
                            a.availabilityStatus === "notAvailableEnum"
                        ) {
                            TimeArray(a.startTime, a.endTime, true);
                            const indexFound = times.length - 1;
                            if (indexFound === -1) return null
                            let endTimeLabel = times[indexFound];
                            if (endTimeLabel.length > 7) endTimeLabel = endTimeLabel.substring(2);

                            let blockLabel = '';

                            switch (size) {
                                case 'small':
                                    blockLabel = times[0];
                                    break;
                                default:
                                    blockLabel = a.availabilityStatus === "bookedEnum"
                                        ? a.description
                                        : t("switch.widget.timegrid.notavailable") ;
                                    blockLabel += ' : ' + times[0] + ' - ' + endTimeLabel
                            }
                            return (
                                <Tooltip
                                    key={indexFound}
                                    title={
                                        <Typography
                                            color="inherit"
                                            style={{ textAlign: "center" }}
                                        >
                                            {a.description + ' : ' + times[0] + ' - ' + endTimeLabel}
                                        </Typography>
                                    }
                                >
                                    <Paper
                                        key={i}
                                        elevation={1}
                                        style={{
                                            width: times.length * 7 + "%",
                                            backgroundColor: "#C0C0C0"
                                        }}
                                    >
                                        <Grid
                                            key={i}
                                            container
                                            direction="row"
                                            justify="center"
                                            alignItems="center"
                                        >
                                            <Grid key={i} item xs={12}>
                                                <Typography
                                                    key={i}
                                                    variant="body2"
                                                    style={{ textAlign: "center" }}
                                                >
                                                    {blockLabel}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Tooltip>
                            );
                        }
                    })}
            </div>
        </Fragment>
    );
}
