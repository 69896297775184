import React from "react";
import BookingItem from './BookingItem';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import { red, green, yellow } from '@material-ui/core/colors';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';


const BookingItemList = ({ context }) => {
    const { t, i18n } = useTranslation();
    if (!context) return null;
    const { calculatedRate, availability, removeBookingItem, addBookingDetailNotes } = context;
    const { lineItems } = calculatedRate;

    const availabilityIcon = id => {
        
        if (typeof availability === "undefined" || !availability.hasOwnProperty('state')) {
            return (<HelpOutlineIcon fontSize="small" style={{ color: yellow[500] }} />);
        }
        let aList = availability.availableResources.filter(extra => extra.id === id);
        let isAvaialble = aList.length > 0 ? true : false;
        if (isAvaialble) {
            return (<CheckCircleOutlineIcon fontSize="small" style={{ color: green[500] }}  />);
        }
        return (<CancelIcon fontSize="small" style={{ color: red[500] }}  />);
    };

    const availabilityHelper = id => {

        if (typeof availability === "undefined" || !availability.hasOwnProperty('state')) {
            return t("switch.widget.bookingitemlist.unknown");
        }
        let aList = availability.unAvailableResources.filter(extra => extra.value.id === id);
        let unAvaialble = aList.length > 0 ? true : false;
        if (unAvaialble) {
            return (aList[0].key);
        }
        return t("switch.widget.bookingitemlist.available");
    };


    if (typeof lineItems === "undefined" || lineItems.length === 0) {
        return (
            <Typography variant="h5" gutterBottom align="center">
                {t("switch.widget.bookingitemlist.noitems")}
          </Typography >
        );
    }
    return (
        <List component="nav" aria-label="booking items" style={{paddingTop: '0px'}}>      
            {lineItems.map(item => {
                return <BookingItem key={item.id} lineitem={item} onRemove={removeBookingItem} icon={availabilityIcon(item.item.id)} availabilityHelper={availabilityHelper(item.item.id)} addBookingDetailNotes={addBookingDetailNotes}  style={{paddingTop: '0px'}}/>;
            })}
        </List>
    );
};

export default BookingItemList;