import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { BrowserRouter, Routes, Route, useNavigate, useMatch } from "react-router-dom";
import { OccasionWrapper } from './OccasionRoute'
import { VenueWrapper } from './VenuesRoute'
import { RoomsWrapper } from './RoomsRoute'
import { ExtrasWrapper } from './ExtrasRoute'
import { PayResult } from "./PayResult";

const drawerWidth = '33%';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        width: '60%',
    },
    logo: {
        width: '134px',
        marginRight: '20px',
        maxHeight: 200,
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginRight: drawerWidth,

    },
    appbar: {
        [theme.breakpoints.up('md')]: {
            padding: '0% 15%'
        },
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center !important',
        },

    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        flexShrink: 0,
        height: "100%",
        disableScrollLock: "true"
    },
    drawerPaper: {
        height: "100%",
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth})`,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        marginBottom: '90px',

    },
    contentFullWidth: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        width: '100%',
        marginBottom: '90px',

    },
    cart: {
        cursor: 'pointer',
        backgroundColor: 'rgb(89,92,95)',
        width: '100%',
        padding: 5,
    },
    lowerContent: {
        maxWidth: '1800px'
    },
    foodBox: {
        paddingTop: '30px',
    },
    title: {
        color: theme.palette.custom.fontStyle === 'light' ? theme.palette.colors.white : theme.palette.colors.black,
    },
    adImage: {
        maxWidth: '500px'
    },
    adBox: {
        textAlign: 'center',
    },
    rootTwo: {
        width: '100%',
        padding: '5% 10%'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    expansionPanel: {
        backgroundColor: theme.palette.custom.primary,
        color: theme.palette.custom.fontStyle === 'light' ? theme.palette.colors.white : theme.palette.colors.black,
    },
    expansionPanelIcon: {
        color: theme.palette.custom.fontStyle === 'light' ? theme.palette.colors.white : theme.palette.colors.black,
    }

}));

function WidgetRoomBooking({ context }) {
    const classes = useStyles();
    //let match = useMatch();
    const navigate = useNavigate();

    if (!context) return null;

    if ((context.bookingRequest.occasionId === null || context.bookingRequest.occasionId === 0) && !window.location.pathname.includes('/occasion') && !window.location.pathname.includes('/payresult')) {
        navigate(`occasion`);
    }
    return (
        <Routes>
            <Route path="occasion" element={<OccasionWrapper context={context} classes={classes} />} />
            <Route path="venue" element={<VenueWrapper context={context} classes={classes} />} />
            <Route path="room" element={<RoomsWrapper context={context} classes={classes} />} />
            <Route path="extras" element={<ExtrasWrapper context={context} classes={classes} />} />
            <Route path="payresult" element={<PayResult context={context} classes={classes} />} />
        </Routes>
    );


}

export default WidgetRoomBooking;
