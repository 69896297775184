import React, { Component } from "react";
import ContentContainer from "./ContentContainer";
import { CurrencyFormat } from "./CurrencyFormat";

import { Box, Grid, Text, Button , Anchor} from "grommet";
import ToolTip from "./ToolTip";



export const AmendmentStatus = (props) => {
    const { booking, onCancel, onSave, loading, rate, availability, goToChat } = props;

    let disableConfirm = loading;
    if (!loading && rate && availability && availability.state === "available") {
        disableConfirm = false;
    } else {
        disableConfirm = true;
    }

    if (!booking) {
        return (<ContentContainer
            title="No Booking Found"
        >
            <Text> No Booking Data </Text>
        </ContentContainer>
        );
    }

    return (
        <ContentContainer
            title="Pending Changes" background="accent-2"
        >

            <Text color="light-1"> You have pending changes for this booking, continue to make any further changes and then submit your request when you are ready</Text>
            {loading && <Text color="light-1"> We are checking our records to ensure you requested amendments are available.</Text>}
            {!loading && availability ?
                availability.state === "available" ? <Text color= "light-1" > We have confirmed all your requests are available</Text> : <Text color="light-1"> There is a problem with some of your requested amendments, please modify the request or contact a member of our team through the {<Anchor onClick={goToChat}> chat </Anchor>}feature to see if we can help</Text> : null}
            {!loading && rate && rate.amendmentRate ?
                rate.amendmentRate.balanceAmendment === 0 ? <Text color="light-1"> No Price Changes for these Changes</Text> :
                    rate.amendmentRate.balanceAmendment > 0 ? <Text color= "light-1"> Additional Costs of {CurrencyFormat(rate.amendmentRate.topUpValue)}</Text> : <Text> Refund of {CurrencyFormat(rate.amendmentRate.refundValue)}</Text>
                : null}



            <Box direction="row" justify="end" >

                <Box gridArea='cancel' justify="center" alignContent="center" pad="small" width="small" >
                    <ToolTip tip="Cancel Amendment"><Button primary id="btnCancel" label="Cancel" onClick={onCancel} /></ToolTip>
                </Box>
                <Box gridArea='edit' justify="center" alignContent="center" pad="small" width="small">
                    <ToolTip tip="Confirm your changes"><Button primary id="btnSave" label="Save Changes" disabled={disableConfirm} onClick={onSave} /></ToolTip>
                </Box>
            </Box>


        </ContentContainer>
    );
}


