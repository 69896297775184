export function ResourceTypeEmojiName(type) {
    switch (type) {
        case "Room":
            return ":european_post_office:";
        case "FoodAndBeverage":
            return ":coffee:";
        case "Equipment":
            return ":microphone:";
        case "Parking":
            return ":parking:";
        case "Accommodation":
            return ":bed:";
    }
}
