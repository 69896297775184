import * as React from 'react'
import { createContext, useContext, useState, useEffect } from 'react'
import { useWidgetContext } from '../widgetContext'
import { RRule } from "rrule";
import GetAdjustedDate from '../library/GetAdjustedDate';
import { useTranslation } from 'react-i18next'

export const useMultiDayForm = () => {
    const context = useMultiDayFormContext()

    return context
}

export const MultiDayFormContext = createContext(undefined)

export const MultiDayFormContextProvider = ({ context, children }) => {
    const widgetContext = useWidgetContext();
    const { enquiryRequest, setEnquiryRequest } = widgetContext;
    const { t, i18n } = useTranslation();

    const IEnumFrequency = {
        "DAILY": "Daily",
        "WEEKLY": "Weekly",
        "MONTHLY": "Monthly",
    };

    const IEnumMonthOption = {
        "DATE": "DATE",
        "POSITION": "POSITION",
    };


    const [isMultiDay, setisMultiDay] = React.useState(false);
    const [repeatType, setRepeatType] = React.useState('block');
    const [repeatFrequency, setRepeatFrequency] = React.useState(IEnumFrequency.DAILY);
    const [monthOption, setMonthOption] = React.useState(IEnumMonthOption.DATE);
    const [startDate, setStartDate] = React.useState();
    const [endDate, setEndDate] = React.useState();
    const [adHocDates, setadHocDates] = React.useState();
    const [ruleText, setRuleText] = useState('');
    const [repeatRule, setRepeatRule] = useState('');
    const [interval, setInterval] = useState(1);
    const [dayOfMonth, setDayOfMonth] = useState(1);
    const [weekOfMonth, setWeekOfMonth] = useState(1);
    const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState([1]);
    const [reviewText, setReviewText] = useState();

    const [dayFilter, setBlockDayFilter] = useState([
        "MO",
        "TU",
        "WE",
        "TH",
        "FR"
    ]);

    const weekDayEnum = {
        "SU": 0,
        "MO": 1,
        "TU": 2,
        "WE": 3,
        "TH": 4,
        "FR": 5,
        "SA": 6
    };

    useEffect(() => {
        if (startDate && endDate) return;
        if (!enquiryRequest?.bookingDate) return;

        const defaultEndDate = _addDay(enquiryRequest?.bookingDate, 1).toISOString()

        const dateString = typeof enquiryRequest?.bookingDate === 'string' ? enquiryRequest?.bookingDate : enquiryRequest?.bookingDate.toISOString();

        setStartDate(dateString.split('T')[0])
        setEndDate(defaultEndDate.split('T')[0])

    }, [enquiryRequest])

    useEffect(() => {
        _updateRuleText()
    }, [repeatType, dayFilter, interval, repeatFrequency, monthOption, dayOfMonth, weekOfMonth, selectedDaysOfWeek,])

    useEffect(() => {
        _updateReview()
    }, [ruleText, startDate, endDate, adHocDates, repeatType])


    useEffect(() => {
        switch (repeatType) {
            case "block":
                enquiryRequest.repeatType = "block";
                break;
            case "repeat":
                enquiryRequest.repeatType = "repeat";
                break;
            case "adHoc":
                enquiryRequest.repeatType = "adHoc";
                break;
        }
        setEnquiryRequest(enquiryRequest);

    }, [repeatType])


    function _addDay(currDate, days) {
        const date = new Date(currDate);
        date.setDate(date.getDate() + days);
        return date;
    }

    function ChangeStartDate(event) {
        setStartDate(event.target.value)
    };

    function ChangeEndDate(event) {
        setEndDate(event.target.value);
    };


    function ChangeMultiDayType(event) {
        setRepeatType(event.target.value);
        if (event.target.value === "adHoc" && !adHocDates) {
            setadHocDates([{ bookingDate: startDate, startTime: enquiryRequest?.startTime, endTime: enquiryRequest?.endTime }])
        }
    };

    function AddAdHocDate() {
        const newBooking = {
            bookingDate: endDate,
            startTime: enquiryRequest?.startTime,
            endTime: enquiryRequest?.endTime
        };
        setadHocDates([...adHocDates, newBooking]);
    };

    function UpdateAdHocDates(index, field, value) {
        const updatedValue = [...adHocDates];
        updatedValue[index][field] = value;
        setadHocDates(updatedValue);
        if (field === "bookingDate") {
            if (new Date(value) > new Date(endDate)) {
                setEndDate(value)
            }

            if (new Date(value) < new Date(startDate)) {
                setStartDate(value)
            }
        }

    };

    //const checkedDaysText = blockDayFilter.map((day) => _dayMap[day]);


    function _dateConverter(dateIn) {
        let date = new Date(dateIn);
        const options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
        let dateOut = date.toLocaleDateString('en-GB', options);
        return dateOut;
    }

    function _updateReview() {
        if (!isMultiDay) return;

        let output = "[0]\r\n[3]\r\nBetween [1] and [2]";

        switch (repeatType) {
            case "block":
                output = output.replace("[0]", t("switch.widget.block.title"))
                output = output.replace("[3]", ruleText)
                break;
            case "repeat":
                output = output.replace("[0]", t("switch.widget.repeat.title"))
                output = output.replace("[3]", ruleText)
                break;
            case "adHoc":
                output = output.replace("[0]", t("switch.widget.adhoc.title"))
                output = output.replace("[3]", "for " + adHocDates?.length + " Dates")
                break;
        }

        output = output.replace("[1]", _dateConverter(startDate))
        output = output.replace("[2]", _dateConverter(endDate))

        setReviewText(output)

    }

    function _updateRuleText() {

        if (repeatType === "block") {

            const bruleOptions = {
                freq: RRule.WEEKLY,
                byweekday: dayFilter.map((day) => RRule[day])
            };

            const blockBookingRule = new RRule(bruleOptions);

            setRuleText(blockBookingRule.toText())
        }

        if (repeatType === "repeat") {

            let rruleOptions = {};

            switch (repeatFrequency) {

                case IEnumFrequency.DAILY:
                    rruleOptions = {
                        freq: RRule.DAILY,
                        interval: interval,
                    };
                    break;
                case IEnumFrequency.WEEKLY:
                    rruleOptions = {
                        freq: RRule.WEEKLY,
                        interval: interval,
                        byweekday: dayFilter.map((day) => RRule[day])
                    };
                    break;

                case IEnumFrequency.MONTHLY:
                    switch (monthOption) {
                        case IEnumMonthOption.DATE:
                            rruleOptions = {
                                freq: RRule.MONTHLY,
                                interval: interval,
                                bymonthday: dayOfMonth
                            };
                            break;
                        case IEnumMonthOption.POSITION:
                            rruleOptions = {
                                freq: RRule.MONTHLY,
                                interval: interval,
                                bysetpos: weekOfMonth,
                                byweekday: selectedDaysOfWeek
                            };
                            break;
                    }


            }

            const repeatBookingRule = new RRule(rruleOptions);

            setRuleText(repeatBookingRule.toText());
            setRepeatRule(repeatBookingRule.toString());
            if (repeatFrequency === IEnumFrequency.MONTHLY && monthOption === IEnumMonthOption.POSITION && selectedDaysOfWeek.length > 0) {
                // There is a known bug in RRULE that NLP does not work when SetPos value is > 1
                // Work Around is to add the number before the ByDay setting i.e. 2MO = 2nd Monday
                // Unsure of impact on other uses so only using this to generate the NLP text
                const ruleString = repeatBookingRule.toString();
                const i = ruleString.indexOf("BYDAY") + 6
                let i2 = ruleString.indexOf(";", i);
                i2 = i2 = -1 ? ruleString.length : i2;
                const daystring = ruleString.substr(i, i2);
                const daylist = daystring.split(",");
                let newDayString = "BYDAY="

                daylist.forEach(d => newDayString += weekOfMonth + d + ",")

                const text = ruleString.substr(0, i - 6) + newDayString.substring(0, newDayString.length - 1) + ruleString.substr(i2)
                const r = new RRule.fromString(text);
                setRuleText(r.toText())
            }
        }

    }

    const UpdateDayFilter = (event) => {
        const day = event.target.name;

        let newFilter = dayFilter;

        if (event.target.checked) {
            newFilter = [...dayFilter, day];
        } else {
            newFilter = dayFilter.filter((d) => d !== day);
        }

        setBlockDayFilter(newFilter)
    };

    function AllowedDay(currDate, allowedWeekDays, filterDays) {
        if (!filterDays) return true;
        const numDayOfWeek = currDate.getDay();

        return !!allowedWeekDays.find(day => weekDayEnum[day] === numDayOfWeek);
    }

    function _getBlockDetail(details, startDate, endDate, dateFilter) {
        let result = []

        for (var d = new Date(startDate); d <= new Date(endDate); d.setDate(d.getDate() + 1)) {


            if (AllowedDay(d, dateFilter, true)) {
                details.forEach(item => {
                    const newDatainstance = {
                        ...item,
                        bookingDate: GetAdjustedDate(d),
                        linkTimesToHeader: true,
                        startTime: enquiryRequest?.startTime,
                        endTime: enquiryRequest?.endTime,
                    };

                    result.push(newDatainstance);
                })
            }

        }

        return result;
    }

    function _getAdHocDetail(details, adHocDates) {
        let result = []

        adHocDates.forEach(d => {
            details.forEach(item => {
                const newDatainstance = {
                    ...item,
                    bookingDate: GetAdjustedDate(d?.bookingDate),
                    linkTimesToHeader: true,
                    startTime: d?.startTime,
                    endTime: d?.endTime,
                };

                result.push(newDatainstance);
            })

        })

        return result;
    }

    function GetRepeatDataRequest() {
        if (!isMultiDay) return enquiryRequest;

        let result = {
            ...enquiryRequest,
            bookingDate: GetAdjustedDate(startDate),
            bookingEndDate: GetAdjustedDate(endDate)
        };

        switch (repeatType) {
            case "block":
                result.repeatInfo = {
                    repeatType: "block"
                }
                result.Details = _getBlockDetail(enquiryRequest?.Details, startDate, endDate, dayFilter);
                break;
            case "adHoc":
                result.repeatInfo = {
                    repeatType: "adHoc",
                    adHocDates: adHocDates,
                }
                result.Details = _getAdHocDetail(enquiryRequest?.Details, adHocDates);
                break;
            case "repeat":
                result.repeatInfo = {
                    repeatType: "repeat",
                    ruleString: repeatRule,
                }
                break;
        }

        return result;
    }

    function SetRepeatInterval(value) {
        const newValue = parseInt(value);
        if (!isNaN(newValue)) {
            setInterval(newValue);
        }
    };

    function SetDayOfTheMonth(dayNumber) {

        const newValue = parseInt(dayNumber);
        if (!isNaN(newValue) && newValue >= 1 && newValue <= 31) {
            setDayOfMonth(newValue);
        }

    }


    let contextData = {
        ...widgetContext,
        IEnumFrequency: IEnumFrequency,
        IEnumMonthOption: IEnumMonthOption,

        adHocDates: adHocDates,
        dayFilter: dayFilter,
        dayOfMonth: dayOfMonth,
        endDate: endDate,
        enquiryRequest: enquiryRequest,
        interval: interval,
        isMultiDay: isMultiDay,
        monthOption, monthOption,
        repeatFrequency: repeatFrequency,
        repeatType: repeatType,
        reviewText: reviewText,
        ruleText: ruleText,
        selectedDaysOfWeek: selectedDaysOfWeek,
        startDate: startDate,
        weekOfMonth: weekOfMonth,


        AddAdHocDate: AddAdHocDate,
        AllowedDay: AllowedDay,
        ChangeStartDate: ChangeStartDate,
        ChangeEndDate: ChangeEndDate,
        ChangeMultiDayType: ChangeMultiDayType,
        GetRepeatDataRequest: GetRepeatDataRequest,
        SetRepeatInterval: SetRepeatInterval,
        SetDayOfTheMonth: SetDayOfTheMonth,
        UpdateAdHocDates: UpdateAdHocDates,
        UpdateDayFilter: UpdateDayFilter,

        setisMultiDay: setisMultiDay,
        setEnquiryRequest: setEnquiryRequest,
        setMonthOption: setMonthOption,
        setRepeatFrequency: setRepeatFrequency,
        setRepeatType: setRepeatType,
        setSelectedDaysOfWeek: setSelectedDaysOfWeek,
        setWeekOfMonth: setWeekOfMonth,

        
        ...context,
    }

    //console.log(contextData)
    return (
        < MultiDayFormContext.Provider value={contextData} >
            {children}
        </MultiDayFormContext.Provider >
    )
}

export const useMultiDayFormContext = () => useContext(MultiDayFormContext)