import React from "react";
import TextField from "@material-ui/core/TextField";
import { useMultiDayForm } from "../contexts/useMultiDayForm";
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

export default function Daily() {
    const { t } = useTranslation();
    const { ruleText, SetRepeatInterval, interval } = useMultiDayForm();



    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TextField
                    label={t("switch.widget.multidayform.daylabel")}
                    type="number"
                    inputProps={{ step: 1 }}
                    value={interval}
                    onChange={(e) => SetRepeatInterval(e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                {t("switch.widget.multidayform.settingslabel")} : {ruleText}
            </Grid>
        </Grid>
    );
}