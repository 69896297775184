import React, { Fragment } from "react";
import Venue from "./Venue";
import { makeStyles } from '@material-ui/core/styles';
import BookingPickersWrapper from './BookingPickersWrapper'; 
import Grid from '@material-ui/core/Grid'
import BackButton from "./BackButton";
import NoSearchResults from "./NoSearchResults";

const useStyles = makeStyles(theme => ({
  root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
  },
  gridList: {
      width: 'auto',
      height: 'auto',
  },
  icon: {
      color: 'rgba(255, 255, 255, 0.54)',
  },
  lowerContent: {
      maxWidth: '422px'
  }
}));

const VenuesList = ({ venue, setVenue}) => {
    const classes = useStyles();


    if (!venue) return <NoSearchResults title={"switch.widget.notfound.norooms"} classes={classes} />;
    return (
        <Grid container direction="row" justify="space-evenly" alignItems="center" spacing={5}>
            <BookingPickersWrapper />
            <BackButton matUiXl="12" margin="0px"/>
            {venue.length === 0 ? 
                <NoSearchResults title={"noRooms"} classes={classes} />
            :
              <Fragment>
                  {venue.map(item => {
                      return (
                      <Grid item xs={12} sm={4} key={item.id} className={classes.lowerContent}>
                          <Venue key={item.id} venue={item} setVenue={setVenue} />
                      </Grid>);
                  })}
              </Fragment>
            }
        </Grid>
    );
};

export default VenuesList;
