import { useCallback, useEffect, useRef } from "react";
import { usePayment } from "../contexts/PaymentContext";
import { Typography } from "@material-ui/core";
export default function HostedPaymentPage({ context }) {


    const {
        redirectURL,
        showIFrame,
        setiFrameResult,
        error,
        iFrameResult,
        monitoring
    } = usePayment();

    var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
    var eventer = window[eventMethod];
    var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

    // Listen to message from child window
    eventer(messageEvent, function (e) {
        var key = e.message ? "message" : "data";
        var data = e[key];
        if (data?.hasOwnProperty("source") && data?.source === "payresult") {
            setiFrameResult(data)
        }
    }, false);


    if (showIFrame) {
        return (
            <iframe class="responsive-iframe" src={redirectURL} frameborder="0" width="100%" height="650px" />
        )
    }
    if (monitoring) {
        return (<Typography>Checking Payment Status ...</Typography>)
    }

    if (iFrameResult) {
        let completionCondition = iFrameResult?.result;
        const reason = !error ? completionCondition : error

        const message = completionCondition === "success" ? "" : "There was a problem with your payment " + ` ${reason}`

        return (
            <Typography>{message}</Typography>
        )
    }


};