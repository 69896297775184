import React, { useState, useEffect } from "react";
import { memo } from "react";
import RoomDetails from './RoomDataPopup';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Grid, Tooltip, Typography } from '@material-ui/core';
import CurrencyFormat from './CurrencyFormat';
import Layouts from './RoomLayoutIcons';
import TimeGrid from './TimeGrid'
import SlideShowComponent from "./SlideShowComponent";
import AvailabilityWeekView from './AvailabilityWeekView';
import { useTranslation } from 'react-i18next'
import { useWidgetResources } from "../contexts/useWidgetResources";

const useStyles = makeStyles(theme => ({
    layoutImage: {
        width: '25px',
        height: '25px',
    },
    room: {
        border: '1px solid',
        borderColor: theme.palette.custom.secondary,
    },
    roomInfoAddToBooking: {
        backgroundColor: theme.palette.custom.secondary,
        color: theme.palette.custom.secondaryStyle === 'light' ? theme.palette.colors.black : theme.palette.colors.white,
    }
}));


const Room = memo(({ room, addBookingDetail, context, resourceAvailability }) => {
    const { t, i18n } = useTranslation();
    if (!room) { return null };
    const { id, venueId, name, slideShow, price, price_type, layouts, resourceType } = room;
    const { GetResourceBestRate } = useWidgetResources();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [rate, setRate] = useState({
        price: price,
        price_type: price_type
    });
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    let notShowAvailabilityCheck;
    if (!context.showAvailabilityCalendar || (context.showAvailabilityCalendar && isMobile)) {
        notShowAvailabilityCheck = true
    } else {
        notShowAvailabilityCheck = false
    }

    let resAvailability;
    if (resourceAvailability && resourceAvailability?.length > 0) {
        resAvailability = resourceAvailability.find(r => r.resourceId === room.id)
    }

    useEffect(
        () => {
            if (!room) return;
            const rate = GetResourceBestRate(room?.id);
            setRate(rate)
        },
        [room]
    );

    // console.log(name);
    return (
        <React.Fragment>
            {notShowAvailabilityCheck &&
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" className={classes.room} id="room">
                    <Grid item xs={12} className="room-info-title">
                        <Typography variant="body1">
                            {name}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className="room-info-container">
                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                            <Grid item xs={6} className="room-img-container">
                                <SlideShowComponent slideShow={slideShow} />
                                {context.showPrices && <div className="price-top">
                                    <CurrencyFormat value={context.showPrices ? price : null} prefix={`${t("switch.widget.from")} ${t("switch.widget.currancysymbol")}`} matUiVariant="body1" />
                                    <p>{price_type === 'Hour' ? t("switch.widget.room.perhour") : `${price_type}`}</p>
                                </div>}
                            </Grid>
                            <Grid item xs={6}>

                            </Grid>
                            <Grid item xs={6} className="room-layout-icon-container">
                                <Layouts layouts={layouts} />
                            </Grid>
                            <Grid item xs={6} className="room-more-info-container">
                                <RoomDetails room={room} addBookingDetail={addBookingDetail} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Tooltip title={<Typography color="inherit" style={{ textAlign: 'center' }}>{t("switch.widget.room.clickaddroom")}</Typography>} aria-label={<Typography color="inherit">{t("switch.widget.room.clickaddroom")}</Typography>}>
                        <Grid item xs={12} id="onClickAdd" className={classes.roomInfoAddToBooking + ' room-info-add-to-booking'} onClick={() => { addBookingDetail(id, venueId, undefined, undefined, undefined, undefined, undefined, resourceType) }}>
                            <Typography component="h6">
                                {t("switch.widget.room.addroom")}
                            </Typography>
                        </Grid>
                    </Tooltip>
                </Grid>
            }
            {context.showAvailabilityCalendar && !isMobile &&
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" className={classes.room} id="room">
                    <Grid item xs={12} className="room-info-title">
                        <Typography variant="body1">
                            {name}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className="room-info-container">
                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                            <Grid item xs={3} className="room-img-container">
                                <SlideShowComponent slideShow={slideShow} />
                                {context.showPrices && <div className="price-top">
                                    <CurrencyFormat value={context.showPrices ? rate?.price : null} prefix={`${t("switch.widget.from")} ${t("switch.widget.currancysymbol")}`} matUiVariant="body1" />
                                    <p>{price_type === 'Hour' ? t("switch.widget.room.perhour") : `${rate?.price_type}`}</p>
                                </div>}
                                <Grid container direction="row" justify="center" alignItems="center">
                                    <Grid item xs={4} className="room-more-info-container">
                                        <Tooltip title={<Typography color="inherit" style={{ textAlign: 'center' }}> {t("switch.widget.room.addtobooking")}</Typography>} aria-label={t("switch.widget.room.addtobooking")}>
                                            <Button id="onClickOpen" data-testid="onClickOpen" onClick={() => addBookingDetail(id, venueId, undefined, undefined, undefined, undefined, undefined, resourceType)} style={{ border: '1px solid black' }}>
                                                {t("switch.widget.room.addroom")}
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={4} className="room-more-info-container">
                                        <RoomDetails room={room} addBookingDetail={addBookingDetail} />
                                    </Grid>
                                    <Grid item xs={4} className="room-more-info-container">
                                        <AvailabilityWeekView room={room} addBookingDetail={addBookingDetail} startDate={context.bookingRequest.bookingDate} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={9} id="timeGrid">
                                <TimeGrid room={room} resAvailability={resAvailability} context={context} addBookingDetail={addBookingDetail} open={open} setOpen={setOpen} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </React.Fragment>
    );
});
export default Room;
