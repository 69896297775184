import React, { Suspense, lazy } from 'react'
import CircularProgress from '@mui/material/CircularProgress';

export const DisplayScreenLoader = () => {
    const DisplayScreens = lazy(() => import("@switch/displayscreens").then(module => ({ default: module.DisplayScreen })));

    return (
        <Suspense fallback={<CircularProgress />}>
            <DisplayScreens />
        </Suspense>
    );

}