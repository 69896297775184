import React from "react";
import { usePayment } from '../contexts/PaymentContext';
import { usePortalManagePayment } from '../contexts/PortalManagePaymentContext';
import CheckoutForm from "./CheckOutForm";
import HostedPaymentPortal from "./HostedPaymentPortal";
import { WindCaveSavedCardCheck } from "./WindCaveSavedCardsCheck";
import PaymentRedirect from "../ClientPortal/Payments/WPM/PaymentRedirect";
import { Loading } from "react-admin";
export default function PaymentForm() {
    const {
        processingType,
        savedCardStepWindCave,
        monitoring
    } = usePayment();

    const portalContext = usePortalManagePayment();

    const SaveBookingWithPendingPayment = portalContext?.SaveBookingWithPendingPayment;

    if (monitoring) return <Loading loadingPrimary={"Waiting for payment provider response"} loadingSecondary="Please wait" />

    const WindCaveSteps = () => {
        if (savedCardStepWindCave) {
            return (<WindCaveSavedCardCheck />)
        } else {
            return (<HostedPaymentPortal />)
        }
    };
    switch (processingType) {

        case "stripe":
            return (
                <CheckoutForm onSuccess={SaveBookingWithPendingPayment} />
            );
        case "pay360SCP":
            return (
                <HostedPaymentPortal />
            );
        case "windCave":
            return (
                <WindCaveSteps />
            );
        case "WPM":
            return (<PaymentRedirect />)
            break;
        default:
            return null;
    }
}

