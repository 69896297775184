import { useCheckout } from "../contexts/useCheckout";
import { useWidgetContext } from "../widgetContext";
import BookingNotes from "./BookingNotes";
import ContactForm from "./ContactForm";
import Loading from "./Loading";
import PaymentForm from "./PaymentForm";
import Review from "./Review";
import SupplementaryQuestions from "./SupplementaryQuestions";

export default function CheckoutStepContent() {

    const { activeStep,
        context,
        Next,
        setPaymentInProgress,
        disable, setBookingRequested } = useCheckout();


    let { contactComplete, occasionBaseData, bookingRequest, booking, addBookingDetailNotes, addPublicDescription } = useWidgetContext();


    switch (activeStep) {
        case 0:
            setBookingRequested(false);
            //contactComplete = false;
            return <ContactForm />;
        case 1:
            setBookingRequested(false);
            const occasion = occasionBaseData.find(o => o.id == bookingRequest.occasionId);
            return (
                <BookingNotes
                    currentNotes={booking.notes}
                    addBookingDetailNotes={addBookingDetailNotes}
                    addPublicDescription={addPublicDescription}
                    bookingDescription={occasion.name + ' - ' + bookingRequest.EnquiryContact.firstName + ' ' + bookingRequest.EnquiryContact.lastName}
                />
            );
        case 2:
            if (
                typeof booking === "undefined" ||
                !booking.hasOwnProperty("id")
            ) {
                return <Loading />;
            }
            return <Review />;
        case 3:
            return (
                <SupplementaryQuestions
                    context={context}
                    disable={disable}
                />
            );
        case 4:
            setBookingRequested(false);
            return (

                <PaymentForm context={{ setPaymentInProgress, Next, ...context }} />
            );
        default:
            throw new Error("Unknown step");
    }

}