import React, { Fragment } from 'react';
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {
    MuiPickersUtilsProvider,
    TimePicker,
    DatePicker,
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import BookingItemTakeAwayContainer from './BookingItemListTakeAway';
import BookingSummary from './BookingSummary';
import BookingButton from './BookingButton';
import Box from '@material-ui/core/Box';
import ProgressButton from "./ProgressButton"
import EnquiryButton from './EnquiryButton';
import DiscountButton from './DiscountButton';
import { useTheme } from '@material-ui/core/styles';
import BookingFormPickers from './BookingFormPickers';
import { useTranslation } from 'react-i18next';


const getUnique = (items, value) => {
    return [...new Set(items.map(item => item[value]))];
};




export default function BookingForm({ context }) {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    if (!context) return null;
    const {
        occasionData,
        occasionBaseData,
        bookingRequest,
        setBookingDate,
        handleChange,
        calculatedRate,
        type,
        minDate,
        maxDate,
        selectedGuests,
        selectedStart,
        selectedEnd,
        hideBookingEndTime,
        setStartTime,
        setEndTime,
        setGuestNumbers,
        resetWidgetData,
        removeBookingItem,
        setOccasion,
        calculating,


    } = context;

    let OccasionType = getUnique(occasionBaseData, "name");
    // add all
    OccasionType = ["all", ...OccasionType];
    // map to jsx
    OccasionType = OccasionType.map((item, index) => (
        <option key={index} value={item}>
            {item}
        </option>
    ));


    // ------------
    // Get occasion from data matching currently selected occasion
    let Occasion = occasionData.filter(x => x.name === type)

    //Calculate Occasion length
    let OccasionLength = '';
    if (Occasion.length > 0) {
        if (Occasion[0].minDurationMinutes === Occasion[0].maxDurationMinutes) {
            OccasionLength = Occasion[0].minDurationMinutes;
        } else {
            OccasionLength = Occasion[0].maxDurationMinutes - Occasion[0].minDurationMinutes;
        }
    }


    return (
        <React.Fragment>
            {
                calculating === true ? (
                    <div style={{ zIndex: '99', position: 'absolute', paddingLeft: '39%', paddingTop: '50%', backgroundColor: 'rgba(0,0,0,0.3)', height: '100%', width: '100%', }}>
                        <ProgressButton />
                    </div>) : ('')
            }
            <Grid container style={{ padding: '20px', height: '100%'}}>
                <BookingFormPickers context={context} hideSettings={
                    {
                        hideOccasion: false,
                        hideDatePicker: false,
                        hideTimePicker: false,
                        hideGuestPicker: true
                    }} />
                <Grid container direction="row" justify="center" alignItems="flex-start" >
                    <Grid item xs={12}>
                        <Divider variant="middle" style={{ margin: '20px 0px' }} />
                        <BookingItemTakeAwayContainer context={context} />

                        <Typography gutterBottom align="center"><Box fontStyle="italic" fontSize={10} fontWeight="fontWeightLight">{t("switch.widget.bookingform.availability")}</Box></Typography >

                        <Divider variant="middle" style={{ margin: '20px 0px 0px 0px' }} />
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end" m={1} p={1} bgcolor="background.paper">
                            <DiscountButton context={context} />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <BookingSummary rate={calculatedRate} showTax={false} />
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-evenly" m={2}>
                            <Button variant="outlined" style={{ border: '2px solid', borderColor: `${theme.palette.colors.black}`, color: `${theme.palette.colors.black}` }} onClick={resetWidgetData}> {t("switch.widget.bookingform.reset")}</Button>
                            <BookingButton context={context} />
                        </Box>
                    </Grid>
                    <Typography gutterBottom align="center">
                        <Box fontStyle="italic" fontSize={12} >
                            {t("switch.widget.bookingform.ta-availability-warn")}
                        </Box>
                    </Typography >
                </Grid>
            </Grid>
        </React.Fragment>
    );

};