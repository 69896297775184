import { usePayment } from "../contexts/PaymentContext";
import Button from "@material-ui/core/Button";
import { useTranslation } from 'react-i18next'
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1)
    }

}));


export default function StripePayButton() {
    const { cardType, ready, paymentStatus, loading } = usePayment();
    const disabled = loading || !ready || paymentStatus === "processing";
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();

    return (<Button
        variant={disabled ? "contained" : "outlined"}
        style={{
            color: disabled
                ? `${theme.palette.info.main}`
                : `${theme.palette.success.main}`,
            border: "2px solid",
            borderColor: disabled
                ? `${theme.palette.info.main}`
                : `${theme.palette.success.main}`
        }}
        disabled={disabled}
        className={classes.button}
        type="submit"
        form={cardType == 'newcard' ? "cardDetails" : "cvcRecapture"}
    >
        {t("switch.widget.checkout.pay")}
    </Button>)
};