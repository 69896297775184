import React from "react";
import { Box, Heading, Paragraph, CheckBox, InfiniteScroll, Text } from "grommet";
import ToolTip from "./ToolTip";
import { CurrencyFormat } from "./CurrencyFormat";

export function CourseSelector(props) {
    const { course, selectionData, onAdd, onRemove, choices , enabled} = props;

    const [selected, setSelected] = React.useState(choices ? choices.filter(c => c.courseId=== course.id).length : 0);

    const onChange = (checked, selection) => {
        if (checked) {
            setSelected(selected + 1);
            if (onAdd) {
                onAdd(selection);
            }

        }
        else {
            setSelected(selected - 1);
            if (onRemove) {
                onRemove(selection);
            }

        }
    };

    if (!course) return null;
    return <Box direction="column" flex="grow">

        <Heading color="neutral-3" pad="xxsmall" level="3">{course.name}</Heading>

        <Box direction="column">

            {course.description &&
                <Paragraph margin="none" fill pad="xsmall">
                    {course.description}
                </Paragraph>}
            {selected < course.minSelection ? <Text id="txtError" color="status-error" margin="none" pad="xsmall">
                {course.maxSelection === 1 ? "Please make a selection" : "Please select up to " + course.maxSelection + " items"}
            </Text> : selected != 0 && selected < course.maxSelection ? <Text id="txtError" color="status-ok" margin="none" pad="xsmall"> {course.maxSelection - selected} selections remaining</Text> : null}
            {selected > course.maxSelection ? <Text id="txtError" color="status-error" margin="none" pad="xsmall">
                Maximum number of selections exceeded
            </Text> : null}
        </Box>


        <Box pad="medium">
            {course.items && <InfiniteScroll items={course.items.sort(compare)}>
                {(item) => (
                    < Box key={item.id} direction="row" pad="small" >
                        <ToolTip tip={item.description}>
                            <CheckBox
                                id={"food" + item.id}
                                label={item.name}
                                checked={!choices || choices.length == 0 ? false : choices.filter(c => c.courseId == course.id && c.menuItemId == item.id).length > 0 ? true : false}
                                onChange={(event) => { { onChange(event.target.checked, selection(item)) } }}
                                disabled={!enabled}
                            />
                            {(item.costPerHead === null) ? (<></>) : ((item.costPerHead === 0 ? (<></>) :
                                <Text id="txtExtra" textAlign="end" weight="bold" color="neutral-1"> Extra - {CurrencyFormat(item.costPerHead)} per head </Text>))}
                        </ToolTip>
                    </Box>

                )}
            </InfiniteScroll>}


        </Box>


    </Box>;

    function selection(item) {
        return {
            ...selectionData,
            menuItemId: item.id,
            detail: selectionData ? selectionData.detail ? selectionData.detail + " : " + item.name : item.name : item.name
        };
    }
}

export function compare(a, b) {
    if (a.displayOrder < b.displayOrder) {
        return -1;
    }
    if (a.displayOrder > b.displayOrder) {
        return 1;
    }
    // a must be equal to b
    return 0;
}