import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useTheme } from '@material-ui/core/styles'
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';

export const EarlyEmailCheck = ({ context }) => {
    const theme = useTheme();
    const [open, setOpen] = React.useState(context ? context.earlyEmailCheck : false);
    const { t, i18n } = useTranslation();
    if (!context) { return null }

    const { handleContactChange, bookingRequest, setEarlyEmailCheck, calculating, skippedEarlyEmailCheck, skipEarlyEmailCheck } = context;


    const handleClose = () => {
        skipEarlyEmailCheck()
        setOpen(false);
    };

    const handleSubmit = () => {
        setOpen(false);
        setEarlyEmailCheck();
    }

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <div id="clickMe" onClick={handleOpen}>
            <Dialog id="dialog" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{t("switch.widget.emailcheck.title")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("switch.widget.emailcheck.note")}
                    </DialogContentText>
                    <TextField
                        margin="dense"
                        id="email"
                        name="email"
                        label={t("switch.widget.emailcheck.email")}
                        type="email"
                        fullWidth
                        autoComplete="billing email"
                        value={bookingRequest.EnquiryContact.email}
                        onChange={handleContactChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button id="skipButton" onClick={handleClose} color="primary">
                        {t("switch.widget.buttons.skip")}
                    </Button>
                    <Button id="submitButton" variant="outlined" style={{ color: `${theme.palette.success.main}`, border: '2px solid', borderColor: `${theme.palette.success.main}` }} onClick={handleSubmit}>
                        {t("switch.widget.buttons.submit")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default EarlyEmailCheck