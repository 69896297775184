import React from 'react';

export default function convertHextoRGBA(hex, a) {
    let r = parseInt(hex.slice(1, 3), 16);
    let g = parseInt(hex.slice(3, 5), 16);
    let b = parseInt(hex.slice(5, 7), 16);

    if (a) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + a + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}