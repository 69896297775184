import React from "react";
import ReactDOM from "react-dom";
// import './index.css';
import './i18n';
import App from "./App";
//import * as serviceWorker from "./serviceWorker";
import { RoomProvider } from "./context";
import { WidgetProvider } from "./widgetContext";
import { BrowserRouter } from "react-router-dom";






ReactDOM.render(
        
            <WidgetProvider>
                <BrowserRouter>
                        <App />
                </BrowserRouter>
            </WidgetProvider>,
        document.getElementById("root")
);

//serviceWorker.unregister();
