import React from "react";
import { Box, Button, Layer, Text } from "grommet";
import ContentContainer from "./ContentContainer";
import { states } from "../pages/states";
import { CurrencyFormat } from "./CurrencyFormat";
import { Dialog } from './Dialog'


export function CancelBookingDialog(props) {
    const { open, loading, onNext, onBack, editState, request_result, error } = props

    let MessageText = "";

    if (!open) return null;
    if (loading) MessageText = "Processing request...";
    if (!loading && editState === states[1]) MessageText = "Are you sure you wish to cancel the booking? ";
    if (!loading && editState === states[2]) MessageText = "Your Booking cancellation request has been succesfully processed";
    if (!loading && error) MessageText = "There was an error processing your request please contact a member of our team";

    const RefundText = loading || error ? <Text></Text> : request_result === 0 ? <Text> No Refund is due for the cancellation of this Booking</Text> : <Text> Your entitled refund amount has been calculated as {CurrencyFormat(request_result.toString())}</Text>

    return <Dialog id="cancelDialog" dialogStyle="MessageBox" emoji=":exclamation:" title="Cancel Booking" content="Cancellation is subject to the terms and conditions agreed at the time of booking and maybe subject to additional admin charges" onCancel={editState === states[1] ? onBack : false} onConfirm={onNext} >
        <Box direction="column" justify="between" align="start">
            {editState === states[1] && RefundText}
            <Text> {MessageText} </Text>
        </Box>
    </Dialog>
}
