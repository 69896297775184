import addMinutes from 'date-fns/addMinutes'

export default function GetAdjustedDate(dateIn) {
    if (dateIn == null) return "";

    if (isNaN(Date.parse(dateIn)) || new Date(dateIn) < new Date("01/01/1970"))  
    {
        return dateIn
    }

    let adjustedDate =
        typeof dateIn == 'string'
            ? new Date(dateIn)
            : dateIn

    let offset = adjustedDate.getTimezoneOffset()
    adjustedDate.setHours(0, 0, 0, 0);
    if (offset !== 0) {
        adjustedDate = addMinutes(adjustedDate, offset * -1)
    }

    const updateString = new Date(adjustedDate).toISOString(); 
    return updateString;
}