import React from "react";
import { Box, Button, Layer, Heading, FormField, ThemeContext, RangeInput , Text} from "grommet";
import TimeInput from './TimeInput';
import { DateInput } from './DateInput';
import { Dialog } from "./Dialog";
import { Add, Subtract } from 'grommet-icons';


export function EditBookingHeader(props) {
    const { booking, onCancel, onSave , onChange} = props
    
    const [locals, setLocals] = React.useState({
        bookingDate: booking.bookingDate,
        startTime: booking.startTime,
        endTime: booking.endTime,
        numberOfGuests: booking.numberOfGuests
    });

    function HandleChange(props) {
        const name = props.target.name;
        const value = props.target.value;

        const newState = {
            ...locals,
            [name]: value
        };

        setLocals(newState);
        
        if (onChange) {
            onChange(newState);
        }
    };

    return <Dialog id="editBookingHeader" dialogStyle="EditBox" emoji=":spiral_calendar_pad:" title="Edit Booking" onCancel={onCancel} onSave={() => { onSave({ ...booking, ...locals }) }} >
        <Box direction="column" flex="grow">
                <Box direction="row" justify="center">
                    <DateInput id="diBookingDate" name="bookingDate" format="dd/mm/yyyy" value={locals.bookingDate} onChange={HandleChange} />                
                </Box>            
            <ThemeContext.Extend value={{ formField: { border: 'none' } }}>
                <Box direction="row-responsive" justify="between" gap="small">
                    <FormField label="Start Time">
                        <TimeInput id="tiStartTime" name="startTime" value={locals.startTime} onChange={(value) => { HandleChange({ target: { name: 'startTime', value: value } }) }} />
                    </FormField>

                    <FormField label="End Time">
                        <TimeInput id="tiEndTime" name="endTime" value={locals.endTime} onChange={(value) => { HandleChange({ target: { name: 'endTime', value: value } }) }} />
                    </FormField>
                </Box>
            </ThemeContext.Extend>
            <FormField label={"Number Of Guests :" + locals.numberOfGuests.toString()}>
                <Box direction="row" align="center" pad="small" gap="small">
                    <Button
                        id="btnMinus"
                        plain={false}
                        disabled={locals.numberOfGuests === 1}
                        icon={<Subtract color="neutral-2" />}
                        onClick={() => HandleChange({ target: { name: 'numberOfGuests', value: locals.numberOfGuests - 1 } })}
                    />
                    <RangeInput id="riGuests" name="numberOfGuests" min={1} max={100} value={locals.numberOfGuests} onChange={HandleChange} />
                    <Button
                        id="btnPlus"
                        plain={false}
                        disabled={locals.numberOfGuests === 100}
                        icon={<Add color="neutral-2" />}
                        onClick={() => HandleChange({ target: { name: 'numberOfGuests', value: locals.numberOfGuests + 1 } })}
                    />
                </Box>
            </FormField>
        </Box>
    </Dialog>
}
