import React from "react";
import Resource from "./Resource";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { useTranslation } from 'react-i18next'
import NoSearchResults from "./NoSearchResults";


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {        
        width: 'auto',
        height: 'auto',
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    }
})); 

const ResourceList = ({ resource, addBookingDetail, removeBookingDetail }) => {
    const classes = useStyles();

    if (resource.length === 0) {
        return (
            <NoSearchResults title={"switch.widget.notfound.resourc"} classes={classes} />
        );
    }
    return (
        <Grid container direction="row" justify="space-evenly" alignItems="center" spacing={4}>
            {resource.map(item => {
                return (
                <Grid item xs={12} lg={4} xl={2} key={item.id}>
                    <Resource key={item.id} Resource={item} addBookingDetail={addBookingDetail} removeBookingDetail={removeBookingDetail} />
                </Grid>);
            })}
        </Grid>

    );
};

export default ResourceList;
