import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import loadingGif from "../images/gif/loading-circles-sm.svg";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
}));

export default function ProgressButton() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <img src={loadingGif} alt="" />
        </div>
    );
}