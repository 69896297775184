import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import CurrencyFormat from './CurrencyFormat';
import { useTranslation } from 'react-i18next'
import { useMultiDayForm } from '../contexts/useMultiDayForm';

const useStyles = makeStyles(theme => ({
    listItem: {
        padding: theme.spacing(1, 0),
    },
    total: {
        fontWeight: '700',
    },
    title: {
        marginTop: theme.spacing(2),
    },
}));

function DateConverter(dateIn) {
    let date = new Date(dateIn);
    const options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
    let dateOut = date.toLocaleDateString('en-GB', options);
    return dateOut;
}


export default function EnquiryReview({ context }) {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    if (!context) return null
    const { enquiryRequest, calculatedRate } = context;
    const { isMultiDay, reviewText } = useMultiDayForm()


    let totalLabel = t("switch.widget.enquiryreview.total");
    if (isMultiDay) {
        totalLabel += " " + t("switch.widget.enquiryreview.perbooking");
    }

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                {t("switch.widget.enquiryreview.title")}
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Typography gutterBottom>{t("switch.widget.enquiryreview.date")} {DateConverter(enquiryRequest.bookingDate)} {(enquiryRequest.flexibleOnDateTime === true ? t("switch.widget.enquiryreview.flexible") : "")} </Typography>
                    <Typography gutterBottom>{t("switch.widget.enquiryreview.guests")} {enquiryRequest.numberOfGuests}</Typography>

                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography gutterBottom>{t("switch.widget.enquiryreview.arriving")} {enquiryRequest.startTime.substring(0, 5)}</Typography>
                    <Typography gutterBottom>{t("switch.widget.enquiryreview.departing")} {enquiryRequest.endTime.substring(0, 5)}</Typography>
                </Grid>
                {isMultiDay &&
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography gutterBottom>{t("switch.widget.enquiryreview.repeatrule")}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography gutterBottom>{reviewText}</Typography>
                        </Grid>
                    </Grid>
                }
            </Grid>
            <List disablePadding>
                {calculatedRate.lineItems.map(product => (
                    <ListItem className={classes.listItem} key={product.id}>
                        <ListItemText primary={product.item.name} secondary={product.notes} />
                        <CurrencyFormat value={product.gross} prefix={t("switch.widget.currancysymbol")} matUiVariant="body1" />
                    </ListItem>
                ))}
                <ListItem className={classes.listItem}>
                    <ListItemText primary={totalLabel} />
                    <CurrencyFormat value={calculatedRate.gross} prefix={t("switch.widget.currancysymbol")} matUiVariant="subtitle1" className={classes.total} />
                </ListItem>
                <ListItem className={classes.listItem}>
                    <ListItemText primary={t("switch.widget.enquiryreview.budget")} />
                    <CurrencyFormat value={enquiryRequest.budget} prefix={t("switch.widget.currancysymbol")} matUiVariant="subtitle1" className={classes.total} />
                </ListItem>
                <ListItem className={classes.listItem}>
                    <ListItemText primary={t("switch.widget.enquiryreview.details")} />
                </ListItem>
            </List>
            <Typography variant="body2" gutterBottom>{enquiryRequest.notes}</Typography>

        </React.Fragment>
    );
}
