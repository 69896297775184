import { grommet , dark} from "grommet/themes";
import { deepMerge } from "grommet/utils";

export const defaultExtendedTheme = {
    global: { 
      colors: {
        // Custom Colours
        customColors: {
          color1: '#000',
          color2: '#FFF',
          color3: '#F00',
          color4: '#FF0',
        },
      }
    },
  }

export const customTheme = deepMerge(grommet, {
    global: {
        breakpoints: {
            xsmall: {
                value: 400
            }
        },
        colors: {
            brand: {
                dark: "#FCD110",
                light: "#FCD110"
               //  dark: "#0033FF",
               // light: "#0033FF"
            },
        }
    },
    heading: {
        level: {
            "1": {
                "font": {},
                    "small": {
                    "size": "34px",
                    "height": "40px",
                    "maxWidth": "816px"
                    },
                    "medium": {
                    "size": "50px",
                    "height": "56px",
                    "maxWidth": "1200px"
                    },
                    "large": {
                    "size": "82px",
                    "height": "88px",
                    "maxWidth": "1968px"
                    },
                    "xlarge": {
                    "size": "114px",
                    "height": "120px",
                    "maxWidth": "2736px"
                    }
            },
            "2": {
                "size": "100px"
            },
            "3": {
                "size": "100px"
            },
            "4": {
                "size": "100px"
            },
            "5": {
                "size": "100px"
            },
            "6": {
                "size": "100px"
            },
        },
        extend: () => `margin-top: 12px; margin-bottom: 12px;`
    },
    paragraph: {
        extend: () => `font-weight: 300; margin-top: 0;`,
        xxlarge: {
            size: "28px"
        }
    },
    textInput: {
        placeholder: {
            extend: () => `color: #44444`
        }
    },
});
//const colors = {
//    active: rgba(221, 221, 221, 0.5),
//    'background-back': {
//        dark: '#33333308',
//        light: '#EDEDED',
//    },
//    'background-front': {
//        dark: '#444444',
//        light: '#FFFFFF',
//    },
//    'background-contrast': {
//        dark: '#33333308',
//        light: '#FFFFFF08',
//    },
//    'active-background': 'background-contrast',
//    'active-text': 'text-strong',
//    black: '#000000',
//    border: {
//        dark: rgba(255, 255, 255, 0.33),
//        light: rgba(0, 0, 0, 0.33),
//    },
//    brand: brandColor,
//    control: {
//        dark: 'accent-1',
//        light: 'brand',
//    },
//    focus: focusColor,
//    'graph-0': 'accent-1',
//    'graph-1': 'neutral-1',
//    'graph-2': 'neutral-2',
//    'graph-3': 'neutral-3',
//    'graph-4': 'neutral-4',
//    placeholder: '#AAAAAA',
//    selected: 'brand',
//    text: {
//        dark: '#f8f8f8',
//        light: '#444444',
//    },
//    'text-strong': {
//        dark: '#FFFFFF',
//        light: '#000000',
//    },
//    'text-weak': {
//        dark: '#CCCCCC',
//        light: '#555555',
//    },
//    'text-xweak': {
//        dark: '#BBBBBB',
//        light: '#666666',
//    },
//    icon: {
//        dark: '#f8f8f8',
//        light: '#666666',
//    },
//    'selected-background': 'brand',
//    'selected-text': 'text-strong',
//    white: '#FFFFFF',
//};