import React, { useEffect, useState } from "react";
import { usePayment } from "../contexts/PaymentContext";
import { Box, Text, CheckBox, RadioButtonGroup } from "grommet";
import { Typography } from "@material-ui/core";

export const WindCaveSavedCardCheck = () => {

    const {
        consent,
        ChangeConsent,
        saveDefault,
        ChangeDefault,
        selectedCard,
        hasSavedCards,
        savedCards,
        handleChangeCard,
        SetSelectedCard,
        SetCardType,
        cardType
    } = usePayment();

    const [saveNewCard, ChangeNewCard] = useState(cardType==="newcard");


    function handleNewCard(event)
    {
        SetCardType(event.target.checked ? "newcard" : "saved");        
        ChangeNewCard(event.target.checked);
        if (event.target.checked) SetSelectedCard(null);
    }

    const cardOptions = !hasSavedCards ? [] : savedCards.map(card => {

        const ExpiryDate = new Date(card.expiry);
        const options = { month: 'long' };
        const monthname = new Intl.DateTimeFormat('en-GB', options).format(ExpiryDate);

        let cardLabel = ' *** ' + card.last4 + ' - Expires(' + monthname + '/' + ExpiryDate.getFullYear() + ')'
        if (card.default) {
            cardLabel += ' - DEFAULT'
        }


        const label = (
            <Box direction="row-responsive">
                <img key={card.id} src={`./image/cards/${card.brand}.png`} alt={card.brand} width="50px" align="bottom" style={{ padding: "0px 10px" }} />
                <Typography variant="body2">{cardLabel}</Typography>
            </Box>
        )

        return {
            label: label,
            value: card.id
        }
    });




    return (
        <>
            <Typography>Select from one of the cards below - to use a new card check the option and continue</Typography>
            <Box align="left" pad="small">
                <RadioButtonGroup
                    name="savedCard"
                    options={cardOptions}
                    value={selectedCard?.id}
                    onChange={handleChangeCard}
                />
            </Box>
           
            <Box gap="small" align="left" pad="small">
                <CheckBox
                    tabIndex="-1"
                    checked={consent}
                    label={<Text>Allow future payments to be collected from this card</Text>}
                    onChange={ChangeConsent}
                />
            </Box>
            <Box gap="small" align="left" pad="small">
                <CheckBox
                    tabIndex="-1"
                    checked={saveDefault}
                    label={<Text>Set as default card for this account</Text>}
                    onChange={ChangeDefault}
                />
            </Box>
            <Box gap="small" align="left" pad="small">
                <CheckBox
                    tabIndex="-1"
                    checked={saveNewCard}
                    label={<Text>Use a different card</Text>}
                    onChange={handleNewCard}
                />
            </Box>
            

        </>
    );
}