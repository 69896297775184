import React, { Component, useState } from "react";
import {
    useStripe,
    useElements,
    PaymentElement
} from "@stripe/react-stripe-js";
import { usePayment } from "../contexts/PaymentContext";


const CheckoutCardForm = (props) => {
    const { handleNext, options } = props;
    const { SetError, paymentCompleted, setPaymentStatus, setReady } = usePayment();

    const stripe = useStripe();
    const elements = useElements();
/*    const options = {
        ...options,
        terms: {
            card: "never"
        }
    };*/


    const handleSubmit = async event => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setPaymentStatus("processing");
        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: window.origin
            },
            redirect: "if_required"
        });

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            console.log(result.error.message);
            SetError(result.error.message);
            setPaymentStatus("error");
        } else {
            paymentCompleted(result.paymentIntent);
            setPaymentStatus("succeeded");
            if (handleNext != null) {
                handleNext();
            }
        }
    };

 
    return (
        <div>
            <form id="cardDetails" onSubmit={handleSubmit}>
                <PaymentElement options={options} onReady={() => setReady(true)} />
            </form>
        </div>
    );
};

export default CheckoutCardForm;
