import React from "react";
import { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useTranslation } from 'react-i18next'
import { useWidgetContext } from "../widgetContext";

export default function ContactForm() {

    const context = useWidgetContext()
    const [charity, setCharity] = useState(false);
    const { t, i18n } = useTranslation();
    if (!context) return null;
    const { handleContactChange, bookingRequest, style } = context;

    if (bookingRequest.EnquiryContact.email === " ") {
        const event = {
            target: {
                name: "email",
                value: ""
            }
        };
        handleContactChange(event);
    }

    const handleEmailChange = (event) => {
        const {value } = event.target;
        let trimmedValue = value.trim();

        if (trimmedValue !== value) {
            event.target.value = trimmedValue;
            handleContactChange(event);
        } else {
            handleContactChange(event);
        }
    };
    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                {t("switch.widget.contactform.title")}
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="firstName"
                        name="firstName"
                        label={t("switch.widget.contactform.firstname")}
                        fullWidth
                        autoComplete="fname"
                        value={bookingRequest.EnquiryContact.firstName}
                        onChange={handleContactChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="lastName"
                        name="lastName"
                        label={t("switch.widget.contactform.lastname")}
                        fullWidth
                        autoComplete="lname"
                        value={bookingRequest.EnquiryContact.lastName}
                        onChange={handleContactChange}
                    />
                </Grid>
                {style === "takeAway" && (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="address1"
                                name="address1"
                                label={t("switch.widget.contactform.address1")}
                                fullWidth
                                autoComplete="address 1"
                                value={bookingRequest.EnquiryContact.address1}
                                onChange={handleContactChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="address2"
                                name="address2"
                                label={t("switch.widget.contactform.address2")}
                                fullWidth
                                autoComplete="address 2"
                                value={bookingRequest.EnquiryContact.address2}
                                onChange={handleContactChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="city"
                                name="city"
                                label={t("switch.widget.contactform.city")}

                                label="City"
                                fullWidth
                                autoComplete="city"
                                value={bookingRequest.EnquiryContact.city}
                                onChange={handleContactChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="postCode"
                                name="postCode"
                                label={t("switch.widget.contactform.postcode")}
                                fullWidth
                                autoComplete="post code"
                                value={bookingRequest.EnquiryContact.postCode}
                                onChange={handleContactChange}
                            />
                        </Grid>
                    </React.Fragment>
                )}
                <Grid item xs={12}>
                    <TextField
                        required
                        id="mobile"
                        name="mobile"
                        label={t("switch.widget.contactform.phone")}
                        fullWidth
                        autoComplete="billing mobile"
                        value={bookingRequest.EnquiryContact.mobile}
                        onChange={handleContactChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="email"
                        name="email"
                        label={t("switch.widget.contactform.email")}
                        fullWidth
                        autoComplete="billing email"
                        value={bookingRequest.EnquiryContact.email}
                        onChange={handleEmailChange}
                    />
                </Grid>
                {style !== "takeAway" && (
                    <React.Fragment>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="company"
                                name="enquiryCompany.name"
                                label={t("switch.widget.contactform.company")}
                                fullWidth
                                autoComplete="billing company"
                                value={
                                    bookingRequest.EnquiryContact.enquiryCompany
                                        ? bookingRequest.EnquiryContact.enquiryCompany.name
                                        : ""
                                }
                                onChange={handleContactChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="secondary"
                                        name="charity"
                                        id="isCharity"
                                        checked={charity}
                                        onChange={e => {
                                            setCharity(e.target.checked);
                                        }}
                                    />
                                }
                                label={t("switch.widget.contactform.charity_prompt")}
                            />
                            {charity && (
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            id="charityname"
                                            name="enquiryCompany.registeredCharityName"
                                            label={t("switch.widget.contactform.charity")}
                                            fullWidth
                                            value={
                                                bookingRequest.EnquiryContact.enquiryCompany
                                                    ? bookingRequest.EnquiryContact.enquiryCompany
                                                        .registeredCharityName
                                                    : ""
                                            }
                                            onChange={handleContactChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            id="charitynumber"
                                            name="enquiryCompany.registeredCharityNumber"
                                            label={t("switch.widget.contactform.charitynumber")}
                                            fullWidth
                                            value={
                                                bookingRequest.EnquiryContact.enquiryCompany
                                                    ? bookingRequest.EnquiryContact.enquiryCompany
                                                        .registeredCharityNumber
                                                    : ""
                                            }
                                            onChange={handleContactChange}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </React.Fragment>
                )}
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="secondary"
                                name="allowMarketing"
                                id="allowMarketing"
                                checked={bookingRequest.EnquiryContact.allowMarketing}
                                onChange={handleContactChange}
                            />
                        }
                        label={t("switch.widget.contactform.marketing")}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="secondary"
                                name="allow3rdPartyMarketing"
                                id="allow3rdPartyMarketing"
                                checked={bookingRequest.EnquiryContact.allow3rdPartyMarketing}
                                onChange={handleContactChange}
                            />
                        }
                        label={t("switch.widget.contactform.partnermarketing")}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
