import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from '@material-ui/core/Button';
import { memo } from "react";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MenuDetails from './MenuDataPopup';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CurrencyFormat from './CurrencyFormat';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { useWidgetContext } from "../widgetContext";

import { useTranslation } from "react-i18next"
import SlideShowComponent from "./SlideShowComponent";
import { useWidgetResources } from "../contexts/useWidgetResources";

const useStyles = makeStyles(theme => ({
    takeAwayDescription: {
        fontSize: 10,
    },
    foodInfo: {
        background: theme.palette.colors.white,
        width: '100% !important',
        fontWeight: '700',
        padding: '10px',
        display: 'table',
        width: '100%',
        height: '88px',
        border: '1px solid',
        borderColor: theme.palette.custom.secondary,
        borderTop: '0px solid',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
    },
    icons: {
        color: theme.palette.custom.secondary,
        cursor:'pointer', 
        verticalAlign: 'middle'
    },
    foodItemCount: {
        border: '1px solid', 
        borderColor: theme.palette.custom.secondary,
        borderRadius: '10px',
    },
    button: {
        marginTop: '10px',
    },
    qtySelector: {
        marginTop: '18px',
    },
    nameBox: {
        minHeight: '48px'
    }
}));




const Food = memo(({ courseId, menuDetails, setMenuDetails, isTakeAway, food, onAdd, onRemove }) => {
    if (!food) return null;
    const { id, venueId, name, slug, slideShow, price, price_type, description, capacityMeasure, minBookingQty } = food;
    const classes = useStyles();
    const context = useWidgetContext();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { GetResourceBestRate } = useWidgetResources();
    const [rate, setRate] = useState({
        price: price,
        price_type: price_type
    });


    useEffect(
        () => {
            if (!food) return;
            const rate = GetResourceBestRate(food?.id);
            setRate(rate)
        },
        [food]
    );

    const existingEntry = context.bookingRequest.Details.filter(x => x.ResourceId === id)
    if (isTakeAway) {
            return (
                <Grid container direction="row" justify="center" alignItems="center" className="takeAway" id={"foodItem-"+food.id }>
                    <Grid item xs={12} sm={3} className="img-container take-away">
                        <SlideShowComponent slideShow={slideShow} isTakeAway={isTakeAway} />
                    <div className="price-top">
                            <CurrencyFormat value={!food.costPerHead ? 0 : food.costPerHead} prefix={`${t("switch.widget.from")} ${t("switch.widget.currancysymbol")}`} matUiVariant="body1" />
                    </div>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Grid container direction="row" justify="center" alignItems="center" className="takeAway">
                            <Tooltip title={<Typography color="inherit" style={{ textAlign: 'center' }}>{t("switch.widget.venue.clikadd")}</Typography>} aria-label={<Typography color="inherit">={t("switch.widget.venue.clikadd")}</Typography>}>
                            <Grid item xs={12} sm={9} className="takeAway-info" style={{display: 'table',width: '100%',height: '88px',cursor:'pointer', textAlign: 'left'}}>
                                <Grid container direction="column" justify="flex-start" alignItems="flex-start" className="takeAway" spacing={1} style={{padding: '0px 20px'}}>
                                    <Grid item xs={12}>
                                        <Typography component="h6"  style={{display: 'table-cell',verticalAlign: 'middle'}}>
                                            {name}
                                        </Typography>
                                    </Grid> 
                                    <Grid item xs={12}>
                                        <Typography component="p" className={classes.takeAwayDescription}  style={{display: 'table-cell',verticalAlign: 'middle', textAlign: 'left'}} dangerouslySetInnerHTML={{ __html: description }} />
                                    </Grid> 
                                </Grid>
                            </Grid>
                            </Tooltip>
                            <Grid item xs={12} sm={3} className="takeAway-info">
                                <Grid container direction="row" justify="center" alignItems="center" >
                                    <Grid item xs={4}>
                                        <AddIcon id="btnAdd" onClick={onAdd} style={{cursor:'pointer'}}/>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography>
                                            {existingEntry[0] ? `${existingEntry[0].Quantity}` : '0'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <RemoveIcon id="btnRemove" data-testid="btnremove" onClick={onRemove} style={{cursor:'pointer'}}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            );
    }

    return (
            <Grid container direction="column" justify="flex-start" alignItems="center" className={context.style === 'takeAway' ? 'takeAway' : 'empty'}>
                <Grid item xs={12} className="img-container">
                    <SlideShowComponent slideShow={slideShow} />
                </Grid>

                <Grid item xs={12} className={classes.foodInfo}>
                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" >
                        <Grid item xs={12}>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" className={classes.nameBox}>
                                <Grid item xs={11}>
                                    <Typography component="h6" className="occasion-info-title">
                                        {name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <MenuDetails food={food} addFoodToBooking={onAdd}/> 
                                </Grid>
                            </Grid>
                            <Divider variant="middle" style={{ margin: '10px 0px 10px 0px' }} />
                        <CurrencyFormat value={context.showPrices ? rate?.price : null} prefix={`${t("switch.widget.from")} ${t("switch.widget.currancysymbol")}`} matUiVariant="body1" capacityMeasure={capacityMeasure} />
                        </Grid>
                        {!existingEntry[0] ? 
                            <Grid item xs={12}>
                                <Grid container direction="row" justify="center" alignItems="center" >
                                    <Button
                                        id="btnAdd"
                                        variant="outlined"
                                        style={{ color: `${theme.palette.custom.secondary}`, border: '2px solid', borderColor: `${theme.palette.custom.secondary}` }}
                                        onClick={onAdd}
                                        className={classes.button}
                                    >
                                    {t("switch.widget.food.addtobasket")}
                                        </Button> 
                                </Grid>
                            </Grid> 
                        :
                            <Grid item xs={12}>
                                <Grid container direction="row" justify="center" alignItems="center" className={classes.qtySelector} >
                                <Tooltip title={<Typography color="inherit" style={{ textAlign: 'center' }}>{t("switch.widget.food.reduceqty")}</Typography>} aria-label={<Typography color="inherit">{t("switch.widget.food.reduceqty")}</Typography>}>
                                    <Grid item xs={1}>
                                        <RemoveIcon id="btnRemove" onClick={onRemove} className={classes.icons} />
                                    </Grid>
                                    </Tooltip>
                                    <Grid item xs={2} className={classes.foodItemCount}>
                                        <Typography style={{padding:'2px 8px', textAlign:'center'}}>
                                            {existingEntry[0].Quantity}
                                        </Typography>
                                    </Grid>
                                <Tooltip title={<Typography color="inherit" style={{ textAlign: 'center' }}>{t("switch.widget.food.increaseqty")}</Typography>} aria-label={<Typography color="inherit">{t("switch.widget.food.increaseqty")}</Typography>}>
                                    <Grid item xs={1}>
                                        <AddIcon id="btnAdd" data-testid="btnadd" onClick={onAdd} className={classes.icons} />
                                    </Grid>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        }
                        </Grid>
                    </Grid>
            </Grid>
    );
});

Food .propTypes = {
    room: PropTypes.shape({
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        images: PropTypes.arrayOf(PropTypes.string).isRequired,
        price: PropTypes.number.isRequired
    })
};
export default Food ;
