import React from "react";
import { Box, Button, TextArea } from "grommet";
import ContentContainer from "./ContentContainer";

export function SpecialRequests(props) {
    const { saveButton, onSave , onChange, value} = props;

    const [newMessageText, setNewMessageText] = React.useState(value ? value : "");

    function handleChange(event)
    {
        setNewMessageText(event.target.value);
        if (onChange) {
            onChange(event.target.value);
        }
    }


    return (<Box gap="large" direction="column">
        <ContentContainer emoji=":page_facing_up:" title="Special Requests" height="large" content="If you have any special requirements to help your event run smoothly leave a note here and our bookings team will be in touch">
            <TextArea
                id="newMessageText"
                fill
                placeholder="type here"
                value={newMessageText}
                onChange={handleChange}
            />
            {saveButton &&
                <Box align="end" pad={{ top: "small" }}>
                    <Button id="btnSaveNotes" primary label="Save" onClick={() => onSave(newMessageText)} disabled={!newMessageText || newMessageText.length === 0} />
                </Box>}

        </ContentContainer>
    </Box>);
}
