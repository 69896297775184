import React, { useEffect } from "react";
import { withWidgetConsumer } from "../widgetContext";
import Loading from "./Loading";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from '@material-ui/core/styles'
import WidgetGeneral from "./WidgetGeneral";
import WidgetTableBooking from "../tableBookingComponents/WidgetTableBooking";
import WidgetSingleEvent from "./WidgetSingleEvent";
import WidgetSingleEventBanner from "./WidgetSingleEventBanner";
import WidgetRoomBooking from "./WidgetRoomBooking";
import WidgetCustomerCollection from "./WidgetCustomerCollection";
import WidgetTakeAway from "./WidgetTakeAway";
import WidgetCustomerCheckin from "./WidgetCustomerCheckin";
import WidgetFooter from "./WidgetFooter";
import WidgetFooterCart from "./WidgetFooterCart";
import ErrorBoundary from "./../ErrorBoundary";
import HubSpotChat from "./useHubSpotChat";
import { Box, Grommet, Heading, ThemeContext } from "grommet";
import { useTranslation } from 'react-i18next'
import { FontPicker } from "@switch/core";

function WidgetContainer({ context }) {
    const { loading, style, error, translations } = context;
    const { t, i18n } = useTranslation();

    document.title = context.name + " | " + t("switch.widget.poweredby");
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    useEffect(() => {
        if (translations) {
            for (const [key, value] of Object.entries(translations)) {
                i18n.addResourceBundle(key, 'translation', value, true, true);
            }
        }
    }, [translations]);


    if (error) {
        return (
            <Grommet full>
                <Box justify="center" align="center" background="status-ok" fill>
                    <Heading>Error: {error}</Heading>
                </Box>
            </Grommet>
        );
    }

    const theme = createTheme({
        typography: {
            fontFamily: context.widgetTheme.headerFont === "Big Caslon" ? "big-caslon-fb,“Big Caslon”,“Book Antiqua”,Palatino,Georgia,serif" : FontPicker(context.widgetTheme.font),
            h1: {
                fontFamily: context.widgetTheme.headerFont === "Big Caslon" ? "big-caslon-fb,“Big Caslon”,“Book Antiqua”,Palatino,Georgia,serif" : FontPicker(context.widgetTheme.font),
            },
            h2: {
                fontFamily: context.widgetTheme.headerFont === "Big Caslon" ? "big-caslon-fb,“Big Caslon”,“Book Antiqua”,Palatino,Georgia,serif" : FontPicker(context.widgetTheme.font),
            },
            h3: {
                fontFamily: context.widgetTheme.headerFont === "Big Caslon" ? "big-caslon-fb,“Big Caslon”,“Book Antiqua”,Palatino,Georgia,serif" : FontPicker(context.widgetTheme.font),
            },
            h4: {
                fontFamily: context.widgetTheme.headerFont === "Big Caslon" ? "big-caslon-fb,“Big Caslon”,“Book Antiqua”,Palatino,Georgia,serif" : FontPicker(context.widgetTheme.font),
            },
            h5: {
                fontFamily: context.widgetTheme.headerFont === "Big Caslon" ? "big-caslon-fb,“Big Caslon”,“Book Antiqua”,Palatino,Georgia,serif" : FontPicker(context.widgetTheme.font),
            },
            h6: {
                fontFamily: context.widgetTheme.headerFont === "Big Caslon" ? "big-caslon-fb,“Big Caslon”,“Book Antiqua”,Palatino,Georgia,serif" : FontPicker(context.widgetTheme.font),
            },
            body1: {
                fontWeight: 500,
            },
        },
        palette: {
            primary: {
                main: "#958E8B",
            },
            secondary: {
                main: "#0033ff",
            },
            error: {
                main: "#7F2323",
            },
            warning: {
                main: "#CC6F0C",
            },
            info: {
                main: "#f9f9f9",
            },
            success: {
                main: "#4E6D18",
            },
            colors: {
                white: "#fff",
                black: "#000",
            },
            custom: {
                primary: context.widgetTheme.primaryColour,
                secondary: context.widgetTheme.secondaryColour,
                fontStyle: context.widgetTheme.fontStyle,
                primaryStyle: context.widgetTheme.primaryStyle,
                secondaryStyle: context.widgetTheme.secondaryStyle,
            },
        },
    });

    if (loading) {
        return (<Loading />);
    }

    // Check for Language Debug
    const urlParams = window.location.search;
    if (
        i18n.language !== "debug" &&
        urlParams.substr(urlParams.length - 5) === "debug"
    ) {
        changeLanguage("debug");
    }

    switch (style) {
        case "singleEvent":
            return (
                <ErrorBoundary context={context}>
                    <ThemeProvider theme={theme}>
                        <WidgetSingleEvent context={context} />
                        <WidgetFooter context={context} />
                    </ThemeProvider>
                </ErrorBoundary>
            );
            break;
        case "singleEventBanner":
            return (
                <ErrorBoundary context={context}>
                    <ThemeProvider theme={theme}>
                        <WidgetSingleEventBanner context={context} />
                        <WidgetFooter context={context} />
                    </ThemeProvider>
                </ErrorBoundary>
            );
            break;
        case "customerCheckin":
            return (
                <ErrorBoundary context={context}>
                    <ThemeProvider theme={theme}>
                        <WidgetCustomerCheckin context={context} />
                    </ThemeProvider>
                </ErrorBoundary>
            );
            break;
        case "roomBooking":
            return (
                <ErrorBoundary context={context}>
                    <ThemeProvider theme={theme}>
                        <WidgetRoomBooking context={context} />
                        <WidgetFooterCart context={context} />
                        {context.hubSpotChatId != null ? (<HubSpotChat id={context.hubSpotChatId} />) : null}
                    </ThemeProvider>
                </ErrorBoundary>
            );
            break;
        case "customerCollection":
            return (
                <ErrorBoundary context={context}>
                    <ThemeProvider theme={theme}>
                        <WidgetCustomerCollection context={context} />
                        <WidgetFooter context={context} />
                    </ThemeProvider>
                </ErrorBoundary>
            );
            break;
        case "takeAway":
            return (
                <ErrorBoundary context={context}>
                    <ThemeProvider theme={theme}>
                        <WidgetTakeAway context={context} />
                        <WidgetFooterCart isTakeAway={true} context={context} />
                    </ThemeProvider>
                </ErrorBoundary>
            );
            break;
        case "tableBooking":
            return (
                <ErrorBoundary context={context}>
                    <ThemeProvider theme={theme}>
                        <WidgetTableBooking context={context} />
                    </ThemeProvider>
                </ErrorBoundary>
            );
            break;
        default:
            return (
                <ErrorBoundary context={context}>
                    <ThemeProvider theme={theme}>
                        <WidgetGeneral context={context} />
                        <WidgetFooter context={context} />
                        <HubSpotChat />
                    </ThemeProvider>
                </ErrorBoundary>
            );
    }
}

export default withWidgetConsumer(WidgetContainer);
