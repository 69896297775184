import React from 'react';
import { Grid, Typography } from '@material-ui/core';

function refreshPage() {
    window.location.reload(false);
}

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, info: null };
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        window.sessionStorage.clear();
        this.setState({
            error: error.toString(),
            info: info
        })
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, info);
    }

    render() {
        if (this.state.info) {
            // Error path
            return (
                <Grid container direction="row" justify="center" alignItems="center" style={{ marginTop: '20%' }}>
                    <Grid item xs={6} md={3}>
                        <Typography variant="h3">Oh no!</Typography>
                        <Typography variant="h6" style={{ marginBottom: '30px' }}>Something went wrong!</Typography>
                        <Typography variant="p">Try a <span onClick={refreshPage} style={{ textDecoration: 'underline', cursor: 'pointer' }}>reload</span>, it the issue persists please <a href="mailto:hello@dispace.co" style={{ color: '#000' }}>contact us</a>.</Typography><br />
                        {this.props.context && <Typography variant="p">Or you can return to the <a href={this.props.context.returnURL} style={{ color: '#000' }}>venue website</a></Typography>}
                        <div style={{ marginTop: '15px' }}>
                            <details style={{ whiteSpace: 'pre-wrap' }}>
                                {this.state.error && this.state.error.split('.')[0]}
                            </details>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <img src={process.env.PUBLIC_URL + "/image/bug.png"} alt="bug" style={{ height: '350px' }} />
                    </Grid>
                </Grid>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}

export default ErrorBoundary