import React from 'react'
import defaultImg from "../images/room-1.jpeg";
import SimpleImageSlider from "react-simple-image-slider";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    ImageTakeAway: {
        width: '100px !important',
        height: '100px',
        objectFit: 'cover',
        display: 'block',
        borderRadius: '50%',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    ImageDefault: {
        width: '100%',
        height: '180px',
        objectFit: 'cover',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    Image: {
        "max- width": '20%',
        width: 'auto',
        height: '214.88px',
        display: 'block',
        objectFit: 'cover',
        objectPosition: 'center center',
        "margin-left": 'auto',
        "margin-right": 'auto',
    }
}));

export const SlideShowComponent = (props) => {
    const classes = useStyles();
    const { slideShow, isTakeaway } = props;

    if (!slideShow || slideShow === null) {
        return (
            <img src={defaultImg} alt="Default Resource Image" className={isTakeaway ? classes.ImageTakeAway : classes.Image} />
        )
    }

    var replaceInArray = function (arr) {
        arr = arr.replace(/\s+/g, "%20")
        return arr.replace(/'/g, '%27');
    }

    const slides = slideShow.imageList.map(replaceInArray)
    return (
        <SimpleImageSlider
            width={"100%"}
            height={214.88}
            images={slides}
            autoPlay={slideShow.autoPlay}
            autoPlayDelay={slideShow.autoPlayDelay}
            loop={slideShow.loop}
            showBullets={slideShow.showBullets}
            showNavs={slideShow.showNavs}
            startIndex={slideShow.startIndex}
        />
    )
}

export default SlideShowComponent