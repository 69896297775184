import React, { Component } from "react";
import ContentContainer from "./ContentContainer";

import { Box, Grid, Text, Button, InfiniteScroll, Anchor, Drop } from "grommet";

import { FeatureEmoji } from "./emojis/FeatureEmoji";
import { AgendaItemDialog } from "./AgendaItemDialog";
import ToolTip from './ToolTip';

export const Agenda = (props) => {
    const { booking, canEdit , onSave} = props;
    const [agendaEditItem, setAgendaEdit] = React.useState();

    function doAddOrEdit(editItem) {
        let newAgenda = booking.agendaItems;
        if (editItem.id != null) {
            const index = newAgenda.findIndex(a => a.id == editItem.id);
            newAgenda[index] = editItem;
        }
        else {
            newAgenda.push(editItem);
        }

        saveAgendaChange(newAgenda);
        
    }

    function deleteItem(editItem) {
        let newAgenda = booking.agendaItems.filter(i => i.id != editItem.id);

        saveAgendaChange(newAgenda);

    }

    function saveAgendaChange(newAgenda) {
        let newBookingObject = {
            ...booking,
            agendaItems: newAgenda
        };
        if (onSave) {
            onSave(newBookingObject);
        }
        setAgendaEdit(null);
    }

    return (
        <Box gap="large" width="xlarge" direction="column">
            <ContentContainer
                emoji=":page_facing_up:"
                title="Agenda"
                height="large"
                content="Plan out your visit schedule..."
            >
                <Box>

                    {booking && booking.agendaItems && < InfiniteScroll items={booking.agendaItems.sort(compare)}>
                        {(item) => (
                            <Box key={item.id ? item.id : item.startTime}>
                                <Box
                                   
                                    pad="xxsmall"
                                    alignSelf="start"
                                    margin="small"
                                    justify="evenly"
                                    direction="row"
                                    fill
                                >
                                    <Grid
                                        areas={[
                                            { name: 'Icon', start: [0, 0], end: [0, 0] },
                                            { name: 'Time', start: [1, 0], end: [1, 0] },
                                            { name: 'Duration', start: [2, 0], end: [2, 0] },
                                            { name: 'Description', start: [3, 0], end: [3, 0] },
                                            { name: 'buttons', start: [4, 0], end: [4, 0] },
                                        ]}
                                        columns={['xxsmall', 'xsmall', 'xsmall', 'small', 'small']}
                                        rows={['xxsmall']}
                                        gap='small'
                                        fill='horizontal'
                                        margin="xsmall"
                                        background="accent-1"
                                    >
                                        <Box align="start" gridArea='Icon' >
                                            <ToolTip tip={item.entryType}> <AgendaIcon type={item.entryType} /></ToolTip>
                                        </Box>
                                        <Box align="start" gridArea='Time' >
                                            {canEdit ? (
                                                <ToolTip tip="Edit Agenda"><Anchor id={"agendaItem" + item.id} onClick={() => { setAgendaEdit(item) } }>
                                                    <Text >{item.startTime}</Text>
                                                </Anchor>
                                                </ToolTip>) : <Text >{item.startTime}</Text>}
                                        </Box>
                                        <Box align="start" gridArea='Duration'  >
                                            <Text >{"(" + item.duration + " min)"}</Text>
                                        </Box>
                                        <Box align="start" gridArea='Description'  >
                                            <Text >{item.description}</Text>
                                        </Box>

                                    </Grid>
                                </Box>
                            </Box>


                        )}
                    </InfiniteScroll>}


                    {canEdit && <Box align="end" pad={{ top: "small" }} >
                        <Button id="btnAddItem" primary label="Add Item" onClick={() => { setAgendaEdit(true) }} />
                    </Box>}
                </Box>
            </ContentContainer>
            <AgendaItemDialog
                open={agendaEditItem}
                item={agendaEditItem}
                onCancel={() => { setAgendaEdit(null) }}
                onSave={() => { doAddOrEdit(agendaEditItem) }}
                onDelete={() => { deleteItem(agendaEditItem)}}
                onChange={(state) => { setAgendaEdit(state)}}
            />
        </Box>
    );
}




export function compare(a, b) {
    const res = TimeStringinSeconds(a.startTime) - TimeStringinSeconds(b.startTime);
    return res;

    //if (Item1 < Item2) {
    //    return -1;
    //}
    //if (Item1 > Item2 ) {
    //    return 1;
    //}
    //// a must be equal to b
    //return 0;
}


export function TimeStringinSeconds(timeString) {
    if (!timeString) return 0;
    const timeSections = timeString.split(":");
    const result = (Number.parseInt(timeSections[0]) * 3600) + (Number.parseInt(timeSections[1]) * 60) + (timeSections.length > 2 ? Number.parseInt(timeSections[2]) : 0);
    return result;

}

export function AgendaIcon({ type }) {

    switch (type) {
        case "arrival":
            return (<FeatureEmoji name=":waving_white_flag:" size="30px" />);
            break;
        case "departure":
            return (<FeatureEmoji name=":chequered_flag:" size="30px" />);
            break;
        case "service":
            return (<FeatureEmoji name=":coffee:" size="30px" />);
            break;
        case "clear":
            return (<FeatureEmoji name=":broom:" size="30px" />);
            break;
        default:
            return (<FeatureEmoji name=":white_circle:" size="30px" />);
            break;
    }

}