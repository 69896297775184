import React, { Fragment, useEffect } from "react";
import Room from "./Room";
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles';
import { useWidgetContext } from "../widgetContext";
import BookingPickersWrapper from "./BookingPickersWrapper"
import Api from '../Api';
import BackButton from "./BackButton";
import NoSearchResults from "./NoSearchResults";


const useStyles = makeStyles(theme => ({
    lowerContent: {
        maxWidth: '574px'
    }
}));

const RoomsList = ({ rooms, addBookingDetail }) => {
    const classes = useStyles();
    const context = useWidgetContext();
    const [ResourceAvailability, setResourceAvailability] = React.useState([]);
    const [loading,setLoading] = React.useState(false);
    const occasionId = context.bookingRequest.occasionId;
    if (!rooms) return null;
    const bookingDate = context.bookingRequest.bookingDate.toISOString();

    useEffect(() => {
        setResourceAvailability([])
    }, [context.bookingRequest.bookingDate]);


    useEffect(() => {
        if (loading) return;
        if (rooms?.length > 0 && ResourceAvailability && (ResourceAvailability?.length === 0 || ResourceAvailability[0]?.date.substring(0, 10) !== bookingDate.substring(0, 10))) {
            console.log({
                rooms,
                ResourceAvailability,
                bookingDate
            })

            getResourceAvailability()
        }
    }, [rooms, ResourceAvailability, bookingDate]);



    const API = new Api(
        {
            urlRoot: window.location.origin + "/Dispace/Switch/1.0.0/BookingRequest/",
            //urlRoot: "https://switchdev.azurewebsites.net/Dispace/Switch/1.0.0/BookingRequest/"
            widgetKey: context.widgetKey
        });

    function getResourceAvailability() {
        const sourceKey = context.widgetKey;
        const startDate = context.bookingRequest.bookingDate.toISOString()
        const endDate = context.bookingRequest.bookingEndDate.toISOString();
        let resourceString;
        {
            rooms.map(room => {
                if (resourceString === undefined) {
                    resourceString = '&resourceId=' + room.id;
                } else {
                    resourceString = resourceString + '&resourceId=' + room.id;
                }
            })
        }
        const queryString = '?venueid=' + rooms[0].venueId + resourceString + '&startDate=' + startDate.substring(0, 19) + '&endDate=' + endDate.substring(0, 19) + '&occasionid=' + occasionId;
        setLoading(true)
        API.getResourceAvailability(sourceKey, queryString).then((result) => {
            setResourceAvailability(result);
            setLoading(false)
        });
    }

 

    return (
        <Grid container direction="row" justify="center" alignItems="center" spacing={4}>
            <BookingPickersWrapper matUiXl="12" />
            <BackButton matUiXl="0" margin="0px" />
            {rooms.length === 0 ?
                <NoSearchResults title={"switch.widget.notfound.norooms"} classes={classes} />
                :
                <Fragment>
                    {rooms.map(item => {
                        return (
                            <Grid item xs={12} key={item.id} className={!context.showAvailabilityCalendar && classes.lowerContent}>
                                <Room key={item.id} room={item} addBookingDetail={addBookingDetail} context={context} resourceAvailability={ResourceAvailability} />
                            </Grid>
                        );
                    })}
                </Fragment>
            }
        </Grid>
    );
};

export default RoomsList;
