import React from "react";
import { Box, Layer, Anchor } from "grommet";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SelectResourceType } from "./SelectResourceType";
import { SelectResource } from "./SelectResource";
import { SelectLayout } from "./SelectLayout";
import { SelectQuantity } from "./SelectQuantity";
import { AddItemSummary } from "./AddItemSummary";
import { Dialog } from './Dialog'


export const AddToBookingDialog = (props) => {
    const { open, onCancel, resources, onSave, booking } = props;

    const defaultState = {
        onCancel: onCancel,
        resources: resources,
        resourceList: null,
        newItem: {
            "servingTime": null,
            "roomLayoutId": null,
            "id": null,
            "resourceId": null,
            "resourceType": null,
            "quantity": 1,
            "hireType": null,
            "unitCost": null,
            "discount": 0,
            "lineItemCost": 0,
            "tax": 0,
            "notes": "",
            "manualPricing": false,
            "menuSelections": [],
            "bookingDate": booking?.bookingDate,
            "linkTimesToHeader": true,
            "endTime": booking?.endTime,
            "startTime": booking?.startTime,
            "guests": booking?.guests
        },
        step: 1
    }
    const [addState, setAddState] = React.useState(defaultState);

    function contentText(step) {
        switch (step) {
            case 1:
                return "We have a range of extras to make your event perfect, What would you like to add ?";
            case 2:
                return "Select an item to add to your booking";
            case 3:
                return "Select your desired layout";
            case 4:
                return "Confirm quantity";
            default:
        }
    }

    function onBack() {
        let steps = 1;
        if (addState.step === 4 && addState.newItem.resourceType != "room") {
            steps = 2
        }
        setAddState({ ...addState, step: addState.step - steps });
    }


    function doCancel() {
        setAddState(defaultState);
        if (onCancel) {
            onCancel();
        }

    }

    function moveToStep(step) {
        setAddState({
            ...addState,
            step: step
        });
    }


    if (!open || !resources) return null;
    //return <Layer id="addBookingItem" onClose={onClose}>
    //    <Box direction="row-responsive" justify="center" align="center" pad="small" margin="small">
    //        <DialogContent {...addState} setState={setAddState} defaultState={defaultState} />
    //    </Box>
    //</Layer>;
    return <Dialog id="addBookingItem" dialogStyle="TabbedEditBox" emoji=":shopping_trolley:" title="Add to Booking" content={contentText(addState.step)} onCancel={doCancel} onBack={addState.step > 1 ? () => onBack() : false} onConfirm={addState.step == 4 ? () => moveToStep(5) : null} onSave={addState.step == 5 ? () => onSave(addState.newItem) : null}>
        <Box direction="column" justify="between" align="start" >
            <DialogContent {...addState} setState={setAddState} defaultState={defaultState} />
        </Box>
    </Dialog >

}


function DialogContent(props) {

    const { step, setState, onClose, defaultState, resources, newItem } = props;




    function setType(type) {

        switch (type) {
            case 1: //  Room
                setState({ ...props, resourceList: resources.rooms, step: 2 });
                break;
            case 2: // Food
                setState({ ...props, resourceList: resources.foodandbeverage, step: 2 });
                break;
            case 3: // Equipment
                setState({ ...props, resourceList: resources.equipment, step: 2 });
                break;
            case 4: // Parking
                setState({ ...props, resourceList: resources.parking, step: 2 });
                break;
            case 5: // Accomodation
                setState({ ...props, resourceList: resources.accommodation, step: 2 });
                break;
        }
    }


    function setResource(resourceItem) {
        const nextStep = resourceItem.resourceType === "Room" && resourceItem.layouts.length > 0 ? 3 : 4;

        setState({
            ...props,
            step: nextStep,
            newItem: {
                ...props.newItem,
                resourceId: resourceItem.id,
                resourceType: resourceItem.resourceType,
            },
            minQty: resourceItem.minBookingQty,
            maxQty: resourceItem.maxBookingQty,
        });
    }

    function setLayout(layoutId) {
        setState({
            ...props,
            step: 5,
            newItem: {
                ...newItem,
                roomLayoutId: layoutId
            }
        });
    }
    function setItem(editItem) {
        setState({
            ...props,
            newItem: {
                ...editItem,
            }
        });
    }

    switch (step) {
        case 1:
            return <SelectResourceType id="selectResourceType" {...props} resources={resources} onClick={(type) => setType(type)} />
            break;
        case 2:
            return <SelectResource id="selectResource" {...props} onClick={(resource) => setResource(resource)} />
            break;
        case 3:
            return <SelectLayout id="selectLayout" {...props} onClick={(id) => setLayout(id)} roomList={resources.rooms} newItem={newItem} />
            break;
        case 4:
            return <SelectQuantity id="selectQuantity" {...props} onChange={(update) => setItem(update)} newItem={newItem} min={props.minQty} max={props.maxQty} />
            break;
        case 5:
            return <AddItemSummary id="addItemSummary"  {...props} newItem={newItem} />
            break;
    }
}

