import React from "react";
import defaultImg from "../images/default_layout_image.jpg";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
    Image: {
        "max- width": '20%',
        width: 'auto',
        height: '180px',
        display: 'block',
        "margin-left": 'auto',
        "margin-right": 'auto'
    }
}));


const RoomLayout = ({ layout, addBookingDetail, room }) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    if (!room) {return null}
    const { name, capacitySeated, capacityStanding, image } = layout;

    return (

        <Grid item xs={12} md={4} className="room-layout">
            <div id="onClickAdd" className="img-container" onClick={() => addBookingDetail(room.id, room.venueId, undefined, undefined, undefined, undefined, layout.id)} style={{cursor:'pointer'}}>
                <img src={image !== null ? image.imageUrl : defaultImg} alt="room layout image" className={classes.Image} />
                {(capacitySeated > 0 || capacityStanding > 0) &&
                <div className="price-top">
                        {capacitySeated >= 1 && <h6> { t("switch.widget.layout.seated")}{capacitySeated}</h6>}
                        {capacityStanding >= 1 && <h6>  {t("switch.widget.layout.standing")} {capacityStanding} </h6>}
                </div>
                }
            </div>
            <Typography variant="body2" className="room-layout-title" >
                {name}
            </Typography>
        </Grid>
    );
};


export default RoomLayout;
