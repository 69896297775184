import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grommet, Box, InfiniteScroll, Button, Text, Anchor, Avatar, Paragraph, TextInput, FormField, ThemeContext
} from "grommet";
import { FormLock, MailOption, FormSearch } from 'grommet-icons';
import { customTheme } from "./theme";
import { deepMerge } from 'grommet/utils';
import { useGuestPortal } from '../contexts/GuestPortalContext';

function Copyright() {
    return (
        <Text color="dark-4" align="center" size="small">
            {'Copyright © '}
            <Anchor color="dark-4" href="https://switch.dispace.co/">
                Switch by Dispace
      </Anchor>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Text>
    );
}

const formTheme = {
    formField: {
        border: {
            error: {
                color: 'border',
            },
            color: 'border',
            side: 'all',
        },
        error: {
            background: {
                color: { light: '#FF404033', dark: '#FF40404D' },
            },
            size: 'xsmall',
            color: 'text-weak',
            margin: {
                start: 'none',
            },
        },
        label: {
            size: 'xsmall',
            color: 'text-weak',
            margin: {
                horizontal: 'none',
            },
        },
        round: '4px',
    },
}

const FormFieldLabel = props => {
    const { required, label, ...rest } = props;
    return (
        <ThemeContext.Extend value={formTheme}>
            <FormField
                label={
                    required ? (
                        <Box direction="row">
                            <Text >{label}</Text>
                            <Text color="status-critical">*</Text>
                        </Box>
                    ) : (
                            label
                        )
                }
                required={required}
                {...rest}
            />
        </ThemeContext.Extend>
    );
};


const spinning = (
    <svg
        version="1.1"
        viewBox="0 0 32 32"
        width="24px"
        height="24px"
        fill="#7D4CDB"
    >
        <path
            opacity=".25"
            d="M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28 A12 12 0 0 1 16 4"
        />
        <path d="M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z">
            <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 16 16"
                to="360 16 16"
                dur="0.8s"
                repeatCount="indefinite"
            />
        </path>
    </svg>
);

export const Spinner = () => (
    <Box align="center" justify="center">
        {spinning}
    </Box>
);

export default function SignIn() {
    const { bookingRef, setBookingRef, email, setEmail, loading, errorText, doSignIn } = useGuestPortal();
       
    return (
        <Grommet theme={customTheme} full>
            <Box align="center" pad="medium">
                <Box width="large">
                    <Box align="center" direction="column" gap="small">
                        <Avatar background="brand">
                            <FormLock />
                        </Avatar>
                        <Text size="large" weight="bold" >Sign in</Text>
                    </Box>


                    <Box alignSelf="center" width="medium" direction="column" gap="small">
                        <Paragraph color="dark-1" margin={{ top: "large", bottom: "small" }}>Please enter your reference and E-mail address so we can find your booking</Paragraph>

                        <FormFieldLabel label="Email Address" htmlFor="tiEmail" required>
                            <Box
                                width="large"
                                direction="row"
                                align="center"
                                pad={{ horizontal: 'small', vertical: 'xsmall' }}
                            >

                                <MailOption color="brand" />
                                <TextInput
                                    plain
                                    autoComplete="email"
                                    label="Email Address"
                                    name="email"
                                    id="tiEmail"
                                    placeholder="Enter your email address..."
                                    value={email}
                                    onChange={(event) => { setEmail(event.target.value) }}
                                />
                            </Box>
                        </FormFieldLabel>

                        <FormFieldLabel label="Booking Reference" htmlFor="bookingref" required>
                            <Box
                                width="large"
                                direction="row"
                                align="center"
                                pad={{ horizontal: 'small', vertical: 'xsmall' }}
                            >
                                <FormSearch color="brand" />
                                <TextInput
                                    plain
                                    placeholder="booking ref e.g ABC-B-99 ..."
                                    name="bookingref"
                                    id="bookingref"
                                    value={bookingRef}
                                    onChange={(event) => { setBookingRef(event.target.value) }}
                                />
                            </Box>
                        </FormFieldLabel>

                        {errorText ?
                            <Box alignSelf="center" width="medium" direction="column" gap="small" background={{
                                color: { light: '#FF404033', dark: '#FF40404D' }
                            }}>
                                <Text margin="small" id="txtError">{errorText}</Text>
                            </Box>

                            : null}

                        <Text margin={{ left: 'small' }} size="small" color="status-critical">
                            * Required Field
        </Text>
                        <Box alignSelf="center" width="small" direction="column" gap="small">
                            <Button id="btnConfirm" primary label={
                                loading ? (
                                    <Box direction="row" gap="small">
                                        {" "}
                                        <Spinner /> <Text size="medium"> Loading... </Text>
                                    </Box>
                                ) : "Sign In"} onClick={!loading ? doSignIn : null} />

                        </Box>
                        <Box alignSelf="center" width="medium    " direction="column" gap="small">
                            <Copyright />
                        </Box>
                        <Box >

                        </Box>
                    </Box>
                </Box>
            </Box>
        </Grommet>
    );
}
