import * as React from 'react'
import { createContext, useContext, useState, useEffect } from 'react'
import { useWidgetContext } from '../widgetContext'
import { useTranslation } from 'react-i18next'
import start_of_tomorrow from "date-fns/startOfTomorrow";

export const useCheckout = () => {
    const context = useCheckoutContext()

    return context
}

export const CheckoutContext = createContext(undefined)

export const CheckoutContextProvider = ({ context, children, handleClose }) => {
    const { resetWidgetData, returnURL, questions, bookingRequest, requestBooking, booking, currentPaymentId, contactComplete, calculateRate } = useWidgetContext();
    const { t, i18n } = useTranslation();

    const [questionsToBeAnswered, SetQuestionsToBeAnswered] = useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [disable, setDisable] = React.useState(true);
    const [paymentInProgress, setPaymentInProgress] = React.useState(false);
    const [bookingRequested, setBookingRequested] = React.useState(false);

    const steps = [
        t("switch.widget.checkout.step1"),
        t("switch.widget.checkout.step2"),
        t("switch.widget.checkout.step3"),
        t("switch.widget.checkout.step5"),
        t("switch.widget.checkout.step4"),
    ];

    // Use Effects
    useEffect(
        () => {
            SetQuestionsToBeAnswered(questions.length > 0);
        }, [questions]);

    useEffect(
        () => {
            setDisable(PerfromStepvalidation());
        },
        [currentPaymentId, contactComplete, booking, activeStep, questions]
    );


    // Conext Functions

    function handleComplete() {
        if (returnURL === null || returnURL === "" || returnURL === "undefined") {
            resetWidgetData();
        }
        else {
            window.location.replace(returnURL);
        }

    };



    function isCardPaymentRequired() {
        const cardPayments = ["stripe", "windCave", "pay360SCP", "wpm"]

        if (
            booking.hasOwnProperty("paymentSchedule") &&
            booking.paymentSchedule.length > 0
        ) {
            let PaymentDateRef = start_of_tomorrow();
            let payment = booking.paymentSchedule.filter(
                p =>
                    new Date(p.dueDate) <= PaymentDateRef &&
                    (cardPayments.indexOf(p.paymentMethod.processingType) > -1) &&
                    p.paymentStatus === "awaitingPayment"
            );
            if (payment.length > 0) {
                return true;
            }
        } else {
            return false;
        }
    };

    function PerfromStepvalidation() {
        switch (activeStep) {
            case 0:
                //
                //let booking = requestBooking();
                const { EnquiryContact } = bookingRequest;
                if (
                    EnquiryContact.firstName.length === 0 ||
                    EnquiryContact.lastName.length === 0 ||
                    EnquiryContact.mobile.length === 0 ||
                    EnquiryContact.email.length === 0
                    // EnquiryContact.address1.length === 0 ||
                    // EnquiryContact.address2.length === 0 ||
                    // EnquiryContact.city.length === 0 ||
                    // EnquiryContact.postCode.length === 0
                ) {
                    return true;
                }
                return false;
            case 1:
                return false;
            case 2:
                if (
                    typeof booking != "undefined" &&
                    booking.hasOwnProperty("id")
                ) {
                    setPaymentInProgress(false);
                    return false;
                }
                if (!bookingRequested) {
                    setBookingRequested(true);
                    requestBooking();
                }
                return true;
            case 3:
                if (questionsToBeAnswered) {
                    return true;
                } else {
                    setActiveStep(activeStep + 1);
                }
                return false;
            case 4:

                if (isCardPaymentRequired()) {
                    return true;
                } else {
                    setActiveStep(activeStep + 1);
                }
                return false;

        }
    };

    function Next() {
        activeStep === 0 ? calculateRate() : null
        setActiveStep(activeStep + 1);
    };

    function Back() {
        setActiveStep(activeStep - 1);
    };


    let contextData = {
        activeStep,
        disable,
        paymentInProgress,
        steps,
        handleComplete,
        Next,
        Back,
        setBookingRequested,
        handleClose,
        ...context,
    }

    return (
        < CheckoutContext.Provider value={contextData} >
            {children}
        </CheckoutContext.Provider >
    )
}

export const useCheckoutContext = () => useContext(CheckoutContext)