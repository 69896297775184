import React, { } from "react"
import { useEffect } from 'react'
import { usePayment } from "../../../contexts/PaymentContext";
import { Typography } from '@mui/material';
import { useTranslate } from 'react-admin';

const SubmitButton = () => {
    const { paymentRecord } = usePayment();

    useEffect(() => {
        if (!paymentRecord?.sessionData) return;
        document.redirectToPayment.submit()
    }, [JSON.stringify(!paymentRecord?.sessionData)]);

    return (<></>)

}
export default function PaymentRedirect() {
    const translate = useTranslate()
    const {
        processing,
        paymentRecord,
        SetWPMRedirect
    } = usePayment();


    if (processing) {
        return (
            <>
                <Typography align='center' sx={{ p: 2, }}>{translate('switch.portal.paymentslist.preparingpaymentdata')}</Typography>
            </>
        )
    }
    if (!paymentRecord) return;
    return (<>
        <form name="redirectToPayment" action={paymentRecord.redirectURL} method="post">
            <textarea hidden="hidden" id="testXML" cols="80" rows="20" name="xml">{SetWPMRedirect(paymentRecord?.sessionData)}</textarea>
            <SubmitButton />
        </form>
    </>
    )

}
