import React from "react";
import { usePayment } from "../contexts/PaymentContext";
import StripePaymentForm from "./StripePaymentForm";
import WindCavePaymentForm from "./WindCavePaymentForm";
import HostedPaymentPage from "./HostedPaymentPage";
import WPMRedirect from "./WPMRedirect";


export default function PaymentForm({ context }) {
    const {
        processingType,
    } = usePayment();



    switch (processingType) {
        case "stripe":
            return (<StripePaymentForm context={context} />);
            break;
        case "pay360SCP":
            return (<HostedPaymentPage context={context} />)
            break;
        case "windCave":
            return (<WindCavePaymentForm context={context} />)
            break;
        case "WPM":
            return (<WPMRedirect context={context} />)
            break;
    }
}
