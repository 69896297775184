import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from 'react-i18next'

const drawerWidth = 480;

const useStyles = makeStyles((theme) => ({
    footer: {
        width: `calc(100% - ${drawerWidth}px)`,
        padding: theme.spacing(3, 2),
        marginTop: "auto",
        backgroundColor: theme.palette.grey[200],
    },
    footerEvent: {
        maxWidth: "960px",
        padding: theme.spacing(3, 2),
        margin: "auto",
        backgroundColor: theme.palette.grey[200],
    },
}));

const WidgetFooter = ({ context }) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    if (!context) return null;
    const { privacyPolicyDoc } = context;

    return (
        <footer
            className={
                context.style === "singleEvent" || context.style === "singleEventBanner"
                    ? classes.footerEvent
                    : classes.footer
            }
        >
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Typography variant="body2" color="textSecondary" align="center">
                            {t("switch.widget.footer.powerdby")}
                            <Link
                                href="https://switch.dispace.co"
                                target="_blank"
                                color="secondary"
                            >
                                {" "}
                                {t("switch.widget.footer.switch")}
                            </Link>
                            {" ©"}
                        </Typography>
                    </Grid>
                    {privacyPolicyDoc !== null && privacyPolicyDoc.documentUrl != "" ? (
                        <Grid item xs={4}>
                            <Typography variant="body2" color="textSecondary" align="center">
                                <Link
                                    href={privacyPolicyDoc.documentUrl}
                                    target="_blank"
                                    color="secondary"
                                >
                                    {" "}
                                    {t("switch.widget.footer.privacy")}
                                </Link>
                            </Typography>
                        </Grid>
                    ) : (
                        <></>
                    )}
                </Grid>
            </Container>
        </footer>
    );
};

export default WidgetFooter;
