import React from "react";
import { Box, Text, Grid, Button, Anchor } from "grommet";
import { CurrencyFormat } from "./CurrencyFormat";
import ToolTip from './ToolTip';
import { ResourceInfo } from "./ResourceInfo";
import { ConfirmDialog } from "./confirmDialog";
import { ResourceEditDialog } from "./ResourceEditDialog";



export function ResourceDetail(props) {
    const { id, item, resourceList, onSave, onDelete, canEdit} = props;

    const [editOpen, setEditOpen] = React.useState(false);
    const [editItem, setItem] = React.useState(item);
    const [ResourceView, SetResourceViewOpen] = React.useState(false);

    const resource = !resourceList ? null : resourceList.filter(r => r.id === item.resourceId)[0];
    const oldDetail = id.includes('bookingItemOLD') ? true : false;
    if (!resource ) return null;

    let resourceName = resource.name;
    if (item.resourceType === "Room" && item.roomLayoutId != null) {
        const layout = resource.layouts.filter(l => l.id === item.roomLayoutId)[0];
        resourceName = resourceName + " (" + (layout ? layout.name : 'Select A Layout') + ")";
    }

    function doSave(newItem) {
        if (onSave) {
            onSave(newItem);
        }
        setEditOpen(false);
    }
    if (oldDetail && !item.doNotReplace) return null;
    return (<Box key={editItem.id}>
        <ResourceInfo open={ResourceView} onClose={() => { SetResourceViewOpen(false); }} resourceName={resourceName} resource={resource} />

        <Grid fill areas={[
            { name: 'Header', start: [0, 0], end: [3, 0]},
            { name: 'Qty', start: [0, 1], end: [0, 1] },
            { name: 'Description', start: [1, 1], end: [1, 1] },
            { name: 'Price', start: [2, 1], end: [2, 1] },
            { name: 'Buttons', start: [3, 1], end: [3, 1] },
        ]} columns={['small', 'medium', 'small', 'flex']} rows={['xxsmall','xsmall']} margin="small"  align="center">
            <Box gridArea='Header'>
                {oldDetail &&
                    <Text size="large">Resource being replaced.</Text>
                }
            </Box>
            <Box gridArea='Qty'>
                {!oldDetail &&
                    <Box height="50px" width="50px" border={{ color: 'brand', size: 'small' }} align="center" justify="center">
                        <Text size="large">{item ? item.quantity : 0}</Text>
                    </Box>
                }
            </Box>

            <Box gridArea='Description'>
                <ToolTip tip="view details"> <Anchor id="resourceView" onClick={() => { SetResourceViewOpen(true); }}> <Text size="large">{resourceName}</Text></Anchor></ToolTip>
            </Box>

            <Box gridArea='Price'>
                <Text size="large" textAlign="end">{CurrencyFormat(editItem.lineItemCost)}</Text>
            </Box>
            {!oldDetail &&
                <React.Fragment>
                    <Box align="end" size="small">
                        <Button id="btnEdit" primary disabled={!canEdit} label="Edit" onClick={() => { setEditOpen(true); }} />
                    </Box> 
                        <ResourceEditDialog
                            open={editOpen}
                            item={editItem}
                            resourceList={resourceList}
                            onCancel={() => { setEditOpen(false); }}
                            onSave={doSave}
                            onDelete={onDelete}
                        />
                </React.Fragment>
            }

        </Grid>
    </Box>);
}


