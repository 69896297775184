import React from "react";
import { Box, InfiniteScroll, Heading } from "grommet";
import { CourseSelector } from "./CourseSelector";
import { compare } from "./CourseSelector";


export const MenuSelector = (props) => {
    const { guest, booking, resources, onChange , enabled } = props;
    const [choices, setSelections] = React.useState(guest && guest.menuSelections ? guest.menuSelections : []);
    const leadBooker = guest.contactId == booking.contactId;
    function addSelection(selection) {
        choices.push({
            ...selection,
            bookingGuestId: guest.id
        });

        setSelections(choices);


        if (onChange) {
            onChange(choices);
        }
    }

    function removeSelection(selection) {
        let newChoices = choices.filter(item =>
            item.menuId !== selection.menuId ||
            item.courseId !== selection.courseId ||
            item.menuItemId !== selection.menuItemId
        );

        setSelections(newChoices);

        if (onChange) {
            onChange(newChoices);
        }
    }

    const bookingFood = !booking ? null : booking.details.filter(d => d.resourceType === "FoodAndBeverage");
    const bookedResource = !resources ? null : resources.foodandbeverage.filter(r => bookingFood.map(f => f.resourceId).includes(r.id) && r.menu != null && (leadBooker || r.menu.individualGuestSelection == true));

    return (<Box align="center" justify="center" direction="column" pad="small">
        <Box align="center" justify="center" width="large" direction="column" pad="medium">
        {bookedResource && <InfiniteScroll items={bookedResource}>
            {(food) => (<Box flex="grow" key={food.id}>
                <Box fill="horizontal" round="small" elevation="medium" align="center" background="light-3" border={{
                    "color": "brand",
                    "size": "medium",
                    "side": "all"
                }}>
                    <Heading level="3">{food.name}</Heading>
                </Box>

                <InfiniteScroll items={food.menu.courses.sort(compare)}>
                    {(course) => (<CourseSelector key={course.id} course={course} selectionData={{ menuId: food.menu.id, courseId: course.id, detail: course.name }} onAdd={addSelection} onRemove={removeSelection} choices={choices} enabled={enabled}/>)}
                </InfiniteScroll>

            </Box>

            )
            }
        </InfiniteScroll>}
        </Box>
    </Box>
    );
};
