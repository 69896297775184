import React from "react";
import { usePayment } from "../contexts/PaymentContext";
import StripePayButton from "./StripePayButton";
import HostedPaymentPayButton  from "./HostedPaymentPayButton";

export default function PayButton() {
    const {
        processingType,
        showIFrame
    } = usePayment();

    switch (processingType) {
        case "stripe":
            return (<StripePayButton />);
            break;
        case "WPM":
        case "pay360SCP":
        case "windCave":
            if (showIFrame) return;
            return (<HostedPaymentPayButton  />)
            break;
    }
}
