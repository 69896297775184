import React from "react";
import PropTypes from "prop-types";
import { Box, Drop} from "grommet";

export const ToolTip = ({ children, tip, ...rest }) => {
    const [over, setOver] = React.useState();
    const ref = React.useRef();
    
    return (
        <Box
            ref={ref}
            onMouseOver={() => setOver(tip != null && true)}
            onMouseOut={() => setOver(false)}
            onFocus={() => { }}
            onBlur={() => { }}
        >

            {children}
            {
                ref.current && over && (<Drop align={{ left: "right" }} target={ref.current} plain>
                    <Box
                        margin="xsmall"
                        width="small"
                        pad="small"
                        background="accent-4"
                        round={{ size: "medium", corner: "left" }}
                        id="tip"
                    >
                        {tip}
                    </Box>
                </Drop>)

            }</Box>)
}


ToolTip.propTypes = {
    tip: PropTypes.string,
    children: PropTypes.node.isRequired
};

export default ToolTip;
