import React from "react";
import {
  Box,
  Button,
  TextInput,
  Text,
  Layer,
  FormField,
  ThemeContext,
  Select
} from "grommet";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import ContentContainer from "./ContentContainer";
import TimeInput from "./TimeInput";
import styled from "styled-components";
import { Dialog } from "./Dialog";
import ToolTip from "./ToolTip";
import sub from "date-fns/sub";

const formTheme = {
  formField: {
    border: {
      error: {
        color: "border"
      },
      color: "border",
      side: "all"
    },
    error: {
      background: {
        color: { light: "#FF404033", dark: "#FF40404D" }
      },
      size: "xsmall",
      color: "text-weak",
      margin: {
        start: "none"
      }
    },
    label: {
      size: "xsmall",
      color: "text-weak",
      margin: {
        horizontal: "none"
      }
    },
    round: "4px"
  }
};

const FormFieldLabel = props => {
  const { required, label, ...rest } = props;
  return (
    <ThemeContext.Extend value={formTheme}>
      <FormField
        label={
          required ? (
            <Box direction="row">
              <Text>{label}</Text>
              <Text color="status-critical">*</Text>
            </Box>
          ) : (
            <Text>{label}</Text>
          )
        }
        required={required}
        {...rest}
      />
    </ThemeContext.Extend>
  );
};

function DefaultStartTime(bookingStart) {
  const date = new Date(0);
  const HH = bookingStart.split(":")[0];
  const MM = bookingStart.split(":")[1];
  const SS = bookingStart.split(":")[2];
  date.setSeconds(SS);
  date.setMinutes(MM);
  date.setHours(HH);
  const result = sub(date, {
    minutes: 5
  });

    return result.toLocaleTimeString('en-GB');
}

export function GuestInvite(props) {
  const { open, onCancel, onSend, portalSettings, bookingStart } = props;
  const [local, setLocals] = React.useState({
    contact: {
      firstName: "",
      lastName: "",
      email: "",
      mobile: ""
    },
    description: "",
    arrivalTime: DefaultStartTime(bookingStart),
    message: portalSettings ? portalSettings.guestInviteTemplate : ""
  });

  function handleContactChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setLocals({
      ...local,
      contact: {
        ...local.contact,
        [name]: value
      }
    });
  }

  function handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setLocals({
      ...local,
      [name]: value
    });
  }

  function handleMessageChange(value) {
    setLocals({
      ...local,
      message: value
    });
  }

  const BorderLessTimeInput = styled(TimeInput)`
  border-style: none;
.bviFhB {
 font:inherit;
 background-color:rgba(255,255,255,1);
 border:1px solid rgba(164,167,181,1);
 border-radius:12rem;
 width:100%;
 -webkit-transition:all 0.1s linear,padding 0s,border 0s;
 transition:all 0.1s linear,padding 0s,border 0s;
 display:inline-block;
 padding:0 1rem;
 line-height:2.5rem;
 height:2.5rem;
 color:rgba(42,48,57,1);
 font-size:1rem;
 box-sizing:border-box;
 margin:0;
 padding-left:1rem;
 padding-right:2.35rem;
 border-style: none;
}

`;

  if (!open) return null;

  return (
    <Dialog
      id="guestInviteDialog"
      dialogStyle="EditBox"
      emoji=":man_in_tuxedo:"
      title="Invite Guest"
      content="Please provide contact details for your guest and we will email them with details for signing on for your event"
      onCancel={onCancel}
      onSend={() => {
        onSend(local);
      }}
    >
      <Box direction="column">
        <Box direction="column" flex="grow">
          <Box direction="row-responsive" justify="between">
            <FormFieldLabel label="First Name" htmlFor="tiFirstName" required>
              <TextInput
                id="tiFirstName"
                name="firstName"
                value={local.contact.firstName}
                onChange={handleContactChange}
              />
            </FormFieldLabel>
            <FormFieldLabel label="Last Name" htmlFor="tiLastName" required>
              <TextInput
                id="tiLastName"
                name="lastName"
                value={local.contact.lastName}
                onChange={handleContactChange}
              />
            </FormFieldLabel>
          </Box>
          <Box direction="row-responsive" justify="between">
            <FormFieldLabel label="Email" htmlFor="tiEmail" required>
              <TextInput
                id="tiEmail"
                name="email"
                type="email"
                value={local.contact.email}
                onChange={handleContactChange}
              />
            </FormFieldLabel>
            <FormFieldLabel label="Mobile" htmlFor="tiMobile">
              <TextInput
                id="tiMobile"
                name="mobile"
                value={local.contact.mobile}
                onChange={handleContactChange}
              />
            </FormFieldLabel>
          </Box>
          <Box direction="row-responsive" justify="between">
            <FormFieldLabel label="Guest Type" htmlFor="tiDescription">
              <Select
                id="tiDescription"
                name="description"
                value={local.description}
                onChange={event =>
                  handleChange({
                    target: { name: "description", value: event.value }
                  })
                }
                options={["Guest", "Lead Booker", "Supplier", "Presenter"]}
              />
            </FormFieldLabel>
            <FormFieldLabel label="Arrival Time" htmlFor="tiArrivalTime">
              <BorderLessTimeInput
                id="tiArrivalTime"
                name="arrivalTime"
                value={local.arrivalTime}
                onChange={value => {
                  handleChange({
                    target: { name: "arrivalTime", value: value }
                  });
                }}
              />
            </FormFieldLabel>
          </Box>
          <FormFieldLabel label="Message" htmlFor="tiMessage" required>
            <Box direction="row-responsive" height="small" overflow="auto">
              <ReactQuill
                theme="bubble"
                id="tiMessage"
                name="message"
                value={local.message}
                onChange={handleMessageChange}
              />
            </Box>
          </FormFieldLabel>
        </Box>
      </Box>
    </Dialog>
  );
}
